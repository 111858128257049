import React, { PropsWithChildren } from 'react';
import { Modal as B_Modal } from 'react-bootstrap';
import { createIntl } from 'react-intl';
import { getLanguage } from '@skiwo/utils';
import classnames from 'classnames';
import Button, { ButtonVariant } from '../Button/Button';
import languages from '../translations/languages';
import translationKeys from '../translations/translationKeys';
import styles from './Modal.module.scss';

interface Props {
  title: React.ReactNode;
  description?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  show?: boolean;
  onHide?: () => void;
  'data-testid'?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  submitButtonVariant?: ButtonVariant;
  submitButtonText?: string;
  isSubmitting?: boolean;
  isSubmitDisabled?: boolean;
}

const Modal = (props: PropsWithChildren<Props>) => {
  const {
    show,
    onHide,
    onCancel,
    onSubmit,
    submitButtonText,
    submitButtonVariant = 'primary',
    title,
    description,
    size = 'medium',
    children,
    isSubmitting,
    isSubmitDisabled,
  } = props;

  const userLanguage = getLanguage();
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });

  /**
   * React bootstrap doesn't support the "md" size, so we're mapping the medium
   * size to "lg", and the large to "xl" since we won't be needing the "xl"
   * size.
   */
  const getSize = () => {
    switch (size) {
      case 'small':
        return 'sm';
      case 'large':
        return 'xl';
      default:
        return 'lg';
    }
  };

  return (
    <B_Modal
      show={show}
      onHide={onHide}
      data-testid={props['data-testid']}
      centered
      className={styles.modal}
      size={getSize()}
    >
      <B_Modal.Header
        closeButton
        className={classnames(styles.modalHeader, { [styles.hasDescription]: !!description })}
      >
        <B_Modal.Title data-testid="modal-title">{title}</B_Modal.Title>
      </B_Modal.Header>
      <B_Modal.Body
        className={classnames(styles.modalBody, { [styles.hasDescription]: !!description })}
      >
        <div data-testid="modal-description" className={styles.description}>
          {description}
        </div>

        {children}
      </B_Modal.Body>
      <B_Modal.Footer
        className={classnames(styles.modalFooter, {
          [styles.noModalFooter]: !(onCancel && onSubmit),
        })}
      >
        {onCancel && (
          <Button data-testid="modal-cancel-button" variant="gray" size="large" onClick={onCancel}>
            {intl.formatMessage({ id: translationKeys.modal_cancel_button })}
          </Button>
        )}

        {submitButtonText && (
          <Button
            data-testid="modal-submit-button"
            variant={submitButtonVariant}
            size="large"
            onClick={onSubmit}
            isLoading={isSubmitting}
            disabled={isSubmitDisabled}
          >
            {submitButtonText}
          </Button>
        )}
      </B_Modal.Footer>
    </B_Modal>
  );
};

export default Modal;

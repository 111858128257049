import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '@skiwo/components';
import { useFormikContext } from 'formik';
import { getTypesafeSetFieldValue } from '../../../../helpers/getTypesafeSetFieldValue';
import translationKeys from '../../../../translations/translationKeys';
import { StatementFormValues } from '../Statement';
import styles from './StatementForm.module.scss';

interface NotPerformeredAsOrderedRowProps {
  isFeedbackLocked: boolean;
  readonly?: boolean;
}

export const NotPerformeredAsOrderedRow = ({
  isFeedbackLocked,
  readonly,
}: NotPerformeredAsOrderedRowProps) => {
  const intl = useIntl();
  const formikProps = useFormikContext<StatementFormValues>();
  const { values } = formikProps;
  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  const isPercentageRange = (value: string) => {
    const number = parseInt(value);
    return number >= 0 && number <= 100;
  };

  return (
    <tr>
      <td className={styles.rowName}>
        <FormattedMessage id={translationKeys.job_statement_not_performed_as_ordered} />
      </td>
      <td>-</td>
      <td>-</td>
      {isFeedbackLocked && (
        <>
          <td>
            {readonly ? (
              `${values.demanderInvoiceDiscountPercentage || 0} ${intl.formatMessage({
                id: translationKeys.job_statement_discount,
              })}`
            ) : (
              <div className={styles.inputTextRow}>
                <div className={styles.input}>
                  <TextField
                    textAlign="right"
                    value={values.demanderInvoiceDiscountPercentage}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (isPercentageRange(value) || e.target.value === '') {
                        setFieldValue('demanderInvoiceDiscountPercentage', value);
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value === '')
                        setFieldValue('demanderInvoiceDiscountPercentage', '0');
                    }}
                    disabled={!isFeedbackLocked}
                  />
                </div>
                <span>
                  <FormattedMessage id={translationKeys.job_statement_discount} />
                </span>
              </div>
            )}
          </td>
          <td>
            {readonly ? (
              `${values.supplierInvoiceDiscountPercentage || 0} ${intl.formatMessage({
                id: translationKeys.job_statement_discount,
              })}`
            ) : (
              <div className={styles.inputTextRow}>
                <div className={styles.input}>
                  <TextField
                    textAlign="right"
                    value={values.supplierInvoiceDiscountPercentage}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (isPercentageRange(value) || e.target.value === '') {
                        setFieldValue('supplierInvoiceDiscountPercentage', value);
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value === '')
                        setFieldValue('supplierInvoiceDiscountPercentage', '0');
                    }}
                    disabled={!isFeedbackLocked}
                  />
                </div>
                <span>
                  <FormattedMessage id={translationKeys.job_statement_discount} />
                </span>
              </div>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export default NotPerformeredAsOrderedRow;

import { IntlShape } from 'react-intl';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobStatementDelayCausedBy } from '../../../../types/ManagerJobStatement';

export const getDelayCausedByLabel = (
  delayCausedBy: ManagerJobStatementDelayCausedBy,
  intl: IntlShape,
) => {
  switch (delayCausedBy) {
    case ManagerJobStatementDelayCausedBy.Demander:
      return intl.formatMessage({ id: translationKeys.job_statement_delay_caused_by_demander });

    case ManagerJobStatementDelayCausedBy.Supplier:
      return intl.formatMessage({ id: translationKeys.job_statement_delay_caused_by_supplier });
  }
};

import { IntlShape } from 'react-intl';
import { SearchDropdownMenuOption } from '@skiwo/components';
import { getBookingMechanismLabel } from '../../CreateInterpretationOrder/utils';
import { ManagerJobBookingMechanism } from '../../types';

export const getBookingMechanismOptions = (intl: IntlShape): SearchDropdownMenuOption[] => [
  {
    id: 1,
    label: getBookingMechanismLabel(ManagerJobBookingMechanism.Chat, intl),
    key: ManagerJobBookingMechanism.Chat,
  },
  {
    id: 2,
    label: getBookingMechanismLabel(ManagerJobBookingMechanism.Email, intl),
    key: ManagerJobBookingMechanism.Email,
  },
  {
    id: 3,
    label: getBookingMechanismLabel(ManagerJobBookingMechanism.Phone, intl),
    key: ManagerJobBookingMechanism.Phone,
  },
  {
    id: 4,
    label: getBookingMechanismLabel(ManagerJobBookingMechanism.Platform, intl),
    key: ManagerJobBookingMechanism.Platform,
  },
];

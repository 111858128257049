import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Support from './Support';

export const supportQueryKeys = {
  adminNotes: (id?: string) => (id ? (['adminNotes', id] as const) : ['adminNotes']),
};

export const useGetAdminNotesQuery = (id?: string) =>
  useQuery({
    queryKey: supportQueryKeys.adminNotes(id),
    queryFn: () => {
      if (!id) return null;
      return Support.getAdminChat(id).then((res) => res.data);
    },
    enabled: !!id,
  });

export const usePostAdminNoteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, note }: { id: string; note: string }) =>
      Support.createAdminChatMessage(id, note),
    onSuccess(_data, { id }) {
      queryClient.invalidateQueries({
        queryKey: supportQueryKeys.adminNotes(id.toString()),
      });
    },
  });
};

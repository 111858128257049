import React from 'react';
import { useIntl } from 'react-intl';
import {
  faEnvelope,
  faLanguage,
  faLocationDot,
  faPhone,
  faVenusMars,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DetailsCard } from '@skiwo/components';
import { formatPhoneNumber } from '@skiwo/utils';
import getFirstLetterCapitalized from '../../helpers/getFirstLetterCapitalized';
import translationKeys from '../../translations/translationKeys';
import { ManagerSupplier } from '../../types';

interface SupplierCardProps {
  supplier: ManagerSupplier;
}

const SupplierCard = ({ supplier }: SupplierCardProps) => {
  const intl = useIntl();

  const getAddress = () => {
    const address = supplier?.address;
    const addressComponents = [address?.city, address?.country].filter((component) => !!component);

    if (addressComponents.length === 0) return;

    return addressComponents.join(', ');
  };

  const interpretationAssignmentsCompleted = supplier.interpretation?.statistics.showedUp ?? 0;
  const translationAssignmentsCompleted = supplier.translation?.statistics.finished ?? 0;

  return (
    <DetailsCard
      avatarUrl={supplier.avatarUrl}
      title={supplier.name}
      subtitleItems={[
        { icon: <FontAwesomeIcon icon={faLocationDot} />, value: getAddress() },
        { icon: <FontAwesomeIcon icon={faEnvelope} />, value: supplier.email },
        {
          icon: <FontAwesomeIcon icon={faPhone} />,
          value: formatPhoneNumber(
            (supplier.phoneCode ? supplier.phoneCode + ' ' : '') + supplier?.phone,
          ),
        },
        {
          icon: <FontAwesomeIcon icon={faVenusMars} />,
          value: supplier.sex ? getFirstLetterCapitalized(supplier.sex) : undefined,
        },
        {
          icon: <FontAwesomeIcon icon={faLanguage} />,
          value: interpretationAssignmentsCompleted
            ? interpretationAssignmentsCompleted.toString()
            : undefined,
          tooltip: `${interpretationAssignmentsCompleted} ${intl.formatMessage({
            id: translationKeys.new_skill_drawer_interpretation_assignments_tooltip_title,
          })}`,
        },
        {
          icon: <FontAwesomeIcon icon={faEnvelope} />,
          value: translationAssignmentsCompleted
            ? translationAssignmentsCompleted.toString()
            : undefined,
          tooltip: `${translationAssignmentsCompleted} ${intl.formatMessage({
            id: translationKeys.new_skill_drawer_translation_assignments_tooltip_title,
          })}`,
        },
      ]}
    />
  );
};

export default SupplierCard;

import React from 'react';
import Badge from 'react-bootstrap/Badge';
import classnames from 'classnames';
import styles from './Tag.module.scss';

export type TagColor = 'success' | 'error' | 'info' | 'warning' | 'primary' | 'neutral';

interface Props {
  children: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  color?: TagColor;
  variant?: 'default' | 'square';
  'data-testId'?: string;
  className?: string;
}

const Tag = ({
  children,
  color = 'success',
  size = 'large',
  variant = 'default',
  className,
  ['data-testId']: dataTestId,
}: Props) => {
  return (
    <Badge
      bg=""
      className={classnames(styles.tag, styles[variant], styles[size], styles[color], className)}
      data-testid={dataTestId || 'tag'}
    >
      <span data-testid="tag-text">{children}</span>
    </Badge>
  );
};

export default Tag;

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchDropdown } from '@skiwo/components';
import { useFormikContext } from 'formik';
import { getTypesafeSetFieldValue } from '../../../../helpers/getTypesafeSetFieldValue';
import translationKeys from '../../../../translations/translationKeys';
import {
  ManagerJobStatementSupplierTravelType,
  ManagerJobStatementTravelType,
} from '../../../../types/ManagerJobStatement';
import { StatementFormValues } from '../Statement';
import { getSupplierTravelMethodLabel } from '../utils/getSupplierTravelMethodLabel';
import { getTravelMethodLabel } from '../utils/getTravelMethodLabel';
import styles from './StatementForm.module.scss';

interface TravelMethodRowProps {
  supplierFeedbackTravelType?: ManagerJobStatementSupplierTravelType;
  isFeedbackLocked: boolean;
  readonly?: boolean;
}

export const TravelMethodRow = ({
  supplierFeedbackTravelType,
  isFeedbackLocked,
  readonly,
}: TravelMethodRowProps) => {
  const intl = useIntl();
  const formikProps = useFormikContext<StatementFormValues>();
  const { values } = formikProps;
  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  return (
    <tr>
      <td className={styles.rowName}>
        <FormattedMessage id={translationKeys.job_statement_travel_method} />
      </td>
      <td></td>
      <td>
        <span>
          {supplierFeedbackTravelType
            ? getSupplierTravelMethodLabel(supplierFeedbackTravelType, intl)
            : '-'}
        </span>
      </td>
      {isFeedbackLocked && (
        <>
          <td>
            {readonly ? (
              values.demanderTravelType ? (
                getTravelMethodLabel(values.demanderTravelType, intl)
              ) : (
                '-'
              )
            ) : (
              <SearchDropdown
                placeholder={intl.formatMessage({
                  id: translationKeys.job_statement_select_placeholder,
                })}
                selectedKeys={values.demanderTravelType ? [values.demanderTravelType] : []}
                options={[
                  {
                    id: 1,
                    label: getTravelMethodLabel(ManagerJobStatementTravelType.None, intl),
                    key: ManagerJobStatementTravelType.None,
                  },
                  {
                    id: 2,
                    label: getTravelMethodLabel(ManagerJobStatementTravelType.Car, intl),
                    key: ManagerJobStatementTravelType.Car,
                  },
                  {
                    id: 3,
                    label: getTravelMethodLabel(
                      ManagerJobStatementTravelType.PublicTransport,
                      intl,
                    ),
                    key: ManagerJobStatementTravelType.PublicTransport,
                  },
                ]}
                onChange={(items) =>
                  items && items.length
                    ? setFieldValue(
                        'demanderTravelType',
                        items[0].key as ManagerJobStatementTravelType,
                      )
                    : setFieldValue('demanderTravelType', null)
                }
                disabled={!isFeedbackLocked}
              />
            )}
          </td>
          <td>
            {readonly ? (
              values.supplierTravelType ? (
                getTravelMethodLabel(values.supplierTravelType, intl)
              ) : (
                '-'
              )
            ) : (
              <SearchDropdown
                placeholder={intl.formatMessage({
                  id: translationKeys.job_statement_select_placeholder,
                })}
                selectedKeys={values.supplierTravelType ? [values.supplierTravelType] : []}
                options={[
                  {
                    id: 1,
                    label: getTravelMethodLabel(ManagerJobStatementTravelType.None, intl),
                    key: ManagerJobStatementTravelType.None,
                  },
                  {
                    id: 2,
                    label: getTravelMethodLabel(ManagerJobStatementTravelType.Car, intl),
                    key: ManagerJobStatementTravelType.Car,
                  },
                  {
                    id: 3,
                    label: getTravelMethodLabel(
                      ManagerJobStatementTravelType.PublicTransport,
                      intl,
                    ),
                    key: ManagerJobStatementTravelType.PublicTransport,
                  },
                ]}
                onChange={(items) =>
                  items && items.length
                    ? setFieldValue(
                        'supplierTravelType',
                        items[0].key as ManagerJobStatementTravelType,
                      )
                    : setFieldValue('supplierTravelType', null)
                }
                disabled={!isFeedbackLocked}
              />
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export default TravelMethodRow;

import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faArrowRight,
  faCheck,
  faEnvelope,
  faPaperPlaneTop,
  faRotateRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Chip, DetailItem, Drawer, Tooltip } from '@skiwo/components';
import classnames from 'classnames';
import { format } from 'date-fns';
import { useApi } from '../../../../providers/ApiProvider';
import { useLanguages } from '../../../../providers/LanguagesProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerOrder } from '../../../../types';
import styles from './OrderEmailsDrawer.module.scss';

enum EmailType {
  Posted = 'posted',
  InProgress = 'in_progress',
  Finished = 'finished',
  CcFinished = 'cc_finished',
  Cancelled = 'cancelled',
  SentByPost = 'sent_by_post',
}

enum EmailStatus {
  Sent = 'SENT',
  Pending = 'PENDING',
}

interface Props {
  show?: boolean;
  onUpdate: () => void;
  onClose: () => void;
  order: ManagerOrder;
}

const OrderEmailsDrawer = (props: Props) => {
  const { show, onUpdate, onClose, order } = props;
  const { getLanguageById } = useLanguages();
  const [loadingEmail, setLoadingEmail] = useState<EmailType | undefined>();
  const api = useApi();
  const intl = useIntl();
  const { showErrorToast } = useToast();

  const emailNames: Record<EmailType, string> = {
    [EmailType.Posted]: intl.formatMessage({
      id: translationKeys.translation_order_emails_order_received,
    }),
    [EmailType.InProgress]: intl.formatMessage({
      id: translationKeys.translation_order_emails_order_in_progress,
    }),
    [EmailType.Finished]: intl.formatMessage({
      id: translationKeys.translation_order_emails_order_completed,
    }),
    [EmailType.CcFinished]: intl.formatMessage({
      id: translationKeys.translation_order_emails_cc_order_completed,
    }),
    [EmailType.Cancelled]: intl.formatMessage({
      id: translationKeys.translation_order_emails_order_cancelled,
    }),
    [EmailType.SentByPost]: intl.formatMessage({
      id: translationKeys.translation_order_emails_send_by_post,
    }),
  };

  const emails = [
    {
      id: EmailType.Posted,
      subject: emailNames[EmailType.Posted],
      status: order.emails?.postedEmailSentAt ? EmailStatus.Sent : EmailStatus.Pending,
    },
    {
      id: EmailType.InProgress,
      subject: emailNames[EmailType.InProgress],
      status: order.emails?.inProgressEmailSentAt ? EmailStatus.Sent : EmailStatus.Pending,
    },
    {
      id: EmailType.Finished,
      subject: emailNames[EmailType.Finished],
      status: order.emails?.finishedEmailSentAt ? EmailStatus.Sent : EmailStatus.Pending,
    },
    {
      id: EmailType.CcFinished,
      subject: emailNames[EmailType.CcFinished],
      status: order.emails?.ccFinishedEmailSentAt ? EmailStatus.Sent : EmailStatus.Pending,
    },
    {
      id: EmailType.Cancelled,
      subject: emailNames[EmailType.Cancelled],
      status: order.emails?.cancelledEmailSentAt ? EmailStatus.Sent : EmailStatus.Pending,
    },
    // {
    //   id: EmailType.SentByPost,
    //   subject: emailNames[EmailType.SentByPost],
    //   status: order.emails.sentByPostEmailSentAt ? EmailStatus.Sent : EmailStatus.Pending,
    // },
  ];

  const getEmailItemSubtitle = (email: EmailType) => {
    let sentDate: string | undefined = undefined;
    if (email === EmailType.Posted) {
      sentDate = order.emails?.postedEmailSentAt;
    } else if (email === EmailType.InProgress) {
      sentDate = order.emails?.inProgressEmailSentAt;
    } else if (email === EmailType.Finished) {
      sentDate = order.emails?.finishedEmailSentAt;
    } else if (email === EmailType.CcFinished) {
      sentDate = order.emails?.ccFinishedEmailSentAt;
    } else if (email === EmailType.Cancelled) {
      sentDate = order.emails?.cancelledEmailSentAt;
    } else if (email === EmailType.SentByPost) {
      sentDate = order.emails?.sentByPostEmailSentAt;
    }

    if (sentDate) {
      return intl.formatMessage(
        {
          id: translationKeys.translation_order_emails_sent_at_label,
        },
        {
          timestamp: format(new Date(sentDate), 'dd.MM.yyyy, HH:mm'),
        },
      );
    }

    return intl.formatMessage({
      id: translationKeys.translation_order_emails_not_sent_label,
    });
  };

  const handleSendEmailLoading = (loading: boolean, email: EmailType) => {
    if (!loading) {
      setLoadingEmail(undefined);
    } else {
      setLoadingEmail(email);
    }
  };

  const sendEmail = async (email: EmailType) => {
    const { error } = await api.sendOrderEmail(order.id.toString(), email, (loading: boolean) =>
      handleSendEmailLoading(loading, email),
    );

    if (error) {
      showErrorToast(error);
    } else {
      onUpdate();
    }
  };

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({
        id: translationKeys.translation_order_emails_title,
      })}
    >
      <div className={styles.orderEmails}>
        <div className={styles.overview}>
          <span className={styles.id}>#{order.id}</span>
          <div className={styles.title}>
            <span>{getLanguageById(order.sourceLanguageId)}</span>
            <FontAwesomeIcon icon={faArrowRight} />
            <span>
              {order.tasks.map((task) => getLanguageById(task.targetLanguageId)).join(', ')}
            </span>
          </div>
          <span className={styles.description}>
            <FormattedMessage id={translationKeys.translation_order_emails_order_label} />
          </span>
        </div>

        <DetailItem
          title={order.owner?.name}
          subtitle={order.enterprise?.name}
          size="large"
          preview={<Avatar size="medium" altText="" url={order.owner?.avatar} />}
        >
          {order.owner?.email && (
            <Tooltip title={order.owner?.email} copyable>
              <div className={styles.detailsItemDetails}>
                <FontAwesomeIcon icon={faEnvelope} />
                <span>{order.owner?.email}</span>
              </div>
            </Tooltip>
          )}
        </DetailItem>

        {order.ccEmails && (
          <div>
            <span className={styles.emailAddressesHeader}>Cc</span>
            <div className={styles.emailAddresses}>
              {order.ccEmails?.map((email) => (
                <Chip key={email}>{email}</Chip>
              ))}
            </div>
          </div>
        )}
        <div className={styles.emails}>
          <div className={styles.header}>
            <FontAwesomeIcon icon={faEnvelope} />
            <span>
              <FormattedMessage id={translationKeys.translation_order_emails_label} />
            </span>
          </div>

          {emails.map((email) => (
            <DetailItem
              key={email.id}
              title={email.subject}
              subtitle={getEmailItemSubtitle(email.id)}
              size="large"
              preview={
                <div
                  className={classnames(styles.emailIndicator, {
                    [styles.sent]: email.status === EmailStatus.Sent,
                  })}
                >
                  {email.status === EmailStatus.Sent && <FontAwesomeIcon icon={faCheck} />}
                  {email.status === EmailStatus.Pending && <FontAwesomeIcon icon={faEnvelope} />}
                </div>
              }
            >
              <div className={styles.detailsItemDetails}>
                {email.status === EmailStatus.Sent && (
                  <Button
                    onClick={() => sendEmail(email.id)}
                    variant="transparent"
                    icon={<FontAwesomeIcon icon={faRotateRight} />}
                    isLoading={loadingEmail === email.id}
                  >
                    <FormattedMessage id={translationKeys.translation_order_emails_resend_button} />
                  </Button>
                )}
                {email.status === EmailStatus.Pending && (
                  <Button
                    onClick={() => sendEmail(email.id)}
                    variant="transparent"
                    icon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
                    isLoading={loadingEmail === email.id}
                    disabled={
                      email.id === EmailType.CcFinished &&
                      (!order.ccEmails || order.ccEmails.length === 0)
                    }
                  >
                    <FormattedMessage id={translationKeys.translation_order_emails_send_button} />
                  </Button>
                )}
              </div>
            </DetailItem>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default OrderEmailsDrawer;

export default {
  checkEmail: '/check-email',
  createInterpretationOrder: '/create-interpretation-order',
  createTranslationOrder: '/create-translation-order',
  customers: '/customers',
  enterprises: '/enterprises',
  forgotPassword: '/forgot-password',
  interpretationSkills: '/interpretation-skills',
  jobs: '/jobs',
  login: '/login',
  setNewPassword: '/set-new-password',
  suppliers: '/suppliers',
  translationSkills: '/translation-skills',
  translationSubtasks: '/translation-subtasks',
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button } from '@skiwo/components';
import SuccessBanner from '../../../components/SuccessBanner/SuccessBanner';
import routes from '../../../helpers/routes';
import translationKeys from '../../../translations/translationKeys';
import styles from './CreateDepartmentSuccess.module.scss';

interface Props {
  onClose: () => void;
  resetShowSuccess: () => void;
  resetSelectedEnterprise: () => void;
  newDepartmentName?: string;
  enterpriseName?: string;
}

const CreateDepartmentSuccess = (props: Props) => {
  const { onClose, resetShowSuccess, resetSelectedEnterprise, newDepartmentName, enterpriseName } =
    props;
  const navigate = useNavigate();

  return (
    <div className={styles.successContainer} data-testid="create-department-success">
      <SuccessBanner />
      <p className={styles.message}>
        <FormattedMessage
          id={translationKeys.create_department_drawer_success_message}
          values={{
            newDepartment: <span>{newDepartmentName}</span>,
            enterprise: <span>{enterpriseName}</span>,
          }}
        />
      </p>
      <div className={styles.actions}>
        <Button
          size="large"
          variant="gray"
          data-testid="go-to-enterprise-button"
          onClick={() => {
            navigate(routes.enterprises);
            onClose();

            setTimeout(() => {
              resetShowSuccess();
              resetSelectedEnterprise();
            }, 300);
          }}
        >
          <FormattedMessage
            id={translationKeys.create_department_drawer_success_go_to_enterprise_button}
          />
        </Button>

        <Button
          size="large"
          data-testid="create-new-enterprise-button"
          onClick={() => {
            resetShowSuccess();
            resetSelectedEnterprise();
          }}
        >
          <FormattedMessage
            id={translationKeys.create_department_drawer_success_create_new_department_button}
          />
        </Button>
      </div>
    </div>
  );
};

export default CreateDepartmentSuccess;

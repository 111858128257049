import { ManagerCustomersResponse, NewCustomerResponse } from '../../../types';
import CustomerToEnterprise from '../../../types/CustomerToEnterprise';
import { ManagerCustomerDetailsResponse } from '../../../types/ManagerCustomer';
import fetchApi, { UrlParams } from '../../fetchApi';

export default {
  getCustomers: (urlParams: UrlParams) => {
    return fetchApi<ManagerCustomersResponse>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/customers`,
        urlParams,
      },
      true,
    );
  },
  getCustomerDetails: (customerId: string) => {
    return fetchApi<ManagerCustomerDetailsResponse>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/customers/${customerId}`,
      },
      true,
    );
  },
  createCustomer: (body: any, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<NewCustomerResponse>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/customers`,
      body,
      loadingCallback,
    });
  },
  upgradeCustomerToEnterprise: (
    customerUid: string,
    enterpriseOrgNumber: string,
    body: CustomerToEnterprise,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/customers/${customerUid}/enterprise/${enterpriseOrgNumber}`,
      body,
      loadingCallback,
    });
  },
};

import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Banner,
  Button,
  Drawer,
  PhoneCodeDropdown,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { format } from 'date-fns';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ApiError } from '../../Api';
import { useGetCustomerDetailsQuery } from '../../Api/Endpoints/Customers/Customers.hooks';
import { emailRegex, phoneRegex } from '../../helpers/regexPatterns';
import { useApi } from '../../providers/ApiProvider';
import translationKeys from '../../translations/translationKeys';
import { Enterprise, ManagerCustomerRole } from '../../types';
import { ManagerCustomerDetails } from '../../types/ManagerCustomer';
import styles from './EditEmployeeDrawer.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
  onComplete?: (hardReload?: boolean) => void;
  customer: Pick<
    ManagerCustomerDetails,
    | 'uid'
    | 'roles'
    | 'name'
    | 'departments'
    | 'phoneCode'
    | 'phone'
    | 'phoneNumber'
    | 'email'
    | 'emailSuppressed'
  > & {
    enterprise?: Pick<Enterprise, 'id'>;
  };
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

const EditEmployeeDrawer = (props: Props) => {
  const { show, customer, onClose, onComplete } = props;
  const intl = useIntl();
  const [updateCustomerError, setUpdateCustomerError] = useState<ApiError | null>(null);
  const [updateCustomerLoading, setUpdateCustomerLoading] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState<SearchDropdownMenuOption[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedPhoneCode, setSelectedPhoneCode] = useState<SearchDropdownMenuOption>();
  const { data: customerDetails, refetch: refetchGetCustomerDetails } = useGetCustomerDetailsQuery({
    customerUid: customer.uid,
  });
  const [roles, setRoles] = useState<ManagerCustomerRole[]>(customer.roles || []);
  const api = useApi();

  const schema = yup.object().shape({
    firstName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.customers_page_edit_employee_first_name_label,
          }),
        },
      ),
    ),
    lastName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.customers_page_edit_employee_last_name_label,
          }),
        },
      ),
    ),
    email: yup
      .string()
      .matches(
        emailRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.customers_page_edit_employee_email_label,
            }),
          },
        ),
      )
      .required(
        intl.formatMessage(
          { id: translationKeys.form_error_required },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.customers_page_edit_employee_email_label,
            }),
          },
        ),
      ),
    phoneNumber: yup
      .string()
      .matches(
        phoneRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.customers_page_edit_employee_phone_label,
            }),
          },
        ),
      )
      .notRequired(),
  });

  const handleSubmit = async (values: FormValues) => {
    if (!customer.enterprise?.id) return;

    const requestBody = new FormData();
    requestBody.append('employee[firstName]', values.firstName);
    requestBody.append('employee[lastName]', values.lastName);
    requestBody.append('employee[email]', values.email);

    for (const department of selectedDepartments) {
      requestBody.append('employee[departments][][id]', department.id.toString());
    }

    for (const role of roles) {
      requestBody.append('employee[roles][]', role);
    }

    if (values.phoneNumber) {
      if (selectedPhoneCode?.key)
        requestBody.append('employee[phoneCode]', selectedPhoneCode.key.toString());
      requestBody.append('employee[phoneNumber]', values.phoneNumber);
    } else {
      requestBody.append('employee[phoneCode]', '');
      requestBody.append('employee[phoneNumber]', '');
    }

    const { data, error } = await api.updateCustomer(
      customer.enterprise.id.toString(),
      customer.uid,
      requestBody,
      setUpdateCustomerLoading,
    );
    if (data) {
      onComplete?.();
      onClose();
    }
    if (error) {
      setUpdateCustomerError(error);
    }
  };

  const handleDepartmentsChange = (departments: SearchDropdownMenuOption[]) => {
    setSelectedDepartments(departments);
  };

  const getNameComponents = () => {
    if (!customer.name) {
      return { firstName: '', lastName: '' };
    }

    const nameComponents = customer.name.split(' ');
    const lastName = nameComponents.pop() || '';
    const firstName = nameComponents.join(' ') || '';

    return { firstName, lastName };
  };

  const handleRoleToggle = (toggledRole: ManagerCustomerRole, checked: boolean) => {
    if (checked) {
      setRoles([...roles, toggledRole]);
    } else {
      const newRoles = roles.filter((role) => role !== toggledRole);
      setRoles(newRoles);
    }
  };
  const getEnterpriseDepartments = async () => {
    if (!customer.enterprise?.id) return;

    const { data } = await api.getEnterpriseDepartments(customer.enterprise.id.toString());

    if (data) {
      if (data?.departments) {
        const dropdownOptions =
          data?.departments.map((department) => {
            return { id: department.id, label: department.name, key: department.name };
          }) || [];

        setDepartmentOptions(dropdownOptions);
        const customerDepartmentIds = customer.departments?.map((department) => department.id);
        const selectedOptions = dropdownOptions.filter((option) =>
          customerDepartmentIds?.includes(option.id),
        );
        setSelectedDepartments(selectedOptions);
      }
    }
  };

  const removeFromSuppressedList = async () => {
    const { error } = await api.removeEmailFromSuppressedList(customer.uid);

    setUpdateCustomerError(error);

    if (!error) {
      refetchGetCustomerDetails();
      onComplete?.();
    }
  };

  useEffect(() => {
    if (!show) return;
    if (!customer.enterprise) return;

    getEnterpriseDepartments();
  }, [show]);

  useEffect(() => {
    if (customer.phoneCode) {
      setSelectedPhoneCode({
        id: Number(customer.phoneCode.replace('+', '')),
        label: customer.phoneCode,
        key: customer.phoneCode,
      });
    }
  }, []);

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.customers_page_edit_employee_title })}
      data-testid="edit-employee-drawer"
    >
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          firstName: getNameComponents().firstName,
          lastName: getNameComponents().lastName,
          email: customer.email,
          phoneNumber: customer.phoneNumber || '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
          <Form onSubmit={handleSubmit} className={styles.container}>
            <div className={styles.nameInputs}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.customers_page_edit_employee_first_name_placeholder,
                })}
                type="text"
                label={intl.formatMessage({
                  id: translationKeys.customers_page_edit_employee_first_name_label,
                })}
                size="large"
                name="firstName"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                errorText={touched.firstName ? errors.firstName : undefined}
                data-testid="first-name-input"
              />

              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.customers_page_edit_employee_last_name_placeholder,
                })}
                type="text"
                required
                label={intl.formatMessage({
                  id: translationKeys.customers_page_edit_employee_last_name_label,
                })}
                size="large"
                name="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                errorText={touched.lastName ? errors.lastName : undefined}
                data-testid="last-name-input"
              />
            </div>

            <TextField
              placeholder={intl.formatMessage({
                id: translationKeys.customers_page_edit_employee_email_placeholder,
              })}
              type="email"
              label={intl.formatMessage({
                id: translationKeys.customers_page_edit_employee_email_label,
              })}
              size="large"
              required
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              errorText={touched.email ? errors.email : undefined}
              data-testid="email-input"
            />

            {customer.emailSuppressed && (
              <Banner
                variant="error"
                title={intl.formatMessage({
                  id: translationKeys.customers_page_edit_employee_email_suppressed_error_label,
                })}
                text={intl.formatMessage({
                  id: translationKeys.customers_page_edit_employee_email_suppressed_error_description,
                })}
              >
                <Button
                  className={styles.buttonLink}
                  variant="transparent"
                  onClick={() => removeFromSuppressedList()}
                >
                  <FormattedMessage
                    id={
                      translationKeys.customers_page_edit_employee_email_suppressed_error_remove_button
                    }
                  />
                </Button>
              </Banner>
            )}

            {customerDetails?.emailSuppressionRemovedAt && (
              <Banner
                variant="information"
                text={intl.formatMessage(
                  {
                    id: translationKeys.customers_page_edit_employee_email_suppressed_info_description,
                  },
                  {
                    author: customerDetails.emailSuppressionRemovedBy,
                    date: format(
                      new Date(customerDetails.emailSuppressionRemovedAt),
                      'dd.MM.yyyy, HH:mm',
                    ),
                  },
                )}
              />
            )}

            <div className={styles.phoneInputs}>
              <div data-testid="phone-code-dropdown">
                <PhoneCodeDropdown
                  selected={selectedPhoneCode}
                  placeholder="+47"
                  label={intl.formatMessage({
                    id: translationKeys.customers_page_edit_employee_country_code_label,
                  })}
                  onChange={(phoneCodes) => {
                    if (phoneCodes && phoneCodes.length > 0 && phoneCodes[0].key) {
                      setSelectedPhoneCode(phoneCodes[0]);
                    } else {
                      setSelectedPhoneCode(undefined);
                    }
                  }}
                />
              </div>

              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.customers_page_edit_employee_phone_placeholder,
                })}
                type="text"
                label={intl.formatMessage({
                  id: translationKeys.customers_page_edit_employee_phone_label,
                })}
                size="large"
                name="phoneNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                errorText={touched.phoneNumber ? errors.phoneNumber : undefined}
                data-testid="phone-number-input"
              />
            </div>

            {departmentOptions.length > 0 && (
              <div data-testid="department-dropdown">
                <SearchDropdown
                  options={departmentOptions}
                  placeholder={intl.formatMessage({
                    id: translationKeys.customers_page_edit_employee_departments_placeholder,
                  })}
                  label={intl.formatMessage({
                    id: translationKeys.customers_page_edit_employee_departments_label,
                  })}
                  multiple
                  selected={selectedDepartments}
                  size="large"
                  onChange={(departments) => {
                    if (departments && departments.length > 0 && departments[0].key) {
                      handleDepartmentsChange(departments);
                    } else {
                      handleDepartmentsChange([]);
                    }
                  }}
                />
              </div>
            )}

            <div>
              <h4 className={styles.editRoleHeader}>
                <FormattedMessage
                  id={translationKeys.customers_page_edit_employee_assign_roles_title}
                />
              </h4>
              <div className={styles.toggleItems}>
                <span>
                  <FormattedMessage
                    id={translationKeys.customers_page_edit_employee_assign_roles_admin}
                  />
                </span>
                <Form.Check
                  type="switch"
                  onChange={(e) =>
                    handleRoleToggle(ManagerCustomerRole.Admin, e.currentTarget.checked)
                  }
                  checked={roles.includes(ManagerCustomerRole.Admin)}
                  data-testid="admin-toggle"
                />
              </div>
              <div className={styles.toggleItems}>
                <span>
                  <FormattedMessage
                    id={translationKeys.customers_page_edit_employee_assign_roles_manager}
                  />
                </span>
                <Form.Check
                  onChange={(e) =>
                    handleRoleToggle(ManagerCustomerRole.Finance, e.currentTarget.checked)
                  }
                  type="switch"
                  checked={roles.includes(ManagerCustomerRole.Finance)}
                  data-testid="manager-toggle"
                />
              </div>
              <div className={styles.toggleItems}>
                <span>
                  <FormattedMessage
                    id={translationKeys.customers_page_edit_employee_assign_roles_coordinator}
                  />
                </span>
                <Form.Check
                  onChange={(e) =>
                    handleRoleToggle(ManagerCustomerRole.Organizer, e.currentTarget.checked)
                  }
                  type="switch"
                  checked={roles.includes(ManagerCustomerRole.Organizer)}
                  data-testid="coordinator-toggle"
                />
              </div>
            </div>

            {updateCustomerError && (
              // TODO: Update error message with a proper message
              <Banner
                variant="error"
                text={intl.formatMessage({ id: translationKeys.global_generic_error })}
              />
            )}

            <div className={styles.actions}>
              <Button size="large" variant="gray" onClick={onClose} data-testid="cancel-button">
                <FormattedMessage id={translationKeys.customers_page_edit_employee_cancel_button} />
              </Button>

              <Button
                size="large"
                type="submit"
                disabled={!isValid}
                data-testid="submit-button"
                isLoading={updateCustomerLoading}
              >
                <FormattedMessage id={translationKeys.customers_page_edit_employee_edit_button} />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditEmployeeDrawer;

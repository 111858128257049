import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { faClock, faLanguage, faRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Tooltip } from '@skiwo/components';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useGetJobQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import { getJobLanguageToId } from '../../../Jobs/utils/getJobToLanguage';
import { useLanguages } from '../../../providers/LanguagesProvider';
import translationKeys from '../../../translations/translationKeys';
import {
  ManagerJobAlternativeLanguageProcessing,
  ManagerJobDowngradeRequirement,
} from '../../../types/ManagerJob';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import RequirementCard from './RequirementCard/RequirementCard';
import styles from './RequirementsSection.module.scss';

interface RequirementsLanguageCardProps {
  downgradeRequirement: (requirement: ManagerJobDowngradeRequirement) => void;
}

export const RequirementsLanguageCard = ({
  downgradeRequirement,
}: RequirementsLanguageCardProps) => {
  const intl = useIntl();
  const { getLanguageById } = useLanguages();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const [showModal, setShowModal] = useState(false);

  if (!job) return null;

  const language = getLanguageById(
    getJobLanguageToId({
      alternativeLangToId: job.interpretationRequirement.alternativeLanguageToId || undefined,
      alternativeLanguageActivated: job.processRequirement.alternativeLanguageActivated,
      alternativeLanguageProcessing: job.processRequirement.alternativeLanguageProcessing,
      langToId: job.interpretationRequirement.languageToId,
    }),
  );

  const alternativeLanguage = getLanguageById(
    getJobLanguageToId({
      alternativeLangToId: job.interpretationRequirement.alternativeLanguageToId || undefined,
      alternativeLanguageActivated: !job.processRequirement.alternativeLanguageActivated,
      alternativeLanguageProcessing: job.processRequirement.alternativeLanguageProcessing,
      langToId: job.interpretationRequirement.languageToId,
    }),
  );

  return (
    <>
      <RequirementCard
        label={intl.formatMessage({ id: translationKeys.job_requirements_card_language_label })}
        icons={<FontAwesomeIcon icon={faLanguage} />}
        value={
          <span>
            {language}
            {job.interpretationRequirement.dialect && `, ${job.interpretationRequirement.dialect}`}
          </span>
        }
      >
        {job.processRequirement.alternativeLanguageProcessing ===
          ManagerJobAlternativeLanguageProcessing.AlternativeLanguageAutoUpdate &&
          job.interpretationRequirement.alternativeLanguageToId &&
          job.processRequirement.autoUpdateAlternativeLanguageAt && (
            <div className={classNames(styles.flexRow, styles.spaceBetween)}>
              <Tooltip
                title={intl.formatMessage(
                  { id: translationKeys.job_requirements_card_language_tooltip },
                  {
                    language: alternativeLanguage,
                    date: format(
                      new Date(job.processRequirement.autoUpdateAlternativeLanguageAt),
                      'dd.MM.yyyy HH:mm',
                    ),
                  },
                )}
              >
                <div className={styles.flexRow}>
                  <FontAwesomeIcon className={styles.clockIcon} icon={faClock} />
                  <span>
                    {format(
                      new Date(job.processRequirement.autoUpdateAlternativeLanguageAt),
                      'dd.MM.yyyy',
                    )}
                  </span>
                </div>
              </Tooltip>
              <Button
                variant="link"
                icon={<FontAwesomeIcon icon={faRotate} />}
                className={styles.resetButton}
                onClick={() => setShowModal(true)}
              >
                {alternativeLanguage}
              </Button>
            </div>
          )}
      </RequirementCard>
      <Modal
        show={showModal}
        title={intl.formatMessage({ id: translationKeys.job_requirements_card_reset_language })}
        description={intl.formatMessage(
          { id: translationKeys.job_requirements_card_reset_language_description },
          {
            language: alternativeLanguage,
          },
        )}
        onCancel={() => setShowModal(false)}
        onHide={() => setShowModal(false)}
        submitButtonText={intl.formatMessage({ id: translationKeys.job_requirements_card_confirm })}
        onSubmit={() => {
          downgradeRequirement(ManagerJobDowngradeRequirement.AlternativeLanguage);
          setShowModal(false);
        }}
      />
    </>
  );
};

export default RequirementsLanguageCard;

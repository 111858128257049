import React from 'react';
import { Button as B_Button, ButtonProps as B_ButtonProps, Spinner } from 'react-bootstrap';
import classnames from 'classnames';
import styles from './Button.module.scss';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'gray'
  | 'link'
  | 'transparent'
  | 'danger'
  | 'white';

export interface ButtonProps extends Omit<B_ButtonProps, 'size'> {
  icon?: JSX.Element;
  iconPosition?: 'left' | 'right';
  children: React.ReactNode;
  size?: 'small' | 'medium' | 'large' | 'x-large';
  disabled?: boolean;
  variant?: ButtonVariant;
  fullWidth?: boolean;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  className?: any;
  isLoading?: boolean;
  'data-testid'?: string;
}

const Button = (props: ButtonProps) => {
  const {
    children,
    icon,
    iconPosition = 'left',
    size = 'medium',
    disabled = false,
    variant = 'primary',
    fullWidth,
    type = 'button',
    onClick,
    isLoading = false,
    className,
    'data-testid': dataTestId,
    ...buttonProps
  } = props;

  const getVariant = () => {
    switch (variant) {
      case 'transparent':
      case 'link':
      case 'danger':
        return variant;

      case 'secondary':
        return 'outline-primary';
      case 'gray':
        return 'outline-secondary';
      case 'white':
        return 'light';
      default:
        return 'primary';
    }
  };

  const handleOnClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    if (isLoading) {
      event.preventDefault();
      return;
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <B_Button
      className={classnames(styles.button, styles[size], styles[variant], {
        [styles.fullWidth]: fullWidth,
        [className]: !!className,
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
      variant={getVariant()}
      data-testid={dataTestId}
      type={type}
      onClick={handleOnClick}
      {...buttonProps}
    >
      {!isLoading && icon && iconPosition === 'left' && (
        <span data-testid="left-icon" className={classnames(styles.icon, styles.left)}>
          {icon}
        </span>
      )}

      {isLoading && (
        <Spinner animation="border" size="sm" className={styles.spinner} data-testid="spinner" />
      )}
      <span data-testid="button-text" className={styles.label}>
        {children}
      </span>

      {!isLoading && icon && iconPosition === 'right' && (
        <span data-testid="right-icon" className={classnames(styles.icon, styles.right)}>
          {icon}
        </span>
      )}
    </B_Button>
  );
};

export default Button;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@skiwo/components';
import { useFormikContext } from 'formik';
import { getTypesafeSetFieldValue } from '../../../../helpers/getTypesafeSetFieldValue';
import { numberWithTwoDecimalsRegex } from '../../../../helpers/regexPatterns';
import translationKeys from '../../../../translations/translationKeys';
import { StatementFormValues } from '../Statement';
import styles from './StatementForm.module.scss';

interface TravelDistanceRowProps {
  supplierTravelDistance?: number;
  isFeedbackLocked: boolean;
  readonly?: boolean;
}

export const TravelDistanceRow = ({
  supplierTravelDistance,
  isFeedbackLocked,
  readonly,
}: TravelDistanceRowProps) => {
  const formikProps = useFormikContext<StatementFormValues>();
  const { values } = formikProps;
  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  return (
    <tr>
      <td className={styles.rowName}>
        <FormattedMessage id={translationKeys.job_statement_travel_distance} />
      </td>
      <td></td>
      <td>
        <span>{supplierTravelDistance ? `${supplierTravelDistance.toFixed(2)} km` : '-'}</span>
      </td>
      {isFeedbackLocked && (
        <>
          <td>
            {readonly ? (
              values.demanderTravelDistance ? (
                `${parseFloat(values.demanderTravelDistance).toFixed(2)} km`
              ) : (
                '-'
              )
            ) : (
              <div className={styles.inputTextRow}>
                <div className={styles.input}>
                  <TextField
                    value={values.demanderTravelDistance}
                    onChange={(e) => {
                      const value = e.target.value.replace(',', '.');
                      const isNumber = numberWithTwoDecimalsRegex.test(value);
                      if (isNumber) {
                        setFieldValue('demanderTravelDistance', value);
                      }
                    }}
                    onBlur={(e) => {
                      setFieldValue('demanderTravelDistance', Number(e.target.value).toFixed(2));
                    }}
                    disabled={!isFeedbackLocked}
                  />
                </div>
                <span>km</span>
              </div>
            )}
          </td>
          <td>
            {readonly ? (
              values.supplierTravelDistance ? (
                `${parseFloat(values.supplierTravelDistance).toFixed(2)} km`
              ) : (
                '-'
              )
            ) : (
              <div className={styles.inputTextRow}>
                <div className={styles.input}>
                  <TextField
                    value={values.supplierTravelDistance}
                    onChange={(e) => {
                      const value = e.target.value.replace(',', '.');
                      const isNumber = numberWithTwoDecimalsRegex.test(value);
                      if (isNumber) {
                        setFieldValue('supplierTravelDistance', value);
                      }
                    }}
                    onBlur={(e) => {
                      setFieldValue('supplierTravelDistance', Number(e.target.value).toFixed(2));
                    }}
                    disabled={!isFeedbackLocked}
                  />
                </div>
                <span>km</span>
              </div>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export default TravelDistanceRow;

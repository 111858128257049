import { useQuery } from '@tanstack/react-query';
import { UrlParams } from '../../fetchApi';
import Customers from './Customers';

export const customersQueryKeys = {
  all: ['customers'],
  filters: (filters: UrlParams) => ['customers', { filters }],
  customer: (customerUid?: string | null) => ['customers', { customerUid }],
};

export const useGetCustomersQuery = (urlParams: UrlParams, options?: { enabled?: boolean }) =>
  useQuery({
    queryKey: customersQueryKeys.filters(urlParams),
    queryFn: () => Customers.getCustomers(urlParams).then((res) => res.data),
    ...options,
  });

export const useGetCustomerDetailsQuery = (
  { customerUid }: { customerUid?: string | null },
  options?: { enabled?: boolean },
) =>
  useQuery({
    queryKey: customersQueryKeys.customer(customerUid),
    queryFn: () => {
      if (!customerUid) return;
      return Customers.getCustomerDetails(customerUid).then((res) => res.data?.customer);
    },
    ...options,
  });

import { IntlShape } from 'react-intl';
import { SearchDropdownMenuOption } from '@skiwo/components';
import translationKeys from '../../translations/translationKeys';
import { ManagerJobSessionType } from '../../types';

export const getSessionTypeOptions = (intl: IntlShape): SearchDropdownMenuOption[] => [
  {
    id: 1,
    label: intl.formatMessage({
      id: translationKeys.create_interpretation_order_assignment_type_phone_label,
    }),
    key: ManagerJobSessionType.Phone,
  },
  {
    id: 2,
    label: intl.formatMessage({
      id: translationKeys.create_interpretation_order_assignment_type_video_label,
    }),
    key: ManagerJobSessionType.Video,
  },
  {
    id: 3,
    label: intl.formatMessage({
      id: translationKeys.create_interpretation_order_assignment_type_in_person_label,
    }),
    key: ManagerJobSessionType.InPerson,
  },
];

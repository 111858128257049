import React from 'react';
import { FormattedMessage } from 'react-intl';
import { faClone, faEnvelope, faPen, faUser, faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@skiwo/components';
import translationKeys from '../../translations/translationKeys';
import { ManagerOrder } from '../../types';
import styles from './OrderDetails.module.scss';

interface Props {
  order: ManagerOrder;
  onAssignSeller: () => void;
}

const LogoutDetailsCard = ({ order, onAssignSeller }: Props) => {
  return (
    <div className={styles.section} data-testid={'order-details-logout-details-section'}>
      <span className={styles.title}>
        <FontAwesomeIcon className={styles.icon} icon={faUser} />
        <span data-testid="logout-details-header">
          <FormattedMessage id={translationKeys.translation_order_details_logout_details_header} />
        </span>

        <Button
          variant="transparent"
          size="large"
          icon={<FontAwesomeIcon className={styles.icon} icon={faPen} />}
          className={styles.action}
          data-testid="logout-details-edit-button"
          disabled
        >
          <FormattedMessage id={translationKeys.translation_order_details_section_edit_button} />
        </Button>
      </span>
      <div className={styles.card}>
        <div className={styles.row}>
          <div>
            <span className={styles.label}>
              <FormattedMessage
                id={translationKeys.translation_order_details_logout_details_name_label}
              />
            </span>
            <span className={styles.value} data-testid="logout-details-name">
              {`${order.loggedOutBookerFirstName} ${order.loggedOutBookerLastName}` || '-'}
            </span>
          </div>
        </div>

        <div className={styles.row}>
          <div>
            <span className={styles.label}>
              <FormattedMessage
                id={translationKeys.translation_order_details_logout_details_enterprise_label}
              />
            </span>
            <span className={styles.value} data-testid="logout-details-enterprise">
              {order.loggedOutBookerOrgName || '-'}
            </span>
          </div>
        </div>

        <div className={styles.row}>
          <div>
            <span className={styles.label}>
              <FormattedMessage
                id={
                  translationKeys.translation_order_details_logout_details_payment_method_enterprise_label
                }
              />
            </span>
            <span className={styles.value} data-testid="logout-details-payment-method-enterprise">
              {order.loggedOutBookerPaymentOrgName || '-'}
            </span>
          </div>
        </div>

        <div className={styles.row}>
          <div>
            <span className={styles.label}>
              <FormattedMessage
                id={translationKeys.translation_order_details_logout_details_email_label}
              />
            </span>
            <span className={styles.value} data-testid="logout-details-email">
              {order.loggedOutBookerEmail || '-'}
            </span>
          </div>
          {order.loggedOutBookerEmail && (
            <div className={styles.tooltip}>
              <Tooltip title={order.loggedOutBookerEmail} copyable>
                <FontAwesomeIcon icon={faEnvelope} />
              </Tooltip>
            </div>
          )}
        </div>

        <div className={styles.row}>
          <div>
            <span className={styles.label}>
              <FormattedMessage
                id={translationKeys.translation_order_details_logout_details_phone_label}
              />
            </span>
            <span className={styles.value} data-testid="logout-details-phone">
              {order.loggedOutBookerPhoneNumber || '-'}
            </span>
          </div>
          {order.loggedOutBookerPhoneNumber && (
            <div className={styles.tooltip}>
              <Tooltip title={order.loggedOutBookerPhoneNumber} copyable>
                <FontAwesomeIcon icon={faClone} />
              </Tooltip>
            </div>
          )}
        </div>

        <div className={styles.row}>
          <Button
            variant="gray"
            icon={<FontAwesomeIcon icon={faUserPlus} />}
            fullWidth
            size="large"
            onClick={onAssignSeller}
          >
            <FormattedMessage
              id={translationKeys.translation_order_details_logout_details_assign_owner_button}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LogoutDetailsCard;

import React from 'react';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getBooleanIcon } from '../../helpers/getBooleanIcon';
import { getBooleanLabel } from '../../helpers/getBooleanLabel';
import styles from './BooleanValue.module.scss';

export enum BooleanValueVariant {
  OnOff = 'on-off',
  YesNo = 'yes-no',
}

interface BooleanValueProps {
  value: boolean;
  variant?: BooleanValueVariant;
}

export const BooleanValue = ({ value, variant }: BooleanValueProps) => {
  const intl = useIntl();

  return (
    <div className={styles.booleanValue}>
      <FontAwesomeIcon
        icon={getBooleanIcon(value)}
        className={value ? styles.iconSuccess : styles.iconError}
      />
      {getBooleanLabel(value, intl, variant)}
    </div>
  );
};

export default BooleanValue;

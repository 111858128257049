import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '@skiwo/components';
import { useFormikContext } from 'formik';
import { getTypesafeSetFieldValue } from '../../../../helpers/getTypesafeSetFieldValue';
import { numberWithTwoDecimalsRegex } from '../../../../helpers/regexPatterns';
import translationKeys from '../../../../translations/translationKeys';
import { StatementFormValues } from '../Statement';
import styles from './StatementForm.module.scss';

interface TravelTimeRowProps {
  supplierTravelTime?: number;
  isFeedbackLocked: boolean;
  readonly?: boolean;
}

export const TravelTimeRow = ({
  supplierTravelTime,
  isFeedbackLocked,
  readonly,
}: TravelTimeRowProps) => {
  const intl = useIntl();
  const formikProps = useFormikContext<StatementFormValues>();
  const { values } = formikProps;
  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  return (
    <tr>
      <td className={styles.rowName}>
        <FormattedMessage id={translationKeys.job_statement_travel_time} />
      </td>
      <td></td>
      <td>
        <span>
          {supplierTravelTime
            ? `${supplierTravelTime.toFixed(2)} ${intl.formatMessage({
                id: translationKeys.job_statement_travel_time_hours,
              })}`
            : '-'}
        </span>
      </td>
      {isFeedbackLocked && (
        <>
          <td>
            {readonly ? (
              values.demanderTravelTime ? (
                `${parseFloat(values.demanderTravelTime).toFixed(2)} ${intl.formatMessage({
                  id: translationKeys.job_statement_travel_time_hours,
                })}`
              ) : (
                '-'
              )
            ) : (
              <div className={styles.inputTextRow}>
                <div className={styles.input}>
                  <TextField
                    value={values.demanderTravelTime}
                    onChange={(e) => {
                      const value = e.target.value.replace(',', '.');
                      const isNumber = numberWithTwoDecimalsRegex.test(value);
                      if (isNumber) {
                        setFieldValue('demanderTravelTime', value);
                      }
                    }}
                    onBlur={(e) => {
                      setFieldValue('demanderTravelTime', Number(e.target.value).toFixed(2));
                    }}
                    disabled={!isFeedbackLocked}
                  />
                </div>
                <span>
                  <FormattedMessage id={translationKeys.job_statement_travel_time_hours} />
                </span>
              </div>
            )}
          </td>
          <td>
            {readonly ? (
              values.supplierTravelTime ? (
                `${parseFloat(values.supplierTravelTime).toFixed(2)} ${intl.formatMessage({
                  id: translationKeys.job_statement_travel_time_hours,
                })}`
              ) : (
                '-'
              )
            ) : (
              <div className={styles.inputTextRow}>
                <div className={styles.input}>
                  <TextField
                    value={values.supplierTravelTime}
                    onChange={(e) => {
                      const value = e.target.value.replace(',', '.');
                      const isNumber = numberWithTwoDecimalsRegex.test(value);
                      if (isNumber) {
                        setFieldValue('supplierTravelTime', value);
                      }
                    }}
                    onBlur={(e) => {
                      setFieldValue('supplierTravelTime', Number(e.target.value).toFixed(2));
                    }}
                    disabled={!isFeedbackLocked}
                  />
                </div>
                <span>
                  <FormattedMessage id={translationKeys.job_statement_travel_time_hours} />
                </span>
              </div>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export default TravelTimeRow;

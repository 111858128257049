import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag, TimePicker } from '@skiwo/components';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { getTypesafeSetFieldValue } from '../../../../helpers/getTypesafeSetFieldValue';
import translationKeys from '../../../../translations/translationKeys';
import { StatementFormValues } from '../Statement';
import styles from './StatementForm.module.scss';

interface ActualFinishRowProps {
  jobFinish: string;
  demanderFeedbackActualFinishTime?: string;
  supplierFeedbackActualFinishTime?: string;
  isFeedbackLocked: boolean;
  readonly?: boolean;
}
export const ActualFinishRow = ({
  jobFinish,
  demanderFeedbackActualFinishTime,
  supplierFeedbackActualFinishTime,
  isFeedbackLocked,
  readonly,
}: ActualFinishRowProps) => {
  const formikProps = useFormikContext<StatementFormValues>();
  const { values } = formikProps;
  const setFieldValue = getTypesafeSetFieldValue(formikProps);
  const formatDate = (date?: string) => (date ? format(new Date(date), 'HH:mm') : '-');

  return (
    <tr>
      <td>
        <span className={classNames(styles.rowName, styles.rowNameFlex)}>
          <FormattedMessage id={translationKeys.job_statement_actual_finish} />
          <Tag variant="square" size="small" color="neutral">
            <span className={styles.dateTag}>{format(new Date(jobFinish), 'HH:mm')}</span>
          </Tag>
        </span>
      </td>
      <td>{formatDate(demanderFeedbackActualFinishTime)}</td>
      <td>{formatDate(supplierFeedbackActualFinishTime)}</td>
      {isFeedbackLocked && (
        <>
          <td>
            {readonly ? (
              values.demanderActualFinishTime?.label || '-'
            ) : (
              <div className={styles.input}>
                <TimePicker
                  size="medium"
                  hideIcon
                  selected={
                    values.demanderActualFinishTime ? [values.demanderActualFinishTime] : []
                  }
                  onChange={(item) =>
                    item && item.length && setFieldValue('demanderActualFinishTime', item[0])
                  }
                  disabled={!isFeedbackLocked}
                />
              </div>
            )}
          </td>
          <td>
            {readonly ? (
              values.supplierActualFinishTime?.label || '-'
            ) : (
              <div className={styles.input}>
                <TimePicker
                  size="medium"
                  hideIcon
                  selected={
                    values.supplierActualFinishTime ? [values.supplierActualFinishTime] : []
                  }
                  onChange={(item) =>
                    item && item.length && setFieldValue('supplierActualFinishTime', item[0])
                  }
                  disabled={!isFeedbackLocked}
                />
              </div>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export default ActualFinishRow;

import React, { useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faClock, faFile, faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, Button, Modal, Tag, TextField, Tooltip } from '@skiwo/components';
import { TimePickerItem } from '@skiwo/components/src/TimePicker/TimePicker';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import {
  useGetJobQuery,
  useGetJobStatementQuery,
  useLockJobMutation,
  useSetJobReadyForFinanceMutation,
  useUnsetJobReadyForFinanceMutation,
  useUpdateJobStatementMutation,
} from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import { addTimeToDate, getTimeOption } from '../../../CreateInterpretationOrder/utils';
import { useToast } from '../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../translations/translationKeys';
import {
  ManagerJobBookingMechanism,
  ManagerJobFinanceStatus,
  ManagerJobRiskStatus,
  ManagerJobSessionType,
  ManagerJobStatus,
} from '../../../types/ManagerJob';
import {
  ManagerJobStatementDelayCausedBy,
  ManagerJobStatementInterpreterTravelled,
  ManagerJobStatementTravelType,
  ManagerJobUpdateStatement,
} from '../../../types/ManagerJobStatement';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import { ManagerJobBookingFee } from '../../utils/getFeesOptions';
import StatementForm from './StatementForm/StatementForm';
import statementSchema from './schema';
import StatementSettings from './StatementSettings';
import styles from './Statement.module.scss';

export interface StatementFormValues {
  chargeAs?: ManagerJobSessionType;
  orderMethod?: ManagerJobBookingMechanism;
  orderedDate?: Date;
  orderedTime?: TimePickerItem;
  fees?: ManagerJobBookingFee[];
  chargeable?: boolean;
  payable?: boolean;
  demanderShowedUp?: boolean;
  demanderInvoiceDiscountPercentage?: string;
  demanderActualStartTime?: TimePickerItem;
  demanderActualFinishTime?: TimePickerItem;
  demanderDelayCausedBy?: ManagerJobStatementDelayCausedBy | null;
  demanderTravelExpensesAmountOverride?: string;
  demanderTravelDietEnabled?: boolean;
  demanderTravelType?: ManagerJobStatementTravelType | null;
  demanderTravelDistance?: string;
  demanderTravelTime?: string;
  demanderInterpreterTravelled?: boolean;
  supplierShowedUp?: boolean;
  supplierInvoiceDiscountPercentage?: string;
  supplierActualStartTime?: TimePickerItem;
  supplierActualFinishTime?: TimePickerItem;
  supplierDelayCausedBy?: ManagerJobStatementDelayCausedBy | null;
  supplierTravelExpensesAmountOverride?: string;
  supplierTravelDietEnabled?: boolean;
  supplierTravelType?: ManagerJobStatementTravelType | null;
  supplierTravelDistance?: string;
  supplierTravelTime?: string;
  supplierInterpreterTravelled?: boolean;
}

export const Statement = () => {
  const intl = useIntl();
  const [showLockModal, setShowLockModal] = useState(false);
  const { showErrorToast } = useToast();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const { data: statementData } = useGetJobStatementQuery(jobId);
  const updateStatement = useUpdateJobStatementMutation();
  const lockJobMutation = useLockJobMutation();
  const setReadyForFinanceMutation = useSetJobReadyForFinanceMutation();
  const unsetReadyForFinanceMutation = useUnsetJobReadyForFinanceMutation();
  const [showReadyForFinanceModal, setShowReadyForFinanceModal] = useState(false);
  const [showUnsetReadyForFinanceModal, setShowUnsetReadyForFinanceModal] = useState(false);
  const [reason, setReason] = useState('');
  const [acceptRisk, setAcceptRisk] = useState(false);

  if (!job) return null;
  const isCancelled = job.status === ManagerJobStatus.Cancelled;
  const isInPersonSessionType =
    job.interpretationRequirement.sessionType === ManagerJobSessionType.InPerson;
  const isReadyForFinance = job.financeStatus === ManagerJobFinanceStatus.ReadyForFinance;
  const isLocked = job.isLocked;

  const getInitialFees = () => {
    const fees = [];
    if (job.info.manualBookingFeeApplied) {
      fees.push(ManagerJobBookingFee.ManualBookingFeeApplied);
    }
    if (job.info.manualEditingFeeApplied) {
      fees.push(ManagerJobBookingFee.manualEditingFeeApplied);
    }
    if (job.info.manualTravelBookingFeeApplied) {
      fees.push(ManagerJobBookingFee.ManualTravelBookingFeeApplied);
    }
    return fees;
  };

  const handleSubmit = async (values: StatementFormValues) => {
    if (!statementData?.statement?.id) return;

    const { chargeAs, orderMethod, orderedDate, orderedTime, fees, ...otherValues } = values;
    const statement: Partial<ManagerJobUpdateStatement> = {
      info: {
        manualBookingFeeApplied: fees?.includes(ManagerJobBookingFee.ManualBookingFeeApplied),
        manualEditingFeeApplied: fees?.includes(ManagerJobBookingFee.manualEditingFeeApplied),
        manualTravelBookingFeeApplied: fees?.includes(
          ManagerJobBookingFee.ManualTravelBookingFeeApplied,
        ),
      },
      job: {
        actualCreatedAt: orderedDate
          ? addTimeToDate(orderedDate, orderedTime).toString()
          : undefined,
        bookingMechanism: orderMethod,
      },
      statement: {
        ...otherValues,
        sessionType: chargeAs,
        demanderInvoiceDiscountPercentage: otherValues?.demanderInvoiceDiscountPercentage
          ? parseInt(otherValues.demanderInvoiceDiscountPercentage)
          : undefined,
        supplierInvoiceDiscountPercentage: otherValues.supplierInvoiceDiscountPercentage
          ? parseInt(otherValues.supplierInvoiceDiscountPercentage)
          : undefined,
        demanderActualStartTime: addTimeToDate(
          new Date(statementData.statement.demanderActualStartTime),
          otherValues.demanderActualStartTime,
        ).toString(),
        demanderActualFinishTime: addTimeToDate(
          new Date(statementData.statement.demanderActualFinishTime),
          otherValues.demanderActualFinishTime,
        ).toString(),
        supplierActualStartTime: addTimeToDate(
          new Date(statementData.statement.supplierActualStartTime),
          otherValues.supplierActualStartTime,
        ).toString(),
        supplierActualFinishTime: addTimeToDate(
          new Date(statementData.statement.supplierActualFinishTime),
          otherValues.supplierActualFinishTime,
        ).toString(),
        demanderTravelDistance: otherValues.demanderTravelDistance
          ? parseFloat(otherValues.demanderTravelDistance)
          : undefined,
        supplierTravelDistance: otherValues.supplierTravelDistance
          ? parseFloat(otherValues.supplierTravelDistance)
          : undefined,
        demanderTravelTime: otherValues.demanderTravelTime
          ? parseFloat(otherValues.demanderTravelTime)
          : undefined,
        supplierTravelTime: otherValues.supplierTravelTime
          ? parseFloat(otherValues.supplierTravelTime)
          : undefined,
        demanderTravelExpensesAmountOverride: otherValues.demanderTravelExpensesAmountOverride,
        supplierTravelExpensesAmountOverride: otherValues.supplierTravelExpensesAmountOverride,
        demanderInterpreterTravelled: otherValues.demanderInterpreterTravelled
          ? ManagerJobStatementInterpreterTravelled.Right
          : ManagerJobStatementInterpreterTravelled.Wrong,
        supplierInterpreterTravelled: otherValues.supplierInterpreterTravelled
          ? ManagerJobStatementInterpreterTravelled.Right
          : ManagerJobStatementInterpreterTravelled.Wrong,
      },
    };

    updateStatement.mutate(
      {
        id: job.id.toString(),
        statementId: statementData.statement.id.toString(),
        statement,
      },
      { onError: (error) => showErrorToast(error) },
    );
  };

  const shouldShowStatement = () => {
    if (!isCancelled) {
      return true;
    }

    // TODO: Extend this check with feedback provided check - needs to be added on BE
    if (!isLocked && isInPersonSessionType) {
      return true;
    }
    
    if (isLocked) {
      return true;
    }

    return false;
  };

  return statementData && shouldShowStatement() ? (
    <div className={styles.statement}>
      <header className={styles.cardHeader}>
        <h4 className={styles.cardTitle}>
          <FontAwesomeIcon icon={faFile} />
          <FormattedMessage id={translationKeys.job_statement_label} />
        </h4>
        <div className={styles.actions}>
          {isLocked && job.jobLockSystemLog && (
            <Tag color="error">
              <span className={styles.tag}>
                <FontAwesomeIcon icon={faClock} size="sm" />
                <FormattedMessage
                  id={translationKeys.job_statement_feedback_locked}
                  values={{
                    date: format(new Date(job.jobLockSystemLog.createdAt), 'dd.MM.yyyy, HH:mm'),
                    lockedBy: job.jobLockSystemLog.actorName || job.jobLockSystemLog.actorRole,
                  }}
                />
              </span>
            </Tag>
          )}
          {!isLocked && job.jobLockScheduledAt && (
            <Tag color="warning">
              <span className={styles.tag}>
                <FontAwesomeIcon icon={faClock} size="sm" />
                <FormattedMessage
                  id={translationKeys.job_statement_feedback_locks}
                  values={{ date: format(new Date(job.jobLockScheduledAt), 'dd.MM.yyyy, HH:mm') }}
                />
              </span>
            </Tag>
          )}
          {isLocked ? (
            <Tooltip
              title={
                job.risk.calculatingSince !== null
                  ? intl.formatMessage({
                      id: translationKeys.job_statement_ready_for_finance_tooltip,
                    })
                  : undefined
              }
            >
              <B_Form.Check
                type="switch"
                label={intl.formatMessage({ id: translationKeys.job_statement_ready_for_finance })}
                data-testid="order-details-waiting-for-customer-toggle"
                checked={job.financeStatus === ManagerJobFinanceStatus.ReadyForFinance}
                onClick={() => {
                  if (job.financeStatus !== ManagerJobFinanceStatus.ReadyForFinance)
                    setShowReadyForFinanceModal(true);
                  else setShowUnsetReadyForFinanceModal(true);
                }}
                disabled={job.risk.calculatingSince !== null}
              />
            </Tooltip>
          ) : (
            <Button
              icon={<FontAwesomeIcon icon={faLock} />}
              variant="transparent"
              size="medium"
              onClick={() => setShowLockModal(true)}
            >
              <FormattedMessage id={translationKeys.job_statement_lock_feedback} />
            </Button>
          )}
        </div>
      </header>
      <Formik
        validationSchema={statementSchema}
        initialValues={{
          chargeAs: statementData.statement?.sessionType,
          orderMethod: job.bookingMechanism,
          orderedDate: new Date(job.actualCreatedAt),
          orderedTime: getTimeOption(new Date(job.actualCreatedAt)),
          fees: getInitialFees(),
          chargeable: statementData.statement?.chargeable,
          payable: statementData.statement?.payable,
          demanderShowedUp: statementData.statement?.demanderShowedUp,
          demanderInvoiceDiscountPercentage:
            statementData.statement?.demanderInvoiceDiscountPercentage.toString(),
          demanderActualStartTime: statementData.statement?.demanderActualStartTime
            ? getTimeOption(new Date(statementData.statement.demanderActualStartTime))
            : undefined,
          demanderActualFinishTime: statementData.statement?.demanderActualFinishTime
            ? getTimeOption(new Date(statementData.statement.demanderActualFinishTime))
            : undefined,
          demanderDelayCausedBy: statementData.statement?.demanderDelayCausedBy,
          demanderTravelExpensesAmountOverride: statementData.statement
            ?.demanderTravelExpensesAmountOverride
            ? parseFloat(statementData.statement?.demanderTravelExpensesAmountOverride).toFixed(2)
            : '0.00',
          demanderTravelDietEnabled: statementData.statement?.demanderTravelDietEnabled,
          demanderTravelType: statementData.statement?.demanderTravelType,
          demanderTravelDistance: statementData.statement?.demanderTravelDistance.toFixed(2),
          demanderTravelTime: statementData.statement?.demanderTravelTime.toFixed(2),
          demanderInterpreterTravelled:
            statementData.statement?.demanderInterpreterTravelled ===
            ManagerJobStatementInterpreterTravelled.Right
              ? true
              : false,
          supplierShowedUp: statementData.statement?.supplierShowedUp,
          supplierInvoiceDiscountPercentage:
            statementData.statement?.supplierInvoiceDiscountPercentage.toString(),
          supplierActualStartTime: statementData.statement?.supplierActualStartTime
            ? getTimeOption(new Date(statementData.statement.supplierActualStartTime))
            : undefined,
          supplierActualFinishTime: statementData.statement?.supplierActualFinishTime
            ? getTimeOption(new Date(statementData.statement.supplierActualFinishTime))
            : undefined,
          supplierDelayCausedBy: statementData.statement?.supplierDelayCausedBy,
          supplierTravelExpensesAmountOverride: statementData.statement
            ?.supplierTravelExpensesAmountOverride
            ? parseFloat(statementData.statement?.supplierTravelExpensesAmountOverride).toFixed(2)
            : '0.00',
          supplierTravelDietEnabled: statementData.statement?.supplierTravelDietEnabled,
          supplierTravelType: statementData.statement?.supplierTravelType,
          supplierTravelDistance: statementData.statement?.supplierTravelDistance.toFixed(2),
          supplierTravelTime: statementData.statement?.supplierTravelTime.toFixed(2),
          supplierInterpreterTravelled:
            statementData.statement?.supplierInterpreterTravelled ===
            ManagerJobStatementInterpreterTravelled.Right
              ? true
              : false,
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit} className={styles.column}>
            {isLocked && <StatementSettings readonly={isReadyForFinance} />}
            {!isLocked && !statementData.demanderFeedback && !statementData.supplierFeedback ? (
              <div className={styles.errorBanner}>
                <Banner
                  variant="error"
                  text={intl.formatMessage({ id: translationKeys.job_statement_no_feedback })}
                />
              </div>
            ) : (
              <>
                <StatementForm statement={statementData} job={job} />
                {!isReadyForFinance && (
                  <div className={styles.submitRow}>
                    <Button size="large" type="submit" isLoading={updateStatement.isPending}>
                      <FormattedMessage id={translationKeys.job_statement_save} />
                    </Button>
                  </div>
                )}
              </>
            )}
          </Form>
        )}
      </Formik>
      <Modal
        title={<FormattedMessage id={translationKeys.job_statement_lock_feedback} />}
        description={
          <FormattedMessage id={translationKeys.job_statement_lock_feedback_description} />
        }
        show={showLockModal}
        onHide={() => setShowLockModal(false)}
        onCancel={() => setShowLockModal(false)}
        submitButtonText={intl.formatMessage({
          id: translationKeys.job_statement_lock_feedback_lock_button,
        })}
        submitButtonVariant={'danger'}
        onSubmit={() => {
          lockJobMutation.mutate(
            { id: job.id.toString() },
            { onError: (error) => showErrorToast(error), onSuccess: () => setShowLockModal(false) },
          );
        }}
        isSubmitting={lockJobMutation.isPending}
      />
      <Modal
        size="large"
        title={<FormattedMessage id={translationKeys.job_statement_ready_for_finance} />}
        description={
          <FormattedMessage id={translationKeys.job_statement_ready_for_finance_description} />
        }
        show={showReadyForFinanceModal}
        onHide={() => setShowReadyForFinanceModal(false)}
        onCancel={() => setShowReadyForFinanceModal(false)}
        isSubmitDisabled={
          job.risk.status === ManagerJobRiskStatus.Risky && !(acceptRisk && reason.length > 0)
        }
        submitButtonVariant={
          job.risk.factors.length > 0 && job.risk.status === ManagerJobRiskStatus.Risky
            ? 'danger'
            : 'primary'
        }
        submitButtonText={intl.formatMessage({
          id: translationKeys.job_statement_ready_for_finance_button,
        })}
        onSubmit={() => {
          setReadyForFinanceMutation.mutate(
            { id: job.id.toString(), reason },
            { onSuccess: () => setShowReadyForFinanceModal(false), onError: showErrorToast },
          );
        }}
        isSubmitting={lockJobMutation.isPending}
      >
        {job.risk.factors.length > 0 && job.risk.status === ManagerJobRiskStatus.Risky && (
          <div className={styles.readyForFinanceModalContent}>
            <div className={styles.errorRiskBanner}>
              <Banner
                variant="error"
                text={intl.formatMessage({
                  id: translationKeys.job_statement_ready_for_finance_risk_banner,
                })}
              />
            </div>
            <TextField
              label={intl.formatMessage({
                id: translationKeys.job_statement_ready_for_finance_risk_reason_label,
              })}
              placeholder={intl.formatMessage({
                id: translationKeys.job_statement_ready_for_finance_risk_reason_placeholder,
              })}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              textArea
            />
            <B_Form.Check
              type="checkbox"
              label={intl.formatMessage({
                id: translationKeys.job_statement_ready_for_finance_risk_checkbox,
              })}
              checked={acceptRisk}
              onClick={() => setAcceptRisk(!acceptRisk)}
            />
          </div>
        )}
      </Modal>

      <Modal
        size="large"
        title={<FormattedMessage id={translationKeys.job_statement_not_ready_for_finance} />}
        description={
          <FormattedMessage id={translationKeys.job_statement_not_ready_for_finance_description} />
        }
        show={showUnsetReadyForFinanceModal}
        onHide={() => setShowUnsetReadyForFinanceModal(false)}
        onCancel={() => setShowUnsetReadyForFinanceModal(false)}
        submitButtonText={intl.formatMessage({
          id: translationKeys.job_statement_not_ready_for_finance_button,
        })}
        onSubmit={() => {
          unsetReadyForFinanceMutation.mutate(
            { id: job.id.toString() },
            { onSuccess: () => setShowUnsetReadyForFinanceModal(false), onError: showErrorToast },
          );
        }}
        isSubmitting={lockJobMutation.isPending}
      />
    </div>
  ) : (
    <> </>
  );
};

export default Statement;

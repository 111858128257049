import { IntlShape } from 'react-intl';
import { faChartLineUp, faLanguage, faUser } from '@fortawesome/pro-light-svg-icons';
import routes from '../helpers/routes';
import translationKeys from '../translations/translationKeys';

const getSuppliers = (intl: IntlShape) => {
  const interpretationSection = {
    name: intl.formatMessage({ id: translationKeys.menu_suppliers_interpretation_section }),
    items: [
      {
        name: intl.formatMessage({
          id: translationKeys.menu_suppliers_interpreters,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_suppliers_interpreters_description,
        }),
        link: routes.suppliers,
        icon: faUser,
      },
      {
        name: intl.formatMessage({
          id: translationKeys.menu_suppliers_interpreters_statistics,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_suppliers_interpreters_statistics_description,
        }),
        link: '#',
        icon: faChartLineUp,
        disabled: true,
      },
    ],
  };

  const talentSection = {
    name: intl.formatMessage({ id: translationKeys.menu_suppliers_talent_section }),
    items: [
      {
        name: intl.formatMessage({
          id: translationKeys.menu_suppliers_translation_skills,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_suppliers_translation_skills_description,
        }),
        link: routes.translationSkills,
        icon: faLanguage,
      },
      {
        name: intl.formatMessage({
          id: translationKeys.menu_suppliers_interpreter_skills,
        }),
        description: intl.formatMessage({
          id: translationKeys.menu_suppliers_interpreter_skills_description,
        }),
        link: '/interpretation-skills',
        icon: faLanguage,
      },
    ],
  };

  return {
    name: intl.formatMessage({ id: translationKeys.menu_suppliers_item }),
    sections: [interpretationSection, talentSection],
  };
};

export default getSuppliers;

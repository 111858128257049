import { AttachmentsFileState } from "../../../helpers/appendFormAttachmentsBlob";

export const getMergedAttachmentsWithDeletedFlag = (
  initialValues: AttachmentsFileState[],
  currentlySelectedValues: AttachmentsFileState[],
) => {
  if (!initialValues.length) {
    return currentlySelectedValues;
  }

  if (!currentlySelectedValues.length) {
    return initialValues.map((item) => ({ ...item, deleted: true }));
  }

  const initialMap = new Map(initialValues.map((item) => [item.uid, item]));
  const currentMap = new Map(currentlySelectedValues.map((item) => [item.uid, item]));

  const result = [];

  for (const initial of initialValues) {
    result.push(!currentMap.has(initial.uid) ? { ...initial, deleted: true } : initial);
  }

  for (const current of currentlySelectedValues) {
    if (!initialMap.has(current.uid)) {
      result.push(current);
    }
  }

  return result;
};

import { IntlShape } from 'react-intl';
import translationKeys from '../translations/translationKeys';

export default function getRequiredMessage(intl: IntlShape, fieldName?: string): string {
  return intl.formatMessage(
    { id: translationKeys.form_error_required },
    {
      fieldName:
        fieldName || intl.formatMessage({ id: translationKeys.global_required_field_this }),
    },
  );
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { faFile } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobStatementReceipt } from '../../../../types/ManagerJobStatement';
import styles from './StatementForm.module.scss';

interface ReceiptsRowProps {
  receipts?: ManagerJobStatementReceipt[];
  isFeedbackLocked: boolean;
}

export const ReceiptsRow = ({ receipts, isFeedbackLocked }: ReceiptsRowProps) => {
  return (
    <tr>
      <td className={styles.rowName}>
        <FormattedMessage id={translationKeys.job_statement_receipts} />
      </td>
      <td></td>
      <td>
        {
          <div className={styles.filesList}>
            {receipts
              ? receipts.map((receipt) => {
                  return (
                    <div key={receipt.fileName}>
                      {receipt && (
                        <div className={styles.fileItem}>
                          <FontAwesomeIcon icon={faFile} className={styles.fileIcon} />
                          <a
                            className={styles.fileLink}
                            href={receipt.fileUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {receipt.fileName}
                          </a>
                        </div>
                      )}
                    </div>
                  );
                })
              : '-'}
          </div>
        }
      </td>
      {isFeedbackLocked && (
        <>
          <td></td>
          <td></td>
        </>
      )}
    </tr>
  );
};

export default ReceiptsRow;

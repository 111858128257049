import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  faClock,
  faEnvelope,
  faInfoCircle,
  faPhone,
  faReply,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Skeleton, Tag, Tooltip } from '@skiwo/components';
import { formatPhoneNumber } from '@skiwo/utils';
import classnames from 'classnames';
import classNames from 'classnames';
import { format } from 'date-fns';
import {
  getAssignmentTypeIcon,
  getAssignmentTypeLabel,
} from '../../CreateInterpretationOrder/utils';
import JobNotesDrawer from '../../Drawers/JobNotesDrawer/JobNotesDrawer';
import BookerHoverCard from '../../Job/JobDetails/BookerHoverCard/BookerHoverCard';
import { useFeatureToggle } from '../../providers/FeatureToggleProvider';
import { useLanguages } from '../../providers/LanguagesProvider';
import translationKeys from '../../translations/translationKeys';
import {
  Feature,
  ManagerJob,
  ManagerJobAlternativeLanguageProcessing,
  ManagerJobStatus,
} from '../../types';
import { ManagerJobFinanceStatus } from '../../types/ManagerJob';
import JobsTab from '../JobsTab';
import getJobStatusName from '../utils/getJobStatusName';
import { getJobLanguageToId } from '../utils/getJobToLanguage';
import getLastActivityName from '../utils/getLastActivityName';
import DangerLevelHint from './HintIcons/DangerLevelHint';
import InviteHint from './HintIcons/InviteHint';
import ManualApprovalHint from './HintIcons/ManualApprovalHint';
import QualificationHint from './HintIcons/QualificationHint';
import SessionTypeHint from './HintIcons/SessionTypeHint';
import styles from './JobItem.module.scss';

interface Props {
  job: ManagerJob;
  activeTab?: string;
}

const JobItem = (props: Props) => {
  const { job, activeTab } = props;
  const { getLanguageById, isLoading: languagesLoading } = useLanguages();
  const intl = useIntl();
  const [showNotesDrawer, setShowNotesDrawer] = useState(false);
  const [badgeCount, setBadgeCount] = useState(3);
  const { isFeatureActive } = useFeatureToggle();

  const jobStartTimestamp = new Date(job.startTime);
  const finishTimestamp = new Date(job.finishTime);
  const createAtTimestamp = new Date(job.createdAt);
  const actualCreateAtTimestamp = new Date(job.actualCreatedAt);

  const onAddNote = () => {
    setBadgeCount(badgeCount + 1);
  };

  const onDeleteNote = () => {
    setBadgeCount(badgeCount - 1);
  };

  useEffect(() => {
    setBadgeCount(job.adminDiscussionMessagesCount);
  }, []);

  const getAlternativeLanguageIcon = () => {
    if (
      job.alternativeLanguageProcessing ===
      ManagerJobAlternativeLanguageProcessing.AlternativeLanguageAutoUpdate
    ) {
      if (job.alternativeLanguageActivated) {
        return (
          <Tooltip
            title={getLanguageById(job.langToId)}
            subtitle={intl.formatMessage({
              id: translationKeys.jobs_page_original_language_tooltip,
            })}
          >
            <FontAwesomeIcon
              icon={faReply}
              className={styles.icon}
              data-testid="job-language-activated"
            />
          </Tooltip>
        );
      }

      return (
        <Tooltip
          title={getLanguageById(job.alternativeLangToId || job.langToId)}
          subtitle={intl.formatMessage({
            id: translationKeys.jobs_page_alternative_language_tooltip,
          })}
        >
          <FontAwesomeIcon
            icon={faClock}
            className={styles.icon}
            data-testid="job-language-processing"
          />
        </Tooltip>
      );
    }

    return null;
  };

  const getStatusTagColor = () => {
    if (job.status === ManagerJobStatus.Published) {
      return 'neutral';
    }

    if (job.status === ManagerJobStatus.Accepted) {
      return 'success';
    }

    if (job.status === ManagerJobStatus.InProgress) {
      return 'warning';
    }

    if (job.status === ManagerJobStatus.Finished) {
      return 'success';
    }

    if (job.status === ManagerJobStatus.Closed) {
      return 'neutral';
    }

    if (job.status === ManagerJobStatus.Cancelled) {
      return 'error';
    }

    return 'neutral';
  };

  return (
    <tr className={styles.jobRow} data-testid="job-item">
      <td className={classnames(styles.hasIndicator, styles[job.pricingTemplateUiColor || ''])}>
        <div
          className={classnames({
            [styles.stuck]: activeTab === JobsTab.Stuck,
          })}
        >
          <div>
            <div
              className={classNames(
                styles.tableTitleText,
                isFeatureActive(Feature.Interpretation) && styles.godmodeLink,
              )}
            >
              <span className={styles.tableTitleText} data-testid="job-id">
                <a target="_blank" href={job.godmodeDemanderUrl} rel="noreferrer">
                  {job.id}
                </a>
              </span>

              {job.godmodeSupplierUrl && (
                <div data-testid="supplier-link">
                  [
                  <a target="_blank" href={job.godmodeSupplierUrl} rel="noreferrer">
                    T
                  </a>
                  ]
                </div>
              )}
            </div>
            {isFeatureActive(Feature.Interpretation) && (
              <Link
                to={`/jobs/${job.id}`}
                data-testid="subtask-id"
                className={styles.tableTitleText}
              >
                {job.id}
              </Link>
            )}
          </div>
          {activeTab === JobsTab.Stuck &&
            job.financeStatus === ManagerJobFinanceStatus.NotReadyForFinance && (
              <div>
                <Tooltip
                  title={intl.formatMessage({
                    id: translationKeys.jobs_page_returned_by_finance_tooltip,
                  })}
                >
                  <FontAwesomeIcon icon={faReply} />
                </Tooltip>
              </div>
            )}
        </div>
      </td>
      <td>
        <span className={styles.tableTitleText} data-testid="job-date">
          {format(jobStartTimestamp, 'dd.MM.yyyy')}
        </span>
        <span className={styles.details} data-testid="job-time">
          {format(jobStartTimestamp, 'HH:mm')} - {format(finishTimestamp, 'HH:mm')}
        </span>
      </td>
      {[JobsTab.All, JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
        <td data-testid="job-status">
          <Tag color={getStatusTagColor()}>{getJobStatusName(job.status, intl)}</Tag>
        </td>
      )}
      <td>
        <div>
          {languagesLoading ? (
            <Skeleton variant="details" />
          ) : (
            <>
              <span className={styles.tableTitleText} data-testid="job-to-language">
                {getLanguageById(
                  getJobLanguageToId({
                    alternativeLangToId: job.alternativeLangToId,
                    alternativeLanguageActivated: job.alternativeLanguageActivated,
                    alternativeLanguageProcessing: job.alternativeLanguageProcessing,
                    langToId: job.langToId,
                  }),
                )}
                {getAlternativeLanguageIcon()}
              </span>
              <span className={styles.details} data-testid="job-from-language">
                {getLanguageById(job.langFromId)}
              </span>
            </>
          )}
        </div>
      </td>
      {[JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
        <td data-testid="job-session-type">
          <div className={styles.flexRow}>
            <FontAwesomeIcon icon={getAssignmentTypeIcon(job.sessionType)} />
            <span className={styles.tableTitleText}>
              {getAssignmentTypeLabel(job.sessionType, intl)}
            </span>
          </div>
        </td>
      )}
      {![JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
        <td>
          <div className={styles.hints}>
            <SessionTypeHint
              sessionType={job.sessionType}
              videoPlatformName={job.videoPlatformName}
              autoUpdateToSessionType={job.autoUpdateToSessionType}
              sessionTypeProcessing={job.sessionTypeProcessing}
              autoUpdateTime={job.autoUpdateTime}
            />

            <InviteHint
              inviteStatus={job.inviteStatus}
              invitationDelay={job.invitationDelay}
              infiniteAutoInvite={job.infiniteAutoInvite}
            />

            <DangerLevelHint
              invitationsCount={job.invitationsCount}
              dangerLevel={job.dangerLevel}
              applicationsCount={job.applicationsCount}
              lastWithdrawalAt={job.lastWithdrawalAt}
            />

            <QualificationHint
              specificQualificationRequested={job.specificQualificationRequested}
              qualificationProcessing={job.qualificationProcessing}
              qualificationPriority={job.qualificationPriority}
              autoUpdateTime={job.autoUpdateTime}
              qualificationName={job.qualificationName}
            />

            <ManualApprovalHint needsManualApproval={job.manualApprovalNeeded} />
          </div>
        </td>
      )}
      {![JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
        <td>
          <div>
            <span className={styles.tableTitleText} data-testid="job-created-at-date">
              {format(actualCreateAtTimestamp, 'dd.MM.yyyy')}
            </span>
            <span className={styles.details} data-testid="job-created-at-time">
              <span>
                {format(actualCreateAtTimestamp, 'HH:mm')}

                <Tooltip
                  title={intl.formatMessage(
                    { id: translationKeys.jobs_page_requested_by_tooltip },
                    { medium: job.bookingMechanism },
                  )}
                  subtitle={format(createAtTimestamp, 'dd.MM.yyyy HH:mm')}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className={styles.icon}
                    data-testid="job-created-at-info"
                  />
                </Tooltip>
              </span>
            </span>
          </div>
        </td>
      )}
      <td>
        <div>
          <span className={styles.indicatorIcons}>
            <Tooltip title={job.ownerEmail} copyable>
              <FontAwesomeIcon
                icon={faEnvelope}
                className={classnames(styles.icon, { [styles.active]: job.ownerEmail })}
                data-testid="job-owner-email"
              />
            </Tooltip>

            <Tooltip title={formatPhoneNumber(job.ownerPhone)} copyable>
              <FontAwesomeIcon
                icon={faPhone}
                className={classnames(styles.icon, { [styles.active]: job.ownerPhone })}
                data-testid="job-owner-phone"
              />
            </Tooltip>
            <BookerHoverCard customerUid={job.ownerUID}>
              <span className={styles.tableTitleText} data-testid="job-owner-name">
                {job.ownerName}
              </span>
            </BookerHoverCard>
          </span>
          <span className={styles.details} data-testid="job-owner-enterprise">
            {job.enterprise}
          </span>
        </div>
      </td>
      {![JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
        <>
          <td>
            <div>
              <span className={styles.tableTitleText} data-testid="job-category-item-name">
                {job.categoryItemName}
              </span>
              <span className={styles.details} data-testid="job-category-name">
                {job.categoryName}
              </span>
            </div>
          </td>
          <td>
            <span className={styles.tableTitleText} data-testid="job-booking-reference">
              {job.bookingReference}
            </span>
            <span className={styles.details} data-testid="job-payment-reference">
              {job.paymentBookingReference}
            </span>
          </td>
        </>
      )}
      {[JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
        <>
          <td>
            <div>
              <span className={styles.tableTitleText} data-testid="job-category-item-name">
                {job.paymentMethod?.orgName || job.paymentMethod.email}
              </span>
              <span className={styles.details} data-testid="job-category-name">
                {job.paymentMethod.orgNumber}
              </span>
            </div>
          </td>
          <td>
            <span
              className={classNames(styles.tableTitleText, styles.template)}
              data-testid="job-booking-reference"
            >
              {job.demanderPricingTemplate.name}
            </span>
          </td>
        </>
      )}
      {[JobsTab.Risky, JobsTab.Stuck, JobsTab.All].includes(activeTab as JobsTab) && (
        <td>
          {job.interpreter?.name && (
            <span className={styles.indicatorIcons}>
              <Tooltip
                title={job.interpreter?.email}
                subtitle={format(createAtTimestamp, 'dd.MM.yyyy HH:mm')}
                copyable
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className={classnames(styles.icon, {
                    [styles.active]: job.interpreter?.email,
                  })}
                  data-testid="job-owner-email"
                />
              </Tooltip>
              <Tooltip
                title={formatPhoneNumber(job.interpreter?.phone)}
                subtitle={format(createAtTimestamp, 'dd.MM.yyyy HH:mm')}
                copyable
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  className={classnames(styles.icon, {
                    [styles.active]: job.interpreter?.phone,
                  })}
                  data-testid="job-owner-phone"
                />
              </Tooltip>
            </span>
          )}
          <Tooltip title={job.interpreter?.name} copyable>
            <span className={styles.tableTitleText}>{job.interpreter?.name}</span>
          </Tooltip>
          <span className={classnames(styles.details, styles.qualification)}>
            {job.interpreter?.qualification}
          </span>
          {!job.interpreter?.name && <span>-</span>}
        </td>
      )}
      {![JobsTab.Risky, JobsTab.Stuck, JobsTab.All].includes(activeTab as JobsTab) && (
        <td>
          <span className={styles.tableTitleText} data-testid="job-last-activity-type">
            {job.lastActivityType ? getLastActivityName(job.lastActivityType, intl) : '-'}
          </span>
          <span className={styles.details}>
            {job.lastActivityCreatedAt && (
              <span data-testid="job-last-activity-timestamp">
                {format(new Date(job.lastActivityCreatedAt), 'dd.MM.yy HH:mm')}
              </span>
            )}
            {job.lastActivityActorName && (
              <span>
                <span className={styles.actorName}>
                  {<FormattedMessage id={translationKeys.jobs_page_last_activity_by_text} />}
                </span>
                <span data-testid="job-last-activity-actor">{job.lastActivityActorName}</span>
              </span>
            )}
          </span>
        </td>
      )}
      {![JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
        <>
          <td>
            <Badge
              variant={badgeCount > 0 ? 'active' : 'default'}
              onClick={() => setShowNotesDrawer(true)}
              data-testid="badge-button"
            >
              {badgeCount}
            </Badge>

            <JobNotesDrawer
              title={intl.formatMessage({ id: translationKeys.job_notes_drawer_title })}
              onClose={() => setShowNotesDrawer(false)}
              show={showNotesDrawer}
              job={job}
              onAddNote={onAddNote}
              onDeleteNote={onDeleteNote}
            />
          </td>
        </>
      )}
      {[JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
        <>
          <td>
            <span
              className={classNames(styles.tableTitleText, styles.template)}
              data-testid="job-booking-reference"
            >
              {job.supplierPricingTemplate ? job.supplierPricingTemplate.name : '-'}
            </span>
          </td>
        </>
      )}
    </tr>
  );
};

export default JobItem;

import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useGetJobNotesQuery, usePostJobNoteMutation } from '../Api/Endpoints/Jobs/Jobs.hooks';
import ChatSection from '../components/ChatSection/ChatSection';
import useGetJobIdFromParam from './hooks/useGetJobIdFromParam';
import JobDetails from './JobDetails/JobDetails';
import styles from './Job.module.scss';

export const Job = () => {
  const jobId = useGetJobIdFromParam();
  const { data } = useGetJobNotesQuery(jobId);

  const messages = data?.messages || [];
  const postNoteMutation = usePostJobNoteMutation();

  return (
    <Container fluid className={styles.container}>
      <Row className={styles.row}>
        <Col lg={10} className={styles.section}>
          <JobDetails />
        </Col>
        <Col lg={2} className={styles.section}>
          <ChatSection
            messages={messages}
            sendMessage={(message) =>
              jobId && postNoteMutation.mutate({ id: jobId, note: message })
            }
            isSubmitLoading={postNoteMutation.isPending}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Job;

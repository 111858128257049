import { FileState } from '@skiwo/components';

export interface AttachmentsFileState extends FileState {
  deleted?: boolean;
}

export default function appendFormAttachmentsBlob(
  formData: FormData,
  files: AttachmentsFileState[],
) {
  if (!files) return;

  files.forEach((file) => {
    const fileBlob = new Blob([file.data], {
      type: file.type,
    });

    if (file.id) {
      formData.append(`attachments[][id]`, file.id.toString());
    }

    if (file.deleted) {
      formData.append(`attachments[][deleted]`, 'true');
    }

    formData.append(`attachments[][file]`, fileBlob, file.name);
    formData.append(`attachments[][comment]`, file.name);
  });
}

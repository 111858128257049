import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { faArrowLeft, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, TextField } from '@skiwo/components';
import { Formik } from 'formik';
import * as yup from 'yup';
import screenWithPadlock from '../../assets/illustration-screen-with-padlock.svg';
import { emailRegex } from '../../helpers/regexPatterns';
import routes from '../../helpers/routes';
import { AuthLayout } from '../../layouts';
import { useApi } from '../../providers/ApiProvider';
import { useAuth } from '../../providers/AuthProvider';
import translationKeys from '../../translations/translationKeys';

interface FormValues {
  username: string;
}

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { setUserNameCache } = useAuth();
  const intl = useIntl();
  const api = useApi();
  const [resetLoading, setResetLoading] = useState(false);

  const phoneRegex = new RegExp(/^\+47\d{8}$/);
  const emailOrPhoneRegex = new RegExp(`${phoneRegex.source}|${emailRegex.source}`);

  const schema = yup.object().shape({
    username: yup
      .string()
      .matches(
        emailOrPhoneRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          { fieldName: intl.formatMessage({ id: translationKeys.forgot_password_username_label }) },
        ),
      )
      .required(
        intl.formatMessage(
          { id: translationKeys.form_error_required },
          { fieldName: intl.formatMessage({ id: translationKeys.forgot_password_username_label }) },
        ),
      ),
  });

  const resetPassword = async (username: string) => {
    await api.resetPassword(username, setResetLoading);

    setUserNameCache(username);
  };

  const handleSubmit = async (values: FormValues) => {
    await resetPassword(values.username);

    navigate(routes.checkEmail);
  };

  function handleBackToLogin() {
    navigate(routes.login);
  }

  return (
    <AuthLayout>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          username: '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, dirty }) => (
          <Form
            title={intl.formatMessage({ id: translationKeys.forgot_password_header_title })}
            description={intl.formatMessage({
              id: translationKeys.forgot_password_header_description,
            })}
            image={screenWithPadlock}
            onSubmit={handleSubmit}
          >
            <TextField
              placeholder={intl.formatMessage({
                id: translationKeys.forgot_password_username_placeholder,
              })}
              type="text"
              icon={<FontAwesomeIcon icon={faEnvelope} />}
              label={intl.formatMessage({
                id: translationKeys.forgot_password_username_label,
              })}
              size="large"
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
              errorText={touched.username ? errors.username : undefined}
              data-testid="username-input"
            />

            <Button
              size="x-large"
              type="submit"
              fullWidth
              disabled={!(isValid && dirty)}
              data-testid="reset-button"
              isLoading={resetLoading}
            >
              <FormattedMessage id={translationKeys.forgot_password_reset_button} />
            </Button>

            <Button
              icon={<FontAwesomeIcon icon={faArrowLeft} />}
              fullWidth
              iconPosition="left"
              size="large"
              variant="transparent"
              onClick={handleBackToLogin}
              data-testid="back-to-login-button"
            >
              <FormattedMessage id={translationKeys.forgot_password_back_to_login_button} />
            </Button>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default ForgotPassword;

import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {
  faBuilding,
  faChevronDown,
  faChevronUp,
  faLanguage,
  faMessages,
  faUserPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@skiwo/components';
import CreateDepartmentDrawer from '../../Drawers/CreateDepartmentDrawer/CreateDepartmentDrawer';
import CreateSupplierDrawer from '../../Drawers/CreateSupplierDrawer/CreateSupplierDrawer';
import urls from '../../helpers/routes';
import { useFeatureToggle } from '../../providers/FeatureToggleProvider';
import QualificationsProvider from '../../providers/QualificationsProvider';
import translationKeys from '../../translations/translationKeys';
import { Feature } from '../../types';
import CreateCustomerDrawer from './CreateCustomerDrawer';
import styles from './CreateDropdown.module.scss';

const CreateDropdown = () => {
  const [menuShown, setMenuShown] = useState(false);
  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
  const [showDepartmentDrawer, setShowDepartmentDrawer] = useState(false);
  const { isFeatureActive } = useFeatureToggle();

  const toggleMenu = () => {
    setMenuShown(!menuShown);
  };

  const showCreateSupplier = () => {
    setShowSupplierModal(true);
  };

  const showCreateDepartment = () => {
    setShowDepartmentDrawer(true);
  };

  return (
    <NavDropdown
      title={
        <Button
          size="large"
          icon={<FontAwesomeIcon icon={menuShown ? faChevronUp : faChevronDown} />}
          iconPosition="right"
          data-testid="create-dropdown"
          className={styles.createMenuBtn}
        >
          <FormattedMessage id={translationKeys.menu_create_button} />
        </Button>
      }
      align="end"
      onToggle={toggleMenu}
      className={styles.createMenu}
      data-testid="settings-dropdown"
    >
      <span className={styles.label}>
        <FormattedMessage id={translationKeys.menu_create_assignment_label} />
      </span>
      <hr />
      <NavDropdown.Item
        href={urls.createInterpretationOrder}
        disabled={!isFeatureActive(Feature.CreateInterpretationOrder)}
      >
        <FontAwesomeIcon icon={faMessages} />
        <FormattedMessage id={translationKeys.menu_create_new_interpretation} />
      </NavDropdown.Item>

      <NavDropdown.Item
        href={urls.createTranslationOrder}
        disabled={!isFeatureActive(Feature.CreateTranslationOrder)}
      >
        <FontAwesomeIcon icon={faLanguage} />
        <FormattedMessage id={translationKeys.menu_create_new_translation} />
      </NavDropdown.Item>

      <span className={styles.label}>
        <FormattedMessage id={translationKeys.menu_create_other_label} />
      </span>
      <hr />
      <NavDropdown.Item href={`#`} onClick={() => setCreateDrawerOpen(true)}>
        <FontAwesomeIcon icon={faUserPlus} />
        <FormattedMessage id={translationKeys.menu_create_new_customer} />
      </NavDropdown.Item>

      <NavDropdown.Item onClick={showCreateSupplier}>
        <FontAwesomeIcon icon={faUserPlus} />
        <FormattedMessage id={translationKeys.menu_create_new_supplier} />
      </NavDropdown.Item>

      <NavDropdown.Item href={'#'} disabled={true}>
        <FontAwesomeIcon icon={faBuilding} />
        <FormattedMessage id={translationKeys.menu_create_new_enterprise} />
      </NavDropdown.Item>

      <NavDropdown.Item onClick={showCreateDepartment}>
        <FontAwesomeIcon icon={faBuilding} />
        <FormattedMessage id={translationKeys.menu_create_new_department} />
      </NavDropdown.Item>

      <QualificationsProvider>
        <CreateSupplierDrawer
          show={showSupplierModal}
          onClose={() => setShowSupplierModal(false)}
          onComplete={() => setShowSupplierModal(false)}
        />
      </QualificationsProvider>

      <CreateCustomerDrawer show={createDrawerOpen} onClose={() => setCreateDrawerOpen(false)} />

      <CreateDepartmentDrawer
        show={showDepartmentDrawer}
        onClose={() => setShowDepartmentDrawer(false)}
        onComplete={() => setShowDepartmentDrawer(false)}
      />
    </NavDropdown>
  );
};

export default CreateDropdown;

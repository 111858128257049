import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Banner,
  Button,
  Drawer,
  SearchDropdown,
  SearchDropdownMenuOption,
} from '@skiwo/components';
import useDebounce from '../../hooks/useDebounce';
import { useApi } from '../../providers/ApiProvider';
import translationKeys from '../../translations/translationKeys';
import { Enterprise } from '../../types';
import { ManagerCustomerDetails } from '../../types/ManagerCustomer';
import styles from './MoveMemberDrawer.module.scss';

interface Props {
  customer: Pick<
    ManagerCustomerDetails,
    'id' | 'name' | 'email' | 'phone' | 'departments' | 'enterpriseMembershipId'
  > & {
    enterprise?: Pick<Enterprise, 'id'>;
  };
  show?: boolean;
  onClose: () => void;
  onComplete?: (hardReload?: boolean) => void;
}

enum EnterpriseType {
  ExistingEnterprises = 'Existing Enterprises',
  BrregEnterprise = 'Results From Brreg.no',
}

const MoveMemberDrawer = (props: Props) => {
  const { show, onClose, customer, onComplete } = props;
  const intl = useIntl();
  const [departmentsLoading, setDepartmentsLoading] = useState(false);
  const [moveCustomerLoading, setMoveCustomerLoading] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState<SearchDropdownMenuOption[]>([]);
  const [enterpriseOptions, setEnterpriseOptions] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedEnterprise, setSelectedEnterprise] = useState<SearchDropdownMenuOption>();
  const [selectedDepartments, setSelectedDepartments] = useState<SearchDropdownMenuOption[]>([]);
  const [moveAssignments, setMoveAssignments] = useState<boolean | undefined>(undefined);
  const debounceEnterpriseSearch = useDebounce(300);
  const api = useApi();

  const clearStates = () => {
    setDepartmentOptions([]);
    setSelectedEnterprise(undefined);
    setSelectedDepartments([]);
    setMoveAssignments(undefined);
  };

  const handleSubmit = async () => {
    const enterprise = selectedEnterprise;
    if (!(enterprise?.key && customer.enterprise?.id && customer.enterpriseMembershipId)) return;

    const { error } = await api.moveCustomer(
      customer.enterprise.id.toString(),
      customer.enterpriseMembershipId.toString(),
      {
        orgNumber: enterprise.key.toString(),
        departmentIds: selectedDepartments.map((department) => parseInt(department.key)),
        moveAssignments,
      },
      setMoveCustomerLoading,
    );

    if (!error) {
      clearStates();
      onComplete?.();
      onClose();
    }
  };

  const handleDepartmentSelect = (departments: SearchDropdownMenuOption[]) => {
    setSelectedDepartments([...departments]);
  };

  const getEnterpriseList = async (query: string) => {
    if (!query.length) return;

    const { data } = await api.searchEnterprises({
      query,
      search_brreg: true,
    });

    if (data) {
      const enterpriseOptions =
        data.enterprises
          .filter((enterprise) => enterprise.id !== customer.enterprise?.id)
          .map((enterprise) => {
            return {
              id: enterprise.id,
              label: enterprise.name,
              key: enterprise.orgNumber,
              group: EnterpriseType.ExistingEnterprises,
            };
          }) || [];

      const brregOptions =
        data.brregSearch.map((enterprise) => {
          return {
            id: parseInt(enterprise.orgNumber),
            label: enterprise.name,
            key: enterprise.orgNumber,
            group: EnterpriseType.BrregEnterprise,
          };
        }) || [];

      setEnterpriseOptions([...enterpriseOptions, ...brregOptions]);
    }
  };

  const getEnterpriseDepartments = async () => {
    if (
      !(selectedEnterprise?.id && selectedEnterprise?.group === EnterpriseType.ExistingEnterprises)
    )
      return;
    const enterpriseId = selectedEnterprise.id?.toString();
    if (!enterpriseId) return;

    const { data } = await api.getEnterpriseDepartments(enterpriseId, false, setDepartmentsLoading);

    if (data?.departments) {
      const dropdownOptions =
        data?.departments.map((department) => {
          return { id: department.id, label: department.name, key: department.id.toString() };
        }) || [];

      setDepartmentOptions(dropdownOptions);
      const customerDepartments = customer.departments?.map((department) => department.id);

      const selectedDepartmentOptions = dropdownOptions.filter((department) =>
        customerDepartments?.includes(department.id),
      );

      setSelectedDepartments(selectedDepartmentOptions);
    }
  };

  useEffect(() => {
    if (!show) return;
    clearStates();
  }, [show]);

  useEffect(() => {
    if (!selectedEnterprise && !show) return;

    getEnterpriseDepartments();
  }, [selectedEnterprise, show]);

  return (
    <Drawer
      show={show}
      onClose={onClose}
      title={intl.formatMessage({ id: translationKeys.customers_page_move_title })}
      subtitle={intl.formatMessage(
        { id: translationKeys.customers_page_move_subtitle },
        { name: customer.name },
      )}
      data-testid="move-member-drawer"
    >
      <div className={styles.container}>
        <div className={styles.infoCard}>
          <div>
            <span className={styles.id}>
              <FormattedMessage
                id={translationKeys.customers_page_move_id_label}
                values={{ id: customer.id }}
              />
            </span>
            <h5>{customer.name}</h5>
          </div>

          <div className={styles.contactInfo}>
            <span>{customer.email}</span>
            {customer.phone && <span>{customer.phone}</span>}
          </div>
        </div>

        <div data-testid="enterprise-dropdown">
          <SearchDropdown
            options={enterpriseOptions}
            placeholder={intl.formatMessage({
              id: translationKeys.customers_page_move_enterprise_placeholder,
            })}
            label={intl.formatMessage({
              id: translationKeys.customers_page_move_enterprise_label,
            })}
            size="large"
            search
            grouped
            onSearch={(query: string) => {
              debounceEnterpriseSearch(() => {
                getEnterpriseList(query);
              });
            }}
            onChange={(enterprise) => {
              if (enterprise && enterprise.length > 0 && enterprise[0].key) {
                // clearStates();

                const selectedEnterprise = enterprise[0];
                setSelectedEnterprise(selectedEnterprise);
              }
            }}
          />
        </div>

        {selectedEnterprise && departmentOptions.length > 0 && (
          <div data-testid="department-dropdown">
            <SearchDropdown
              options={departmentOptions}
              placeholder={intl.formatMessage({
                id: translationKeys.customers_page_move_departments_placeholder,
              })}
              label={intl.formatMessage({
                id: translationKeys.customers_page_move_departments_label,
              })}
              multiple
              disabled={!selectedEnterprise || departmentsLoading}
              size="large"
              selected={selectedDepartments}
              onChange={(departments) => {
                handleDepartmentSelect(departments || []);
              }}
            />
          </div>
        )}

        <div className={styles.moveAssignments}>
          <Form.Check
            type="radio"
            label={intl.formatMessage({
              id: translationKeys.customers_page_move_do_not_move_assignments,
            })}
            name="moveAssignments"
            onChange={() => setMoveAssignments(false)}
            data-testid="not-move-assignments-toggle"
          />
          <Form.Check
            type="radio"
            label={intl.formatMessage({
              id: translationKeys.customers_page_move_move_all_assignments,
            })}
            name="moveAssignments"
            onChange={() => setMoveAssignments(true)}
            data-testid="move-assignments-toggle"
          />
        </div>

        {moveAssignments != null && selectedEnterprise && (
          <Banner
            variant="warning"
            text={intl.formatMessage(
              {
                id: moveAssignments
                  ? translationKeys.customers_page_move_move_assignments_message
                  : translationKeys.customers_page_move_do_not_move_assignments_message,
              },
              {
                name: customer.name,
                enterpriseName: selectedEnterprise?.label,
              },
            )}
          />
        )}

        <div className={styles.actions}>
          <Button size="large" variant="gray" onClick={onClose} data-testid="cancel-button">
            <FormattedMessage id={translationKeys.customers_page_move_cancel_button} />
          </Button>

          <Button
            size="large"
            type="submit"
            onClick={handleSubmit}
            disabled={moveAssignments == null}
            data-testid="submit-button"
            isLoading={moveCustomerLoading}
          >
            <FormattedMessage id={translationKeys.customers_page_move_move_button} />
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default MoveMemberDrawer;

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { faCircleDollar, faFileInvoice } from '@fortawesome/pro-solid-svg-icons';
import {
  useGetDemanderInvoicesQuery,
  useGetJobQuery,
  useGetSupplierInvoicesQuery,
} from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import getFirstLetterCapitalized from '../../../helpers/getFirstLetterCapitalized';
import translationKeys from '../../../translations/translationKeys';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import FinanceCard from './FinanceCard';
import FinanceDrawer, { FinanceBreakdownTab } from './FinanceDrawer';
import styles from './FinanceSection.module.scss';

const getFormattedCurrency = (value?: number | null) => `${(value ?? 0).toFixed(2)} NOK`;

interface GetLabelProps {
  error: boolean;
  errorMsg: string;
  loading: boolean;
  loadingMsg: string;
  successMsg: string;
}

const getLabel = ({ error, errorMsg, loading, loadingMsg, successMsg }: GetLabelProps) => {
  if (error) return errorMsg;
  if (loading) return loadingMsg;
  return successMsg;
};

export default function FinanceSection() {
  const intl = useIntl();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const [visibleDrawer, setVisibleDrawer] = useState<FinanceBreakdownTab>();

  const { data: supplier, error: supplierError } = useGetSupplierInvoicesQuery(job?.id.toString());
  const { data: demander, error: demanderError } = useGetDemanderInvoicesQuery(job?.id.toString());

  if (!job) return null;

  const isDemanderLoading = !demander; // TODO add loading based on the invoice change
  const isSupplierLoading = !supplier; // TODO add loading based on the invoice change

  return job.acceptedInterpreter ? (
    <>
      <div className={styles.financeSection}>
        <FinanceCard
          title={intl.formatMessage({ id: translationKeys.job_customer_invoice_title })}
          icon={faFileInvoice}
          buttonText={intl.formatMessage({ id: translationKeys.job_customer_invoice_button_label })}
          totalTitle={getLabel({
            error: !!demanderError,
            errorMsg: intl.formatMessage({ id: translationKeys.job_finance_card_error_label }),
            loading: isDemanderLoading,
            loadingMsg: intl.formatMessage({ id: translationKeys.job_finance_card_updating_label }),
            successMsg: getFormattedCurrency(demander?.totalAmount),
          })}
          totalSubtitle={getLabel({
            error: !!demanderError,
            errorMsg: intl.formatMessage({
              id: translationKeys.job_customer_invoice_error_subtitle,
            }),
            loading: isDemanderLoading,
            loadingMsg: intl.formatMessage({
              id: translationKeys.job_customer_invoice_loading_subtitle,
            }),
            successMsg: intl.formatMessage({ id: translationKeys.job_finance_card_total_subtitle }),
          })}
          tags={[
            { children: getFirstLetterCapitalized(demander?.financeType), color: 'info' },
            { children: demander?.templateName, color: 'info' },
          ]}
          status={demander?.status}
          onButtonClick={() => setVisibleDrawer(FinanceBreakdownTab.Invoice)}
        />
        <FinanceCard
          title={intl.formatMessage({ id: translationKeys.job_interpreter_payout_title })}
          icon={faCircleDollar}
          buttonText={intl.formatMessage({
            id: translationKeys.job_interpreter_payout_button_label,
          })}
          totalTitle={getLabel({
            error: !!supplierError,
            errorMsg: intl.formatMessage({ id: translationKeys.job_finance_card_error_label }),
            loading: isSupplierLoading,
            loadingMsg: intl.formatMessage({ id: translationKeys.job_finance_card_updating_label }),
            successMsg: getFormattedCurrency(supplier?.totalAmount),
          })}
          totalSubtitle={getLabel({
            error: !!supplierError,
            errorMsg: intl.formatMessage({
              id: translationKeys.job_interpreter_payout_error_subtitle,
            }),
            loading: isSupplierLoading,
            loadingMsg: intl.formatMessage({
              id: translationKeys.job_interpreter_payout_loading_subtitle,
            }),
            successMsg: intl.formatMessage({ id: translationKeys.job_finance_card_total_subtitle }),
          })}
          tags={[
            { children: getFirstLetterCapitalized(supplier?.financeType), color: 'success' },
            { children: supplier?.templateName, color: 'success' },
          ]}
          status={supplier?.status}
          onButtonClick={() => setVisibleDrawer(FinanceBreakdownTab.Payout)}
        />
      </div>
      {visibleDrawer && (
        <FinanceDrawer
          show={visibleDrawer !== undefined}
          onClose={() => setVisibleDrawer(undefined)}
          activeTab={visibleDrawer}
          invoiceData={demander || undefined}
          payoutData={supplier || undefined}
        />
      )}
    </>
  ) : (
    <> </>
  );
}

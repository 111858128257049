import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faBuilding,
  faCalendar,
  faEnvelope,
  faFile,
  faFileInvoice,
  faFilePen,
  faLanguage,
  faLock,
  faMailbox,
  faPhone,
  faSlidersUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, FileState, Tooltip } from '@skiwo/components';
import { FormikProps } from 'formik';
import { CustomerShape } from '../../components/SearchCustomerSection/SearchCustomerSection';
import getAccessLevelName from '../../InterpretationSkills/utils/getAccessLevelName';
import { useLanguages } from '../../providers/LanguagesProvider';
import getSubtaskTypeName from '../../TranslationOrder/utils/getSubtaskTypeName';
import translationKeys from '../../translations/translationKeys';
import {
  CustomerPaymentMethod,
  ManagerOrderDeliveryMethod,
  ManagerOrderSubtaskType,
} from '../../types';
import { CreateTranslationOrderFormValues } from '../CreateTranslationOrder';
import SummaryItem from './SummaryItem/SummaryItem';
import SummarySectionContainer from './SummarySectionContainer/SummarySectionContainer';
import styles from './SummaryView.module.scss';

interface SummaryViewProps {
  customer: CustomerShape;
  selectedDepartment?: string;
  paymentMethod?: CustomerPaymentMethod;
  postageAddressRequired: boolean;
  selectedTranslationMethod?: string;
  formikProps: FormikProps<CreateTranslationOrderFormValues>;
}

export const SummaryView = ({
  customer,
  selectedDepartment,
  paymentMethod,
  postageAddressRequired,
  selectedTranslationMethod,
  formikProps,
}: SummaryViewProps) => {
  const intl = useIntl();
  const { getLanguageById } = useLanguages();

  const handleFileDownload = (file: FileState) => {
    const blob = new Blob([file.data], { type: file.type });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    link.click();

    URL.revokeObjectURL(url);
  };

  const getSubtaskTypeIcon = (subtaskType: ManagerOrderSubtaskType) => {
    switch (subtaskType) {
      case ManagerOrderSubtaskType.Translation:
        return <FontAwesomeIcon icon={faFile} />;
      case ManagerOrderSubtaskType.Correction:
        return <FontAwesomeIcon icon={faFilePen} />;
      case ManagerOrderSubtaskType.InPerson:
        return <FontAwesomeIcon icon={faBuilding} />;

      default:
        return null;
    }
  };

  return (
    <div className={styles.summaryView} data-testid="summary-view">
      <SummarySectionContainer
        icon={<FontAwesomeIcon icon={faBuilding} />}
        title={intl.formatMessage({
          id: translationKeys.create_translation_order_summary_customer_label,
        })}
      >
        {formikProps.values.bookerName && (
          <SummaryItem
            label={intl.formatMessage({
              id: translationKeys.create_translation_order_summary_customer_booker_label,
            })}
          >
            <span>{formikProps.values.bookerName}</span>
          </SummaryItem>
        )}
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_translation_order_summary_customer_name_label,
          })}
        >
          <span>{customer.name}</span>
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_translation_order_summary_customer_email_label,
          })}
        >
          <div className={styles.flexRow}>
            <Tooltip title={customer.email} copyable>
              <FontAwesomeIcon icon={faEnvelope} />
            </Tooltip>
            <span>{customer.email}</span>
          </div>
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_translation_order_summary_customer_phone_label,
          })}
        >
          {customer.phoneNumber && customer.phone ? (
            <div className={styles.flexRow}>
              <Tooltip title={customer.phone} copyable>
                <FontAwesomeIcon icon={faPhone} />
              </Tooltip>
              <span>{customer.phoneNumber}</span>
            </div>
          ) : (
            <span>-</span>
          )}
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_translation_order_summary_customer_enterprise_label,
          })}
        >
          <div className={styles.flexRow}>
            {customer.enterprise?.logoUrl && (
              <Avatar
                size="small"
                altText={customer.enterprise?.name || ''}
                url={customer.enterprise.logoUrl}
              />
            )}
            <span>{customer.enterprise?.name}</span>
          </div>
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_translation_order_summary_customer_department_label,
          })}
        >
          <span>{selectedDepartment}</span>
        </SummaryItem>
      </SummarySectionContainer>
      <hr />
      <SummarySectionContainer
        icon={<FontAwesomeIcon icon={faFileInvoice} />}
        title={intl.formatMessage({
          id: translationKeys.create_translation_order_summary_invoicing_label,
        })}
      >
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_translation_order_summary_invoicing_payment_method_label,
          })}
        >
          <div>
            <div className={styles.flexRow}>
              <span>
                {paymentMethod?.orgNumber ? paymentMethod?.orgName || '-' : paymentMethod?.email}
              </span>
              {paymentMethod?.default && (
                <span className={styles.defaultBadge}>
                  {intl.formatMessage({ id: translationKeys.radio_options_default_label })}
                </span>
              )}
            </div>
            <span className={styles.description}>{paymentMethod?.orgNumber || 'Email'}</span>
          </div>
        </SummaryItem>
        {paymentMethod?.allowBookingReference && (
          <SummaryItem label={paymentMethod.labelForBookingReference}>
            {formikProps.values.bookingReference || '-'}
          </SummaryItem>
        )}
        {paymentMethod?.allowPaymentBookingReference && (
          <SummaryItem label={paymentMethod.labelForPaymentBookingReference}>
            {formikProps.values.paymentBookingReference || '-'}
          </SummaryItem>
        )}
        {paymentMethod?.allowCaseNumber && (
          <SummaryItem label={paymentMethod.labelForCaseNumber}>
            {formikProps.values.caseNumber || '-'}
          </SummaryItem>
        )}
      </SummarySectionContainer>
      <hr />
      {formikProps.values.deliveryMethod === ManagerOrderDeliveryMethod.Post && (
        <>
          <SummarySectionContainer
            icon={<FontAwesomeIcon icon={faMailbox} />}
            title={intl.formatMessage({
              id: translationKeys.create_translation_order_summary_postage_label,
            })}
          >
            {!!formikProps.values.coverLetters.length && (
              <SummaryItem
                label={intl.formatMessage({
                  id: translationKeys.create_translation_order_summary_postage_cover_letter_label,
                })}
              >
                <div>
                  <div className={styles.fileItem}>
                    <FontAwesomeIcon icon={faFile} className={styles.fileIcon} />
                    <button
                      className={styles.fileButton}
                      onClick={() => handleFileDownload(formikProps.values.coverLetters[0])}
                    >
                      {formikProps.values.coverLetters[0].name}
                    </button>
                  </div>
                  <span className={styles.description}>
                    {!postageAddressRequired &&
                      intl.formatMessage({
                        id: translationKeys.create_translation_order_summary_postage_file_has_address,
                      })}
                  </span>
                </div>
              </SummaryItem>
            )}
            {postageAddressRequired && (
              <>
                <span className={styles.returnAddressLabel}>
                  <FormattedMessage
                    id={
                      translationKeys.create_translation_order_summary_postage_return_address_label
                    }
                  />
                </span>
                <SummaryItem
                  label={intl.formatMessage({
                    id: translationKeys.create_translation_order_summary_postage_name_label,
                  })}
                >
                  <span>{formikProps.values.sendByPostName || '-'}</span>
                </SummaryItem>
                <SummaryItem
                  label={intl.formatMessage({
                    id: translationKeys.create_translation_order_summary_postage_company_label,
                  })}
                >
                  <span>{formikProps.values.sendByPostEnterpriseName || '-'}</span>
                </SummaryItem>
                <SummaryItem
                  label={intl.formatMessage({
                    id: translationKeys.create_translation_order_summary_postage_address_label,
                  })}
                >
                  <span>{formikProps.values.sendByPostAddress || '-'}</span>
                </SummaryItem>
              </>
            )}
          </SummarySectionContainer>
          <hr />
        </>
      )}
      <SummarySectionContainer
        icon={<FontAwesomeIcon icon={faLanguage} />}
        title={intl.formatMessage({
          id: translationKeys.create_translation_order_summary_information_label,
        })}
      >
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_translation_order_summary_information_type_label,
          })}
        >
          <div className={styles.flexRow}>
            <div className={styles.icon}>
              {getSubtaskTypeIcon(formikProps.values.bookedSubtaskType)}
            </div>
            <span>{getSubtaskTypeName(formikProps.values.bookedSubtaskType, intl)}</span>
          </div>
        </SummaryItem>
        {formikProps.values.sourceLanguageId && (
          <SummaryItem
            label={intl.formatMessage({
              id: translationKeys.create_translation_order_summary_information_from_label,
            })}
          >
            <span>{getLanguageById(parseInt(formikProps.values.sourceLanguageId))}</span>
          </SummaryItem>
        )}
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_translation_order_summary_information_to_label,
          })}
        >
          <div className={styles.textList}>
            {formikProps.values.targetLanguageIds.map((languageId) => {
              return <span key={languageId}>{getLanguageById(parseInt(languageId))}</span>;
            })}
          </div>
        </SummaryItem>
        {!!formikProps.values.attachments.length && (
          <SummaryItem
            label={intl.formatMessage(
              {
                id: translationKeys.create_translation_order_summary_information_files_label,
              },
              {
                filesNumber:
                  !!formikProps.values.attachments.length &&
                  `${formikProps.values.attachments.length}`,
              },
            )}
          >
            <div className={styles.filesList}>
              {formikProps.values.attachments.map((file) => {
                return (
                  <div key={file.uid}>
                    {file.uid && (
                      <div className={styles.fileItem}>
                        <FontAwesomeIcon
                          icon={formikProps.values.filePasswords[file.uid] ? faLock : faFile}
                          className={styles.fileIcon}
                        />
                        <button
                          className={styles.fileButton}
                          onClick={() => handleFileDownload(file)}
                        >
                          {file.name}
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </SummaryItem>
        )}
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_translation_order_summary_information_word_count_label,
          })}
        >
          <span>{formikProps.values.countOfWords || '-'}</span>
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_translation_order_summary_information_deadline_label,
          })}
        >
          <div className={styles.flexRow}>
            <div className={styles.icon}>
              <FontAwesomeIcon icon={faCalendar} />
            </div>
            <span>{formikProps.values.externalDeadline || 'Flexible'}</span>
          </div>
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_translation_order_summary_information_subject_label,
          })}
        >
          <span>
            {formikProps.values.translationMethodId
              ? selectedTranslationMethod
              : formikProps.values.otherSubject}
          </span>
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_translation_order_summary_information_description_label,
          })}
        >
          <span>{formikProps.values.instructionsFromDemander || '-'}</span>
        </SummaryItem>
      </SummarySectionContainer>
      {(!!formikProps.values.ccEmails.length || formikProps.values.accessLevel) && (
        <>
          <hr />
          <SummarySectionContainer
            icon={<FontAwesomeIcon icon={faSlidersUp} />}
            title={intl.formatMessage({
              id: translationKeys.create_translation_order_summary_settings_label,
            })}
          >
            {!!formikProps.values.ccEmails.length && (
              <SummaryItem
                label={intl.formatMessage({
                  id: translationKeys.create_translation_order_summary_settings_cc_label,
                })}
              >
                <div className={styles.textList}>
                  {formikProps.values.ccEmails.map((email) => {
                    return <span key={email}>{email}</span>;
                  })}
                </div>
              </SummaryItem>
            )}

            {formikProps.values.accessLevel && (
              <SummaryItem
                label={intl.formatMessage({
                  id: translationKeys.create_translation_order_summary_settings_access_level_label,
                })}
              >
                <span>{getAccessLevelName(formikProps.values.accessLevel, intl)}</span>
              </SummaryItem>
            )}
          </SummarySectionContainer>
        </>
      )}
    </div>
  );
};

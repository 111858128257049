import { ManagerJobBookingReferenceFrom } from '../types/ManagerJob';

interface DefaultReferencesProps {
  allowReference?: boolean;
  customerDefaultReference?: string | null;
  departmentDefaultReference?: string | null;
  enterpriseDefaultReference?: string | null;
}

interface DefaultReferencesReturn {
  defaultReference: string;
  defaultReferenceFrom?: ManagerJobBookingReferenceFrom;
}

export const getDefaultReferences = ({
  allowReference,
  customerDefaultReference,
  departmentDefaultReference,
  enterpriseDefaultReference,
}: DefaultReferencesProps): DefaultReferencesReturn => {
  let defaultReference = '';
  let defaultReferenceFrom;

  if (allowReference) {
    if (customerDefaultReference) {
      defaultReference = customerDefaultReference;
      defaultReferenceFrom = ManagerJobBookingReferenceFrom.Owner;
    } else if (departmentDefaultReference) {
      defaultReference = departmentDefaultReference;
      defaultReferenceFrom = ManagerJobBookingReferenceFrom.Department;
    } else if (enterpriseDefaultReference) {
      defaultReference = enterpriseDefaultReference;
      defaultReferenceFrom = ManagerJobBookingReferenceFrom.Enterprise;
    }
  }

  return { defaultReference, defaultReferenceFrom };
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button } from '@skiwo/components';
import SuccessBanner from '../../../../components/SuccessBanner/SuccessBanner';
import routes from '../../../../helpers/routes';
import translationKeys from '../../../../translations/translationKeys';
import styles from './NewSkillSuccess.module.scss';

interface Props {
  supplierType: string;
  resetShowSuccess: () => void;
  resetSelectedSupplier: () => void;
  onClose: () => void;
}

const NewSkillSuccess = (props: Props) => {
  const { supplierType, resetShowSuccess, resetSelectedSupplier, onClose } = props;
  const navigate = useNavigate();

  function getLocalizedSupplierType(value: string) {
    const locale = localStorage.getItem('locale');

    if (locale === 'nb-NO') {
      if (value === 'interpreter') {
        return 'tolke';
      } else if (value === 'translator') {
        return 'oversetter';
      }
    }
    return value;
  }

  return (
    <div className={styles.successContainer} data-testid="skill-success-container">
      <SuccessBanner />
      <p className={styles.message} data-testid="localized-supplier-type-text">
        <FormattedMessage
          id={translationKeys.new_skill_success_drawer_text}
          values={{ supplierType: getLocalizedSupplierType(supplierType) }}
        />
      </p>
      <div className={styles.actions}>
        <Button
          size="large"
          variant="gray"
          data-testid="button-close"
          onClick={() => {
            if (supplierType === 'translator') {
              navigate(routes.translationSkills);
            } else {
              navigate(routes.interpretationSkills);
            }
            onClose();

            setTimeout(() => {
              resetShowSuccess();
              resetSelectedSupplier();
            }, 300);
          }}
        >
          <FormattedMessage id={translationKeys.new_skill_success_drawer_go_to_skills_button} />
        </Button>

        <Button
          size="large"
          onClick={() => {
            resetShowSuccess();
            resetSelectedSupplier();
          }}
          data-testid="new-skill-button"
        >
          <FormattedMessage id={translationKeys.new_skill_success_drawer_create_new_skill_button} />
        </Button>
      </div>
    </div>
  );
};

export default NewSkillSuccess;

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@skiwo/components';
import { stripObject } from '@skiwo/utils';
import { useGetCustomersQuery } from '../Api/Endpoints/Customers/Customers.hooks';
import usePagination from '../hooks/usePagination';
import translationKeys from '../translations/translationKeys';
import { ManagerCustomer } from '../types';
import CustomersTable from './CustomersTable/CustomersTable';
import getCustomerFilters from './utils/getCustomerFilters';
import CustomersFilterField from './CustomersFilterField';
import styles from './Customers.module.scss';

const Customers = () => {
  const { pagination, setPagination, resetPagination } = usePagination();
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [customers, setCustomers] = useState<ManagerCustomer[]>([]);

  const {
    data: customersData,
    isLoading: isCustomersLoading,
    error: customersError,
    refetch,
  } = useGetCustomersQuery({ page: pagination.page, 's[s]': 'id desc', ...stripObject(filters) });

  const handleFilterChange = (field: CustomersFilterField, value: string) => {
    resetPagination();
    setFilters(getCustomerFilters(filters, field, value));
  };

  const handleLoadMore = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  const handleReloadData = () => {
    refetch();
  };

  useEffect(() => {
    if (!customersData) return;

    const appendData = customersData.page > 1;

    setCustomers(
      appendData ? (prev) => [...prev, ...customersData.customers] : customersData.customers,
    );
    setPagination({ page: customersData.page, totalPages: customersData.pages });
  }, [customersData]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 data-testid="customers-header">
          <FormattedMessage id={translationKeys.customers_page_title} />
        </h1>
      </div>

      <div className={styles.tableContainer}>
        <CustomersTable
          customers={customers}
          error={customersError}
          isLoading={isCustomersLoading}
          onFilterChange={handleFilterChange}
          onCompleteAction={handleReloadData}
        />
      </div>

      {!isCustomersLoading && customers && pagination.page < pagination.totalPages && (
        <div className={styles.loadMoreButton}>
          <Button
            variant="secondary"
            size="large"
            onClick={handleLoadMore}
            data-testid="load-more-button"
          >
            <FormattedMessage id={translationKeys.jobs_page_load_more_button} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Customers;

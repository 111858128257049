enum JobsTab {
  Urgent = 'urgent',
  NextUp = 'nextUp',
  OtherPublished = 'otherPublished',
  Vip = 'vip',
  All = 'all',
  Risky = 'risky',
  Stuck = 'stuck',
}

export default JobsTab;

import React, { useEffect, useRef, useState } from 'react';
import { Menu, MenuItem, Typeahead, TypeaheadRef } from 'react-bootstrap-typeahead';
import {
  Option,
  TypeaheadInputProps,
  TypeaheadPropsAndState,
} from 'react-bootstrap-typeahead/types/types';
import { createIntl } from 'react-intl';
import {
  faClock,
  faMagnifyingGlass as faMagnifyingGlassLight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguage } from '@skiwo/utils';
import classnames from 'classnames';
import { setMinutes } from 'date-fns';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import TextField from '../TextField/TextField';
import languages from '../translations/languages';
import translationKeys from '../translations/translationKeys';
import styles from './TimePicker.module.scss';

export interface TimePickerItem {
  id: number;
  label: string;
  key?: string | number;
  hour: string;
  minute: string;
}

const roundToNearestMinutes = (date: Date, interval: number): Date => {
  const minutes = date.getMinutes();

  const remainder = minutes % interval;

  if (remainder === 0) {
    return date;
  }

  const roundedMinutes = minutes + interval - remainder;
  const maxMinutes = 60 - interval;

  if (roundedMinutes >= maxMinutes) {
    return setMinutes(date, maxMinutes);
  }

  return setMinutes(date, roundedMinutes);
};

const MAX_INPUT_LENGTH = 5;

interface Props {
  size?: 'medium' | 'large';
  hideIcon?: boolean;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  selected?: TimePickerItem[];
  hint?: string;
  onChange?: (item: TimePickerItem[] | null) => void;
  errorText?: string;
  isInvalid?: boolean;
  interval?: number;
  'data-testid'?: string;
  rangeStart?: {
    hour: string;
    minute: string;
  };
}

const TimePicker = ({
  size = 'large',
  placeholder,
  onChange,
  label,
  hideIcon = false,
  disabled = false,
  required = false,
  selected,
  hint,
  errorText,
  isInvalid = false,
  interval = 5,
  'data-testid': dataTestId,
  rangeStart,
}: Props) => {
  const inputRef = useRef<TypeaheadRef>(null);
  const userLanguage = getLanguage();
  const [selectedItems, setSelectedItems] = useState<TimePickerItem[]>(selected || []);
  const [prevItems, setPrevItems] = useState<TimePickerItem[]>(selected || []);
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });

  const generateTimeOptions = (): TimePickerItem[] => {
    const options: TimePickerItem[] = [];

    const startTime = rangeStart ?? { hour: '00', minute: '00' };

    const startMinute = parseInt(startTime.hour) * 60 + parseInt(startTime.minute);
    const startMinuteRounded = Math.ceil(startMinute / interval) * interval;

    for (let i = startMinuteRounded; i < 24 * 60; i += interval) {
      const hours = Math.floor(i / 60);
      const minutes = i % 60;
      const label = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

      options.push({
        id: i,
        label: label,
        key: i,
        hour: hours.toString().padStart(2, '0'),
        minute: minutes.toString().padStart(2, '0'),
      });
    }

    return options;
  };

  const filterBy = (option: any, state: TypeaheadPropsAndState) => {
    const { text } = state;

    if (text.length >= MAX_INPUT_LENGTH) {
      const [inputHour, inputMinute] = text.split(':');
      const isSameHour = inputHour === option.hour;
      const isSameMinute = option.minute === inputMinute;
      const isOneIntervalBefore = parseInt(inputMinute) - interval === parseInt(option.minute);

      return isSameHour && (isSameMinute || isOneIntervalBefore);
    }

    const normalizedText = text.toLowerCase().replace(/\s/g, '');
    const normalizedLabel = option.label.toLowerCase();

    return normalizedLabel.includes(normalizedText);
  };

  const handleOnChange = (selected: TimePickerItem[]) => {
    if (onChange) {
      setPrevItems(selected);
      setSelectedItems(selected);
      onChange(selected);
      inputRef.current?.blur();
    }
  };

  useEffect(() => {
    setPrevItems(selected || []);
    setSelectedItems(selected || []);
  }, [selected]);

  const renderMenu = (results: Option[]) => {
    const options = results as TimePickerItem[];

    const menuContent = options.map((item: TimePickerItem) => (
      <div key={item.id}>
        <MenuItem
          option={item}
          position={item.id}
          className={styles.menuItem}
          data-testid="dropdown-menu-item"
        >
          <li key={item.id}>{item.label}</li>
        </MenuItem>
      </div>
    ));

    const renderEmptyState = () => {
      return (
        <div className={styles.noItems} data-testid="empty-state-section">
          <FontAwesomeIcon className={styles.icon} icon={faMagnifyingGlassLight} />
          <span className={styles.description}>
            {intl.formatMessage({ id: translationKeys.search_dropdown_empty_items_state })}
          </span>
        </div>
      );
    };

    return (
      <Menu id="menu" className={styles.dropdownMenu} data-testid="dropdown-menu">
        <div
          className={classnames(styles.dropdownContent, {
            [styles.hasSelection]: selectedItems.length > 0,
          })}
        >
          {menuContent.length ? menuContent : renderEmptyState()}
        </div>
      </Menu>
    );
  };

  const renderInput = (inputProps: TypeaheadInputProps) => {
    const inputPlaceholder = inputProps.placeholder;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, inputRef, referenceElementRef, ...modifiedProps } = inputProps;
    const inputPropsValue = inputProps.value ? inputProps.value.toString() : '';
    const [prevVal, setPrevVal] = useState<string>(inputPropsValue);

    return (
      <div className={styles.textField}>
        <TextField
          size={size}
          {...modifiedProps}
          ref={inputRef}
          placeholder={inputPlaceholder}
          hint={hint}
          icon={!hideIcon && <FontAwesomeIcon icon={faClock} />}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value.replace(/[^0-9:]/g, '');

            const isBackspacePressed = prevVal.length > value.length;

            if (!isBackspacePressed) {
              if (value.length > MAX_INPUT_LENGTH) {
                value = value.slice(0, MAX_INPUT_LENGTH);
                return;
              }

              if (value.includes(':') && value.length <= 2) {
                return;
              }

              if (value.split(':').length > 2) {
                return;
              }

              if (value.length === 1 && parseInt(value) > 2) {
                value = `0${value}:`;
              }

              if (value.length >= 2 && !value.includes(':')) {
                value = `${value.slice(0, 2)}:${value.slice(2)}`;
              }

              const [hours, minutes] = value.split(':');

              if (hours && parseInt(hours) > 23) {
                value = '23:';
              }

              if (minutes && minutes.length === 1 && parseInt(minutes) > 5) {
                value = `${hours}:5`;
              }

              if (minutes && parseInt(minutes) > 59) {
                value = `${hours}:59`;
              }

              if (value.length === MAX_INPUT_LENGTH) {
                const date = new Date();
                date.setHours(parseInt(hours) || 0);
                date.setMinutes(parseInt(minutes) || 0);
                date.setSeconds(0);
                date.setMilliseconds(0);

                const roundedDate = roundToNearestMinutes(date, interval);
                const roundedHours = roundedDate.getHours().toString().padStart(2, '0');
                const roundedMinutes = roundedDate.getMinutes().toString().padStart(2, '0');

                value = `${roundedHours}:${roundedMinutes}`;
              }
            }

            setPrevVal(value);
            e.target.value = value;
            inputProps.onChange?.(e);
          }}
          value={inputPropsValue}
          errorText={errorText}
          isInvalid={isInvalid}
          data-testid={dataTestId}
        />
      </div>
    );
  };

  return (
    <div className={styles.timePickerDropdown}>
      <span data-testid="time-picker-dropdown-label" className={styles.label}>
        {label}
      </span>
      {required && <span>*</span>}

      <div
        data-testid="time-picker-dropdown"
        className={classnames(styles.dropdownContainer, { [styles.dropdownWithLabel]: label })}
      >
        <Typeahead
          id="time-picker-dropdown"
          labelKey="label"
          selected={selectedItems}
          options={generateTimeOptions()}
          placeholder={placeholder}
          className={classnames(styles.timePickerDropdownInput, {
            [styles.hasSelectedItems]: selectedItems.length > 0,
          })}
          ref={inputRef}
          renderInput={renderInput}
          disabled={disabled}
          maxResults={1500}
          size={size === 'large' ? 'lg' : undefined}
          onChange={(option) => handleOnChange(option as unknown as TimePickerItem[])}
          filterBy={filterBy}
          renderMenu={renderMenu}
          onFocus={() => {
            setPrevItems(selectedItems);
            setSelectedItems([]);
          }}
          onBlur={() => {
            setSelectedItems(prevItems);
          }}
        />
      </div>
    </div>
  );
};

export default TimePicker;

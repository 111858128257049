import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getFormattedDateWithHours } from '../../../../helpers/getFormattedDateWithHours';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobStatementLastUpdateLog } from '../../../../types/ManagerJobStatement';
import styles from './StatementForm.module.scss';

interface LastSavedRowProps {
  supplierFeedbackLastUpdateLog?: ManagerJobStatementLastUpdateLog;
  demanderFeedbackLastUpdateLog?: ManagerJobStatementLastUpdateLog;
  supplierLastUpdateLog?: ManagerJobStatementLastUpdateLog;
  isFeedbackLocked: boolean;
}

export const LastSavedRow = ({
  supplierFeedbackLastUpdateLog,
  demanderFeedbackLastUpdateLog,
  supplierLastUpdateLog,
  isFeedbackLocked,
}: LastSavedRowProps) => {
  return (
    <tr>
      <td className={styles.rowName}>
        <FormattedMessage id={translationKeys.job_statement_last_saved} />
      </td>
      <td>
        {demanderFeedbackLastUpdateLog ? (
          <div>
            <span className={styles.lastUpdatedAuthor}>
              {demanderFeedbackLastUpdateLog?.actorName}
            </span>
            <span className={styles.lastUpdatedDate}>
              {getFormattedDateWithHours(demanderFeedbackLastUpdateLog?.createdAt)}
            </span>
          </div>
        ) : (
          <span>-</span>
        )}
      </td>
      <td>
        {supplierFeedbackLastUpdateLog ? (
          <div>
            <span className={styles.lastUpdatedAuthor}>
              {supplierFeedbackLastUpdateLog?.actorName}
            </span>
            <span className={styles.lastUpdatedDate}>
              {getFormattedDateWithHours(supplierFeedbackLastUpdateLog?.createdAt)}
            </span>
          </div>
        ) : (
          <span>-</span>
        )}
      </td>
      {isFeedbackLocked && (
        <>
          <td>
            {supplierLastUpdateLog ? (
              <div>
                <span className={styles.lastUpdatedAuthor}>{supplierLastUpdateLog?.actorName}</span>
                <span className={styles.lastUpdatedDate}>
                  {getFormattedDateWithHours(supplierLastUpdateLog?.createdAt)}
                </span>
              </div>
            ) : (
              <span>-</span>
            )}
          </td>
          <td></td>
        </>
      )}
    </tr>
  );
};

export default LastSavedRow;

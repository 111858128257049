import { IntlShape } from 'react-intl';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobStatementSupplierTravelType } from '../../../../types/ManagerJobStatement';

export const getSupplierTravelMethodLabel = (
  travelMethod: ManagerJobStatementSupplierTravelType,
  intl: IntlShape,
) => {
  switch (travelMethod) {
    case ManagerJobStatementSupplierTravelType.Car:
      return intl.formatMessage({ id: translationKeys.job_statement_supplier_travel_method_car });

    case ManagerJobStatementSupplierTravelType.Default:
      return intl.formatMessage({
        id: translationKeys.job_statement_supplier_travel_method_default,
      });

    case ManagerJobStatementSupplierTravelType.PublicTransport:
      return intl.formatMessage({
        id: translationKeys.job_statement_supplier_travel_method_public_transport,
      });

    case ManagerJobStatementSupplierTravelType.Flight:
      return intl.formatMessage({
        id: translationKeys.job_statement_supplier_travel_method_flight,
      });
  }
};

import React from 'react';
import CustomerCard from './CustomerCard';
import InformationCard from './InformationCard';
import InvoicingCard from './InvoicingCard';
import styles from './JobFooter.module.scss';

const JobFooter = () => {
  return (
    <footer className={styles.jobFooter}>
      <InformationCard />
      <CustomerCard />
      <InvoicingCard />
    </footer>
  );
};

export default JobFooter;

import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faClock,
  faExclamationCircle,
  faRotate,
  faVenusMars,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Tooltip } from '@skiwo/components';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useGetJobQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import { getGenderLabel } from '../../../CreateInterpretationOrder/utils';
import translationKeys from '../../../translations/translationKeys';
import {
  ManagerJobDowngradeRequirement,
  ManagerJobGenderProcessing,
} from '../../../types/ManagerJob';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import RequirementCard from './RequirementCard/RequirementCard';
import styles from './RequirementsSection.module.scss';

interface RequirementsGenderCardProps {
  downgradeRequirement: (requirement: ManagerJobDowngradeRequirement) => void;
}

export const RequirementsGenderCard = ({ downgradeRequirement }: RequirementsGenderCardProps) => {
  const intl = useIntl();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const [showModal, setShowModal] = useState(false);

  if (!job) return null;

  return (
    <>
      <RequirementCard
        label={intl.formatMessage({ id: translationKeys.job_requirements_card_gender_label })}
        icons={<FontAwesomeIcon icon={faVenusMars} />}
        value={getGenderLabel(job.interpretationRequirement.sex, intl)}
      >
        {job.processRequirement.genderProcessing === ManagerJobGenderProcessing.GenderAutoUpdate &&
          job.processRequirement.autoUpdateGenderAt && (
            <div className={classNames(styles.flexRow, styles.spaceBetween)}>
              <Tooltip
                title={intl.formatMessage(
                  { id: translationKeys.job_requirements_card_gender_tooltip },
                  {
                    date: format(
                      new Date(job.processRequirement.autoUpdateGenderAt),
                      'dd.MM.yyyy HH:mm',
                    ),
                  },
                )}
              >
                <div className={styles.flexRow}>
                  <FontAwesomeIcon className={styles.clockIcon} icon={faClock} />
                  <span>
                    {format(new Date(job.processRequirement.autoUpdateGenderAt), 'dd.MM.yyyy')}
                  </span>
                </div>
              </Tooltip>
              <Button
                variant="link"
                icon={<FontAwesomeIcon icon={faRotate} />}
                className={styles.resetButton}
                onClick={() => setShowModal(true)}
              >
                <FormattedMessage id={translationKeys.job_requirements_card_reset} />
              </Button>
            </div>
          )}
        {job.processRequirement.genderProcessing ===
          ManagerJobGenderProcessing.GenderCancelNotFound && (
          <div className={classNames(styles.flexRow, styles.spaceBetween)}>
            <div className={styles.flexRow}>
              <FontAwesomeIcon className={styles.exclamationMarkIcon} icon={faExclamationCircle} />
              <span>
                <FormattedMessage
                  id={translationKeys.job_requirements_card_cancel_if_no_interpreter}
                />
              </span>
            </div>
            {/* <Button
              variant="link"
              icon={<FontAwesomeIcon icon={faRotate} />}
              className={styles.resetButton}
              onClick={() => downgradeRequirement(ManagerJobDowngradeRequirement.Gender)}
            >
              <FormattedMessage id={translationKeys.job_requirements_card_reset} />
            </Button> */}
          </div>
        )}
      </RequirementCard>
      <Modal
        show={showModal}
        title={intl.formatMessage({ id: translationKeys.job_requirements_card_reset_gender })}
        description={intl.formatMessage({
          id: translationKeys.job_requirements_card_reset_gender_description,
        })}
        onCancel={() => setShowModal(false)}
        onHide={() => setShowModal(false)}
        submitButtonText={intl.formatMessage({ id: translationKeys.job_requirements_card_confirm })}
        onSubmit={() => {
          downgradeRequirement(ManagerJobDowngradeRequirement.Gender);
          setShowModal(false);
        }}
      />
    </>
  );
};

export default RequirementsGenderCard;

import React, { useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker, TextField, TimePicker } from '@skiwo/components';
import { TimePickerItem } from '@skiwo/components/src/TimePicker/TimePicker';
import classNames from 'classnames';
import { format } from 'date-fns';
import translationKeys from '../../translations/translationKeys';
import { ManagerJobBookingMechanism } from '../../types';
import styles from './ManualBookingSection.module.scss';

interface ManualBookingActualCreatedAtProps {
  actualCreatedAtDate: Date;
  actualCreatedAtTime: TimePickerItem;
  bookingMechanism: ManagerJobBookingMechanism;
  setActualCreatedAtDate: (date: Date) => void;
  setActualCreatedAtTime: (time: TimePickerItem) => void;
  setBookingMechanism: (bookingMechanism: ManagerJobBookingMechanism) => void;
}

export const ManualBookingActualCreatedAt = ({
  actualCreatedAtDate,
  actualCreatedAtTime,
  bookingMechanism,
  setActualCreatedAtDate,
  setActualCreatedAtTime,
  setBookingMechanism,
}: ManualBookingActualCreatedAtProps) => {
  const intl = useIntl();
  const [showBookingDatePicker, setShowBookingDatePicker] = useState(false);

  return (
    <div className={styles.manualBookingSection}>
      <span className={styles.label}>
        <FormattedMessage
          id={translationKeys.create_interpretation_order_manual_booking_actual_booking_receive}
        />
      </span>
      <div className={classNames(styles.flexRow, styles['flexRow--sm'])}>
        <div>
          <TextField
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_date_label,
            })}
            placeholder={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_date_placeholder,
            })}
            icon={<FontAwesomeIcon icon={faCalendar} />}
            value={format(actualCreatedAtDate, 'dd.MM.yyyy')}
            size="large"
            onFocus={() => setShowBookingDatePicker(true)}
            type="search"
            readOnly
          />
          {showBookingDatePicker && (
            <DatePicker
              monthCount={1}
              onClose={() => setShowBookingDatePicker(false)}
              singleDate
              onChange={(date) => {
                setActualCreatedAtDate(date);
                setShowBookingDatePicker(false);
              }}
              selected={actualCreatedAtDate}
            />
          )}
        </div>

        <div className={styles.timePicker}>
          <TimePicker
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_time_label,
            })}
            placeholder="00:00"
            interval={1}
            selected={[actualCreatedAtTime]}
            onChange={(item) => {
              if (item && item.length > 0) {
                setActualCreatedAtTime(item[0]);
              }
            }}
          />
        </div>
      </div>
      <div className={styles.flexRow}>
        <div className={styles.radioItem}>
          <B_Form.Check
            type="radio"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_phone_option,
            })}
            name="bookingMechanism"
            checked={bookingMechanism === ManagerJobBookingMechanism.Phone}
            onChange={() => setBookingMechanism(ManagerJobBookingMechanism.Phone)}
          />
        </div>
        <div className={styles.radioItem}>
          <B_Form.Check
            type="radio"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_e_mail_option,
            })}
            name="bookingMechanism"
            checked={bookingMechanism === ManagerJobBookingMechanism.Email}
            onChange={() => setBookingMechanism(ManagerJobBookingMechanism.Email)}
          />
        </div>
        <div className={styles.radioItem}>
          <B_Form.Check
            type="radio"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_chat_option,
            })}
            name="bookingMechanism"
            checked={bookingMechanism === ManagerJobBookingMechanism.Chat}
            onChange={() => setBookingMechanism(ManagerJobBookingMechanism.Chat)}
          />
        </div>
        <div className={styles.radioItem}>
          <B_Form.Check
            type="radio"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_platform_option,
            })}
            name="bookingMechanism"
            checked={bookingMechanism === ManagerJobBookingMechanism.Platform}
            onChange={() => setBookingMechanism(ManagerJobBookingMechanism.Platform)}
          />
        </div>
      </div>
    </div>
  );
};

import { IntlShape } from 'react-intl';
import { CustomerSearchDropdownMenuOption } from '../components/CustomerSearchDropdown/CustomerSearchDropdown';
import translationKeys from '../translations/translationKeys';
import { ManagerCustomer } from '../types';

export default function mapManagerCustomerToOption(
  customer: ManagerCustomer,
  intl: IntlShape,
): CustomerSearchDropdownMenuOption {
  const departmentsLength = customer.departments?.length || 0;

  return {
    id: customer.id,
    uid: customer.uid,
    key: customer.uid,
    logoUrl: customer.enterprise?.logoUrl,
    name: customer.name,
    enterpriseName: customer.enterprise?.name,
    departmentName:
      departmentsLength === 1
        ? customer.departments?.[0].name || ''
        : intl.formatMessage(
            {
              id: translationKeys.global_departments_number,
            },
            { departmentsNumber: departmentsLength },
          ),
    email: customer.email,
    phone: customer.phone,
  };
}

import React from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import BooleanValue, {
  BooleanValueVariant,
} from '../../../../components/BooleanValue/BooleanValue';
import { getBooleanLabel } from '../../../../helpers/getBooleanLabel';
import { getTypesafeSetFieldValue } from '../../../../helpers/getTypesafeSetFieldValue';
import translationKeys from '../../../../translations/translationKeys';
import { StatementFormValues } from '../Statement';
import styles from './StatementForm.module.scss';

interface ChargePayRowProps {
  isFeedbackLocked: boolean;
  readonly?: boolean;
}

export const ChargePayRow = ({ isFeedbackLocked, readonly }: ChargePayRowProps) => {
  const intl = useIntl();
  const formikProps = useFormikContext<StatementFormValues>();
  const { values } = formikProps;
  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  return (
    <tr>
      <td className={styles.rowName}>
        <FormattedMessage id={translationKeys.job_statement_charge_pay} />
      </td>
      <td></td>
      <td></td>
      {isFeedbackLocked && (
        <>
          <td>
            {readonly ? (
              values.chargeable != null ? (
                <BooleanValue value={values.chargeable} variant={BooleanValueVariant.YesNo} />
              ) : (
                '-'
              )
            ) : (
              <div className={styles.flexRow}>
                <B_Form.Check
                  type="radio"
                  label={getBooleanLabel(true, intl, BooleanValueVariant.YesNo)}
                  checked={values.chargeable}
                  onChange={() => setFieldValue('chargeable', true)}
                  disabled={!isFeedbackLocked}
                />
                <B_Form.Check
                  type="radio"
                  label={getBooleanLabel(false, intl, BooleanValueVariant.YesNo)}
                  checked={!values.chargeable}
                  onChange={() => setFieldValue('chargeable', false)}
                  disabled={!isFeedbackLocked}
                />
              </div>
            )}
          </td>
          <td>
            {readonly ? (
              values.payable != null ? (
                <BooleanValue value={values.payable} variant={BooleanValueVariant.YesNo} />
              ) : (
                '-'
              )
            ) : (
              <div className={styles.flexRow}>
                <B_Form.Check
                  type="radio"
                  label={getBooleanLabel(true, intl, BooleanValueVariant.YesNo)}
                  checked={values.payable}
                  onChange={() => setFieldValue('payable', true)}
                />
                <B_Form.Check
                  type="radio"
                  label={getBooleanLabel(false, intl, BooleanValueVariant.YesNo)}
                  checked={!values.payable}
                  onChange={() => setFieldValue('payable', false)}
                />
              </div>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export default ChargePayRow;

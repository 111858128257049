import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faClock, faExclamationCircle, faRotate } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Tooltip } from '@skiwo/components';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useGetJobQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import {
  getAssignmentTypeIcon,
  getAssignmentTypeLabel,
} from '../../../CreateInterpretationOrder/utils';
import translationKeys from '../../../translations/translationKeys';
import {
  ManagerJobDowngradeRequirement,
  ManagerJobSessionTypeProcessing,
} from '../../../types/ManagerJob';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import getAlternativeSessionTypeName from '../../utils/getAlternativeSessionTypeName';
import RequirementCard from './RequirementCard/RequirementCard';
import styles from './RequirementsSection.module.scss';

interface RequirementsTypeCardProps {
  downgradeRequirement: (requirement: ManagerJobDowngradeRequirement) => void;
}

export const RequirementsTypeCard = ({ downgradeRequirement }: RequirementsTypeCardProps) => {
  const intl = useIntl();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const [showModal, setShowModal] = useState(false);

  if (!job) return null;

  const alternativeSessionTypeName =
    job.processRequirement.autoUpdateToSessionType &&
    getAlternativeSessionTypeName(job.processRequirement.autoUpdateToSessionType, intl);

  return (
    <>
      <RequirementCard
        label={intl.formatMessage({ id: translationKeys.job_requirements_card_type_label })}
        icons={
          <FontAwesomeIcon
            icon={getAssignmentTypeIcon(job.interpretationRequirement.sessionType)}
          />
        }
        value={getAssignmentTypeLabel(job.interpretationRequirement.sessionType, intl)}
      >
        {job.processRequirement.sessionTypeProcessing ===
          ManagerJobSessionTypeProcessing.SessionTypeAutoUpdate &&
          job.processRequirement.autoUpdateSessionTypeAt &&
          job.processRequirement.autoUpdateToSessionType && (
            <div className={classNames(styles.flexRow, styles.spaceBetween)}>
              <Tooltip
                title={intl.formatMessage(
                  { id: translationKeys.job_requirements_card_type_tooltip },
                  {
                    type: alternativeSessionTypeName,
                    date: format(
                      new Date(job.processRequirement.autoUpdateSessionTypeAt),
                      'dd.MM.yyyy HH:mm',
                    ),
                  },
                )}
              >
                <div className={styles.flexRow}>
                  <FontAwesomeIcon className={styles.clockIcon} icon={faClock} />
                  <span>
                    {format(new Date(job.processRequirement.autoUpdateSessionTypeAt), 'dd.MM.yyyy')}
                  </span>
                </div>
              </Tooltip>
              <Button
                variant="link"
                icon={<FontAwesomeIcon icon={faRotate} />}
                className={styles.resetButton}
                onClick={() => setShowModal(true)}
              >
                {alternativeSessionTypeName}
              </Button>
            </div>
          )}
        {job.processRequirement.sessionTypeProcessing ===
          ManagerJobSessionTypeProcessing.SessionTypeCancelNotFound && (
          <div className={styles.flexRow}>
            <FontAwesomeIcon className={styles.exclamationMarkIcon} icon={faExclamationCircle} />
            <span>
              <FormattedMessage
                id={translationKeys.job_requirements_card_cancel_if_no_interpreter}
              />
            </span>
            {/* <Button
              variant="link"
              icon={<FontAwesomeIcon icon={faRotate} />}
              className={styles.resetButton}
              onClick={() => downgradeRequirement(ManagerJobDowngradeRequirement.SessionType)}
            >
              {getAlternativeSessionTypeName(ManagerJobAutoUpdateToSessionType.Phone, intl)}
            </Button> */}
          </div>
        )}
      </RequirementCard>
      <Modal
        show={showModal}
        title={intl.formatMessage({ id: translationKeys.job_requirements_card_reset_type })}
        description={
          job.processRequirement.autoUpdateToSessionType &&
          intl.formatMessage(
            { id: translationKeys.job_requirements_card_reset_type_description },
            {
              type: alternativeSessionTypeName,
            },
          )
        }
        onCancel={() => setShowModal(false)}
        onHide={() => setShowModal(false)}
        submitButtonText={intl.formatMessage({ id: translationKeys.job_requirements_card_confirm })}
        onSubmit={() => {
          downgradeRequirement(ManagerJobDowngradeRequirement.SessionType);
          setShowModal(false);
        }}
      />
    </>
  );
};

export default RequirementsTypeCard;

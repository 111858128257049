import React, { PropsWithChildren } from 'react';
import InfoItem from '../../InfoItem/InfoItem';
import styles from './RequirementCard.module.scss';

interface RequirementCardProps {
  label: string;
  icons?: React.ReactNode;
  value?: React.ReactNode;
}

export const RequirementCard = ({
  label,
  icons,
  value,
  children,
}: PropsWithChildren<RequirementCardProps>) => {
  return (
    <div className={styles.requirementCard}>
      <InfoItem label={label} icons={icons}>
        {value}
      </InfoItem>

      <div className={styles.resetWrapper}>{children}</div>
    </div>
  );
};

export default RequirementCard;

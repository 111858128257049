import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@skiwo/components';
import { useFormikContext } from 'formik';
import { getTypesafeSetFieldValue } from '../../../../helpers/getTypesafeSetFieldValue';
import { numberWithTwoDecimalsRegex } from '../../../../helpers/regexPatterns';
import translationKeys from '../../../../translations/translationKeys';
import { StatementFormValues } from '../Statement';
import styles from './StatementForm.module.scss';

interface ExpensesRowProps {
  supplierFeedbackExpenses?: string;
  isFeedbackLocked: boolean;
  readonly?: boolean;
}

export const ExpensesRow = ({
  supplierFeedbackExpenses,
  isFeedbackLocked,
  readonly,
}: ExpensesRowProps) => {
  const formikProps = useFormikContext<StatementFormValues>();
  const { values } = formikProps;
  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  return (
    <tr>
      <td className={styles.rowName}>
        <FormattedMessage id={translationKeys.job_statement_expenses} />
      </td>
      <td></td>
      <td>{supplierFeedbackExpenses ? `${supplierFeedbackExpenses} NOK` : '-'}</td>
      {isFeedbackLocked && (
        <>
          <td>
            {readonly ? (
              values.demanderTravelExpensesAmountOverride ? (
                `${parseFloat(values.demanderTravelExpensesAmountOverride).toFixed(2)} NOK`
              ) : (
                '-'
              )
            ) : (
              <div className={styles.inputTextRow}>
                <div className={styles.input}>
                  <TextField
                    value={values.demanderTravelExpensesAmountOverride}
                    onChange={(e) => {
                      const value = e.target.value.replace(',', '.');
                      const isNumber = numberWithTwoDecimalsRegex.test(value);
                      if (isNumber) {
                        setFieldValue('demanderTravelExpensesAmountOverride', value);
                      }
                    }}
                    onBlur={(e) => {
                      setFieldValue(
                        'demanderTravelExpensesAmountOverride',
                        Number(e.target.value).toFixed(2),
                      );
                    }}
                    disabled={!isFeedbackLocked}
                  />
                </div>
                <span>NOK</span>
              </div>
            )}
          </td>
          <td>
            {readonly ? (
              values.supplierTravelExpensesAmountOverride ? (
                `${values.supplierTravelExpensesAmountOverride} NOK`
              ) : (
                '-'
              )
            ) : (
              <div className={styles.inputTextRow}>
                <div className={styles.input}>
                  <TextField
                    value={values.supplierTravelExpensesAmountOverride}
                    onChange={(e) => {
                      const value = e.target.value.replace(',', '.');
                      const isNumber = numberWithTwoDecimalsRegex.test(value);
                      if (isNumber) {
                        setFieldValue('supplierTravelExpensesAmountOverride', value);
                      }
                    }}
                    onBlur={(e) => {
                      setFieldValue(
                        'supplierTravelExpensesAmountOverride',
                        Number(e.target.value).toFixed(2),
                      );
                    }}
                    disabled={!isFeedbackLocked}
                  />
                </div>
                <span>NOK</span>
              </div>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export default ExpensesRow;

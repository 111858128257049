import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faDesktop, faShieldCheck, faVideo, faWifi } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer } from '@skiwo/components';
import chromeLogo from '../../../../assets/chrome-logo.svg';
import edgeLogo from '../../../../assets/edge-logo.svg';
import firefoxLogo from '../../../../assets/firefox-logo.svg';
import safariLogo from '../../../../assets/safari-logo.svg';
import vivaldiLogo from '../../../../assets/vivaldi-logo.svg';
import translationKeys from '../../../../translations/translationKeys';
import styles from './AssignmentTypeVideoSolutionDrawer.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
}

const AssignmentTypeVideoSolutionDrawer = ({ show, onClose }: Props) => {
  const intl = useIntl();

  const browsers = [
    { name: 'Chrome', logo: chromeLogo, downloadUrl: 'https://www.google.com/chrome/' },
    {
      name: 'Safari',
      logo: safariLogo,
    },
    { name: 'Firefox', logo: firefoxLogo, downloadUrl: 'https://www.mozilla.org/firefox/new/' },
    { name: 'Vivaldi', logo: vivaldiLogo, downloadUrl: 'https://vivaldi.com/download/' },
    {
      name: 'Microsoft Edge',
      logo: edgeLogo,
      downloadUrl: 'https://www.microsoft.com/edge/download',
    },
  ];

  return (
    <Drawer
      data-testid="salita-video-drawer"
      show={show}
      onClose={onClose}
      title={intl.formatMessage({
        id: translationKeys.create_interpretation_order_video_solution_drawer_title,
      })}
      subtitle={
        <span className={styles.subtitle}>
          <FormattedMessage
            id={translationKeys.create_interpretation_order_video_solution_drawer_subtitle}
          />
        </span>
      }
    >
      <div className={styles.videoSolutionDrawer}>
        <div className={styles.item}>
          <div className={styles.icon}>
            <FontAwesomeIcon icon={faDesktop} />
          </div>
          <div className={styles.content}>
            <span className={styles.title}>
              <FormattedMessage
                id={translationKeys.create_interpretation_order_video_solution_drawer_update_title}
              />
            </span>
            <span className={styles.description}>
              <FormattedMessage
                id={
                  translationKeys.create_interpretation_order_video_solution_drawer_update_subtitle
                }
              />
              <div className={styles.browsersWrapper}>
                {browsers.map((browser, i) => {
                  return (
                    <div className={styles.browserItem} key={i}>
                      <div className={styles.browserItemContent}>
                        <img src={browser.logo} alt={browser.name} />
                        <span>{browser.name}</span>
                      </div>
                      {browser.downloadUrl ? (
                        <Button
                          className={styles.downloadButton}
                          variant="transparent"
                          href={browser.downloadUrl}
                          target="_blank"
                        >
                          <FormattedMessage
                            id={
                              translationKeys.create_interpretation_order_video_solution_drawer_download
                            }
                          />
                        </Button>
                      ) : (
                        <span className={styles.downloadButton}>
                          <FormattedMessage
                            id={
                              translationKeys.create_interpretation_order_video_solution_drawer_included_with_macos
                            }
                          />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            </span>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <FontAwesomeIcon icon={faVideo} />
          </div>
          <div>
            <span className={styles.title}>
              <FormattedMessage
                id={translationKeys.create_interpretation_order_video_solution_drawer_camera_title}
              />
            </span>
            <span className={styles.description}>
              <FormattedMessage
                id={
                  translationKeys.create_interpretation_order_video_solution_drawer_camera_subtitle
                }
              />
            </span>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <FontAwesomeIcon icon={faWifi} />
          </div>
          <div>
            <span className={styles.title}>
              <FormattedMessage
                id={translationKeys.create_interpretation_order_video_solution_drawer_wifi_title}
              />
            </span>
            <span className={styles.description}>
              <FormattedMessage
                id={translationKeys.create_interpretation_order_video_solution_drawer_wifi_subtitle}
              />
            </span>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <FontAwesomeIcon icon={faShieldCheck} />
          </div>
          <div>
            <span className={styles.title}>
              <FormattedMessage
                id={
                  translationKeys.create_interpretation_order_video_solution_drawer_permissions_title
                }
              />
            </span>
            <span className={styles.description}>
              <FormattedMessage
                id={
                  translationKeys.create_interpretation_order_video_solution_drawer_permissions_subtitle
                }
              />
            </span>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default AssignmentTypeVideoSolutionDrawer;

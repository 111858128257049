import React from 'react';
import { useIntl } from 'react-intl';
import { faClock, faUser, faUserGroup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useGetJobQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import translationKeys from '../../../translations/translationKeys';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import InfoItem from '../InfoItem/InfoItem';
import styles from './InterpretersSection.module.scss';

const getFormattedDate = (date: string) => format(new Date(date), 'dd MMM yyyy, HH:mm');

export default function InterpretersSection() {
  const intl = useIntl();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);

  if (!job) return null;

  const invitations = job.interpreterInvitations;

  return (
    <div className={styles.interpretersSection}>
      <h3 className={styles.title}>
        <FontAwesomeIcon icon={faUser} />
        {intl.formatMessage({
          id: translationKeys.job_interpreters_section_title,
        })}
      </h3>
      {!!invitations && (
        <div className={styles.infoRow}>
          <InfoItem
            label={intl.formatMessage({
              id: translationKeys.job_interpreters_next_invite_label,
            })}
            icons={<FontAwesomeIcon icon={faClock} />}
          >
            {invitations.nextInvitationTime && getFormattedDate(invitations.nextInvitationTime)}
          </InfoItem>
          <InfoItem
            label={intl.formatMessage({
              id: translationKeys.job_interpreters_invited_so_far_label,
            })}
            icons={<FontAwesomeIcon icon={faUserGroup} />}
          >
            {`${invitations.alreadyInvited}/${invitations.suitableForInvitation}`}
          </InfoItem>
          <InfoItem
            label={intl.formatMessage({
              id: translationKeys.job_interpreters_total_invited_label,
            })}
            icons={<FontAwesomeIcon icon={faUserGroup} />}
          >
            {invitations.totalSuitableInterpreters}
          </InfoItem>
          <InfoItem
            label={intl.formatMessage({
              id: translationKeys.job_interpreters_last_invited_label,
            })}
            icons={<FontAwesomeIcon icon={faUser} />}
          >
            {invitations.lastInvitedInterpreter.name &&
              invitations.lastInvitedInterpreter.invitedAt &&
              `${invitations.lastInvitedInterpreter.name} • ${getFormattedDate(
                invitations.lastInvitedInterpreter.invitedAt,
              )}`}
          </InfoItem>
        </div>
      )}
    </div>
  );
}

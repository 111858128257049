import React from 'react';
import { useIntl } from 'react-intl';
import { faBuilding, faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@skiwo/components';
import { useGetJobQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import translationKeys from '../../../translations/translationKeys';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import EditCustomerDrawer from '../EditCustomerDrawer/EditCustomerDrawer';
import {
  JobDetailsDrawerName,
  useJobDetailsDrawer,
} from '../JobDetailsDrawerContext/JobDetailsDrawerContext';
import Card from './Card/Card';

const CustomerCard = () => {
  const intl = useIntl();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const { openDrawer } = useJobDetailsDrawer();

  const handleOpenDrawer = () => openDrawer(JobDetailsDrawerName.EditCustomerDrawer);

  if (!job) return null;

  const personName = job.info.realPersonName ?? job.owner?.person?.name;

  return (
    <>
      <Card
        titleIcon={<FontAwesomeIcon icon={faBuilding} />}
        title={intl.formatMessage({
          id: translationKeys.job_customer_label,
        })}
        emptyText={intl.formatMessage({
          id: translationKeys.job_customer_empty_text,
        })}
        handleEdit={handleOpenDrawer}
      >
        {personName && (
          <Card.BodyItem
            title={intl.formatMessage({
              id: translationKeys.job_customer_name_label,
            })}
          >
            {personName}
          </Card.BodyItem>
        )}
        {job.attendees && job.attendees.length > 0 && (
          <Card.BodyItem
            title={intl.formatMessage({
              id: translationKeys.job_customer_attendees_label,
            })}
          >
            {job.attendees.map((attendee) => (
              <Card.ItemRow key={attendee.uid}>
                {attendee.phone && (
                  <Tooltip title={attendee.phone} copyable>
                    <FontAwesomeIcon icon={faPhone} />
                  </Tooltip>
                )}
                {attendee.email && (
                  <Tooltip title={attendee.email} copyable>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </Tooltip>
                )}
                {attendee.name}
              </Card.ItemRow>
            ))}
          </Card.BodyItem>
        )}
        {job.info.ccEmails && job.info.ccEmails.length > 0 && (
          <Card.BodyItem
            title={intl.formatMessage({
              id: translationKeys.job_customer_cc_label,
            })}
          >
            {job.info.ccEmails.map((email) => (
              <Card.ItemRow key={email}>{email}</Card.ItemRow>
            ))}
          </Card.BodyItem>
        )}
      </Card>
      <EditCustomerDrawer />
    </>
  );
};

export default CustomerCard;

import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import {
  faCircleCheck,
  faCirclePause,
  faCirclePlay,
  faCircleStop,
  faFilePen,
  faGraduationCap,
  faLocationDot,
  faPersonCircleCheck,
  faPersonCircleExclamation,
  faPersonCircleMinus,
  faPhone,
  faReply,
  faVideo,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  DatePicker,
  Dropdown,
  EmptyState,
  SearchDropdown,
  SearchDropdownMenuOption,
  Table,
  TextField,
} from '@skiwo/components';
import classnames from 'classnames';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ApiError } from '../../Api';
import {
  useGetDemanderPricingTemplatesQuery,
  useGetSupplierPricingTemplatesQuery,
} from '../../Api/Endpoints/Finance/Finance.hooks';
import errorStateIllustration from '../../assets/empty-table-error.svg';
import emptyStateIllustration from '../../assets/empty-table-none-available.svg';
import useDebounce from '../../hooks/useDebounce';
import { getPricingTemplateOptions } from '../../Job/utils/getPricingTemplateOptions';
import { useJobCategories } from '../../providers/JobCategoriesProvider';
import { useLanguages } from '../../providers/LanguagesProvider';
import translationKeys from '../../translations/translationKeys';
import {
  JobCategory,
  ManagerJob,
  ManagerJobInviteStatus,
  ManagerJobSessionType,
  ManagerJobStatus,
} from '../../types';
import { ManagerJobFinanceStatus } from '../../types/ManagerJob';
import JobItem from '../JobItem/JobItem';
import JobItemSkeleton from '../JobItemSkeleton/JobItemSkeleton';
import JobsFilterField from '../JobsFilterField';
import JobsTab from '../JobsTab';
import getJobStatusName from '../utils/getJobStatusName';
import styles from './JobsTable.module.scss';

interface Props {
  jobs: ManagerJob[];
  isLoading: boolean;
  error: ApiError | null;
  activeTab?: string;
  onFilterChange: (field: JobsFilterField, value: string[]) => void;
}

const JobsTable = (props: Props) => {
  const { languages } = useLanguages();
  const { jobs, isLoading, error, activeTab, onFilterChange } = props;
  const intl = useIntl();
  const [activeDatePicker, setActiveDatePicker] = useState<JobsFilterField | null>();
  const [filteredStartDate, setFilteredStartDate] = useState('');
  const [jobCategoryOptions, setJobCategoryOptions] = useState<SearchDropdownMenuOption[]>([]);
  const [filteredCreatedDate, setFilteredCreatedDate] = useState('');
  const [filterFinancialStatus, setFilterFinancialStatus] = useState(false);
  const debounceFilterChange = useDebounce(300);
  const [activeHintFilters, setActiveHintFilters] = useState<Record<string, string>>({
    [JobsFilterField.SessionType]: '',
    [JobsFilterField.InviteStatus]: '',
    [JobsFilterField.Withdrawal]: '',
    [JobsFilterField.QualificationRequested]: '',
    [JobsFilterField.NeedsManualApproval]: '',
  });
  const { jobCategories } = useJobCategories();
  const { data: demanderPricingTemplates } = useGetDemanderPricingTemplatesQuery();
  const { data: supplierPricingTemplates } = useGetSupplierPricingTemplatesQuery();

  const handleFilterChange = (field: JobsFilterField, value: string) => {
    if (Object.keys(activeHintFilters).includes(field)) {
      setActiveHintFilters({ ...activeHintFilters, ...{ [field]: value } });
    }

    debounceFilterChange(() => {
      onFilterChange(field, [value]);
    });
  };

  const handleDatePickerClose = () => {
    setActiveDatePicker(null);
  };

  const handleDatePickerChange = (field: JobsFilterField, start: Date, end?: Date) => {
    const formattedStartDate = format(start, 'dd.MM.yy');
    const formattedEndDate = end ? format(end, 'dd.MM.yy') : null;

    if (field === JobsFilterField.StartDate && end) {
      onFilterChange(JobsFilterField.StartDate, [start.toISOString(), end.toISOString()]);
      setFilteredStartDate(`${formattedStartDate} - ${formattedEndDate}`);
    }

    if (field === JobsFilterField.CreatedDate && end) {
      onFilterChange(JobsFilterField.CreatedDate, [start.toISOString(), end.toISOString()]);
      setFilteredCreatedDate(`${formattedStartDate} - ${formattedEndDate}`);
    }

    handleDatePickerClose();
  };

  const languageOptions =
    languages.map((language) => {
      return { id: language.id, label: language.name || '', key: language.id.toString() };
    }) || [];

  const statusOptions =
    Object.values(ManagerJobStatus).map((status, index) => {
      return { id: index, label: getJobStatusName(status, intl), key: status };
    }) || [];

  const handleDateChange = (field: JobsFilterField, e: React.ChangeEvent<any>) => {
    const newValue = e.currentTarget.value;
    if (!newValue) {
      if (field === JobsFilterField.StartDate) {
        setFilteredStartDate('');
      }

      if (field === JobsFilterField.CreatedDate) {
        setFilteredCreatedDate('');
      }

      onFilterChange(field, ['', '']);
      setActiveDatePicker(null);
    }
  };

  useEffect(() => {
    const categoryOptions = jobCategories.reduce(
      (acc: SearchDropdownMenuOption[], group: JobCategory) => {
        if (group.items) {
          const itemsWithGroup = group.items?.map((item) => ({
            id: item.id,
            label: item.name || '',
            group: group.name || '',
            key: item.id.toString(),
          }));
          return [...acc, ...itemsWithGroup];
        }
        return acc;
      },
      [],
    );

    setJobCategoryOptions(categoryOptions);
  }, [jobCategories]);

  if (error) {
    return (
      <div className={styles.emptyStateContainer}>
        <EmptyState
          image={errorStateIllustration}
          text={intl.formatMessage({
            id: translationKeys.jobs_page_error_state_title,
          })}
          description={intl.formatMessage({
            id: translationKeys.jobs_page_error_state_subtitle,
          })}
          refreshable
        />
      </div>
    );
  }

  const getSessionTypeIcon = () => {
    switch (activeHintFilters[JobsFilterField.SessionType]) {
      case ManagerJobSessionType.Phone:
        return faPhone;
      case ManagerJobSessionType.Video:
        return faVideo;
      case ManagerJobSessionType.InPerson:
        return faLocationDot;
      default:
        return faVideo;
    }
  };

  const getInviteStatusIcon = () => {
    switch (activeHintFilters[JobsFilterField.InviteStatus]) {
      case ManagerJobInviteStatus.Ongoing:
        return faCirclePlay;
      case ManagerJobInviteStatus.Paused:
        return faCirclePause;
      case ManagerJobInviteStatus.Finished:
        return faCircleCheck;
      case ManagerJobInviteStatus.Disabled:
        return faCircleStop;
      default:
        return faCirclePlay;
    }
  };

  const getWithdrawalIcon = () => {
    switch (activeHintFilters[JobsFilterField.Withdrawal]) {
      case 'true':
        return faPersonCircleMinus;
      case 'false':
        return faPersonCircleCheck;

      default:
        return faPersonCircleExclamation;
    }
  };

  return (
    <>
      <Table striped data-testid="jobs-table">
        <thead className={styles.tableHeader}>
          <tr className={styles.filters}>
            <th
              className={classNames(styles.idFilter, {
                [styles['idFilter--stuck']]: activeTab === JobsTab.Stuck,
              })}
            >
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.jobs_page_id_filter_placeholder,
                })}
                data-testid="id-filter"
                onChange={(e) => handleFilterChange(JobsFilterField.Id, e.currentTarget.value)}
              />
              {activeTab === JobsTab.Stuck && (
                <Button
                  variant="transparent"
                  onClick={() => {
                    setFilterFinancialStatus(!filterFinancialStatus);
                    handleFilterChange(
                      JobsFilterField.FinancialStatus,
                      filterFinancialStatus ? '' : ManagerJobFinanceStatus.NotReadyForFinance,
                    );
                  }}
                  className={
                    filterFinancialStatus ? styles[`filterButton--active`] : styles.filterButton
                  }
                >
                  <FontAwesomeIcon icon={faReply} />
                </Button>
              )}
            </th>
            <th className={styles.dateFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.jobs_page_job_date_filter_placeholder,
                })}
                data-testid="state-date-filter"
                icon={<FontAwesomeIcon icon={faCalendar} />}
                onFocus={() => setActiveDatePicker(JobsFilterField.StartDate)}
                value={filteredStartDate}
                onChange={(e) => handleDateChange(JobsFilterField.StartDate, e)}
                type="search"
              />

              {activeDatePicker === JobsFilterField.StartDate && (
                <DatePicker
                  className={styles.datePicker}
                  onClose={handleDatePickerClose}
                  onChange={(start: Date, end?: Date) =>
                    handleDatePickerChange(JobsFilterField.StartDate, start, end)
                  }
                />
              )}
            </th>
            {[JobsTab.All, JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <th className={styles.statusFilter}>
                <SearchDropdown
                  options={statusOptions}
                  placeholder={intl.formatMessage({
                    id: translationKeys.jobs_page_status_placeholder,
                  })}
                  onChange={(status) => {
                    if (status && status.length > 0 && status[0].key) {
                      handleFilterChange(JobsFilterField.Status, `${status[0].key}`);
                    } else {
                      handleFilterChange(JobsFilterField.Status, '');
                    }
                  }}
                />
              </th>
            )}
            <th data-testid="language-filter" className={styles.languageFilter}>
              <SearchDropdown
                maxResults={languageOptions.length}
                options={languageOptions}
                placeholder={intl.formatMessage({
                  id: translationKeys.jobs_page_language_filter_placeholder,
                })}
                multiple
                onChange={(languages) => {
                  if (languages && languages.length > 0) {
                    const languageIds = languages.map((language) => language.id);
                    handleFilterChange(JobsFilterField.Language, languageIds.join(','));
                  } else {
                    handleFilterChange(JobsFilterField.Language, '');
                  }
                }}
              />
            </th>
            {[JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <th className={styles.typeFilter}>
                <SearchDropdown
                  options={statusOptions}
                  placeholder={intl.formatMessage({
                    id: translationKeys.jobs_page_type_filter_placeholder,
                  })}
                  onChange={(status) => {
                    if (status && status.length > 0 && status[0].key) {
                      handleFilterChange(JobsFilterField.Status, `${status[0].key}`);
                    } else {
                      handleFilterChange(JobsFilterField.Status, '');
                    }
                  }}
                />
              </th>
            )}
            {![JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <th className={styles.hintsFilter}>
                <div className={styles.hints}>
                  <Dropdown
                    data-testid="session-type-dropdown"
                    toggle={
                      <FontAwesomeIcon
                        icon={getSessionTypeIcon()}
                        data-testid={
                          activeHintFilters[JobsFilterField.SessionType]
                            ? 'session-hint-active'
                            : 'session-hint'
                        }
                        className={classnames(styles.icon, {
                          [styles.active]: !!activeHintFilters[JobsFilterField.SessionType],
                        })}
                      />
                    }
                    onSelect={(selection) =>
                      handleFilterChange(JobsFilterField.SessionType, selection || '')
                    }
                    selectedItemId={activeHintFilters[JobsFilterField.SessionType]}
                    items={[
                      {
                        id: ManagerJobSessionType.Phone,
                        text: intl.formatMessage({
                          id: translationKeys.jobs_page_session_type_phone,
                        }),
                        icon: faPhone,
                      },
                      {
                        id: ManagerJobSessionType.Video,
                        text: intl.formatMessage({
                          id: translationKeys.jobs_page_session_type_video,
                        }),
                        icon: faVideo,
                      },
                      {
                        id: ManagerJobSessionType.InPerson,
                        text: intl.formatMessage({
                          id: translationKeys.jobs_page_session_type_in_person,
                        }),
                        icon: faLocationDot,
                      },
                    ]}
                  />

                  <Dropdown
                    data-testid="invite-status-dropdown"
                    toggle={
                      <FontAwesomeIcon
                        icon={getInviteStatusIcon()}
                        data-testid={
                          activeHintFilters[JobsFilterField.InviteStatus]
                            ? 'invite-status-hint-active'
                            : 'invite-status-hint'
                        }
                        className={classnames(styles.icon, {
                          [styles.active]: !!activeHintFilters[JobsFilterField.InviteStatus],
                        })}
                      />
                    }
                    onSelect={(selection) =>
                      handleFilterChange(JobsFilterField.InviteStatus, selection || '')
                    }
                    selectedItemId={activeHintFilters[JobsFilterField.InviteStatus]}
                    items={[
                      {
                        id: ManagerJobInviteStatus.Ongoing,
                        text: intl.formatMessage({
                          id: translationKeys.jobs_page_invite_status_ongoing,
                        }),
                        icon: faCirclePlay,
                      },
                      {
                        id: ManagerJobInviteStatus.Paused,
                        text: intl.formatMessage({
                          id: translationKeys.jobs_page_invite_status_paused,
                        }),
                        icon: faCirclePause,
                      },
                      {
                        id: ManagerJobInviteStatus.Finished,
                        text: intl.formatMessage({
                          id: translationKeys.jobs_page_invite_status_finished,
                        }),
                        icon: faCircleCheck,
                      },
                      {
                        id: ManagerJobInviteStatus.Disabled,
                        text: intl.formatMessage({
                          id: translationKeys.jobs_page_invite_status_stopped,
                        }),
                        icon: faCircleStop,
                      },
                    ]}
                  />

                  <Dropdown
                    data-testid="withdrawal-dropdown"
                    toggle={
                      <FontAwesomeIcon
                        icon={getWithdrawalIcon()}
                        data-testid={
                          activeHintFilters[JobsFilterField.Withdrawal]
                            ? 'withdrawal-hint-active'
                            : 'withdrawal-hint'
                        }
                        className={classnames(styles.icon, {
                          [styles.active]: !!activeHintFilters[JobsFilterField.Withdrawal],
                          [styles.critical]:
                            activeHintFilters[JobsFilterField.Withdrawal] === 'true',
                        })}
                      />
                    }
                    selectedItemId={activeHintFilters[JobsFilterField.Withdrawal]}
                    onSelect={(selection) =>
                      handleFilterChange(JobsFilterField.Withdrawal, selection || '')
                    }
                    items={[
                      {
                        id: 'true',
                        text: intl.formatMessage({
                          id: translationKeys.jobs_page_withdrawal_withdrawn,
                        }),
                        icon: faPersonCircleMinus,
                        variant: 'critical',
                      },
                      {
                        id: 'false',
                        text: intl.formatMessage({
                          id: translationKeys.jobs_page_withdrawal_not_withdrawn,
                        }),
                        icon: faPersonCircleCheck,
                      },
                    ]}
                  />

                  <Dropdown
                    data-testid="qualification-dropdown"
                    toggle={
                      <FontAwesomeIcon
                        icon={faGraduationCap}
                        data-testid={
                          activeHintFilters[JobsFilterField.QualificationRequested]
                            ? 'qualification-hint-active'
                            : 'qualification-hint'
                        }
                        className={classnames(styles.icon, {
                          [styles.active]:
                            !!activeHintFilters[JobsFilterField.QualificationRequested],
                          [styles.critical]:
                            activeHintFilters[JobsFilterField.QualificationRequested] === 'true',
                        })}
                      />
                    }
                    selectedItemId={activeHintFilters[JobsFilterField.QualificationRequested]}
                    onSelect={(selection) =>
                      handleFilterChange(JobsFilterField.QualificationRequested, selection || '')
                    }
                    items={[
                      {
                        id: 'true',
                        text: intl.formatMessage({
                          id: translationKeys.jobs_page_qualification_required,
                        }),
                        icon: faGraduationCap,
                        variant: 'critical',
                      },
                      {
                        id: 'false',
                        text: intl.formatMessage({
                          id: translationKeys.jobs_page_qualification_not_required,
                        }),
                        icon: faGraduationCap,
                      },
                    ]}
                  />
                  <Dropdown
                    data-testid="manual-approval-dropdown"
                    toggle={
                      <FontAwesomeIcon
                        icon={faFilePen}
                        data-testid={
                          activeHintFilters[JobsFilterField.NeedsManualApproval]
                            ? 'manual-approval-hint-active'
                            : 'manual-approval-hint'
                        }
                        className={classnames(styles.icon, {
                          [styles.active]: !!activeHintFilters[JobsFilterField.NeedsManualApproval],
                          [styles.critical]:
                            activeHintFilters[JobsFilterField.NeedsManualApproval] === 'true',
                        })}
                      />
                    }
                    selectedItemId={activeHintFilters[JobsFilterField.NeedsManualApproval]}
                    onSelect={(selection) =>
                      handleFilterChange(JobsFilterField.NeedsManualApproval, selection || '')
                    }
                    items={[
                      {
                        id: 'true',
                        text: intl.formatMessage({
                          id: translationKeys.jobs_page_manual_approved_required,
                        }),
                        icon: faFilePen,
                        variant: 'critical',
                      },
                      {
                        id: 'false',
                        text: intl.formatMessage({
                          id: translationKeys.jobs_page_other_published_assignments,
                        }),
                        icon: faFilePen,
                      },
                    ]}
                  />
                </div>
              </th>
            )}
            {![JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <th className={styles.dateFilter}>
                <TextField
                  type="search"
                  placeholder={intl.formatMessage({
                    id: translationKeys.jobs_page_created_at_filter_placeholder,
                  })}
                  data-testid="created-date-filter"
                  icon={<FontAwesomeIcon icon={faCalendar} />}
                  onFocus={() => setActiveDatePicker(JobsFilterField.CreatedDate)}
                  value={filteredCreatedDate}
                  onChange={(e) => handleDateChange(JobsFilterField.CreatedDate, e)}
                />
                {activeDatePicker === JobsFilterField.CreatedDate && (
                  <DatePicker
                    className={styles.datePicker}
                    onClose={handleDatePickerClose}
                    onChange={(start: Date, end?: Date) =>
                      handleDatePickerChange(JobsFilterField.CreatedDate, start, end)
                    }
                  />
                )}
              </th>
            )}
            <th className={styles.ownerFilter}>
              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.jobs_page_owner_participant_filter_placeholder,
                })}
                data-testid="owner-participant-filter"
                onChange={(e) =>
                  handleFilterChange(JobsFilterField.OwnerAndParticipants, e.currentTarget.value)
                }
              />
            </th>
            {[JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <th className={styles.paymentMethodFilter}>
                <TextField
                  placeholder={intl.formatMessage({
                    id: translationKeys.jobs_page_payment_method_filter_placeholder,
                  })}
                  data-testid="owner-participant-filter"
                  onChange={(e) =>
                    handleFilterChange(JobsFilterField.PaymentMethod, e.currentTarget.value)
                  }
                />
              </th>
            )}
            {[JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <th data-testid="customer-template-filter" className={styles.customerTemplateFilter}>
                {demanderPricingTemplates?.templates && (
                  <SearchDropdown
                    options={getPricingTemplateOptions(demanderPricingTemplates.templates.external)}
                    placeholder={intl.formatMessage({
                      id: translationKeys.jobs_page_customer_template_filter_placeholder,
                    })}
                    multiple
                    onChange={(customerTemplates) => {
                      if (customerTemplates && customerTemplates.length > 0) {
                        const customerTemplateIds = customerTemplates.map(
                          (customerTemplate) => customerTemplate.id,
                        );
                        handleFilterChange(
                          JobsFilterField.CustomerTemplate,
                          customerTemplateIds.join(','),
                        );
                      } else {
                        handleFilterChange(JobsFilterField.CustomerTemplate, '');
                      }
                    }}
                  />
                )}
              </th>
            )}
            {![JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <>
                <th className={styles.categoryFilter} data-testid="job-categories-filter">
                  <SearchDropdown
                    options={jobCategoryOptions}
                    placeholder={intl.formatMessage({
                      id: translationKeys.jobs_page_category_subject_filter_placeholder,
                    })}
                    grouped
                    onChange={(category) => {
                      if (category && category.length > 0 && category[0].id) {
                        handleFilterChange(
                          JobsFilterField.CategoryAndSubject,
                          category[0].id.toString(),
                        );
                      } else {
                        handleFilterChange(JobsFilterField.CategoryAndSubject, '');
                      }
                    }}
                  />
                </th>
                <th className={styles.buyerFilter}>
                  <TextField
                    placeholder={intl.formatMessage({
                      id: translationKeys.jobs_page_buyer_ref_filter_placeholder,
                    })}
                    data-testid="buyer-order-ref"
                    onChange={(e) =>
                      handleFilterChange(JobsFilterField.BuyerAndOrderRef, e.currentTarget.value)
                    }
                  />
                </th>
              </>
            )}
            {[JobsTab.All, JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <th>
                <TextField
                  placeholder={intl.formatMessage({
                    id: translationKeys.jobs_page_interpreter_placeholder,
                  })}
                  onChange={(e) =>
                    handleFilterChange(JobsFilterField.Interpreter, e.currentTarget.value)
                  }
                />
              </th>
            )}
            {[JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <th
                data-testid="interpreter-template-filter"
                className={styles.interpreterTemplateFilter}
              >
                {supplierPricingTemplates?.templates && (
                  <SearchDropdown
                    options={getPricingTemplateOptions(supplierPricingTemplates.templates.company)}
                    placeholder={intl.formatMessage({
                      id: translationKeys.jobs_page_interpreter_template_filter_placeholder,
                    })}
                    multiple
                    onChange={(interpreterTemplates) => {
                      if (interpreterTemplates && interpreterTemplates.length > 0) {
                        const interpreterTemplateIds = interpreterTemplates.map(
                          (interpreterTemplate) => interpreterTemplate.id,
                        );
                        handleFilterChange(
                          JobsFilterField.InterpreterTemplate,
                          interpreterTemplateIds.join(','),
                        );
                      } else {
                        handleFilterChange(JobsFilterField.InterpreterTemplate, '');
                      }
                    }}
                  />
                )}
              </th>
            )}
            {![JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && <th></th>}
            {![JobsTab.Risky, JobsTab.Stuck, JobsTab.All].includes(activeTab as JobsTab) && (
              <th></th>
            )}
          </tr>

          <tr className={styles.headers}>
            <th>
              <FormattedMessage id={translationKeys.jobs_page_id_header} />
            </th>
            <th>
              <FormattedMessage id={translationKeys.jobs_page_job_date_header} />
            </th>
            {[JobsTab.All, JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <th>
                <FormattedMessage id={translationKeys.jobs_page_status_header} />
              </th>
            )}
            <th>
              <FormattedMessage id={translationKeys.jobs_page_language_header} />
            </th>
            {[JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && <th>Type</th>}
            {![JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <>
                <th>
                  <FormattedMessage id={translationKeys.jobs_page_hints_header} />
                </th>
                <th>
                  <FormattedMessage id={translationKeys.jobs_page_created_at_header} />
                </th>
              </>
            )}
            <th>
              <FormattedMessage id={translationKeys.jobs_page_owner_participant_header} />
            </th>
            {![JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <>
                <th>
                  <FormattedMessage id={translationKeys.jobs_page_category_subject_header} />
                </th>
                <th>
                  <FormattedMessage id={translationKeys.jobs_page_buyer_ref_header} />
                </th>
              </>
            )}
            {[JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <th>Payment method</th>
            )}
            {[JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <th>Customer template</th>
            )}
            {[JobsTab.Risky, JobsTab.Stuck, JobsTab.All].includes(activeTab as JobsTab) && (
              <th>
                <FormattedMessage id={translationKeys.jobs_page_interpreter_header} />
              </th>
            )}
            {[JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <th>Interpreter template</th>
            )}
            {![JobsTab.Risky, JobsTab.Stuck, JobsTab.All].includes(activeTab as JobsTab) && (
              <th>
                <FormattedMessage id={translationKeys.jobs_page_last_activity_header} />
              </th>
            )}
            {![JobsTab.Risky, JobsTab.Stuck].includes(activeTab as JobsTab) && (
              <>
                <th>
                  <FormattedMessage id={translationKeys.jobs_page_notes_header} />
                </th>
              </>
            )}
          </tr>
        </thead>

        <tbody>
          {jobs.map((job) => (
            <JobItem job={job} key={job.id} activeTab={activeTab} />
          ))}

          {isLoading &&
            [...Array(3)].map((_, index) => <JobItemSkeleton key={index} activeTab={activeTab} />)}
        </tbody>
      </Table>

      {!isLoading && jobs.length === 0 && (
        <div className={styles.emptyStateContainer}>
          <EmptyState
            image={emptyStateIllustration}
            text={intl.formatMessage({
              id: translationKeys.jobs_page_empty_state_title,
            })}
            description={intl.formatMessage({
              id: translationKeys.jobs_page_empty_state_subtitle,
            })}
          />
        </div>
      )}
    </>
  );
};

export default JobsTable;

import React, { useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faTriangleExclamation, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  defaultPhoneCodeList,
  IconButton,
  PhoneCodeDropdown,
  SearchDropdownMenuOption,
  Tag,
  TextField,
} from '@skiwo/components';
import { emailRegex } from '../../helpers/regexPatterns';
import translationKeys from '../../translations/translationKeys';
import ExpandableToggle from '../ExpandableToggle/ExpandableToggle';
import styles from './CcExpandableToggle.module.scss';

interface CcExpandableToggleProps {
  active: boolean;
  emails?: string[];
  confirmationPhones?: string[];
  onActiveChange?: (active: boolean) => void;
  onAddEmail?: (email: string) => void;
  onDeleteEmail?: (email: string) => void;
  onAddPhone?: (phone: string) => void;
  onDeletePhone?: (phone: string) => void;
  isErrorVisible?: boolean;
}

const DEFAULT_COUNTRY_CODE = defaultPhoneCodeList[0];

export default function CcExpandableToggle({
  active,
  emails = [],
  confirmationPhones = [],
  onActiveChange,
  onAddEmail,
  onDeleteEmail,
  onAddPhone,
  onDeletePhone,
  isErrorVisible = false,
}: CcExpandableToggleProps) {
  const intl = useIntl();
  const [countryCode, setCountryCode] = useState<SearchDropdownMenuOption>(DEFAULT_COUNTRY_CODE);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const addPhoneNumber = () => {
    if (confirmationPhones.includes(countryCode.label + phoneNumber)) return;

    if (phoneNumber.length) {
      onAddPhone?.(countryCode.label + phoneNumber);
      setPhoneNumber('');
    }
  };

  const validateAndAddEmail = () => {
    if (emails.includes(email)) return;

    if (!email.match(emailRegex)) {
      setEmailError(true);
    } else {
      if (emailError) {
        setEmailError(false);
      }
      if (email.length) {
        onAddEmail?.(email);
        setEmail('');
      }
    }
  };

  return (
    <ExpandableToggle
      title={intl.formatMessage({
        id: translationKeys.job_order_settings_cc_title,
      })}
      description={intl.formatMessage({
        id: translationKeys.job_order_settings_cc_description,
      })}
      active={active}
      action={
        <B_Form.Check
          data-testid="cc-switch"
          type="switch"
          checked={active}
          onChange={(e) => {
            onActiveChange?.(e.target.checked);
            setEmail('');
            setPhoneNumber('');
            setEmailError(false);
            setCountryCode(DEFAULT_COUNTRY_CODE);
          }}
        />
      }
    >
      <span className={styles.additionalInfo}>
        <FormattedMessage id={translationKeys.job_order_settings_cc_text} />
      </span>
      <div>
        <B_Form.Label>
          <FormattedMessage
            id={translationKeys.job_order_settings_cc_email_label}
          />
        </B_Form.Label>
        <div className={styles.emailInputRow}>
          <div className={styles.emailInput}>
            <TextField
              placeholder={intl.formatMessage({
                id: translationKeys.job_order_settings_cc_email_label,
              })}
              size="large"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              data-testid="email"
            />
          </div>
          <Button
            variant="gray"
            size="large"
            onClick={() => {
              validateAndAddEmail();
            }}
          >
            <FormattedMessage
              id={translationKeys.job_order_settings_cc_add_button}
            />
          </Button>
        </div>
        {emailError && (
          <span className={styles.errorMessage}>
            <span>
              <FontAwesomeIcon icon={faTriangleExclamation} />
            </span>
            <span data-testid="textfield-error-text">
              <FormattedMessage
                id={translationKeys.form_error_not_valid}
                values={{
                  fieldName: intl.formatMessage({
                    id: translationKeys.job_order_settings_cc_email_label,
                  }),
                }}
              />
            </span>
          </span>
        )}
        <div className={styles.list}>
          {emails.map((email) => (
            <Tag key={email} variant="square" color="neutral">
              <div className={styles.item}>
                {email}
                <IconButton
                  variant="transparent"
                  icon={<FontAwesomeIcon icon={faXmark} />}
                  size="small"
                  onClick={() => {
                    onDeleteEmail?.(email);
                  }}
                />
              </div>
            </Tag>
          ))}
        </div>
      </div>

      <div className={styles.sectionItemContent}>
        <div className={styles.gridRow}>
          <B_Form.Label>
            <FormattedMessage
              id={translationKeys.job_order_settings_cc_country_code_label}
            />
          </B_Form.Label>
          <B_Form.Label>
            <FormattedMessage
              id={translationKeys.job_order_settings_cc_mobile_number_label}
            />
          </B_Form.Label>
        </div>
        <div className={styles.gridRow}>
          <PhoneCodeDropdown
            selected={countryCode}
            onChange={(phoneCodes) => {
              if (phoneCodes && phoneCodes.length > 0 && phoneCodes[0].key) {
                setCountryCode(phoneCodes[0]);
              } else {
                setCountryCode(DEFAULT_COUNTRY_CODE);
              }
            }}
          />
          <TextField
            placeholder={intl.formatMessage({
              id: translationKeys.job_order_settings_cc_mobile_number_label,
            })}
            size="large"
            value={phoneNumber}
            onChange={(e) => {
              if (/^\d*$/.test(e.target.value)) {
                setPhoneNumber(e.target.value);
              }
            }}
          />
          <Button
            variant="gray"
            size="large"
            onClick={() => {
              addPhoneNumber();
            }}
          >
            <FormattedMessage
              id={translationKeys.job_order_settings_cc_add_button}
            />
          </Button>
        </div>
        <div className={styles.list}>
          {confirmationPhones.map((phoneNumber) => (
            <Tag key={phoneNumber} variant="square" color="neutral">
              <div className={styles.item}>
                {phoneNumber}
                <IconButton
                  variant="transparent"
                  icon={<FontAwesomeIcon icon={faXmark} />}
                  size="small"
                  onClick={() => {
                    onDeletePhone?.(phoneNumber);
                  }}
                />
              </div>
            </Tag>
          ))}
        </div>

        {isErrorVisible && (
          <span className={styles.errorMessage}>
            <span>
              <FontAwesomeIcon icon={faTriangleExclamation} />
            </span>
            <FormattedMessage
              id={translationKeys.job_order_settings_cc_validation_error}
            />
          </span>
        )}
      </div>
    </ExpandableToggle>
  );
}

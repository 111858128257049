import { ManagerJobAlternativeLanguageProcessing } from '../../types';

interface GetToLanguageProps {
  alternativeLanguageProcessing?: ManagerJobAlternativeLanguageProcessing;
  alternativeLanguageActivated?: boolean;
  alternativeLangToId?: number;
  langToId: number;
}

export const getJobLanguageToId = ({
  alternativeLanguageProcessing,
  alternativeLanguageActivated,
  alternativeLangToId,
  langToId,
}: GetToLanguageProps) => {
  if (
    alternativeLanguageProcessing ===
      ManagerJobAlternativeLanguageProcessing.AlternativeLanguageAutoUpdate &&
    alternativeLanguageActivated &&
    alternativeLangToId
  ) {
    return alternativeLangToId;
  }

  return langToId;
};

import { IntlShape } from 'react-intl';
import { SearchDropdownMenuOption } from '@skiwo/components';
import translationKeys from '../../translations/translationKeys';

export enum ManagerJobBookingFee {
  ManualBookingFeeApplied = 'manualBookingFeeApplied',
  manualEditingFeeApplied = 'manualEditingFeeApplied',
  ManualTravelBookingFeeApplied = 'manualTravelBookingFeeApplied',
}

export const getFeesOptions = (intl: IntlShape): SearchDropdownMenuOption[] => [
  {
    id: 1,
    label: intl.formatMessage({
      id: translationKeys.create_interpretation_order_manual_booking_manual_booking_fee_option,
    }),
    key: ManagerJobBookingFee.ManualBookingFeeApplied,
  },
  {
    id: 2,
    label: intl.formatMessage({
      id: translationKeys.create_interpretation_order_manual_booking_manual_editing_fee_option,
    }),
    key: ManagerJobBookingFee.manualEditingFeeApplied,
  },
  {
    id: 3,
    label: intl.formatMessage({
      id: translationKeys.create_interpretation_order_manual_booking_travel_booking_fee_option,
    }),
    key: ManagerJobBookingFee.ManualTravelBookingFeeApplied,
  },
];

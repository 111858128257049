import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchDropdown } from '@skiwo/components';
import { useFormikContext } from 'formik';
import { getTypesafeSetFieldValue } from '../../../../helpers/getTypesafeSetFieldValue';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobStatementDelayCausedBy } from '../../../../types/ManagerJobStatement';
import { StatementFormValues } from '../Statement';
import { getDelayCausedByLabel } from '../utils/getDelayCausedByLabel';
import styles from './StatementForm.module.scss';

interface DelayCausedByRowProps {
  demanderFeedbackDelayCausedBy?: ManagerJobStatementDelayCausedBy;
  supplierFeedbackDelayCausedBy?: ManagerJobStatementDelayCausedBy;
  isFeedbackLocked: boolean;
  readonly?: boolean;
}

export const DelayCausedByRow = ({
  demanderFeedbackDelayCausedBy,
  supplierFeedbackDelayCausedBy,
  isFeedbackLocked,
  readonly,
}: DelayCausedByRowProps) => {
  const intl = useIntl();
  const formikProps = useFormikContext<StatementFormValues>();
  const { values } = formikProps;
  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  return (
    <tr>
      <td className={styles.rowName}>
        <FormattedMessage id={translationKeys.job_statement_delay_caused_by} />
      </td>
      <td>
        {demanderFeedbackDelayCausedBy
          ? getDelayCausedByLabel(demanderFeedbackDelayCausedBy, intl)
          : '-'}
      </td>
      <td>
        {supplierFeedbackDelayCausedBy
          ? getDelayCausedByLabel(supplierFeedbackDelayCausedBy, intl)
          : '-'}
      </td>
      {isFeedbackLocked && (
        <>
          <td>
            {readonly ? (
              values.demanderDelayCausedBy ? (
                getDelayCausedByLabel(values.demanderDelayCausedBy, intl)
              ) : (
                '-'
              )
            ) : (
              <SearchDropdown
                placeholder={intl.formatMessage({
                  id: translationKeys.job_statement_select_placeholder,
                })}
                selectedKeys={values.demanderDelayCausedBy ? [values.demanderDelayCausedBy] : []}
                options={[
                  {
                    id: 1,
                    label: getDelayCausedByLabel(ManagerJobStatementDelayCausedBy.Demander, intl),
                    key: ManagerJobStatementDelayCausedBy.Demander,
                  },
                  {
                    id: 2,
                    label: getDelayCausedByLabel(ManagerJobStatementDelayCausedBy.Supplier, intl),
                    key: ManagerJobStatementDelayCausedBy.Supplier,
                  },
                ]}
                onChange={(items) =>
                  items && items.length
                    ? setFieldValue(
                        'demanderDelayCausedBy',
                        items[0].key as ManagerJobStatementDelayCausedBy,
                      )
                    : setFieldValue('demanderDelayCausedBy', null)
                }
                disabled={!isFeedbackLocked}
              />
            )}
          </td>
          <td>
            {readonly ? (
              values.supplierDelayCausedBy ? (
                getDelayCausedByLabel(values.supplierDelayCausedBy, intl)
              ) : (
                '-'
              )
            ) : (
              <SearchDropdown
                placeholder={intl.formatMessage({
                  id: translationKeys.job_statement_select_placeholder,
                })}
                selectedKeys={values.supplierDelayCausedBy ? [values.supplierDelayCausedBy] : []}
                options={[
                  {
                    id: 1,
                    label: getDelayCausedByLabel(ManagerJobStatementDelayCausedBy.Demander, intl),
                    key: ManagerJobStatementDelayCausedBy.Demander,
                  },
                  {
                    id: 2,
                    label: getDelayCausedByLabel(ManagerJobStatementDelayCausedBy.Supplier, intl),
                    key: ManagerJobStatementDelayCausedBy.Supplier,
                  },
                ]}
                onChange={(items) =>
                  items && items.length
                    ? setFieldValue(
                        'supplierDelayCausedBy',
                        items[0].key as ManagerJobStatementDelayCausedBy,
                      )
                    : setFieldValue('supplierDelayCausedBy', null)
                }
                disabled={!isFeedbackLocked}
              />
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export default DelayCausedByRow;

import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerJobStatus } from '../../types';

const getJobStatusName = (status: ManagerJobStatus, intl: IntlShape): string => {
  if (status === ManagerJobStatus.Accepted) {
    return intl.formatMessage({
      id: translationKeys.job_awarded_status,
    });
  }

  if (status === ManagerJobStatus.Applied) {
    return intl.formatMessage({
      id: translationKeys.job_applied_status,
    });
  }

  if (status === ManagerJobStatus.Cancelled) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_status_cancelled,
    });
  }

  if (status === ManagerJobStatus.Closed) {
    return intl.formatMessage({
      id: translationKeys.jobs_page_status_closed,
    });
  }

  if (status === ManagerJobStatus.Finished) {
    return intl.formatMessage({
      id: translationKeys.job_finished_status,
    });
  }

  if (status === ManagerJobStatus.InProgress) {
    return intl.formatMessage({
      id: translationKeys.job_in_progress_status,
    });
  }

  if (status === ManagerJobStatus.Published) {
    return intl.formatMessage({
      id: translationKeys.job_received_status,
    });
  }

  return '';
};

export default getJobStatusName;

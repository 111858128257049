import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { faArrowLeft, faLock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, Button, Form, TextField } from '@skiwo/components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ApiError } from '../../Api';
import screenWithKey from '../../assets/illustration-screen-with-key-and-password-field.svg';
import routes from '../../helpers/routes';
import { AuthLayout } from '../../layouts';
import { useApi } from '../../providers/ApiProvider';
import translationKeys from '../../translations/translationKeys';

interface FormValues {
  password: string;
  confirmPassword: string;
}

const SetNewPassword = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  useEffect(() => {
    const token = searchParams.get('token');
    if (!token) {
      navigate(routes.forgotPassword);
    }
  }, []);

  const [searchParams] = useSearchParams();

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(
        intl.formatMessage(
          { id: translationKeys.form_error_required },
          { fieldName: intl.formatMessage({ id: translationKeys.set_password_password_label }) },
        ),
      )
      .min(
        8,
        intl.formatMessage(
          { id: translationKeys.form_error_min_length },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.set_password_password_label,
            }),
            minLength: 8,
          },
        ),
      ),
    confirmPassword: yup
      .string()
      .required(
        intl.formatMessage(
          { id: translationKeys.form_error_required },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.set_password_confirm_password_label,
            }),
          },
        ),
      )
      .min(
        8,
        intl.formatMessage(
          { id: translationKeys.form_error_min_length },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.set_password_confirm_password_label,
            }),
            minLength: 8,
          },
        ),
      )
      .oneOf(
        [yup.ref('password')],
        intl.formatMessage(
          { id: translationKeys.form_error_mismatch },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.set_password_confirm_password_label,
            }),
            secondFieldName: intl.formatMessage({
              id: translationKeys.set_password_password_label,
            }),
          },
        ),
      ),
  });

  const handleSubmit = async (values: FormValues) => {
    const token = searchParams.get('token');

    const { data, error } = await api.setNewPassword(token || '', values.password, setIsLoading);

    setError(error);

    if (data) {
      navigate(routes.login);
    }
  };

  function handleBackToLogin() {
    navigate(routes.login);
  }

  const getErrorTextForCode = (code: number) => {
    if (code === 404) {
      return 'It looks like your token has expired, please request another one';
    }

    return 'An error occurred, please try again later';
  };

  return (
    <AuthLayout data-testid="page-layout">
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, dirty }) => (
          <Form
            title={intl.formatMessage({ id: translationKeys.set_password_header_title })}
            description={intl.formatMessage({
              id: translationKeys.set_password_header_description,
            })}
            image={screenWithKey}
            onSubmit={handleSubmit}
          >
            {error && (
              <Banner
                text={getErrorTextForCode(error.code)}
                variant="error"
                data-testid="error-banner"
              >
                {error.code === 404 && (
                  <Link to={routes.forgotPassword} data-testid="back-to-forgot-password-button">
                    Back to forgot password
                  </Link>
                )}
              </Banner>
            )}

            <TextField
              placeholder={intl.formatMessage({
                id: translationKeys.set_password_password_placeholder,
              })}
              type="password"
              icon={<FontAwesomeIcon icon={faLock} />}
              label={intl.formatMessage({
                id: translationKeys.set_password_password_label,
              })}
              size="large"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              errorText={touched.password ? errors.password : undefined}
              data-testid="password-input"
            />

            <TextField
              placeholder={intl.formatMessage({
                id: translationKeys.set_password_confirm_password_placeholder,
              })}
              type="password"
              icon={<FontAwesomeIcon icon={faLock} />}
              label={intl.formatMessage({
                id: translationKeys.set_password_confirm_password_label,
              })}
              size="large"
              name="confirmPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.confirmPassword}
              errorText={errors.confirmPassword}
              data-testid="confirm-password-input"
            />

            <Button
              size="x-large"
              type="submit"
              fullWidth
              disabled={!(isValid && dirty)}
              data-testid="reset-password-button"
              isLoading={isLoading}
            >
              <FormattedMessage id={translationKeys.set_password_reset_button} />
            </Button>

            <Button
              icon={<FontAwesomeIcon icon={faArrowLeft} />}
              fullWidth
              iconPosition="left"
              size="large"
              variant="transparent"
              onClick={handleBackToLogin}
              data-testid="back-to-login-button"
            >
              <FormattedMessage id={translationKeys.set_password_back_to_login_button} />
            </Button>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default SetNewPassword;

import React from 'react';

export const LockExclamationIcon = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.10417 6.91669V5.35419C3.10417 2.7663 5.20378 0.666687 7.79167 0.666687C10.3796 0.666687 12.4792 2.7663 12.4792 5.35419V6.91669H13C14.1491 6.91669 15.0833 7.85093 15.0833 9.00002V9.13426C14.6806 9.04634 14.2624 9.00002 13.8333 9.00002C10.6117 9.00002 8 11.6117 8 14.8334C8 15.728 8.20141 16.5756 8.56136 17.3334H2.58333C1.43424 17.3334 0.5 16.3991 0.5 15.25V9.00002C0.5 7.85093 1.43424 6.91669 2.58333 6.91669H3.10417ZM5.1875 5.35419V6.91669H10.3958V5.35419C10.3958 3.91539 9.23047 2.75002 7.79167 2.75002C6.35286 2.75002 5.1875 3.91539 5.1875 5.35419Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7796 11.8868C15.9981 11.1054 14.9383 10.6664 13.8332 10.6664C12.728 10.6664 11.6682 11.1054 10.8868 11.8868C10.1053 12.6683 9.66632 13.7281 9.66632 14.8332C9.66632 15.9383 10.1053 16.9982 10.8868 17.7796C11.6682 18.5611 12.728 19.0001 13.8332 19.0001C14.9383 19.0001 15.9981 18.5611 16.7796 17.7796C17.561 16.9982 18 15.9383 18 14.8332C18 13.7281 17.561 12.6683 16.7796 11.8868ZM14.3242 17.4077C14.194 17.5379 14.0173 17.6111 13.8332 17.6111C13.649 17.6111 13.4723 17.5379 13.3421 17.4077C13.2119 17.2775 13.1387 17.1008 13.1387 16.9166C13.1387 16.7325 13.2119 16.5558 13.3421 16.4256C13.4723 16.2953 13.649 16.2222 13.8332 16.2222C14.0173 16.2222 14.194 16.2953 14.3242 16.4256C14.4545 16.5558 14.5276 16.7325 14.5276 16.9166C14.5276 17.1008 14.4545 17.2775 14.3242 17.4077ZM13.3702 12.5183C13.3702 12.2637 13.5785 12.0553 13.8332 12.0553C14.0878 12.0553 14.2961 12.2637 14.2961 12.5183V14.8332C14.2961 15.0879 14.0878 15.2962 13.8332 15.2962C13.5785 15.2962 13.3702 15.0879 13.3702 14.8332V12.5183Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { FileState } from '@skiwo/components';
import { useQueries } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import System from './System';

export const systemQueryKeys = {
  attachments: (name: string) => ['attachments', name],
};

export const useGetFilesQueries = (urls?: { id?: number; fileName: string; fileUrl: string }[]) =>
  useQueries({
    queries:
      urls?.map(({ fileUrl, id, fileName }) => ({
        queryKey: systemQueryKeys.attachments(fileName),
        queryFn: async (): Promise<FileState | undefined> => {
          try {
            const urlWithToken = await System.downloadFileUrlWithToken(fileUrl).then(
              (res) => res.data,
            );

            if (!urlWithToken) return;

            const { data: fileBlob } = await System.downloadFileBlob(urlWithToken);

            if (!fileBlob) return;

            const file = new File([fileBlob], fileName, { type: fileBlob.type });
            const resolvedBuffer = await file.arrayBuffer();

            return {
              id: id,
              uid: uuidv4(),
              name: file.name,
              size: file.size,
              type: file.type,
              lastModified: file.lastModified,
              webkitRelativePath: file.webkitRelativePath,
              arrayBuffer: file.arrayBuffer,
              slice: file.slice,
              stream: file.stream,
              text: file.text,
              data: new Uint8Array(resolvedBuffer),
              preview: URL.createObjectURL(file),
            } satisfies FileState;
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error downloading file', error);
          }
        },
      })) || [],
  });

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faArrowRight,
  faBuilding,
  faCalendar,
  faCircleExclamation,
  faEnvelope,
  faFile,
  faFileInvoice,
  faLanguage,
  faList,
  faPhone,
  faSlidersUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, FileState, Tooltip } from '@skiwo/components';
import { getFormattedAddress } from '@skiwo/utils';
import { format } from 'date-fns';
import { FormikProps } from 'formik';
import { CustomerSearchDropdownMenuOption } from '../../components/CustomerSearchDropdown/CustomerSearchDropdown';
import { CustomerShape } from '../../components/SearchCustomerSection/SearchCustomerSection';
import { getBooleanIcon } from '../../helpers/getBooleanIcon';
import { getBooleanLabel } from '../../helpers/getBooleanLabel';
import { useLanguages } from '../../providers/LanguagesProvider';
import translationKeys from '../../translations/translationKeys';
import { CustomerPaymentMethod, ManagerJobSessionType } from '../../types';
import { AddressType, CreateInterpretationOrderFormValues } from '../schema';
import {
  getAssignmentAlternativeLabel,
  getAssignmentTypeIcon,
  getAssignmentTypeLabel,
  getBookingMechanismIcon,
  getBookingMechanismLabel,
  getFeesLabels,
  getGenderAlternativeLabel,
  getGenderIcon,
  getGenderLabel,
  getQualificationAlternativeLabel,
  getSpecificInterpreterAlternativeLabel,
} from '../utils';
import SummaryItem from './SummaryItem/SummaryItem';
import SummarySectionContainer from './SummarySectionContainer/SummarySectionContainer';
import styles from './SummaryView.module.scss';

interface SummaryViewProps {
  customer: CustomerShape;
  selectedDepartment?: string;
  additionalCustomers?: CustomerSearchDropdownMenuOption[];
  paymentMethod?: CustomerPaymentMethod;
  formikProps: FormikProps<CreateInterpretationOrderFormValues>;
}

export const SummaryView = ({
  customer,
  selectedDepartment,
  additionalCustomers,
  paymentMethod,
  formikProps,
}: SummaryViewProps) => {
  const intl = useIntl();
  const { getLanguageById } = useLanguages();
  const alternativeAssignmentType = getAssignmentAlternativeLabel(
    intl,
    formikProps.values.sessionType,
    formikProps.values.assignmentVideoAlternative,
    formikProps.values.assignmentInPersonAlternative,
  );

  const showSettingsSection =
    formikProps.values.alternativeLanguageActive ||
    formikProps.values.specificInterpreterActive ||
    formikProps.values.specificGenderActive ||
    formikProps.values.qualificationLevelActive ||
    formikProps.values.blockInterpretersActive ||
    formikProps.values.blockCitiesActive ||
    formikProps.values.messageToInterpreterActive ||
    formikProps.values.uploadFilesActive ||
    formikProps.values.ccActive;

  const handleFileDownload = (file: FileState) => {
    const blob = new Blob([file.data], { type: file.type });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <div className={styles.summaryView} data-testid="summary-view">
      <SummarySectionContainer
        icon={<FontAwesomeIcon icon={faBuilding} />}
        title={intl.formatMessage({
          id: translationKeys.create_interpretation_order_summary_label,
        })}
      >
        {formikProps.values.bookerName && (
          <SummaryItem
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_summary_booker_label,
            })}
          >
            <span>{formikProps.values.bookerName}</span>
          </SummaryItem>
        )}
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_customer_name_label,
          })}
        >
          <span>{customer.name}</span>
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_customer_email_label,
          })}
        >
          <div className={styles.flexRow}>
            <Tooltip title={customer.email} copyable>
              <FontAwesomeIcon icon={faEnvelope} />
            </Tooltip>
            <span>{customer.email}</span>
          </div>
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_customer_phone_label,
          })}
        >
          {customer.phoneNumber && customer.phone ? (
            <div className={styles.flexRow}>
              <Tooltip title={customer.phone} copyable>
                <FontAwesomeIcon icon={faPhone} />
              </Tooltip>
              <span>{customer.phoneNumber}</span>
            </div>
          ) : (
            <span>-</span>
          )}
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_customer_enterprise_label,
          })}
        >
          <div className={styles.flexRow}>
            {customer.enterprise?.logoUrl && (
              <Avatar
                size="small"
                altText={customer.enterprise?.name || ''}
                url={customer.enterprise.logoUrl}
              />
            )}
            <span>{customer.enterprise?.name}</span>
          </div>
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_customer_department_label,
          })}
        >
          <span>{selectedDepartment}</span>
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_summary_attendees_label,
          })}
        >
          {additionalCustomers?.length ? (
            <span>{additionalCustomers?.map((item) => item.name).join(', ')}</span>
          ) : (
            <span>-</span>
          )}
        </SummaryItem>
      </SummarySectionContainer>
      <hr />
      <SummarySectionContainer
        icon={<FontAwesomeIcon icon={faFileInvoice} />}
        title={intl.formatMessage({
          id: translationKeys.create_interpretation_order_payment_label,
        })}
      >
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_payment_method_label,
          })}
        >
          <div>
            <div className={styles.flexRow}>
              <span>
                {paymentMethod?.orgNumber ? paymentMethod?.orgName || '-' : paymentMethod?.email}
              </span>
              {paymentMethod?.default && (
                <span className={styles.defaultBadge}>
                  {intl.formatMessage({ id: translationKeys.radio_options_default_label })}
                </span>
              )}
            </div>
            <span className={styles.description}>{paymentMethod?.orgNumber || 'Email'}</span>
          </div>
        </SummaryItem>
        {paymentMethod?.allowBookingReference && (
          <SummaryItem label={paymentMethod.labelForBookingReference}>
            {formikProps.values.bookingReference || '-'}
          </SummaryItem>
        )}
        {paymentMethod?.allowPaymentBookingReference && (
          <SummaryItem label={paymentMethod.labelForPaymentBookingReference}>
            {formikProps.values.paymentBookingReference || '-'}
          </SummaryItem>
        )}
        {paymentMethod?.allowCaseNumber && (
          <SummaryItem label={paymentMethod.labelForCaseNumber}>
            {formikProps.values.caseNumber || '-'}
          </SummaryItem>
        )}
      </SummarySectionContainer>
      <hr />
      <SummarySectionContainer
        icon={<FontAwesomeIcon icon={faLanguage} />}
        title={intl.formatMessage({
          id: translationKeys.create_interpretation_order_information_label,
        })}
      >
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_information_language_label,
          })}
        >
          <div className={styles.flexRow}>
            <span>{getLanguageById(3)}</span>
            <span className={styles.arrow}>
              <FontAwesomeIcon icon={faArrowRight} />
            </span>
            <span>{getLanguageById(parseInt(formikProps.values.languageId))}</span>
          </div>
        </SummaryItem>
        {formikProps.values.dialect && (
          <SummaryItem
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_information_dialect_label,
            })}
          >
            <span>{formikProps.values.dialect}</span>
          </SummaryItem>
        )}
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_summary_date_and_time_label,
          })}
        >
          <div className={styles.flexRow}>
            <FontAwesomeIcon icon={faCalendar} />
            <span>
              {`${format(formikProps.values.dates[0].date, 'dd.MM.yyyy')}, ${
                formikProps.values.dates[0].startTime?.label
              } - ${formikProps.values.dates[0].finishTime?.label}`}
            </span>
          </div>
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_information_category_label,
          })}
        >
          <span>{formikProps.values.jobCategory?.label || '-'}</span>
        </SummaryItem>
        {formikProps.values.subject && (
          <SummaryItem
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_information_subject_label,
            })}
          >
            <span>{formikProps.values.subject}</span>
          </SummaryItem>
        )}
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_assignment_type_label,
          })}
        >
          <div>
            <div className={styles.flexRow}>
              {formikProps.values.sessionType && (
                <FontAwesomeIcon icon={getAssignmentTypeIcon(formikProps.values.sessionType)} />
              )}
              {getAssignmentTypeLabel(formikProps.values.sessionType, intl)}
            </div>
            {alternativeAssignmentType && (
              <div className={styles.alternativeWrapper}>
                <div className={styles.exclamationIcon}>
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </div>
                <span>
                  <FormattedMessage
                    id={translationKeys.create_interpretation_order_summary_if_no_interpreter}
                    values={{
                      alternative: (
                        <span className={styles.alternative}>{alternativeAssignmentType}</span>
                      ),
                    }}
                  />
                </span>
              </div>
            )}
          </div>
        </SummaryItem>
        {formikProps.values.videoPlatform && (
          <SummaryItem
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_video_solution_label,
            })}
          >
            <span>{formikProps.values.videoPlatform?.label}</span>
          </SummaryItem>
        )}
        {formikProps.values.videoPlatformUrl && (
          <SummaryItem
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_video_system_url_label,
            })}
          >
            <a
              href={formikProps.values.videoPlatformUrl}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {formikProps.values.videoPlatformUrl}
            </a>
          </SummaryItem>
        )}
        {formikProps.values.sessionType === ManagerJobSessionType.InPerson && (
          <>
            <SummaryItem
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_person_label,
              })}
            >
              <span>{formikProps.values.contactPersonName}</span>
            </SummaryItem>
            <SummaryItem
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_phone_number_label,
              })}
            >
              <span className={styles.flexRow}>
                <FontAwesomeIcon icon={faPhone} />
                {formikProps.values.contactPersonPhoneNumber}
              </span>
            </SummaryItem>
            <SummaryItem
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_label,
              })}
            >
              <span>
                {getFormattedAddress(formikProps.values.contactPersonAddress?.customData)}
              </span>
            </SummaryItem>
            {formikProps.values.contactPersonAddressType === AddressType.Google &&
              formikProps.values.contactPersonDirections && (
                <SummaryItem
                  label={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_directions_label,
                  })}
                >
                  <span>{formikProps.values.contactPersonDirections}</span>
                </SummaryItem>
              )}
          </>
        )}
      </SummarySectionContainer>
      <hr />
      {showSettingsSection && (
        <>
          <SummarySectionContainer
            icon={<FontAwesomeIcon icon={faList} />}
            title={intl.formatMessage({
              id: translationKeys.create_translation_order_settings_label,
            })}
          >
            {formikProps.values.alternativeLanguageActive && (
              <SummaryItem
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_alternative_language_title,
                })}
              >
                {formikProps.values.alternativeLanguageTo?.label}
              </SummaryItem>
            )}
            {formikProps.values.specificInterpreterActive && (
              <SummaryItem
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_specific_interpreter_title,
                })}
              >
                <div>
                  {formikProps.values.specificInterpreter?.label}
                  {formikProps.values.specificInterpreterProcessing && (
                    <div className={styles.alternativeWrapper}>
                      <div className={styles.exclamationIcon}>
                        <FontAwesomeIcon icon={faCircleExclamation} />
                      </div>
                      <span>
                        <FormattedMessage
                          id={
                            translationKeys.create_interpretation_order_summary_if_no_specific_interpreter
                          }
                          values={{
                            alternative: (
                              <span className={styles.alternative}>
                                {getSpecificInterpreterAlternativeLabel(
                                  formikProps.values.specificInterpreterProcessing,
                                  intl,
                                )}
                              </span>
                            ),
                          }}
                        />
                      </span>
                    </div>
                  )}
                </div>
              </SummaryItem>
            )}
            {formikProps.values.specificGenderActive && formikProps.values.specificGender && (
              <SummaryItem
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_specific_gender_title,
                })}
              >
                <div>
                  <div className={styles.flexRow}>
                    <FontAwesomeIcon icon={getGenderIcon(formikProps.values.specificGender)} />
                    <span>{getGenderLabel(formikProps.values.specificGender, intl)}</span>
                  </div>
                  {formikProps.values.genderProcessing && (
                    <div className={styles.alternativeWrapper}>
                      <div className={styles.exclamationIcon}>
                        <FontAwesomeIcon icon={faCircleExclamation} />
                      </div>
                      <span>
                        <FormattedMessage
                          id={
                            translationKeys.create_interpretation_order_summary_if_no_gender_interpreter
                          }
                          values={{
                            alternative: (
                              <span className={styles.alternative}>
                                {getGenderAlternativeLabel(
                                  formikProps.values.genderProcessing,
                                  intl,
                                )}
                              </span>
                            ),
                          }}
                        />
                      </span>
                    </div>
                  )}
                </div>
              </SummaryItem>
            )}
            {formikProps.values.qualificationLevelActive && (
              <SummaryItem
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_qualification_level_label,
                })}
              >
                <div>
                  {formikProps.values.specificQualification?.label || '-'}
                  {formikProps.values.qualificationProcessing && (
                    <div className={styles.alternativeWrapper}>
                      <div className={styles.exclamationIcon}>
                        <FontAwesomeIcon icon={faCircleExclamation} />
                      </div>
                      <span>
                        <FormattedMessage
                          id={
                            translationKeys.create_interpretation_order_summary_if_no_qualification
                          }
                          values={{
                            alternative: (
                              <span className={styles.alternative}>
                                {getQualificationAlternativeLabel(
                                  formikProps.values.qualificationProcessing,
                                  intl,
                                )}
                              </span>
                            ),
                          }}
                        />
                      </span>
                    </div>
                  )}
                </div>
              </SummaryItem>
            )}
            {formikProps.values.blockInterpretersActive && (
              <SummaryItem
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_block_interpreters_label,
                })}
              >
                <span>
                  {formikProps.values.blockedInterpreters?.map((item) => item.label).join(', ')}
                </span>
              </SummaryItem>
            )}
            {formikProps.values.blockCitiesActive && (
              <SummaryItem
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_block_cities_title,
                })}
              >
                <span>
                  {formikProps.values.blockedCities?.map((item) => item.label).join(' • ')}
                </span>
              </SummaryItem>
            )}
            {formikProps.values.messageToInterpreterActive && (
              <SummaryItem
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_settings_message_to_interpreter_title,
                })}
              >
                <span>{formikProps.values.messageToInterpreter}</span>
              </SummaryItem>
            )}
            {formikProps.values.uploadFilesActive && !!formikProps.values.attachments?.length && (
              <SummaryItem
                label={intl.formatMessage(
                  {
                    id: translationKeys.create_translation_order_summary_information_files_label,
                  },
                  {
                    filesNumber:
                      !!formikProps.values.attachments.length &&
                      `${formikProps.values.attachments.length}`,
                  },
                )}
              >
                <div className={styles.filesList}>
                  {formikProps.values.attachments.map((file) => {
                    return (
                      <div key={file.uid}>
                        {file.uid && (
                          <div className={styles.fileItem}>
                            <FontAwesomeIcon icon={faFile} className={styles.fileIcon} />
                            <button
                              className={styles.fileButton}
                              onClick={() => handleFileDownload(file)}
                            >
                              {file.name}
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </SummaryItem>
            )}
            {formikProps.values.ccActive &&
              (!!formikProps.values.ccEmails.length ||
                !!formikProps.values.confirmationPhones.length) && (
                <SummaryItem
                  label={intl.formatMessage({
                    id: translationKeys.create_interpretation_order_settings_cc_title,
                  })}
                >
                  <div className={styles.textList}>
                    {formikProps.values.ccEmails.map((email) => {
                      return (
                        <span key={email}>
                          <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
                          {email}
                        </span>
                      );
                    })}
                    {formikProps.values.confirmationPhones.map((phoneNumber) => {
                      return (
                        <span key={phoneNumber}>
                          <FontAwesomeIcon icon={faPhone} className={styles.icon} />
                          {phoneNumber}
                        </span>
                      );
                    })}
                  </div>
                </SummaryItem>
              )}
          </SummarySectionContainer>
          <hr />
        </>
      )}
      <SummarySectionContainer
        icon={<FontAwesomeIcon icon={faSlidersUp} />}
        title={intl.formatMessage({
          id: translationKeys.create_interpretation_order_manual_booking_title,
        })}
      >
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_summary_date_and_time_label,
          })}
        >
          <div className={styles.flexRow}>
            <FontAwesomeIcon icon={faCalendar} />
            <span>
              {`${format(formikProps.values.actualCreatedAtDate, 'dd.MM.yyyy')}, ${
                formikProps.values.actualCreatedAtTime?.label
              }`}
            </span>
          </div>
        </SummaryItem>
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_summary_booking_mechanism_label,
          })}
        >
          <div className={styles.flexRow}>
            <FontAwesomeIcon icon={getBookingMechanismIcon(formikProps.values.bookingMechanism)} />
            <span>{getBookingMechanismLabel(formikProps.values.bookingMechanism, intl)}</span>
          </div>
        </SummaryItem>
        {(formikProps.values.manualBookingFeeApplied ||
          formikProps.values.manualEditingFeeApplied ||
          formikProps.values.manualTravelBookingFeeApplied) && (
          <SummaryItem
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_summary_fees_label,
            })}
          >
            <div className={styles.flexRow}>
              <span>
                {getFeesLabels(
                  !!formikProps.values.manualBookingFeeApplied,
                  !!formikProps.values.manualEditingFeeApplied,
                  !!formikProps.values.manualTravelBookingFeeApplied,
                  intl,
                )}
              </span>
            </div>
          </SummaryItem>
        )}
        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_summary_confirmation_label,
          })}
        >
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={getBooleanIcon(!!formikProps.values.deadlineConfirmationActive)}
              className={
                formikProps.values.deadlineConfirmationActive
                  ? styles.iconSuccess
                  : styles.iconError
              }
            />
            {getBooleanLabel(!!formikProps.values.deadlineConfirmationActive, intl)}
          </div>
        </SummaryItem>
        {formikProps.values.confirmationByDate &&
          formikProps.values.confirmationByTime &&
          formikProps.values.deadlineConfirmationActive && (
            <SummaryItem
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_summary_confirm_booking_by_label,
              })}
            >
              <div className={styles.flexRow}>
                <FontAwesomeIcon icon={faCalendar} />
                <span>
                  {`${format(formikProps.values.confirmationByDate, 'dd.MM.yyyy')}, ${
                    formikProps.values.confirmationByTime?.label
                  }`}
                </span>
              </div>
            </SummaryItem>
          )}

        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_manual_booking_auto_invite,
          })}
        >
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={getBooleanIcon(!!formikProps.values.manualBookingAutoInvite)}
              className={
                formikProps.values.manualBookingAutoInvite ? styles.iconSuccess : styles.iconError
              }
            />
            {getBooleanLabel(!!formikProps.values.manualBookingAutoInvite, intl)}
          </div>
        </SummaryItem>

        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_manual_booking_standby,
          })}
        >
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={getBooleanIcon(!!formikProps.values.manualBookingStandBy)}
              className={
                formikProps.values.manualBookingStandBy ? styles.iconSuccess : styles.iconError
              }
            />
            {getBooleanLabel(!!formikProps.values.manualBookingStandBy, intl)}
          </div>
        </SummaryItem>

        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_manual_booking_forever,
          })}
        >
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={getBooleanIcon(!!formikProps.values.manualBookingForever)}
              className={
                formikProps.values.manualBookingForever ? styles.iconSuccess : styles.iconError
              }
            />
            {getBooleanLabel(!!formikProps.values.manualBookingForever, intl)}
          </div>
        </SummaryItem>

        <SummaryItem
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_manual_booking_auto_award,
          })}
        >
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={getBooleanIcon(!!formikProps.values.manualBookingAutoAward)}
              className={
                formikProps.values.manualBookingAutoAward ? styles.iconSuccess : styles.iconError
              }
            />
            {getBooleanLabel(!!formikProps.values.manualBookingAutoAward, intl)}
          </div>
        </SummaryItem>
      </SummarySectionContainer>
    </div>
  );
};

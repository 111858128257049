import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerJobCancellationReason } from '../../types/ManagerJob';

const getCancellationReason = (reason: ManagerJobCancellationReason, intl: IntlShape) => {
  const translation = translationKeys[`job_cancel_modal_reason_${reason}`];

  if (translation) {
    return intl.formatMessage({ id: translation });
  } else {
    return reason;
  }
};

export default getCancellationReason;

import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Banner,
  Button,
  RadioOptions,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import { useApi } from '../../../providers/ApiProvider';
import translationKeys from '../../../translations/translationKeys';
import { VideoPlatform } from '../../../types/VideoPlatforms';
import { CreateInterpretationOrderFormValues } from '../../schema';
import AssignmentTypeVideoSolutionDrawer from './AssignmentTypeVideoSolutionDrawer/AssignmentTypeVideoSolutionDrawer';
import styles from './AssignmentTypeVideoSection.module.scss';

interface AssignmentTypeVideoSectionProps {
  formikProps: FormikProps<CreateInterpretationOrderFormValues>;
  inPersonMode?: boolean;
}

export enum AssignmentVideoSolution {
  SalitaVideo = 'salita-video',
  OwnVideo = 'own-video',
}

export enum AssignmentVideoAlternative {
  PhoneInterpreter = 'phone-interpreter',
  CancelAssignment = 'cancel-assignment',
}

export const AssignmentTypeVideoSection = ({
  formikProps,
  inPersonMode,
}: AssignmentTypeVideoSectionProps) => {
  const intl = useIntl();
  const [showDrawer, setShowDrawer] = useState(false);
  const [videoPlatforms, setVideoPlatforms] = useState<VideoPlatform[]>([]);
  const [videoPlatformOptions, setVideoPlatformOptions] = useState<SearchDropdownMenuOption[]>([]);
  const api = useApi();

  const getVideoPlatforms = async () => {
    const { data } = await api.getVideoPlatforms();

    if (data) {
      setVideoPlatforms(data);
      setVideoPlatformOptions(
        data
          .filter((platform) => platform.default !== true)
          .map((platform) => ({
            id: platform.id,
            label: platform.name,
            key: platform.id.toString(),
          })),
      );
    }
  };

  useEffect(() => {
    getVideoPlatforms();
  }, []);

  return (
    <>
      {!inPersonMode && (
        <span className={styles.hint}>
          <FormattedMessage
            id={translationKeys.create_interpretation_order_assignment_type_video_hint}
          />
        </span>
      )}
      <div data-testid="video-assignment-body">
        <RadioOptions
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_assignment_type_video_solution_label,
          })}
          options={[
            {
              id: AssignmentVideoSolution.SalitaVideo,
              title: intl.formatMessage({
                id: translationKeys.create_interpretation_order_assignment_type_salita_video_solution,
              }),
              dataTestId: 'salita-video-option',
            },
            {
              id: AssignmentVideoSolution.OwnVideo,
              title: intl.formatMessage({
                id: translationKeys.create_interpretation_order_assignment_type_own_video_solution,
              }),
              dataTestId: 'own-video-option',
            },
          ]}
          onSelect={(option) => {
            formikProps.setFieldValue('assignmentVideoSolution', option);
            if (option === AssignmentVideoSolution.SalitaVideo) {
              const defaultPlatform = videoPlatforms.find((p) => p.default);
              formikProps.setFieldValue('videoPlatform', {
                id: defaultPlatform?.id,
                label: defaultPlatform?.name,
              });
            } else {
              formikProps.setFieldValue('videoPlatform', videoPlatformOptions[0]);
            }
          }}
          selected={formikProps.values.assignmentVideoSolution}
          errorText={
            formikProps.touched.videoPlatform || formikProps.touched.assignmentVideoSolution
              ? formikProps.errors.assignmentVideoSolution
              : undefined
          }
        />
      </div>
      {formikProps.values.assignmentVideoSolution === AssignmentVideoSolution.SalitaVideo && (
        <Banner
          variant="warning"
          text={
            <span className={styles.banner}>
              <FormattedMessage
                id={translationKeys.create_interpretation_order_assignment_type_software_banner}
              />
              <span className={styles.bannerButton}>
                <Button
                  variant="transparent"
                  onClick={() => setShowDrawer(true)}
                  data-testid="salita-open-drawer"
                >
                  <FormattedMessage
                    id={
                      translationKeys.create_interpretation_order_assignment_type_software_banner_learn_more
                    }
                  />
                </Button>
              </span>
            </span>
          }
        />
      )}
      {formikProps.values.assignmentVideoSolution === AssignmentVideoSolution.OwnVideo && (
        <div
          className={classNames(
            inPersonMode ? styles.videoSystemWrapperNoBorder : styles.videoSystemWrapper,
          )}
        >
          <div className={styles.videoSystem}>
            <SearchDropdown
              size="large"
              data-testid="video-platform-dropdown"
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_assignment_type_video_system_label,
              })}
              placeholder={intl.formatMessage({
                id: translationKeys.create_interpretation_order_assignment_type_video_system_placeholder,
              })}
              options={videoPlatformOptions}
              selectedKeys={
                formikProps.values.videoPlatform ? [formikProps.values.videoPlatform.key] : []
              }
              onChange={(option) => {
                if (option && option.length > 0) {
                  formikProps.setFieldValue('videoPlatform', option[0]);
                }
              }}
              errorText={
                formikProps.touched.videoPlatform ? formikProps.errors.videoPlatform : undefined
              }
              hideClearSelected
            />
            <div className={styles.videoSystemUrl}>
              <TextField
                data-testid="video-platform-url"
                label={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_assignment_type_video_system_url_label,
                })}
                placeholder={intl.formatMessage({
                  id: translationKeys.create_interpretation_order_assignment_type_video_system_url_placeholder,
                })}
                name="videoPlatformUrl"
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
                size="large"
                value={formikProps.values.videoPlatformUrl}
                errorText={
                  formikProps.touched.videoPlatformUrl
                    ? formikProps.errors.videoPlatformUrl
                    : undefined
                }
              />
            </div>
          </div>

          <TextField
            data-testid="video-platform-instructions"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_video_instructions_label,
            })}
            placeholder={intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_video_instructions_placeholder,
            })}
            textArea
            size="large"
            name="videoPlatformInstructions"
            value={formikProps.values.videoPlatformInstructions}
            onBlur={formikProps.handleBlur}
            onChange={formikProps.handleChange}
            errorText={
              formikProps.touched.videoPlatformInstructions
                ? formikProps.errors.videoPlatformInstructions
                : undefined
            }
          />

          <Form.Check
            data-testid="video-platform-checkbox"
            type="checkbox"
            id="videoPlatformDataRequired"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_video_checkbox,
            })}
            checked={!formikProps.values.videoPlatformDataRequired}
            onChange={(e) =>
              formikProps.setFieldValue('videoPlatformDataRequired', !e.target.checked)
            }
          />
        </div>
      )}
      {!inPersonMode && (
        <>
          <span className={styles.title}>
            <FormattedMessage
              id={
                translationKeys.create_interpretation_order_assignment_type_alternative_type_label
              }
            />
          </span>
          <RadioOptions
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_assignment_type_if_no_interpreter_available,
            })}
            options={[
              {
                id: AssignmentVideoAlternative.PhoneInterpreter,
                title: intl.formatMessage({
                  id: translationKeys.create_interpretation_order_assignment_type_alternative_type_phone,
                }),
                dataTestId: 'phone-interpreter-option',
              },
              {
                id: AssignmentVideoAlternative.CancelAssignment,
                title: intl.formatMessage({
                  id: translationKeys.create_interpretation_order_assignment_type_alternative_type_cancel,
                }),
                dataTestId: 'cancel-assignment-option',
              },
            ]}
            selected={formikProps.values.assignmentVideoAlternative}
            onSelect={(option) => formikProps.setFieldValue('assignmentVideoAlternative', option)}
            errorText={
              formikProps.touched.assignmentVideoAlternative || formikProps.touched.sessionType
                ? formikProps.errors.assignmentVideoAlternative
                : undefined
            }
          />
        </>
      )}
      <AssignmentTypeVideoSolutionDrawer show={showDrawer} onClose={() => setShowDrawer(false)} />
    </>
  );
};

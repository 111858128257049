import React, { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from '@skiwo/components';
import classNames from 'classnames';
import translationKeys from '../../translations/translationKeys';
import styles from './CreateOrderSectionContainer.module.scss';

interface CreateOrderSectionContainerProps {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  action?: React.ReactNode;
  optional?: boolean;
  'data-testid'?: string;
  marginSize?: 'sm' | 'md' | 'none';
}

const CreateOrderSectionContainer = ({
  icon,
  title,
  description,
  action,
  children,
  optional = false,
  'data-testid': dataTestId,
  marginSize = 'md',
}: PropsWithChildren<CreateOrderSectionContainerProps>) => {
  const hasIconTitleWrapper = icon || title || optional;
  const hasHeaderWrapper = description || action || hasIconTitleWrapper;

  return (
    <div data-testid={dataTestId}>
      {hasHeaderWrapper && (
        <div className={classNames(styles.headerWrapper, marginSize !== 'none' && styles[`headerWrapper--${marginSize}`])}>
          <div className={styles.header}>
            {hasIconTitleWrapper && (
              <div className={styles.iconTitleWrapper}>
                {icon}
                {title && <span className={styles.title}>{title}</span>}
                {optional && (
                  <Tag color="neutral" variant="square" size="small">
                    <FormattedMessage id={translationKeys.create_translation_order_optional_tag} />
                  </Tag>
                )}
              </div>
            )}
            {action && <div className={styles.actions}>{action}</div>}
          </div>
          {description && <span className={styles.description}>{description}</span>}
        </div>
      )}
      {children}
    </div>
  );
};

export default CreateOrderSectionContainer;

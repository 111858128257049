import React, { forwardRef, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './InfoItem.module.scss';

interface InfoItemProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  icons?: React.ReactNode;
  hasHover?: boolean;
}

export const InfoItem = forwardRef<HTMLDivElement, PropsWithChildren<InfoItemProps>>(
  ({ label, icons, children, hasHover = false, ...rest }, ref) => (
    <div
      {...rest}
      ref={ref}
      className={classNames(styles.infoItem, hasHover && styles['infoItem--is-hoverable'])}
    >
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>
        {icons}
        {children || '-'}
      </span>
    </div>
  ),
);

InfoItem.displayName = 'InfoItem';

export default InfoItem;

import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './ExpandableToggle.module.scss';

interface ExpandableToggleProps {
  title: string;
  description?: string;
  action?: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
}

const ExpandableToggle = ({
  title,
  description,
  action,
  children,
  active,
  disabled,
}: PropsWithChildren<ExpandableToggleProps>) => {
  return (
    <div className={styles.toggleItem} data-testid="settings-item">
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <div className={styles.iconTitleWrapper}>
            <span className={classNames(styles.title, disabled && styles.disabled)}>{title}</span>
          </div>
          <div className={styles.actions}>{action}</div>
        </div>
        <span className={classNames(styles.description, disabled && styles.disabled)}>
          {description}
        </span>
      </div>
      {active && <div className={styles.body}>{children}</div>}
    </div>
  );
};

export default ExpandableToggle;

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import getFirstLetterCapitalized from '../../../helpers/getFirstLetterCapitalized';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobRisk, ManagerJobRiskStatus } from '../../../types/ManagerJob';
import { riskFactorIcons } from './riskFactorIcons';
import styles from './RiskFactorsSection.module.scss';

interface RiskFactorsSectionProps {
  risk: ManagerJobRisk;
}

export const RiskFactorsSection = ({ risk }: RiskFactorsSectionProps) => {
  const intl = useIntl();

  const getRiskStatus = (status: ManagerJobRiskStatus) => {
    if (status === ManagerJobRiskStatus.RiskAccepted) {
      return intl.formatMessage({ id: translationKeys.job_risk_factors_accepted });
    } else {
      return intl.formatMessage({ id: translationKeys.job_risk_factors_risky });
    }
  };
  return (
    <div className={classNames(styles.riskFactors, styles[`riskFactors--${risk.status}`])}>
      <div>
        <span className={classNames(styles.text, styles.title)}>
          <FormattedMessage id={translationKeys.job_risk_factors_status} />
        </span>
        <div>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className={styles[`exclamationIcon--${risk.status}`]}
          />
          <span className={styles.text}>{getRiskStatus(risk.status)}</span>
        </div>
      </div>
      <div className={styles.riskList}>
        {risk.factors.map((factor) => {
          const icon = riskFactorIcons[factor as keyof typeof riskFactorIcons];
          const translationKey =
            translationKeys[`job_risk_factors_${factor}` as keyof typeof translationKeys];

          return (
            <div className={styles.riskItem} key={factor}>
              <FontAwesomeIcon icon={icon || faExclamationCircle} className={styles.icon} />
              <span className={styles.text}>
                {translationKey ? (
                  <FormattedMessage id={translationKey} />
                ) : (
                  getFirstLetterCapitalized(factor).replaceAll('_', ' ')
                )}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

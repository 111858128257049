import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { Option, TypeaheadInputProps } from 'react-bootstrap-typeahead/types/types';
import { createIntl } from 'react-intl';
import { faMagnifyingGlass as faMagnifyingGlassLight } from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Spinner, TextField } from '@skiwo/components';
import { ButtonProps } from '@skiwo/components/src/Button/Button';
import languages from '@skiwo/components/src/translations/languages';
import translationKeys from '@skiwo/components/src/translations/translationKeys';
import { getLanguage } from '@skiwo/utils';
import classnames from 'classnames';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import styles from './CustomerSearchDropdown.module.scss';

export interface CustomerSearchDropdownMenuOption {
  id: number;
  uid: string;
  name: string;
  enterpriseName?: string;
  departmentName: string;
  email: string;
  phone?: string;
  key: string;
  logoUrl?: string;
}

interface Props {
  options: CustomerSearchDropdownMenuOption[];
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  selected?: CustomerSearchDropdownMenuOption[];
  selectedKeys?: string[];
  onChange?: (item: CustomerSearchDropdownMenuOption[] | null) => void;
  onSearch?: (query: string) => void;
  onLoadMore?: () => void;
  pagination?: { page: number; totalPages: number };
  isLoading?: boolean;
  isLoadingMore?: boolean;
  multiple?: boolean;
  errorText?: string;
  'data-testid'?: string;
}

function isCustomerSearchMenuOption(
  option: Option[],
): option is CustomerSearchDropdownMenuOption[] {
  return typeof option[0] === 'object' && 'uid' in option[0];
}

const CustomerSearchDropdown = ({
  placeholder,
  options,
  onChange,
  disabled = false,
  isLoading = false,
  isLoadingMore = false,
  selected,
  selectedKeys,
  onSearch,
  multiple = false,
  errorText,
  'data-testid': dataTestId,
  onLoadMore,
  pagination = { page: 1, totalPages: 1 },
}: Props) => {
  const inputRef = useRef<any>();
  const userLanguage = getLanguage();
  const intl = createIntl({
    locale: userLanguage,
    messages: languages[userLanguage],
  });
  const [selectedItems, setSelectedItems] = useState<CustomerSearchDropdownMenuOption[]>(
    selected || [],
  );
  const nonEmptyMenuOptions = options.filter((item) => !!item.name);

  const handleOnChange = (selected: Option[]) => {
    if (!isCustomerSearchMenuOption(selected)) return;

    if (multiple) {
      const lastSelected = selected.pop();
      if (!lastSelected) return;

      let newItems = selectedItems;

      if (selectedItems.map((item) => item.id).includes(lastSelected.id)) {
        newItems = newItems.filter((item) => item.id !== lastSelected.id);
      } else {
        newItems.push(lastSelected);
      }

      setSelectedItems(newItems);

      inputRef.current.toggleMenu();

      if (onChange) {
        onChange(newItems);
      }

      return;
    }

    if (onChange) {
      setSelectedItems(selected);
      onChange(selected);
    }
  };

  const handleClearSelection = () => {
    inputRef.current.clear();
    inputRef.current.blur();

    setSelectedItems([]);
    if (onChange) {
      onChange(null);
    }
  };

  useEffect(() => {
    if (selectedKeys?.length && options?.length) {
      setSelectedItems(options.filter((option) => selectedKeys.includes(option.key)));
    }
  }, [selectedKeys]);

  const renderMenu = () => {
    let options = nonEmptyMenuOptions;

    if (multiple) {
      const selectedOptionsNotInResults = selectedItems.filter(
        (selectedItem) => !options.find((result) => result.id === selectedItem.id),
      );

      options = options.concat(selectedOptionsNotInResults);

      options = options.sort((firstItem, secondItem) => {
        const firstItemIndex = selectedItems.findIndex((obj) => obj.id === firstItem.id);
        const secondItemIndex = selectedItems.findIndex((obj) => obj.id === secondItem.id);

        if (firstItemIndex !== -1 && secondItemIndex === -1) {
          return -1;
        } else if (firstItemIndex === -1 && secondItemIndex !== -1) {
          return 1;
        }
        return options.indexOf(firstItem) - options.indexOf(secondItem);
      });
    }

    const menuContent = options.map((item, index) => {
      const isItemSelected =
        multiple &&
        !(options.length === selectedItems.length) &&
        (selectedItems?.length === index + 1 ||
          options.filter((option) =>
            selectedItems.find((selectedItem) => option.id == selectedItem.id),
          ).length ==
            index + 1) &&
        selectedItems.some((selectedItem) => selectedItem.id == item.id);

      return (
        <div key={item.id}>
          <MenuItem
            option={item}
            position={item.id}
            className={styles.menuItem}
            data-testid="dropdown-menu-item"
          >
            <li key={item.id}>
              {multiple ? (
                <Form.Check
                  type="checkbox"
                  checked={selectedItems.map((item) => item.id).includes(item.id)}
                  onChange={() => null}
                />
              ) : (
                <Avatar url={item.logoUrl} altText={item.name} />
              )}
              <span className={styles.menuItemRow}>
                <span className={styles.menuItemTopRow}>
                  <span>{item.name}</span>
                  <span className={styles.separator}>•</span>
                  {item.email}
                  {item.phone && (
                    <>
                      <span className={styles.separator}>•</span>
                      {item.phone}
                    </>
                  )}
                </span>
                <span>
                  {item.enterpriseName}
                  <span className={styles.separator}>•</span>
                  {item.departmentName}
                </span>
              </span>
            </li>
          </MenuItem>
          {isItemSelected && <hr className={styles.divider} />}
        </div>
      );
    });

    const renderEmptyState = () => {
      return (
        <div className={styles.noItems} data-testid="empty-state-section">
          <FontAwesomeIcon className={styles.icon} icon={faMagnifyingGlassLight} />
          <span className={styles.description}>
            {intl.formatMessage({ id: translationKeys.search_dropdown_empty_items_state })}
          </span>
        </div>
      );
    };

    const renderLoadingState = () => {
      return (
        <div className={styles.noItems} data-testid="loading-state-section">
          <Spinner color="primary" />
          <span className={styles.description}>
            {intl.formatMessage({ id: translationKeys.search_dropdown_loading_state })}
          </span>
        </div>
      );
    };

    const pagesLeft = pagination.totalPages - pagination.page;

    return (
      <Menu id="menu" maxHeight="unset" className={styles.dropdownMenu} data-testid="dropdown-menu">
        <div className={styles.dropdownContent}>
          {isLoading ? renderLoadingState() : menuContent.length ? menuContent : renderEmptyState()}
        </div>
        {selectedItems.length > 0 && (
          <DropdownFooter onClick={handleClearSelection} data-testid="clear-button">
            {intl.formatMessage({ id: translationKeys.dropdown_menu_clear_selected })}
          </DropdownFooter>
        )}
        {pagesLeft > 0 && onLoadMore && (
          <DropdownFooter
            onClick={onLoadMore}
            disabled={isLoadingMore}
            data-testid="load-more-button"
          >
            {intl.formatMessage({ id: translationKeys.dropdown_menu_load_more })}
          </DropdownFooter>
        )}
      </Menu>
    );
  };

  const renderInput = (inputProps: TypeaheadInputProps) => {
    let inputPlaceholder = inputProps.placeholder;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, inputRef, referenceElementRef, inputClassName, ...modifiedProps } = inputProps;

    if (multiple && selectedItems.length > 0 && !inputProps.value) {
      inputPlaceholder = selectedItems.map((item) => item.name).join(', ');
    }

    return (
      <div className={styles.textField}>
        <TextField
          size="large"
          data-testid={dataTestId}
          {...modifiedProps}
          ref={inputProps.inputRef}
          placeholder={inputPlaceholder}
          icon={<FontAwesomeIcon icon={faMagnifyingGlass} data-testid="search-icon" />}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (inputProps.onChange) {
              inputProps.onChange(e);
            }

            if (onSearch) {
              onSearch(e.currentTarget.value || '');
            }
          }}
          value={inputProps.value ? String(inputProps.value) : ''}
          errorText={errorText}
        />
      </div>
    );
  };

  return (
    <div className={styles.searchDropdown}>
      <div data-testid="search-dropdown" className={styles.dropdownContainer}>
        <Typeahead
          id="search-dropdown"
          labelKey="name"
          selected={selectedItems}
          options={nonEmptyMenuOptions}
          placeholder={placeholder}
          className={classnames(styles.searchDropdownInput, styles.hasDecoration, {
            [styles.hasSelectedItems]: selectedItems.length > 0,
          })}
          ref={inputRef}
          renderInput={renderInput}
          renderToken={() => <></>}
          disabled={disabled}
          multiple={multiple}
          size="lg"
          onChange={handleOnChange}
          paginate={false}
          renderMenu={renderMenu}
        />
      </div>
    </div>
  );
};

const DropdownFooter = (props: ButtonProps) => (
  <div className={styles.dropdownFooter}>
    <Button variant="transparent" size="medium" {...props} />
  </div>
);

export default CustomerSearchDropdown;

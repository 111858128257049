import React from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import styles from './ManualBookingSection.module.scss';

interface ManualBookingFeesProps {
  manualBookingFeeApplied?: boolean;
  manualEditingFeeApplied?: boolean;
  manualTravelBookingFeeApplied?: boolean;
  setManualBookingFeeApplied: (manualBookingFeeApplied: boolean) => void;
  setManualEditingFeeApplied: (manualEditingFeeApplied: boolean) => void;
  setManualTravelBookingFeeApplied: (manualTravelBookingFeeApplied: boolean) => void;
}

export const ManualBookingFees = ({
  manualBookingFeeApplied,
  manualEditingFeeApplied,
  manualTravelBookingFeeApplied,
  setManualBookingFeeApplied,
  setManualEditingFeeApplied,
  setManualTravelBookingFeeApplied,
}: ManualBookingFeesProps) => {
  const intl = useIntl();

  return (
    <div className={styles.manualBookingSection}>
      <span className={styles.label}>
        <FormattedMessage
          id={translationKeys.create_interpretation_order_manual_booking_manual_fees_question}
        />
      </span>
      <div className={styles.flexRow}>
        <div className={styles.radioItem}>
          <B_Form.Check
            type="checkbox"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_manual_booking_fee_option,
            })}
            name="manualBookingFeeApplied"
            checked={manualBookingFeeApplied}
            onChange={(e) => setManualBookingFeeApplied(e.target.checked)}
          />
        </div>
        <div className={styles.radioItem}>
          <B_Form.Check
            type="checkbox"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_manual_editing_fee_option,
            })}
            name="manualEditingFeeApplied"
            checked={manualEditingFeeApplied}
            onChange={(e) => setManualEditingFeeApplied(e.target.checked)}
          />
        </div>
        <div className={styles.radioItem}>
          <B_Form.Check
            type="checkbox"
            label={intl.formatMessage({
              id: translationKeys.create_interpretation_order_manual_booking_travel_booking_fee_option,
            })}
            name="manualTravelBookingFeeApplied"
            checked={manualTravelBookingFeeApplied}
            onChange={(e) => setManualTravelBookingFeeApplied(e.target.checked)}
          />
        </div>
      </div>
    </div>
  );
};

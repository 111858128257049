import { SearchDropdownMenuOption } from '@skiwo/components';
import { PricingTemplate } from '../../types/PricingTemplate';

export const getPricingTemplateOptions = (
  pricingTemplates: PricingTemplate[],
): SearchDropdownMenuOption[] => {
  return pricingTemplates.map((template) => ({
    id: template.id,
    label: template.name,
    key: template.id.toString(),
  }));
};

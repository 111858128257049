import React from 'react';
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@skiwo/components';
import { formatPhoneNumber } from '@skiwo/utils';
import classnames from 'classnames';
import BookerHoverCard from '../../Job/JobDetails/BookerHoverCard/BookerHoverCard';
import { ManagerEnterprise } from '../../types';
import styles from './EnterpriseItem.module.scss';

interface Props {
  enterprise: ManagerEnterprise;
  onCompleteAction: (hardReload?: boolean) => void;
}

const EnterpriseItem = (props: Props) => {
  const { enterprise } = props;

  return (
    <tr className={styles.itemRow} data-testid="enterprise-item">
      <td className={styles.tableTitleText} data-testid="enterprise-id">
        <a target="_blank" href={enterprise.owner.godmodeDemanderUrl} rel="noreferrer">
          {enterprise.id}
        </a>
      </td>
      <td className={styles.tableTitleText} data-testid="enterprise-org-number">
        {enterprise.orgNumber}
      </td>
      <td className={styles.tableTitleText} data-testid="enterprise-org-name">
        {enterprise.name}
      </td>
      <td data-testid="enterprise-owner">
        <div className={styles.indicatorIcons}>
          <Tooltip title={enterprise.owner.email} copyable>
            <FontAwesomeIcon
              icon={faEnvelope}
              className={classnames(styles.icon, { [styles.active]: enterprise.owner.email })}
            />
          </Tooltip>

          <Tooltip title={formatPhoneNumber(enterprise.owner.fullPhoneNumber)} copyable>
            <FontAwesomeIcon
              icon={faPhone}
              className={classnames(styles.icon, {
                [styles.active]: enterprise.owner.fullPhoneNumber,
              })}
            />
          </Tooltip>
          <BookerHoverCard customerUid={enterprise.owner.uid}>
            <span className={styles.tableTitleText}>{enterprise.owner.fullName}</span>
          </BookerHoverCard>
        </div>
      </td>
      <td className={styles.tableTitleText} data-testid="enterprise-departments">
        {enterprise.departmentsCount}
      </td>
      <td className={styles.tableTitleText} data-testid="enterprise-members">
        {enterprise.membersCount}
      </td>
      <td className={styles.tableTitleText} data-testid="enterprise-job-count">
        {enterprise.postedJobsCount}
      </td>
    </tr>
  );
};

export default EnterpriseItem;

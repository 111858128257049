import { useState } from 'react';

const INITIAL_PAGINATION = { page: 1, totalPages: 1 };

export default function usePagination() {
  const [pagination, setPagination] = useState(INITIAL_PAGINATION);

  const resetPagination = () => {
    setPagination(INITIAL_PAGINATION);
  };

  return {
    pagination,
    setPagination,
    resetPagination,
  };
}

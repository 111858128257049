import React from 'react';
import {
  useDowngradeJobRequirementMutation,
  useGetJobQuery,
} from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import { useToast } from '../../../providers/ToastProvider/ToastProvider';
import { ManagerJobDowngradeRequirement } from '../../../types/ManagerJob';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import RequirementsGenderCard from './RequirementsGenderCard';
import RequirementsLanguageCard from './RequirementsLanguageCard';
import RequirementsQualificationCard from './RequirementsQualificationCard';
import RequirementsTypeCard from './RequirementsTypeCard';
import styles from './RequirementsSection.module.scss';

export const RequirementsSection = () => {
  const { showErrorToast } = useToast();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const downgradeRequirementMutation = useDowngradeJobRequirementMutation();

  if (!job) return null;

  const downgradeRequirement = (requirement: ManagerJobDowngradeRequirement) => {
    downgradeRequirementMutation.mutate(
      {
        id: job.id.toString(),
        requirement,
      },
      { onError: (error) => showErrorToast(error) },
    );
  };

  return (
    <div className={styles.requirementsSection}>
      <RequirementsTypeCard downgradeRequirement={downgradeRequirement} />
      <RequirementsLanguageCard downgradeRequirement={downgradeRequirement} />
      <RequirementsGenderCard downgradeRequirement={downgradeRequirement} />
      <RequirementsQualificationCard downgradeRequirement={downgradeRequirement} />
    </div>
  );
};

export default RequirementsSection;

import React from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Banner, Button, Modal, RadioOptions, TextField } from '@skiwo/components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useCancelJobMutation, useGetJobQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import { DateTimePicker } from '../../../components/DateTimePicker/DateTimePicker';
import { getTypesafeSetFieldValue } from '../../../helpers/getTypesafeSetFieldValue';
import urls from '../../../helpers/routes';
import { useToast } from '../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobCancellationReason } from '../../../types/ManagerJob';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import getCancellationReason from '../../utils/getCancellationReason';
import styles from './CancelJobModal.module.scss';

interface CancelJobModalProps {
  show: boolean;
  onClose: () => void;
}

enum CancelJobOption {
  Cancel = 'cancel',
  CancelAndMakeReplacement = 'cancel_and_make_replacement',
}

interface CancelJobForm {
  cancelOption?: CancelJobOption;
  cancellationReason?: ManagerJobCancellationReason;
  customerCancellationReasonText?: string;
  adminCancellationReason?: string;
  customerRequestedCancelAt?: Date;
  sendCancellationEmailToCustomer: boolean;
  realPersonName?: string;
}

export const CancelJobModal = ({ show, onClose }: CancelJobModalProps) => {
  const intl = useIntl();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const cancelJob = useCancelJobMutation();
  const { showErrorToast } = useToast();
  const navigate = useNavigate();

  const customerReasons = [
    ManagerJobCancellationReason.DontNeedInterpreter,
    ManagerJobCancellationReason.NeedToRebook,
    ManagerJobCancellationReason.FoundInterpreterFromAnotherProvider,
    ManagerJobCancellationReason.Other,
  ];

  if (!job) return null;

  const cancelOption = [
    {
      id: CancelJobOption.Cancel,
      title: intl.formatMessage({ id: translationKeys.job_cancel_modal_cancel_option }),
    },
    {
      id: CancelJobOption.CancelAndMakeReplacement,
      title: intl.formatMessage({
        id: translationKeys.job_cancel_modal_cancel_and_make_replacement_option,
      }),
    },
  ];

  const handleSubmit = (values: CancelJobForm) => {
    if (!jobId) return;

    if (values.cancelOption === CancelJobOption.CancelAndMakeReplacement) {
      cancelJob.mutate(
        {
          id: job.id,
          reason: ManagerJobCancellationReason.NeedToRebook,
          sendEmail: false,
          other: false,
        },
        {
          onSuccess: () => {
            navigate(`${urls.createInterpretationOrder}?jobid=${jobId}`);
          },
          onError: (error) => showErrorToast(error),
        },
      );
    }
    if (values.cancelOption === CancelJobOption.Cancel && !!values.cancellationReason) {
      cancelJob.mutate(
        {
          id: job.id,
          reason: values.cancellationReason,
          other:
            values.cancellationReason === ManagerJobCancellationReason.Other ||
            values.cancellationReason === ManagerJobCancellationReason.AdminOther,
          description:
            values.cancellationReason === ManagerJobCancellationReason.Other
              ? values.customerCancellationReasonText
              : undefined,
          adminOther:
            values.cancellationReason === ManagerJobCancellationReason.AdminOther
              ? values.adminCancellationReason
              : undefined,
          sendEmail: values.sendCancellationEmailToCustomer,
          cancellationDate: values.customerRequestedCancelAt,
          realPersonName:
            customerReasons.includes(values.cancellationReason) && job.owner?.person?.isShared
              ? values.realPersonName
              : undefined,
        },
        { onSuccess: () => onClose(), onError: (error) => showErrorToast(error) },
      );
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: translationKeys.job_cancel_modal_title })}
      description={intl.formatMessage({ id: translationKeys.job_cancel_modal_description })}
      show={show}
      onHide={onClose}
      size="large"
    >
      <Formik<CancelJobForm>
        initialValues={{
          cancelOption: undefined,
          cancellationReason: undefined,
          customerCancellationReasonText: undefined,
          adminCancellationReason: undefined,
          customerRequestedCancelAt: undefined,
          sendCancellationEmailToCustomer: false,
          realPersonName: undefined,
        }}
        onSubmit={handleSubmit}
        validationSchema={yup.object().shape({
          cancellationReason: yup.string().when(['cancelOption'], ([cancelOption], sch) => {
            if (cancelOption === CancelJobOption.Cancel) {
              return sch.required();
            } else {
              return sch.notRequired();
            }
          }),
          customerRequestedCancelAt: yup
            .string()
            .when(
              ['cancelOption', 'cancellationReason'],
              ([cancelOption, cancellationReason], sch) => {
                if (
                  cancelOption === CancelJobOption.Cancel &&
                  customerReasons.includes(cancellationReason)
                ) {
                  return sch.required();
                }
                return sch.notRequired();
              },
            ),
        })}
      >
        {(formikProps) => {
          const setFieldValue = getTypesafeSetFieldValue(formikProps);
          return (
            <Form onSubmit={formikProps.handleSubmit}>
              <RadioOptions
                options={cancelOption}
                onSelect={(selectedOption) =>
                  setFieldValue('cancelOption', selectedOption as CancelJobOption)
                }
                selected={formikProps.values.cancelOption}
              />

              {formikProps.values.cancelOption === CancelJobOption.Cancel && (
                <div className={styles.content}>
                  <Banner
                    variant="warning"
                    text={intl.formatMessage({
                      id: translationKeys.job_cancel_modal_customer_banner,
                    })}
                  />
                  <span>
                    <FormattedMessage id={translationKeys.job_cancel_modal_customer_reasons} />
                  </span>
                  <div>
                    <div className={styles.radioOptions}>
                      <B_Form.Check
                        name="cancellationReason"
                        type="radio"
                        label={getCancellationReason(
                          ManagerJobCancellationReason.DontNeedInterpreter,
                          intl,
                        )}
                        value={ManagerJobCancellationReason.DontNeedInterpreter}
                        onChange={() =>
                          setFieldValue(
                            'cancellationReason',
                            ManagerJobCancellationReason.DontNeedInterpreter,
                          )
                        }
                      />
                      <B_Form.Check
                        name="cancellationReason"
                        type="radio"
                        label={getCancellationReason(
                          ManagerJobCancellationReason.NeedToRebook,
                          intl,
                        )}
                        value={ManagerJobCancellationReason.NeedToRebook}
                        onChange={() =>
                          setFieldValue(
                            'cancellationReason',
                            ManagerJobCancellationReason.NeedToRebook,
                          )
                        }
                      />
                      <B_Form.Check
                        type="radio"
                        name="cancellationReason"
                        label={getCancellationReason(
                          ManagerJobCancellationReason.FoundInterpreterFromAnotherProvider,
                          intl,
                        )}
                        value={ManagerJobCancellationReason.FoundInterpreterFromAnotherProvider}
                        onChange={() =>
                          setFieldValue(
                            'cancellationReason',
                            ManagerJobCancellationReason.FoundInterpreterFromAnotherProvider,
                          )
                        }
                      />
                      <B_Form.Check
                        type="radio"
                        name="cancellationReason"
                        label={getCancellationReason(ManagerJobCancellationReason.Other, intl)}
                        value={ManagerJobCancellationReason.Other}
                        onChange={() =>
                          setFieldValue('cancellationReason', ManagerJobCancellationReason.Other)
                        }
                      />
                    </div>
                    {formikProps.values.cancellationReason ===
                      ManagerJobCancellationReason.Other && (
                      <TextField
                        name="customerCancellationReasonText"
                        placeholder={intl.formatMessage({
                          id: translationKeys.job_cancel_modal_reason_other_placeholder,
                        })}
                        className={styles.textArea}
                        textArea
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                      />
                    )}
                  </div>
                  {job.owner?.person?.isShared && (
                    <div>
                      <Banner
                        variant="warning"
                        text={intl.formatMessage({
                          id: translationKeys.job_cancel_modal_is_shared_warning,
                        })}
                      />
                      <TextField
                        size="large"
                        name="realPersonName"
                        label={intl.formatMessage({
                          id: translationKeys.job_cancel_modal_real_name,
                        })}
                        placeholder={intl.formatMessage({
                          id: translationKeys.job_cancel_modal_real_name,
                        })}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                      />
                    </div>
                  )}
                  <DateTimePicker
                    date={formikProps.values.customerRequestedCancelAt}
                    setDate={(date) => setFieldValue('customerRequestedCancelAt', date)}
                    isInvalid={Boolean(
                      formikProps.touched.customerRequestedCancelAt &&
                        formikProps.errors.customerRequestedCancelAt,
                    )}
                  />
                  <Banner
                    variant="warning"
                    text={intl.formatMessage({ id: translationKeys.job_cancel_modal_admin_banner })}
                  />
                  <span>
                    <FormattedMessage id={translationKeys.job_cancel_modal_admin_reasons} />
                  </span>
                  <div>
                    <div className={styles.radioOptions}>
                      <B_Form.Check
                        type="radio"
                        name="cancellationReason"
                        label={getCancellationReason(
                          ManagerJobCancellationReason.InterpretersBusy,
                          intl,
                        )}
                        value={ManagerJobCancellationReason.InterpretersBusy}
                        onChange={() =>
                          setFieldValue(
                            'cancellationReason',
                            ManagerJobCancellationReason.InterpretersBusy,
                          )
                        }
                      />
                      <B_Form.Check
                        type="radio"
                        name="cancellationReason"
                        label={intl.formatMessage({
                          id: translationKeys.job_cancel_modal_reason_other,
                        })}
                        value={ManagerJobCancellationReason.AdminOther}
                        onChange={() =>
                          setFieldValue(
                            'cancellationReason',
                            ManagerJobCancellationReason.AdminOther,
                          )
                        }
                      />
                    </div>
                    {formikProps.values.cancellationReason ===
                      ManagerJobCancellationReason.AdminOther && (
                      <TextField
                        name="adminCancellationReason"
                        placeholder={intl.formatMessage({
                          id: translationKeys.job_cancel_modal_reason_other_placeholder,
                        })}
                        className={styles.textArea}
                        textArea
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                      />
                    )}
                  </div>
                  <B_Form.Check
                    type="switch"
                    label={intl.formatMessage({
                      id: translationKeys.job_cancel_modal_send_email_to_customer,
                    })}
                    checked={formikProps.values.sendCancellationEmailToCustomer}
                    onChange={(e) =>
                      setFieldValue('sendCancellationEmailToCustomer', e.target.checked)
                    }
                  />
                </div>
              )}

              {formikProps.values.cancelOption === CancelJobOption.CancelAndMakeReplacement && (
                <div className={styles.content}>
                  <div className={styles.list}>
                    <span className={styles.listTitle}>
                      <FormattedMessage id={translationKeys.job_cancel_modal_this_action_will} />
                    </span>
                    <ul>
                      <li>
                        <FormattedMessage
                          id={translationKeys.job_cancel_modal_cancel_this_assignment}
                        />
                      </li>
                      <li>
                        <FormattedMessage id={translationKeys.job_cancel_modal_mark_as_no_show} />
                      </li>
                      <li>
                        <FormattedMessage
                          id={translationKeys.job_cancel_modal_take_to_the_booking_form}
                        />
                      </li>
                    </ul>
                  </div>
                  <Banner
                    variant="warning"
                    text={intl.formatMessage({
                      id: translationKeys.job_cancel_modal_this_will_take_time_banner,
                    })}
                  />
                </div>
              )}

              <div className={styles.actionButtons}>
                <Button
                  onClick={onClose}
                  variant="gray"
                  size="large"
                  className={styles.cancelButton}
                  data-testid="cancel-button"
                >
                  <FormattedMessage id={translationKeys.job_cancel_modal_back} />
                </Button>

                {formikProps.values.cancelOption && (
                  <Button
                    iconPosition="left"
                    size="large"
                    variant="danger"
                    type="submit"
                    className={styles.renameFileButton}
                    data-testid="submit-button"
                  >
                    {formikProps.values.cancelOption === CancelJobOption.Cancel ? (
                      <FormattedMessage id={translationKeys.job_cancel_modal_cancel_option} />
                    ) : (
                      <FormattedMessage
                        id={translationKeys.job_cancel_modal_cancel_and_make_replacement_option}
                      />
                    )}
                  </Button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

import appendFormAttachmentsBlob from '../../helpers/appendFormAttachmentsBlob';
import { appendNestedFormDataObjectWithoutEmptyValues } from '../../helpers/appendNestedFormDataObject';
import { ManagerCustomer, ManagerJobStandbyProcessing, Qualification } from '../../types';
import {
  ManagerJobAdminAutoInviteProcessing,
  ManagerJobBookingReferenceFrom,
} from '../../types/ManagerJob';
import { CreateInterpretationOrderFormValues } from '../schema';
import { addTimeToDate, getInformationOrderData } from '../utils';

export interface GetCreateInterpretationOrderFormDataProps {
  originalJobId?: string | null;
  values: CreateInterpretationOrderFormValues;
  qualifications: Qualification[];
  customerUid?: ManagerCustomer['uid'];
  defaultLanguageId?: number;
  defaultBookingReference: string;
  defaultBookingReferenceFrom?: ManagerJobBookingReferenceFrom;
  defaultPaymentBookingReference: string;
  defaultPaymentBookingReferenceFrom?: ManagerJobBookingReferenceFrom;
  forceCreate?: boolean;
}

// Requirement for the old application (the VUE one)
const generateRandomString = (length: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
};

export const getCreateInterpretationOrderData = ({
  originalJobId,
  customerUid,
  defaultBookingReference,
  defaultBookingReferenceFrom,
  defaultPaymentBookingReference,
  defaultPaymentBookingReferenceFrom,
  qualifications,
  defaultLanguageId,
  values,
  forceCreate = false,
}: GetCreateInterpretationOrderFormDataProps) => {
  const informationOrderData = getInformationOrderData(values, qualifications);

  const createOrderData = {
    ...informationOrderData,
    originalJobId,
    forceCreate,
    ownerUid: customerUid,
    bookingMechanism: values.bookingMechanism,
    // TODO: Probably not necessary for Connect app
    creationIdempotencyKey: generateRandomString(64),
    allowAutoAward: values.manualBookingAutoAward,
    actualCreatedAt: addTimeToDate(
      values.actualCreatedAtDate,
      values.actualCreatedAtTime,
    ).toString(),
    interpretationRequirement: {
      ...informationOrderData.interpretationRequirement,
      departmentId: values.departmentId,
      languageFromId: defaultLanguageId,
      confirmationBy:
        values.deadlineConfirmationActive && values.confirmationByDate && values.confirmationByTime
          ? addTimeToDate(values.confirmationByDate, values.confirmationByTime).toString()
          : undefined,
    },
    info: {
      ...informationOrderData.info,
      realPersonName: values.bookerName,
      bookingReference: values.bookingReference,
      bookingReferenceFrom:
        !!values.bookingReference && values.bookingReference !== defaultBookingReference
          ? ManagerJobBookingReferenceFrom.User
          : defaultBookingReferenceFrom,
      paymentBookingReference: values.paymentBookingReference,
      paymentBookingReferenceFrom:
        !!values.paymentBookingReference &&
        values.paymentBookingReference !== defaultPaymentBookingReference
          ? ManagerJobBookingReferenceFrom.User
          : defaultPaymentBookingReferenceFrom,
      caseNumber: values.caseNumber,
      confirmationPhones: values.ccActive ? values.confirmationPhones : [],
      ccEmails: values.ccActive ? values.ccEmails : [],
      manualBookingFeeApplied: values.manualBookingFeeApplied,
      manualEditingFeeApplied: values.manualEditingFeeApplied,
      manualTravelBookingFeeApplied: values.manualTravelBookingFeeApplied,
    },
    processRequirement: {
      ...informationOrderData.processRequirement,
      adminAutoInviteProcessing: values.specificInterpreterActive
        ? ManagerJobAdminAutoInviteProcessing.AdminAutoInviteNoAction
        : values.manualBookingAutoInvite
        ? ManagerJobAdminAutoInviteProcessing.AdminAutoInviteStart
        : ManagerJobAdminAutoInviteProcessing.AdminAutoInviteStop,
      infiniteAutoInvite: values.specificInterpreterActive ? false : values.manualBookingForever,
      standbyProcessing: values.manualBookingStandBy
        ? ManagerJobStandbyProcessing.TurnedOn
        : ManagerJobStandbyProcessing.TurnedOff,
      directProcessing:
        values.specificInterpreterActive && values.specificInterpreterProcessing
          ? values.specificInterpreterProcessing
          : undefined,
    },
    participants: values.additionalCustomerUids?.map((customerUid) => ({ uid: customerUid })),
    paymentMethodId: values.paymentMethodId,
    directInterpreterUid: values.specificInterpreterActive
      ? values.specificInterpreter?.customData?.person.uid
      : undefined,
  };

  return createOrderData;
};

export default function getCreateInterpretationOrderFormData({
  customerUid,
  values,
  ...props
}: GetCreateInterpretationOrderFormDataProps): FormData {
  const createOrderFormData = new FormData();

  if (!customerUid) return createOrderFormData;

  const createOrderData = getCreateInterpretationOrderData({
    values,
    customerUid,
    ...props,
  });

  appendNestedFormDataObjectWithoutEmptyValues(createOrderFormData, createOrderData);
  appendFormAttachmentsBlob(createOrderFormData, values.attachments);

  return createOrderFormData;
}

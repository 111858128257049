import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  faBuilding,
  faBuildingCircleArrowRight,
  faEllipsis,
  faEnvelope,
  faPen,
  faPersonMilitaryToPerson,
  faPhone,
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, IconButton, Tag, Tooltip } from '@skiwo/components';
import { formatPhoneNumber } from '@skiwo/utils';
import classnames from 'classnames';
import format from 'date-fns/format';
import envelopeCheckIcon from '../../assets/icons/envelope-check.svg';
import envelopeClockIcon from '../../assets/icons/envelope-clock.svg';
import CustomerToEnterpriseDrawer from '../../components/CustomerToEnterpriseDrawer/CustomerToEnterpriseDrawer';
import EditEmployeeDrawer from '../../components/EditEmployeeDrawer/EditEmployeeDrawer';
import MergeCustomersDrawer from '../../components/MergeCustomersDrawer/MergeCustomersDrawer';
import MoveMemberDrawer from '../../components/MoveMemberDrawer/MoveMemberDrawer';
import BookerHoverCard from '../../Job/JobDetails/BookerHoverCard/BookerHoverCard';
import { useApi } from '../../providers/ApiProvider';
import { useToast } from '../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../translations/translationKeys';
import { ManagerCustomer, ManagerCustomerAccountStatus, ManagerCustomerType } from '../../types';
import { DeleteCustomerModal } from '../Modals/DeleteCustomerModal/DeleteCustomerModal';
import getAccountStatusName from '../utils/getAccountStatusName';
import getTypeName from '../utils/getTypeName';
import styles from './CustomerItem.module.scss';

enum CustomerPageActions {
  EditEmployee = 'EDIT_EMPLOYEE',
  MoveMember = 'MOVE_MEMBER',
  MergeCustomers = 'MERGE_CUSTOMERS',
  CustomerToEnterprise = 'CUSTOMER_TO_ENTERPRISE',
  DeleteCustomer = 'DELETE_CUSTOMER',
}

interface Props {
  customer: ManagerCustomer;
  onCompleteAction: (hardReload?: boolean) => void;
}

const CustomerItem = (props: Props) => {
  const { customer, onCompleteAction } = props;
  const intl = useIntl();
  const api = useApi();
  const { showErrorToast } = useToast();
  const [openAction, setOpenAction] = useState<CustomerPageActions | null>();

  const getTypeTagColor = () => {
    if (customer.type === ManagerCustomerType.Private) {
      return 'info';
    }

    if (customer.type === ManagerCustomerType.Enterprise) {
      return 'success';
    }

    return 'neutral';
  };

  const getAccountStatusTagColor = () => {
    if (customer.accountStatus.name === ManagerCustomerAccountStatus.Active) {
      return 'success';
    }

    if (customer.accountStatus.name === ManagerCustomerAccountStatus.Paused) {
      return 'warning';
    }

    if (
      customer.accountStatus.name === ManagerCustomerAccountStatus.Blocked ||
      customer.accountStatus.name === ManagerCustomerAccountStatus.Banned ||
      customer.accountStatus.name === ManagerCustomerAccountStatus.Deleted
    ) {
      return 'error';
    }

    return 'neutral';
  };

  const handleActionComplete = (hardReload?: boolean) => {
    onCompleteAction(hardReload);
  };

  const resendVerificationEmail = async () => {
    const { error } = await api.sendVerificationEmail(customer.uid);

    if (error) {
      showErrorToast(error);
    } else {
      handleActionComplete(true);
    }
  };

  const getEmailVerificationIcon = () => {
    if (customer.emailVerified) {
      return <img className={styles.verificationIcon} src={envelopeCheckIcon} />;
    } else if (customer.emailVerificationSentAt) {
      return <img className={styles.verificationIcon} src={envelopeClockIcon} />;
    } else {
      return <FontAwesomeIcon icon={faEnvelope} />;
    }
  };

  const getEmailVerificationMessage = () => {
    if (customer.emailVerified) {
      return intl.formatMessage({
        id: translationKeys.customer_page_email_verified,
      });
    } else if (customer.emailVerificationSentAt) {
      return intl.formatMessage(
        {
          id: translationKeys.customer_page_email_verification_sent,
        },
        { date: format(new Date(customer.emailVerificationSentAt), 'dd.MM.yyyy') },
      );
    } else {
      return intl.formatMessage({
        id: translationKeys.customer_page_email_not_verified,
      });
    }
  };

  return (
    <tr className={styles.customerRow} data-testid="customer-item">
      <td className={styles.tableTitleText} data-testid="customer-id">
        <a target="_blank" href={customer.godmodeDemanderUrl} rel="noreferrer">
          {customer.id}
        </a>
      </td>
      <td data-testid="customer-type">
        <Tag color={getTypeTagColor()}>{getTypeName(customer.type, intl)}</Tag>
      </td>
      <td data-testid="customer-account_status">
        <Tag color={getAccountStatusTagColor()}>
          {getAccountStatusName(customer.accountStatus.name, intl)}
        </Tag>
      </td>
      <td>
        <div className={styles.indicatorIcons}>
          {customer.emailSuppressed ? (
            <Tooltip
              title={intl.formatMessage({
                id: translationKeys.customer_page_suppressed_email_tooltip,
              })}
              onClick={() => setOpenAction(CustomerPageActions.EditEmployee)}
            >
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className={classnames(styles.icon, { [styles.error]: customer.email })}
                data-testid="customer-email-suppressed"
              />
            </Tooltip>
          ) : (
            <Tooltip title={customer.email} copyable>
              <FontAwesomeIcon
                icon={faEnvelope}
                className={classnames(styles.icon, { [styles.active]: customer.email })}
                data-testid="customer-email"
              />
            </Tooltip>
          )}

          <Tooltip title={formatPhoneNumber(customer.phone)} copyable>
            <FontAwesomeIcon
              icon={faPhone}
              className={classnames(styles.icon, { [styles.active]: customer.phone })}
              data-testid="customer-phone"
            />
          </Tooltip>
        </div>

        <BookerHoverCard customerUid={customer.uid}>
          <span data-testid="customer-name">
            <span className={styles.tableTitleText}>{customer.name || customer.email}</span>
          </span>
        </BookerHoverCard>
      </td>
      <td className={styles.tableTitleText} data-testid="customer-enterprise">
        {customer.enterprise?.name || '-'}
      </td>
      <td>
        <Tooltip
          list={
            customer.departments && customer?.departments.length > 1 ? (
              <ul className={styles.departmentTooltip}>
                {customer.departments?.map((department) => (
                  <li key={department.id}>{department.name}</li>
                ))}
              </ul>
            ) : null
          }
        >
          <div className={styles.departments}>
            {customer.departments && customer.departments.length === 1 ? (
              <span
                key={customer.departments[0].id}
                className={styles.tableTitleText}
                data-testid="customer-department"
              >
                {customer.departments[0].name}
              </span>
            ) : customer.departments && customer.departments.length > 1 ? (
              <span className={styles.tableTitleText} data-testid="customer-department">
                {customer.departments.length} Departments
              </span>
            ) : (
              <span data-testid="no-department">-</span>
            )}
          </div>
        </Tooltip>
      </td>
      <td>
        <div className={styles.actionsSection}>
          <Tooltip
            title={intl.formatMessage({
              id: translationKeys.customer_page_edit_info_tooltip,
            })}
          >
            <IconButton
              disabled={customer.type !== ManagerCustomerType.Enterprise}
              icon={<FontAwesomeIcon icon={faPen} />}
              onClick={() => setOpenAction(CustomerPageActions.EditEmployee)}
              data-testid="customer-action-edit"
            />
          </Tooltip>
          <Tooltip title={customer.email} subtitle={getEmailVerificationMessage()}>
            <IconButton
              icon={getEmailVerificationIcon()}
              onClick={() => !customer.emailVerified && resendVerificationEmail()}
            />
          </Tooltip>

          <Dropdown
            data-testid="action-more"
            toggle={
              <IconButton
                size="medium"
                data-testid="detail-item-actions-button"
                icon={<FontAwesomeIcon icon={faEllipsis} />}
              />
            }
            onSelect={(selection) => setOpenAction((selection as CustomerPageActions) || '')}
            items={[
              {
                id: CustomerPageActions.MoveMember,
                text: intl.formatMessage({
                  id: translationKeys.customer_page_edit_enterprise_tooltip,
                }),
                icon: faBuildingCircleArrowRight,
                disabled: customer.type !== ManagerCustomerType.Enterprise,
                'data-testId': 'customer-action-move',
              },
              {
                id: CustomerPageActions.MergeCustomers,
                text: intl.formatMessage({
                  id: translationKeys.customer_page_merge_customer_tooltip,
                }),
                icon: faPersonMilitaryToPerson,
                disabled: customer.type !== ManagerCustomerType.Enterprise,
                'data-testId': 'customer-action-merge',
              },
              {
                id: CustomerPageActions.CustomerToEnterprise,
                text: intl.formatMessage({
                  id: translationKeys.customer_page_convert_customer_to_enterprise_tooltip,
                }),
                icon: faBuilding,
                disabled: customer.type === ManagerCustomerType.Enterprise,
                'data-testId': 'customer-action-convert',
              },
              {
                id: CustomerPageActions.DeleteCustomer,
                text: intl.formatMessage({
                  id: translationKeys.customer_page_delete_tooltip,
                }),
                icon: faTrash,
                disabled: customer.hasOngoingAssignment,
                tooltip: customer.hasOngoingAssignment
                  ? intl.formatMessage({
                      id: translationKeys.customer_page_delete_disabled_tooltip,
                    })
                  : undefined,
                variant: 'critical',
                'data-testId': 'customer-action-delete',
              },
            ]}
          />
        </div>

        <EditEmployeeDrawer
          show={openAction === CustomerPageActions.EditEmployee}
          onClose={() => setOpenAction(null)}
          customer={customer}
          onComplete={handleActionComplete}
        />

        <MoveMemberDrawer
          customer={customer}
          show={openAction === CustomerPageActions.MoveMember}
          onClose={() => setOpenAction(null)}
          onComplete={() => handleActionComplete(true)}
        />

        <MergeCustomersDrawer
          customer={customer}
          show={openAction === CustomerPageActions.MergeCustomers}
          onClose={() => setOpenAction(null)}
          onComplete={handleActionComplete}
        />

        <CustomerToEnterpriseDrawer
          customer={customer}
          show={openAction === CustomerPageActions.CustomerToEnterprise}
          onClose={() => setOpenAction(null)}
          onComplete={() => handleActionComplete(true)}
        />

        <DeleteCustomerModal
          customer={customer}
          show={openAction === CustomerPageActions.DeleteCustomer}
          onClose={() => setOpenAction(null)}
          onComplete={() => handleActionComplete(true)}
        />
      </td>
    </tr>
  );
};

export default CustomerItem;

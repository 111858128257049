import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowRightArrowLeft,
  faBuilding,
  faClone,
  faEnvelope,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
  Tooltip,
} from '@skiwo/components';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import { useGetCustomersQuery } from '../../Api/Endpoints/Customers/Customers.hooks';
import CreateOrderSectionContainer from '../../components/CreateOrderSectionContainer/CreateOrderSectionContainer';
import CustomerSearchDropdown, {
  CustomerSearchDropdownMenuOption,
} from '../../components/CustomerSearchDropdown/CustomerSearchDropdown';
import { CustomerShape } from '../../components/SearchCustomerSection/SearchCustomerSection';
import CreateDepartmentDrawer from '../../Drawers/CreateDepartmentDrawer/CreateDepartmentDrawer';
import mapManagerCustomerToOption from '../../helpers/mapManagerCustomerToOption';
import useDebounce from '../../hooks/useDebounce';
import usePagination from '../../hooks/usePagination';
import { useApi } from '../../providers/ApiProvider';
import translationKeys from '../../translations/translationKeys';
import { EnterpriseDepartment } from '../../types';
import { CreateInterpretationOrderFormValues } from '../schema';
import { ConfimChangeCustomerModal } from './ConfimChangeCustomerModal/ConfimChangeCustomerModal';
import styles from './CustomerSection.module.scss';

interface CustomerSectionProps {
  customer: CustomerShape;
  changeCustomer: () => void;
  selectedDepartment?: SearchDropdownMenuOption;
  setSelectedDepartment: (value: SearchDropdownMenuOption) => void;
  setDepartmentList: (value: EnterpriseDepartment[]) => void;
  additionalCustomers?: CustomerSearchDropdownMenuOption[];
  setAdditionalCustomers: (value: CustomerSearchDropdownMenuOption[]) => void;
  formikProps: FormikProps<CreateInterpretationOrderFormValues>;
}

const CustomerSection = ({
  customer,
  changeCustomer,
  selectedDepartment,
  setSelectedDepartment,
  setDepartmentList,
  additionalCustomers,
  setAdditionalCustomers,
  formikProps,
}: CustomerSectionProps) => {
  const [showCreateDepartmentDrawer, setShowCreateDepartmentDrawer] = useState(false);
  const [showChangeCustomerModal, setShowChangeCustomerModal] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState<SearchDropdownMenuOption[]>([]);
  const [customerOptions, setCustomerOptions] = useState<CustomerSearchDropdownMenuOption[]>([]);
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const { pagination, setPagination, resetPagination } = usePagination();
  const debounceCustomerSearch = useDebounce(300);
  const api = useApi();
  const intl = useIntl();

  const { data, isLoading: isGetCustomersLoading } = useGetCustomersQuery({
    's[enterprise_members]': true,
    's[enterprise_cont]': customer.enterprise?.name,
    's[lookup_fields_or_enterprise_or_departments_cont]': debouncedQuery,
    page: pagination.page > 0 ? pagination.page : 1,
  });

  useEffect(() => {
    if (!data) return;

    const appendData = data.page > 1;
    const customerOptions =
      data.customers
        .filter((item) => item.uid !== customer.uid)
        .map((customer) => mapManagerCustomerToOption(customer, intl)) || [];

    setCustomerOptions(appendData ? (prev) => [...prev, ...customerOptions] : customerOptions);
    setPagination({ page: data.page, totalPages: data.pages });
  }, [data]);

  const getEnterpriseDepartments = async () => {
    if (!customer.enterprise?.id) return;

    const { data } = await api.getDepartments(customer.enterprise.id.toString(), true);

    if (data) {
      setDepartmentList(data.departments);
      return data.departments;
    }
  };

  const getDepartmentOptions = async () => {
    const enterpriseDepartments = await getEnterpriseDepartments();

    const customerDepartmentOptions =
      customer.departments?.map((department) => ({
        id: department.id,
        key: department.id.toString(),
        label: department.name,
        group: intl.formatMessage({
          id: translationKeys.create_interpretation_order_customer_departments_group,
        }),
      })) || [];
    const enterpriseDepartmentOptions =
      enterpriseDepartments
        ?.filter(
          (department) => !customerDepartmentOptions.find((item) => item.id === department.id),
        )
        .map((department) => ({
          id: department.id,
          key: department.id.toString(),
          label: department.name,
          group: intl.formatMessage({
            id: translationKeys.create_interpretation_order_all_departments_group,
          }),
        })) || [];

    const allDepartmentOptions = [...customerDepartmentOptions, ...enterpriseDepartmentOptions];
    const initialSelectedDepartment = allDepartmentOptions.find(
      (item) => item.id.toString() === formikProps.initialValues.departmentId,
    );

    if (initialSelectedDepartment) {
      setSelectedDepartment(initialSelectedDepartment);
    } else {
      formikProps.setFieldValue('departmentId', allDepartmentOptions[0]?.id.toString());
      setSelectedDepartment(allDepartmentOptions[0]);
    }

    setDepartmentOptions(allDepartmentOptions);
  };

  useEffect(() => {
    if (!formikProps.initialValues.additionalCustomerUids?.length || !customerOptions?.length)
      return;

    const additionalCustomers = customerOptions.filter((item) =>
      formikProps.initialValues.additionalCustomerUids?.includes(item.key),
    );

    setAdditionalCustomers(additionalCustomers);
  }, [formikProps.initialValues.additionalCustomerUids, customerOptions]);

  useEffect(() => {
    if (!customer) return;
    getDepartmentOptions();
  }, []);

  return (
    <>
      <CreateOrderSectionContainer
        icon={<FontAwesomeIcon icon={faBuilding} />}
        title={intl.formatMessage({
          id: translationKeys.create_interpretation_order_customer_label,
        })}
        action={
          <Button
            variant="link"
            data-testid="change-customer-button"
            icon={<FontAwesomeIcon icon={faArrowRightArrowLeft} />}
            size="large"
            onClick={() => setShowChangeCustomerModal(true)}
          >
            <FormattedMessage
              id={translationKeys.create_interpretation_order_change_customer_button}
            />
          </Button>
        }
        data-testid="customer-section"
        marginSize="sm"
      >
        <div className={styles.customerDetails}>
          <div className={styles.row}>
            <div className={styles.infoItem}>
              {customer.isShared ? (
                <div className={styles.input}>
                  <TextField
                    name="bookerName"
                    size="large"
                    data-testid="customer-booker-name"
                    placeholder={intl.formatMessage({
                      id: translationKeys.create_interpretation_order_customer_booker_name_placeholder,
                    })}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.bookerName}
                    errorText={
                      formikProps.touched.bookerName ? formikProps.errors.bookerName : undefined
                    }
                  />
                </div>
              ) : (
                <div className={styles.data}>
                  <span className={styles.title}>
                    <FormattedMessage
                      id={translationKeys.create_interpretation_order_customer_name_label}
                    />
                  </span>
                  <span className={styles.value} data-testid="customer-name">
                    {customer.name}
                  </span>
                </div>
              )}
            </div>
            <div className={styles.infoItem}>
              <div className={styles.data}>
                <span className={styles.title}>
                  <FormattedMessage
                    id={translationKeys.create_interpretation_order_customer_email_label}
                  />
                </span>
                <span className={styles.value} data-testid="customer-email">
                  {customer.email}
                </span>
              </div>
              {customer.email && (
                <div className={styles.iconWrapper}>
                  <Tooltip title={customer.email} copyable>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </Tooltip>
                </div>
              )}
            </div>
            <div className={styles.infoItem}>
              <div className={styles.data}>
                <span className={styles.title}>
                  <FormattedMessage
                    id={translationKeys.create_interpretation_order_customer_phone_label}
                  />
                </span>
                <span className={styles.value} data-testid="customer-phone">
                  {customer.phone || '-'}
                </span>
              </div>
              {customer.phone && (
                <div className={styles.iconWrapper}>
                  <Tooltip title={customer.phone} copyable>
                    <FontAwesomeIcon icon={faClone} />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
          <hr />
          <div className={styles.row}>
            <div className={styles.infoItem}>
              <div className={styles.data}>
                <span className={styles.title}>
                  <FormattedMessage
                    id={translationKeys.create_interpretation_order_customer_enterprise_label}
                  />
                </span>
                <span className={styles.value} data-testid="customer-enterprise-name">
                  {customer.enterprise?.name || '-'}
                </span>
              </div>
              {customer.enterprise?.logoUrl && (
                <Avatar
                  size="medium"
                  altText={customer.enterprise?.name || ''}
                  url={customer.enterprise.logoUrl}
                />
              )}
            </div>
            <div className={classNames(styles.infoItem, styles.departmentInfoItem)}>
              {!!departmentOptions.length &&
                (departmentOptions.length === 1 ? (
                  <div className={styles.data}>
                    <span className={styles.title}>
                      <FormattedMessage
                        id={translationKeys.create_interpretation_order_customer_department_label}
                      />
                    </span>
                    <span className={styles.value}>{selectedDepartment?.label}</span>
                  </div>
                ) : (
                  <div className={styles.departmentSearch} data-testid="department-dropdown">
                    {selectedDepartment && (
                      <SearchDropdown
                        size="large"
                        options={departmentOptions}
                        placeholder=""
                        grouped
                        selected={[selectedDepartment]}
                        hideClearSelected={true}
                        onChange={(item) => {
                          if (item && item.length > 0 && item[0].key) {
                            const selectedEnterprise = item[0];
                            setSelectedDepartment(selectedEnterprise);

                            formikProps.setFieldValue(
                              'departmentId',
                              selectedEnterprise.id.toString(),
                            );
                          }
                        }}
                      />
                    )}
                  </div>
                ))}
              <Button
                variant="gray"
                size="x-large"
                data-testid="add-department-button"
                onClick={() => setShowCreateDepartmentDrawer(true)}
                className={classNames(!departmentOptions.length && styles.button)}
              >
                <FontAwesomeIcon icon={faPlus} />{' '}
                {!departmentOptions.length && (
                  <span>
                    <FormattedMessage
                      id={
                        translationKeys.create_interpretation_order_customer_add_department_button
                      }
                    />
                  </span>
                )}
              </Button>
            </div>
          </div>

          <hr />
          <CustomerSearchDropdown
            placeholder={intl.formatMessage({
              id: translationKeys.create_interpretation_order_customer_additional_customers_placeholder,
            })}
            selectedKeys={additionalCustomers?.map(({ key }) => key)}
            options={customerOptions}
            data-testid="add-more-attendees-customer-dropdown-input"
            onSearch={(query) => {
              resetPagination();
              debounceCustomerSearch(() => {
                setDebouncedQuery(query);
              });
            }}
            onLoadMore={() => {
              setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
            }}
            isLoadingMore={isGetCustomersLoading}
            pagination={pagination}
            onChange={(customers) => {
              if (!customers?.length) {
                setDebouncedQuery('');
              }

              if (customers && customers.length > 0) {
                setAdditionalCustomers(customers);

                formikProps.setFieldValue(
                  'additionalCustomerUids',
                  customers.map((item) => item.uid),
                );
              }
            }}
            multiple
          />
        </div>
      </CreateOrderSectionContainer>

      <CreateDepartmentDrawer
        show={showCreateDepartmentDrawer}
        onClose={() => setShowCreateDepartmentDrawer(false)}
        onComplete={() => {
          getDepartmentOptions();
        }}
      />

      <ConfimChangeCustomerModal
        show={showChangeCustomerModal}
        onClose={() => setShowChangeCustomerModal(false)}
        changeCustomer={() => {
          changeCustomer();
          formikProps.resetForm();
        }}
      />
    </>
  );
};

export default CustomerSection;

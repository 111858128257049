import { FeatureToggle, Language } from '../../../types';
import { GoogleGeocoderResponse } from '../../../types/Geocoder';
import { LogoutOrderTokenResponse } from '../../../types/LogoutToken';
import { VideoPlatform } from '../../../types/VideoPlatforms';
import fetchApi, { UrlParams } from '../../fetchApi';

export default {
  getLanguages: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<Language[]>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/languages`,
      loadingCallback,
    });
  },
  getAddressList: (urlParams: UrlParams, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<GoogleGeocoderResponse[]>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/geocodes`,
      urlParams,
      loadingCallback,
    });
  },
  getFeatures: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<FeatureToggle>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/feature_toggles`,
      loadingCallback,
    });
  },
  createLogoutTokens: (industry: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<LogoutOrderTokenResponse>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v2/logout_tokens`,
      body: { industry },
      loadingCallback,
    });
  },
  getVideoPlatforms: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<VideoPlatform[]>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/video_platforms`,
      loadingCallback,
    });
  },
  downloadFileUrlWithToken: (url: string) => {
    return fetchApi<string>({
      method: 'POST',
      url,
    }, true);
  },
  downloadFileBlob: (url: string) => {
    return fetchApi<Blob>({
      method: 'GET',
      isBlob: true,
      url,
    }, true);
  }
};

import React from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import BooleanValue, {
  BooleanValueVariant,
} from '../../../../components/BooleanValue/BooleanValue';
import { getBooleanLabel } from '../../../../helpers/getBooleanLabel';
import { getTypesafeSetFieldValue } from '../../../../helpers/getTypesafeSetFieldValue';
import translationKeys from '../../../../translations/translationKeys';
import { StatementFormValues } from '../Statement';
import styles from './StatementForm.module.scss';

interface ShowedUpRowProps {
  supplierFeedbackShowedUp?: boolean;
  demanderFeedbackShowedUp?: boolean;
  isFeedbackLocked: boolean;
  readonly?: boolean;
}

export const ShowedUpRow = ({
  supplierFeedbackShowedUp,
  demanderFeedbackShowedUp,
  isFeedbackLocked,
  readonly,
}: ShowedUpRowProps) => {
  const intl = useIntl();
  const formikProps = useFormikContext<StatementFormValues>();
  const { values } = formikProps;
  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  return (
    <tr>
      <td className={styles.rowName}>
        <FormattedMessage id={translationKeys.job_statement_showed_up} />
      </td>
      <td>
        {demanderFeedbackShowedUp != null ? (
          <BooleanValue value={demanderFeedbackShowedUp} variant={BooleanValueVariant.YesNo} />
        ) : (
          '-'
        )}
      </td>
      <td>
        {supplierFeedbackShowedUp != null ? (
          <BooleanValue value={supplierFeedbackShowedUp} variant={BooleanValueVariant.YesNo} />
        ) : (
          '-'
        )}
      </td>
      {isFeedbackLocked && (
        <>
          <td>
            {readonly ? (
              values.payable && values.demanderShowedUp ? (
                <BooleanValue value={values.demanderShowedUp} variant={BooleanValueVariant.YesNo} />
              ) : (
                '-'
              )
            ) : (
              <div className={styles.flexRow}>
                <B_Form.Check
                  type="radio"
                  label={getBooleanLabel(true, intl, BooleanValueVariant.YesNo)}
                  checked={values.demanderShowedUp}
                  onChange={() => setFieldValue('demanderShowedUp', true)}
                  disabled={!isFeedbackLocked}
                />
                <B_Form.Check
                  type="radio"
                  label={getBooleanLabel(false, intl, BooleanValueVariant.YesNo)}
                  checked={!values.demanderShowedUp}
                  onChange={() => setFieldValue('demanderShowedUp', false)}
                  disabled={!isFeedbackLocked}
                />
              </div>
            )}
          </td>
          <td>
            {readonly ? (
              values.payable && values.supplierShowedUp ? (
                <BooleanValue value={values.supplierShowedUp} variant={BooleanValueVariant.YesNo} />
              ) : (
                '-'
              )
            ) : (
              <div className={styles.flexRow}>
                <B_Form.Check
                  type="radio"
                  label={getBooleanLabel(true, intl, BooleanValueVariant.YesNo)}
                  checked={values.supplierShowedUp}
                  onChange={() => setFieldValue('supplierShowedUp', true)}
                  disabled={!isFeedbackLocked}
                />
                <B_Form.Check
                  type="radio"
                  label={getBooleanLabel(false, intl, BooleanValueVariant.YesNo)}
                  checked={!values.supplierShowedUp}
                  onChange={() => setFieldValue('supplierShowedUp', false)}
                  disabled={!isFeedbackLocked}
                />
              </div>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export default ShowedUpRow;

import React from 'react';
import { Placeholder } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './BookerHoverCard.module.scss';

export default function BookerHoverCardSkeleton() {
  return (
    <Placeholder as="div" animation="glow" className={styles.overlayCard}>
      <div className={styles.bookerHoverCardTop}>
        <div className={styles.bookerHoverCardIconRow}>
          <Placeholder
            className={classNames(styles.bookerHoverCardAvatarLoader, styles.bookerHoverCardAvatar)}
          />
          <Placeholder className={styles.bookerHoverCardActionBtnLoader} />
          <Placeholder className={styles.bookerHoverCardActionBtnLoader} />
          <Placeholder className={styles.bookerHoverCardActionBtnLoader} />
          <Placeholder className={styles.bookerHoverCardActionBtnLoader} />
          <Placeholder className={styles.bookerHoverCardActionBtnLoader} />
        </div>
        <div>
          <Placeholder
            className={styles.bookerHoverCardLargeLoader}
            style={{ width: '45%', marginBottom: '4px' }}
          />
        </div>
        <div>
          <Placeholder className={styles.bookerHoverCardLargeLoader} style={{ width: '27%' }} />
        </div>
      </div>
      <div className={styles.bookerHoverCardBottom}>
        <Placeholder className={styles.bookerHoverCardLargeLoader} style={{ width: '1.25rem' }} />
        <Placeholder className={styles.bookerHoverCardLargeLoader} style={{ width: '55%' }} />
        <Placeholder
          className={styles.bookerHoverCardLargeLoader}
          style={{ width: '18%', margin: '0 0 0 auto' }}
        />
      </div>
    </Placeholder>
  );
}

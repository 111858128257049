import React, { createContext, useContext, useState } from 'react';

export enum JobDetailsDrawerName {
  EditInformationDrawer = 'edit-information-drawer',
  EditInvoicingDrawer = 'edit-invoicing-drawer',
  EditCustomerDrawer = 'edit-customer-drawer',
}

interface JobDetailsDrawerContextType {
  openDrawer: (name: JobDetailsDrawerName) => void;
  closeDrawer: (name: JobDetailsDrawerName) => void;
  isDrawerOpen: (name: JobDetailsDrawerName) => boolean;
}

const JobDetailsDrawerContext = createContext<JobDetailsDrawerContextType | undefined>(undefined);

export const useJobDetailsDrawer = (): JobDetailsDrawerContextType => {
  const context = useContext(JobDetailsDrawerContext);
  if (!context) {
    throw new Error('useJobDetailsDrawer must be used within a JobDetailsDrawerProvider');
  }
  return context;
};

export const JobDetailsDrawerProvider = ({ children }: React.PropsWithChildren) => {
  const [openDrawers, setOpenDrawers] = useState<JobDetailsDrawerName[]>([]);

  const openDrawer = (name: JobDetailsDrawerName) => {
    setOpenDrawers((prev) => [...prev, name]);
  };

  const closeDrawer = (name: JobDetailsDrawerName) => {
    setOpenDrawers((prev) => prev.filter((drawer) => drawer !== name));
  };

  const isDrawerOpen = (name: JobDetailsDrawerName): boolean => {
    return openDrawers.includes(name);
  };

  return (
    <JobDetailsDrawerContext.Provider value={{ openDrawer, closeDrawer, isDrawerOpen }}>
      {children}
    </JobDetailsDrawerContext.Provider>
  );
};

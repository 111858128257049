import React from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import BooleanValue, {
  BooleanValueVariant,
} from '../../../../components/BooleanValue/BooleanValue';
import { getBooleanLabel } from '../../../../helpers/getBooleanLabel';
import { getTypesafeSetFieldValue } from '../../../../helpers/getTypesafeSetFieldValue';
import translationKeys from '../../../../translations/translationKeys';
import { StatementFormValues } from '../Statement';
import styles from './StatementForm.module.scss';

interface TravelStartedRowProps {
  supplierFeedbackTravelStarted?: boolean;
  isFeedbackLocked: boolean;
  readonly?: boolean;
}

export const TravelStartedRow = ({
  supplierFeedbackTravelStarted,
  isFeedbackLocked,
  readonly,
}: TravelStartedRowProps) => {
  const intl = useIntl();
  const formikProps = useFormikContext<StatementFormValues>();
  const { values } = formikProps;
  const setFieldValue = getTypesafeSetFieldValue(formikProps);

  return (
    <tr>
      <td className={styles.rowName}>
        <FormattedMessage id={translationKeys.job_statement_travel_started} />
      </td>
      <td></td>
      <td>
        {supplierFeedbackTravelStarted != null ? (
          <BooleanValue value={supplierFeedbackTravelStarted} variant={BooleanValueVariant.YesNo} />
        ) : (
          '-'
        )}
      </td>
      {isFeedbackLocked && (
        <>
          <td>
            {readonly ? (
              values.payable && values.demanderInterpreterTravelled ? (
                <BooleanValue
                  value={values.demanderInterpreterTravelled}
                  variant={BooleanValueVariant.YesNo}
                />
              ) : (
                '-'
              )
            ) : (
              <div className={styles.flexRow}>
                <B_Form.Check
                  type="radio"
                  label={getBooleanLabel(true, intl, BooleanValueVariant.YesNo)}
                  checked={values.demanderInterpreterTravelled}
                  onChange={() => setFieldValue('demanderInterpreterTravelled', true)}
                  disabled={!isFeedbackLocked}
                />
                <B_Form.Check
                  type="radio"
                  label={getBooleanLabel(false, intl, BooleanValueVariant.YesNo)}
                  checked={!values.demanderInterpreterTravelled}
                  onChange={() => setFieldValue('demanderInterpreterTravelled', false)}
                  disabled={!isFeedbackLocked}
                />
              </div>
            )}
          </td>
          <td>
            {readonly ? (
              values.payable && values.supplierInterpreterTravelled ? (
                <BooleanValue
                  value={values.supplierInterpreterTravelled}
                  variant={BooleanValueVariant.YesNo}
                />
              ) : (
                '-'
              )
            ) : (
              <div className={styles.flexRow}>
                <B_Form.Check
                  type="radio"
                  label={getBooleanLabel(true, intl, BooleanValueVariant.YesNo)}
                  checked={values.supplierInterpreterTravelled}
                  onChange={() => setFieldValue('supplierInterpreterTravelled', true)}
                  disabled={!isFeedbackLocked}
                />
                <B_Form.Check
                  type="radio"
                  label={getBooleanLabel(false, intl, BooleanValueVariant.YesNo)}
                  checked={!values.supplierInterpreterTravelled}
                  onChange={() => setFieldValue('supplierInterpreterTravelled', false)}
                  disabled={!isFeedbackLocked}
                />
              </div>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export default TravelStartedRow;

// This file is automatically generated; Do not edit.
const translationKeys = {
  both_gender_label: 'both_gender_label',
  chat_section_new_message_placeholder: 'chat_section_new_message_placeholder',
  chat_section_no_messages: 'chat_section_no_messages',
  chat_section_title: 'chat_section_title',
  chat_section_today_label: 'chat_section_today_label',
  chat_section_yesterday_label: 'chat_section_yesterday_label',
  check_email_back_to_login_button: 'check_email_back_to_login_button',
  check_email_header_description: 'check_email_header_description',
  check_email_header_title: 'check_email_header_title',
  check_email_resend_button: 'check_email_resend_button',
  create_customer_drawer_banner: 'create_customer_drawer_banner',
  create_customer_drawer_clear_button: 'create_customer_drawer_clear_button',
  create_customer_drawer_company_info_org_address:
    'create_customer_drawer_company_info_org_address',
  create_customer_drawer_company_info_org_county:
    'create_customer_drawer_company_info_org_county',
  create_customer_drawer_company_info_org_form:
    'create_customer_drawer_company_info_org_form',
  create_customer_drawer_company_info_org_name:
    'create_customer_drawer_company_info_org_name',
  create_customer_drawer_company_info_org_number:
    'create_customer_drawer_company_info_org_number',
  create_customer_drawer_company_info_org_website:
    'create_customer_drawer_company_info_org_website',
  create_customer_drawer_company_info_postal_address:
    'create_customer_drawer_company_info_postal_address',
  create_customer_drawer_company_info_title:
    'create_customer_drawer_company_info_title',
  create_customer_drawer_country_code_label:
    'create_customer_drawer_country_code_label',
  create_customer_drawer_create_button: 'create_customer_drawer_create_button',
  create_customer_drawer_customer_type_enterprise:
    'create_customer_drawer_customer_type_enterprise',
  create_customer_drawer_customer_type_private:
    'create_customer_drawer_customer_type_private',
  create_customer_drawer_customer_type_title:
    'create_customer_drawer_customer_type_title',
  create_customer_drawer_email_label: 'create_customer_drawer_email_label',
  create_customer_drawer_email_placeholder:
    'create_customer_drawer_email_placeholder',
  create_customer_drawer_enterprise_label:
    'create_customer_drawer_enterprise_label',
  create_customer_drawer_enterprise_placeholder:
    'create_customer_drawer_enterprise_placeholder',
  create_customer_drawer_first_name_label:
    'create_customer_drawer_first_name_label',
  create_customer_drawer_first_name_placeholder:
    'create_customer_drawer_first_name_placeholder',
  create_customer_drawer_group_brreg: 'create_customer_drawer_group_brreg',
  create_customer_drawer_group_enterprise:
    'create_customer_drawer_group_enterprise',
  create_customer_drawer_last_name_label:
    'create_customer_drawer_last_name_label',
  create_customer_drawer_last_name_placeholder:
    'create_customer_drawer_last_name_placeholder',
  create_customer_drawer_password_checkbox:
    'create_customer_drawer_password_checkbox',
  create_customer_drawer_phone_label: 'create_customer_drawer_phone_label',
  create_customer_drawer_phone_placeholder:
    'create_customer_drawer_phone_placeholder',
  create_customer_drawer_success_close_button:
    'create_customer_drawer_success_close_button',
  create_customer_drawer_success_message:
    'create_customer_drawer_success_message',
  create_customer_drawer_success_message_first_part:
    'create_customer_drawer_success_message_first_part',
  create_customer_drawer_success_message_second_part:
    'create_customer_drawer_success_message_second_part',
  create_customer_drawer_success_profile_button:
    'create_customer_drawer_success_profile_button',
  create_customer_drawer_success_title: 'create_customer_drawer_success_title',
  create_customer_drawer_title: 'create_customer_drawer_title',
  create_department_drawer_address_input_label:
    'create_department_drawer_address_input_label',
  create_department_drawer_address_input_placeholder:
    'create_department_drawer_address_input_placeholder',
  create_department_drawer_cancel_button:
    'create_department_drawer_cancel_button',
  create_department_drawer_country_code_input_label:
    'create_department_drawer_country_code_input_label',
  create_department_drawer_department_input_error:
    'create_department_drawer_department_input_error',
  create_department_drawer_department_input_label:
    'create_department_drawer_department_input_label',
  create_department_drawer_department_input_placeholder:
    'create_department_drawer_department_input_placeholder',
  create_department_drawer_department_member_select_label:
    'create_department_drawer_department_member_select_label',
  create_department_drawer_department_member_select_placeholder:
    'create_department_drawer_department_member_select_placeholder',
  create_department_drawer_duplicate_name_error:
    'create_department_drawer_duplicate_name_error',
  create_department_drawer_email_help_text:
    'create_department_drawer_email_help_text',
  create_department_drawer_email_input: 'create_department_drawer_email_input',
  create_department_drawer_enterprise_input_label:
    'create_department_drawer_enterprise_input_label',
  create_department_drawer_enterprise_input_placeholder:
    'create_department_drawer_enterprise_input_placeholder',
  create_department_drawer_org_info_department_label:
    'create_department_drawer_org_info_department_label',
  create_department_drawer_org_info_members_label:
    'create_department_drawer_org_info_members_label',
  create_department_drawer_org_info_org_number_label:
    'create_department_drawer_org_info_org_number_label',
  create_department_drawer_org_info_owner_label:
    'create_department_drawer_org_info_owner_label',
  create_department_drawer_phone_help_text:
    'create_department_drawer_phone_help_text',
  create_department_drawer_phone_number_input:
    'create_department_drawer_phone_number_input',
  create_department_drawer_submit_button:
    'create_department_drawer_submit_button',
  create_department_drawer_success_create_new_department_button:
    'create_department_drawer_success_create_new_department_button',
  create_department_drawer_success_go_to_enterprise_button:
    'create_department_drawer_success_go_to_enterprise_button',
  create_department_drawer_success_message:
    'create_department_drawer_success_message',
  create_department_drawer_success_title:
    'create_department_drawer_success_title',
  create_department_drawer_title: 'create_department_drawer_title',
  create_interpretation_order_all_departments_group:
    'create_interpretation_order_all_departments_group',
  create_interpretation_order_assignment_created_successfully:
    'create_interpretation_order_assignment_created_successfully',
  create_interpretation_order_assignment_type_alternative_type_cancel:
    'create_interpretation_order_assignment_type_alternative_type_cancel',
  create_interpretation_order_assignment_type_alternative_type_label:
    'create_interpretation_order_assignment_type_alternative_type_label',
  create_interpretation_order_assignment_type_alternative_type_phone:
    'create_interpretation_order_assignment_type_alternative_type_phone',
  create_interpretation_order_assignment_type_alternative_type_video:
    'create_interpretation_order_assignment_type_alternative_type_video',
  create_interpretation_order_assignment_type_dangerous_field:
    'create_interpretation_order_assignment_type_dangerous_field',
  create_interpretation_order_assignment_type_dangerous_field_finished:
    'create_interpretation_order_assignment_type_dangerous_field_finished',
  create_interpretation_order_assignment_type_if_no_interpreter_available:
    'create_interpretation_order_assignment_type_if_no_interpreter_available',
  create_interpretation_order_assignment_type_in_person_alternative_type_label:
    'create_interpretation_order_assignment_type_in_person_alternative_type_label',
  create_interpretation_order_assignment_type_in_person_contact_address_city_label:
    'create_interpretation_order_assignment_type_in_person_contact_address_city_label',
  create_interpretation_order_assignment_type_in_person_contact_address_city_placeholder:
    'create_interpretation_order_assignment_type_in_person_contact_address_city_placeholder',
  create_interpretation_order_assignment_type_in_person_contact_address_country_label:
    'create_interpretation_order_assignment_type_in_person_contact_address_country_label',
  create_interpretation_order_assignment_type_in_person_contact_address_country_placeholder:
    'create_interpretation_order_assignment_type_in_person_contact_address_country_placeholder',
  create_interpretation_order_assignment_type_in_person_contact_address_county_label:
    'create_interpretation_order_assignment_type_in_person_contact_address_county_label',
  create_interpretation_order_assignment_type_in_person_contact_address_county_placeholder:
    'create_interpretation_order_assignment_type_in_person_contact_address_county_placeholder',
  create_interpretation_order_assignment_type_in_person_contact_address_directions_label:
    'create_interpretation_order_assignment_type_in_person_contact_address_directions_label',
  create_interpretation_order_assignment_type_in_person_contact_address_directions_placeholder:
    'create_interpretation_order_assignment_type_in_person_contact_address_directions_placeholder',
  create_interpretation_order_assignment_type_in_person_contact_address_label:
    'create_interpretation_order_assignment_type_in_person_contact_address_label',
  create_interpretation_order_assignment_type_in_person_contact_address_line_1_label:
    'create_interpretation_order_assignment_type_in_person_contact_address_line_1_label',
  create_interpretation_order_assignment_type_in_person_contact_address_line_1_placeholder:
    'create_interpretation_order_assignment_type_in_person_contact_address_line_1_placeholder',
  create_interpretation_order_assignment_type_in_person_contact_address_line_2_label:
    'create_interpretation_order_assignment_type_in_person_contact_address_line_2_label',
  create_interpretation_order_assignment_type_in_person_contact_address_line_2_placeholder:
    'create_interpretation_order_assignment_type_in_person_contact_address_line_2_placeholder',
  create_interpretation_order_assignment_type_in_person_contact_address_placeholder:
    'create_interpretation_order_assignment_type_in_person_contact_address_placeholder',
  create_interpretation_order_assignment_type_in_person_contact_address_postcode_label:
    'create_interpretation_order_assignment_type_in_person_contact_address_postcode_label',
  create_interpretation_order_assignment_type_in_person_contact_address_postcode_placeholder:
    'create_interpretation_order_assignment_type_in_person_contact_address_postcode_placeholder',
  create_interpretation_order_assignment_type_in_person_contact_address_search_placeholder:
    'create_interpretation_order_assignment_type_in_person_contact_address_search_placeholder',
  create_interpretation_order_assignment_type_in_person_contact_country_code_label:
    'create_interpretation_order_assignment_type_in_person_contact_country_code_label',
  create_interpretation_order_assignment_type_in_person_contact_person_label:
    'create_interpretation_order_assignment_type_in_person_contact_person_label',
  create_interpretation_order_assignment_type_in_person_contact_person_placeholder:
    'create_interpretation_order_assignment_type_in_person_contact_person_placeholder',
  create_interpretation_order_assignment_type_in_person_contact_phone_number_label:
    'create_interpretation_order_assignment_type_in_person_contact_phone_number_label',
  create_interpretation_order_assignment_type_in_person_contact_phone_number_placeholder:
    'create_interpretation_order_assignment_type_in_person_contact_phone_number_placeholder',
  create_interpretation_order_assignment_type_in_person_dangerous_field:
    'create_interpretation_order_assignment_type_in_person_dangerous_field',
  create_interpretation_order_assignment_type_in_person_dangerous_field_finished:
    'create_interpretation_order_assignment_type_in_person_dangerous_field_finished',
  create_interpretation_order_assignment_type_in_person_hint:
    'create_interpretation_order_assignment_type_in_person_hint',
  create_interpretation_order_assignment_type_in_person_label:
    'create_interpretation_order_assignment_type_in_person_label',
  create_interpretation_order_assignment_type_in_person_save_address_checkbox:
    'create_interpretation_order_assignment_type_in_person_save_address_checkbox',
  create_interpretation_order_assignment_type_in_person_show_saved_addresses_button:
    'create_interpretation_order_assignment_type_in_person_show_saved_addresses_button',
  create_interpretation_order_assignment_type_in_person_use_other_address_button:
    'create_interpretation_order_assignment_type_in_person_use_other_address_button',
  create_interpretation_order_assignment_type_label:
    'create_interpretation_order_assignment_type_label',
  create_interpretation_order_assignment_type_own_video_solution:
    'create_interpretation_order_assignment_type_own_video_solution',
  create_interpretation_order_assignment_type_phone_hint:
    'create_interpretation_order_assignment_type_phone_hint',
  create_interpretation_order_assignment_type_phone_label:
    'create_interpretation_order_assignment_type_phone_label',
  create_interpretation_order_assignment_type_salita_video_solution:
    'create_interpretation_order_assignment_type_salita_video_solution',
  create_interpretation_order_assignment_type_software_banner:
    'create_interpretation_order_assignment_type_software_banner',
  create_interpretation_order_assignment_type_software_banner_learn_more:
    'create_interpretation_order_assignment_type_software_banner_learn_more',
  create_interpretation_order_assignment_type_video_checkbox:
    'create_interpretation_order_assignment_type_video_checkbox',
  create_interpretation_order_assignment_type_video_hint:
    'create_interpretation_order_assignment_type_video_hint',
  create_interpretation_order_assignment_type_video_instructions_label:
    'create_interpretation_order_assignment_type_video_instructions_label',
  create_interpretation_order_assignment_type_video_instructions_placeholder:
    'create_interpretation_order_assignment_type_video_instructions_placeholder',
  create_interpretation_order_assignment_type_video_labe:
    'create_interpretation_order_assignment_type_video_labe',
  create_interpretation_order_assignment_type_video_label:
    'create_interpretation_order_assignment_type_video_label',
  create_interpretation_order_assignment_type_video_solution_label:
    'create_interpretation_order_assignment_type_video_solution_label',
  create_interpretation_order_assignment_type_video_system_label:
    'create_interpretation_order_assignment_type_video_system_label',
  create_interpretation_order_assignment_type_video_system_placeholder:
    'create_interpretation_order_assignment_type_video_system_placeholder',
  create_interpretation_order_assignment_type_video_system_url_label:
    'create_interpretation_order_assignment_type_video_system_url_label',
  create_interpretation_order_assignment_type_video_system_url_placeholder:
    'create_interpretation_order_assignment_type_video_system_url_placeholder',
  create_interpretation_order_booking_mechanism_chat:
    'create_interpretation_order_booking_mechanism_chat',
  create_interpretation_order_booking_mechanism_email:
    'create_interpretation_order_booking_mechanism_email',
  create_interpretation_order_booking_mechanism_phone:
    'create_interpretation_order_booking_mechanism_phone',
  create_interpretation_order_booking_mechanism_platform:
    'create_interpretation_order_booking_mechanism_platform',
  create_interpretation_order_change_customer_button:
    'create_interpretation_order_change_customer_button',
  create_interpretation_order_change_customer_modal_back_button:
    'create_interpretation_order_change_customer_modal_back_button',
  create_interpretation_order_change_customer_modal_change_button:
    'create_interpretation_order_change_customer_modal_change_button',
  create_interpretation_order_change_customer_modal_description:
    'create_interpretation_order_change_customer_modal_description',
  create_interpretation_order_change_customer_modal_label:
    'create_interpretation_order_change_customer_modal_label',
  create_interpretation_order_customer_add_department_button:
    'create_interpretation_order_customer_add_department_button',
  create_interpretation_order_customer_additional_customers_placeholder:
    'create_interpretation_order_customer_additional_customers_placeholder',
  create_interpretation_order_customer_all_departments_group_label:
    'create_interpretation_order_customer_all_departments_group_label',
  create_interpretation_order_customer_booker_name_placeholder:
    'create_interpretation_order_customer_booker_name_placeholder',
  create_interpretation_order_customer_department_label:
    'create_interpretation_order_customer_department_label',
  create_interpretation_order_customer_departments_group:
    'create_interpretation_order_customer_departments_group',
  create_interpretation_order_customer_departments_group_label:
    'create_interpretation_order_customer_departments_group_label',
  create_interpretation_order_customer_email_label:
    'create_interpretation_order_customer_email_label',
  create_interpretation_order_customer_enterprise_label:
    'create_interpretation_order_customer_enterprise_label',
  create_interpretation_order_customer_label:
    'create_interpretation_order_customer_label',
  create_interpretation_order_customer_name_label:
    'create_interpretation_order_customer_name_label',
  create_interpretation_order_customer_phone_label:
    'create_interpretation_order_customer_phone_label',
  create_interpretation_order_duplicated_jobs_modal_submit_label:
    'create_interpretation_order_duplicated_jobs_modal_submit_label',
  create_interpretation_order_duplicated_jobs_modal_title:
    'create_interpretation_order_duplicated_jobs_modal_title',
  create_interpretation_order_information_add_time_button:
    'create_interpretation_order_information_add_time_button',
  create_interpretation_order_information_banner:
    'create_interpretation_order_information_banner',
  create_interpretation_order_information_booking_threshold_info_label:
    'create_interpretation_order_information_booking_threshold_info_label',
  create_interpretation_order_information_category_label:
    'create_interpretation_order_information_category_label',
  create_interpretation_order_information_category_placeholder:
    'create_interpretation_order_information_category_placeholder',
  create_interpretation_order_information_date_label:
    'create_interpretation_order_information_date_label',
  create_interpretation_order_information_dialect_hint:
    'create_interpretation_order_information_dialect_hint',
  create_interpretation_order_information_dialect_label:
    'create_interpretation_order_information_dialect_label',
  create_interpretation_order_information_dialect_placeholder:
    'create_interpretation_order_information_dialect_placeholder',
  create_interpretation_order_information_finish_time_label:
    'create_interpretation_order_information_finish_time_label',
  create_interpretation_order_information_label:
    'create_interpretation_order_information_label',
  create_interpretation_order_information_language_dangerous_field:
    'create_interpretation_order_information_language_dangerous_field',
  create_interpretation_order_information_language_dangerous_field_finished:
    'create_interpretation_order_information_language_dangerous_field_finished',
  create_interpretation_order_information_language_label:
    'create_interpretation_order_information_language_label',
  create_interpretation_order_information_language_placeholder:
    'create_interpretation_order_information_language_placeholder',
  create_interpretation_order_information_min_time_error:
    'create_interpretation_order_information_min_time_error',
  create_interpretation_order_information_series_assignment_banner:
    'create_interpretation_order_information_series_assignment_banner',
  create_interpretation_order_information_start_time_label:
    'create_interpretation_order_information_start_time_label',
  create_interpretation_order_information_subject_label:
    'create_interpretation_order_information_subject_label',
  create_interpretation_order_information_subject_placeholder:
    'create_interpretation_order_information_subject_placeholder',
  create_interpretation_order_information_time_dangerous_field:
    'create_interpretation_order_information_time_dangerous_field',
  create_interpretation_order_information_time_dangerous_field_finished:
    'create_interpretation_order_information_time_dangerous_field_finished',
  create_interpretation_order_label: 'create_interpretation_order_label',
  create_interpretation_order_manual_booking_actual_booking_receive:
    'create_interpretation_order_manual_booking_actual_booking_receive',
  create_interpretation_order_manual_booking_auto_award:
    'create_interpretation_order_manual_booking_auto_award',
  create_interpretation_order_manual_booking_auto_invite:
    'create_interpretation_order_manual_booking_auto_invite',
  create_interpretation_order_manual_booking_chat_option:
    'create_interpretation_order_manual_booking_chat_option',
  create_interpretation_order_manual_booking_confirmations:
    'create_interpretation_order_manual_booking_confirmations',
  create_interpretation_order_manual_booking_date_label:
    'create_interpretation_order_manual_booking_date_label',
  create_interpretation_order_manual_booking_date_placeholder:
    'create_interpretation_order_manual_booking_date_placeholder',
  create_interpretation_order_manual_booking_e_mail_option:
    'create_interpretation_order_manual_booking_e_mail_option',
  create_interpretation_order_manual_booking_forever:
    'create_interpretation_order_manual_booking_forever',
  create_interpretation_order_manual_booking_manual_booking_fee_option:
    'create_interpretation_order_manual_booking_manual_booking_fee_option',
  create_interpretation_order_manual_booking_manual_editing_fee_option:
    'create_interpretation_order_manual_booking_manual_editing_fee_option',
  create_interpretation_order_manual_booking_manual_fees_question:
    'create_interpretation_order_manual_booking_manual_fees_question',
  create_interpretation_order_manual_booking_phone_option:
    'create_interpretation_order_manual_booking_phone_option',
  create_interpretation_order_manual_booking_platform_option:
    'create_interpretation_order_manual_booking_platform_option',
  create_interpretation_order_manual_booking_set_deadline_cant_be_later_error:
    'create_interpretation_order_manual_booking_set_deadline_cant_be_later_error',
  create_interpretation_order_manual_booking_set_deadline_checkbox:
    'create_interpretation_order_manual_booking_set_deadline_checkbox',
  create_interpretation_order_manual_booking_standby:
    'create_interpretation_order_manual_booking_standby',
  create_interpretation_order_manual_booking_time_label:
    'create_interpretation_order_manual_booking_time_label',
  create_interpretation_order_manual_booking_title:
    'create_interpretation_order_manual_booking_title',
  create_interpretation_order_manual_booking_travel_booking_fee_option:
    'create_interpretation_order_manual_booking_travel_booking_fee_option',
  create_interpretation_order_multiple_duplicated_jobs_modal_message:
    'create_interpretation_order_multiple_duplicated_jobs_modal_message',
  create_interpretation_order_payment_label:
    'create_interpretation_order_payment_label',
  create_interpretation_order_payment_method_label:
    'create_interpretation_order_payment_method_label',
  create_interpretation_order_preview_booking_button:
    'create_interpretation_order_preview_booking_button',
  create_interpretation_order_search_customer_placeholder:
    'create_interpretation_order_search_customer_placeholder',
  create_interpretation_order_settings_alternative_language_description:
    'create_interpretation_order_settings_alternative_language_description',
  create_interpretation_order_settings_alternative_language_label:
    'create_interpretation_order_settings_alternative_language_label',
  create_interpretation_order_settings_alternative_language_placeholder:
    'create_interpretation_order_settings_alternative_language_placeholder',
  create_interpretation_order_settings_alternative_language_title:
    'create_interpretation_order_settings_alternative_language_title',
  create_interpretation_order_settings_block_cities_description:
    'create_interpretation_order_settings_block_cities_description',
  create_interpretation_order_settings_block_cities_label:
    'create_interpretation_order_settings_block_cities_label',
  create_interpretation_order_settings_block_cities_placeholder:
    'create_interpretation_order_settings_block_cities_placeholder',
  create_interpretation_order_settings_block_cities_text:
    'create_interpretation_order_settings_block_cities_text',
  create_interpretation_order_settings_block_cities_title:
    'create_interpretation_order_settings_block_cities_title',
  create_interpretation_order_settings_block_interpreters_description:
    'create_interpretation_order_settings_block_interpreters_description',
  create_interpretation_order_settings_block_interpreters_label:
    'create_interpretation_order_settings_block_interpreters_label',
  create_interpretation_order_settings_block_interpreters_placeholder:
    'create_interpretation_order_settings_block_interpreters_placeholder',
  create_interpretation_order_settings_block_interpreters_text:
    'create_interpretation_order_settings_block_interpreters_text',
  create_interpretation_order_settings_block_interpreters_title:
    'create_interpretation_order_settings_block_interpreters_title',
  create_interpretation_order_settings_cc_add_button:
    'create_interpretation_order_settings_cc_add_button',
  create_interpretation_order_settings_cc_country_code_label:
    'create_interpretation_order_settings_cc_country_code_label',
  create_interpretation_order_settings_cc_description:
    'create_interpretation_order_settings_cc_description',
  create_interpretation_order_settings_cc_email_label:
    'create_interpretation_order_settings_cc_email_label',
  create_interpretation_order_settings_cc_mobile_number_label:
    'create_interpretation_order_settings_cc_mobile_number_label',
  create_interpretation_order_settings_cc_text:
    'create_interpretation_order_settings_cc_text',
  create_interpretation_order_settings_cc_title:
    'create_interpretation_order_settings_cc_title',
  create_interpretation_order_settings_cc_validation_error:
    'create_interpretation_order_settings_cc_validation_error',
  create_interpretation_order_settings_if_no_specific_interpreter:
    'create_interpretation_order_settings_if_no_specific_interpreter',
  create_interpretation_order_settings_message_to_interpreter_description:
    'create_interpretation_order_settings_message_to_interpreter_description',
  create_interpretation_order_settings_message_to_interpreter_hint:
    'create_interpretation_order_settings_message_to_interpreter_hint',
  create_interpretation_order_settings_message_to_interpreter_label:
    'create_interpretation_order_settings_message_to_interpreter_label',
  create_interpretation_order_settings_message_to_interpreter_placeholder:
    'create_interpretation_order_settings_message_to_interpreter_placeholder',
  create_interpretation_order_settings_message_to_interpreter_text:
    'create_interpretation_order_settings_message_to_interpreter_text',
  create_interpretation_order_settings_message_to_interpreter_title:
    'create_interpretation_order_settings_message_to_interpreter_title',
  create_interpretation_order_settings_qualification_level_alternative_cancel:
    'create_interpretation_order_settings_qualification_level_alternative_cancel',
  create_interpretation_order_settings_qualification_level_alternative_find_another_qualification:
    'create_interpretation_order_settings_qualification_level_alternative_find_another_qualification',
  create_interpretation_order_settings_qualification_level_alternative_question:
    'create_interpretation_order_settings_qualification_level_alternative_question',
  create_interpretation_order_settings_qualification_level_description:
    'create_interpretation_order_settings_qualification_level_description',
  create_interpretation_order_settings_qualification_level_label:
    'create_interpretation_order_settings_qualification_level_label',
  create_interpretation_order_settings_qualification_level_placeholder:
    'create_interpretation_order_settings_qualification_level_placeholder',
  create_interpretation_order_settings_qualification_level_title:
    'create_interpretation_order_settings_qualification_level_title',
  create_interpretation_order_settings_qualification_subtitle_empty:
    'create_interpretation_order_settings_qualification_subtitle_empty',
  create_interpretation_order_settings_qualification_subtitle_internal:
    'create_interpretation_order_settings_qualification_subtitle_internal',
  create_interpretation_order_settings_qualification_subtitle_plural:
    'create_interpretation_order_settings_qualification_subtitle_plural',
  create_interpretation_order_settings_qualification_subtitle_singular:
    'create_interpretation_order_settings_qualification_subtitle_singular',
  create_interpretation_order_settings_specific_gender_alternative_cancel:
    'create_interpretation_order_settings_specific_gender_alternative_cancel',
  create_interpretation_order_settings_specific_gender_alternative_find_any_gender:
    'create_interpretation_order_settings_specific_gender_alternative_find_any_gender',
  create_interpretation_order_settings_specific_gender_alternative_question:
    'create_interpretation_order_settings_specific_gender_alternative_question',
  create_interpretation_order_settings_specific_gender_description:
    'create_interpretation_order_settings_specific_gender_description',
  create_interpretation_order_settings_specific_gender_female:
    'create_interpretation_order_settings_specific_gender_female',
  create_interpretation_order_settings_specific_gender_male:
    'create_interpretation_order_settings_specific_gender_male',
  create_interpretation_order_settings_specific_gender_title:
    'create_interpretation_order_settings_specific_gender_title',
  create_interpretation_order_settings_specific_interpreter_alternative_cancel:
    'create_interpretation_order_settings_specific_interpreter_alternative_cancel',
  create_interpretation_order_settings_specific_interpreter_alternative_find_another:
    'create_interpretation_order_settings_specific_interpreter_alternative_find_another',
  create_interpretation_order_settings_specific_interpreter_availability:
    'create_interpretation_order_settings_specific_interpreter_availability',
  create_interpretation_order_settings_specific_interpreter_availability_not_available_legend:
    'create_interpretation_order_settings_specific_interpreter_availability_not_available_legend',
  create_interpretation_order_settings_specific_interpreter_availability_selected_legend:
    'create_interpretation_order_settings_specific_interpreter_availability_selected_legend',
  create_interpretation_order_settings_specific_interpreter_availability_standby_legend:
    'create_interpretation_order_settings_specific_interpreter_availability_standby_legend',
  create_interpretation_order_settings_specific_interpreter_availability_travel_time_legend:
    'create_interpretation_order_settings_specific_interpreter_availability_travel_time_legend',
  create_interpretation_order_settings_specific_interpreter_busy_error_message:
    'create_interpretation_order_settings_specific_interpreter_busy_error_message',
  create_interpretation_order_settings_specific_interpreter_description:
    'create_interpretation_order_settings_specific_interpreter_description',
  create_interpretation_order_settings_specific_interpreter_gender_warning:
    'create_interpretation_order_settings_specific_interpreter_gender_warning',
  create_interpretation_order_settings_specific_interpreter_label:
    'create_interpretation_order_settings_specific_interpreter_label',
  create_interpretation_order_settings_specific_interpreter_language_warning:
    'create_interpretation_order_settings_specific_interpreter_language_warning',
  create_interpretation_order_settings_specific_interpreter_placeholder:
    'create_interpretation_order_settings_specific_interpreter_placeholder',
  create_interpretation_order_settings_specific_interpreter_qualification_warning:
    'create_interpretation_order_settings_specific_interpreter_qualification_warning',
  create_interpretation_order_settings_specific_interpreter_session_type_warning:
    'create_interpretation_order_settings_specific_interpreter_session_type_warning',
  create_interpretation_order_settings_specific_interpreter_title:
    'create_interpretation_order_settings_specific_interpreter_title',
  create_interpretation_order_settings_upload_file_description:
    'create_interpretation_order_settings_upload_file_description',
  create_interpretation_order_settings_upload_file_text:
    'create_interpretation_order_settings_upload_file_text',
  create_interpretation_order_settings_upload_file_title:
    'create_interpretation_order_settings_upload_file_title',
  create_interpretation_order_single_duplicated_job_modal_message:
    'create_interpretation_order_single_duplicated_job_modal_message',
  create_interpretation_order_summary_attendees_label:
    'create_interpretation_order_summary_attendees_label',
  create_interpretation_order_summary_booker_label:
    'create_interpretation_order_summary_booker_label',
  create_interpretation_order_summary_booking_mechanism_label:
    'create_interpretation_order_summary_booking_mechanism_label',
  create_interpretation_order_summary_confirm_booking_by_label:
    'create_interpretation_order_summary_confirm_booking_by_label',
  create_interpretation_order_summary_confirmation_label:
    'create_interpretation_order_summary_confirmation_label',
  create_interpretation_order_summary_date_and_time_label:
    'create_interpretation_order_summary_date_and_time_label',
  create_interpretation_order_summary_directions_label:
    'create_interpretation_order_summary_directions_label',
  create_interpretation_order_summary_fees_label:
    'create_interpretation_order_summary_fees_label',
  create_interpretation_order_summary_if_no_gender_interpreter:
    'create_interpretation_order_summary_if_no_gender_interpreter',
  create_interpretation_order_summary_if_no_interpreter:
    'create_interpretation_order_summary_if_no_interpreter',
  create_interpretation_order_summary_if_no_qualification:
    'create_interpretation_order_summary_if_no_qualification',
  create_interpretation_order_summary_if_no_specific_interpreter:
    'create_interpretation_order_summary_if_no_specific_interpreter',
  create_interpretation_order_summary_label:
    'create_interpretation_order_summary_label',
  create_interpretation_order_summary_off_label:
    'create_interpretation_order_summary_off_label',
  create_interpretation_order_summary_on_label:
    'create_interpretation_order_summary_on_label',
  create_interpretation_order_top_four_label:
    'create_interpretation_order_top_four_label',
  create_interpretation_order_video_solution_drawer_camera_subtitle:
    'create_interpretation_order_video_solution_drawer_camera_subtitle',
  create_interpretation_order_video_solution_drawer_camera_title:
    'create_interpretation_order_video_solution_drawer_camera_title',
  create_interpretation_order_video_solution_drawer_download:
    'create_interpretation_order_video_solution_drawer_download',
  create_interpretation_order_video_solution_drawer_included_with_macos:
    'create_interpretation_order_video_solution_drawer_included_with_macos',
  create_interpretation_order_video_solution_drawer_permissions_subtitle:
    'create_interpretation_order_video_solution_drawer_permissions_subtitle',
  create_interpretation_order_video_solution_drawer_permissions_title:
    'create_interpretation_order_video_solution_drawer_permissions_title',
  create_interpretation_order_video_solution_drawer_subtitle:
    'create_interpretation_order_video_solution_drawer_subtitle',
  create_interpretation_order_video_solution_drawer_title:
    'create_interpretation_order_video_solution_drawer_title',
  create_interpretation_order_video_solution_drawer_update_subtitle:
    'create_interpretation_order_video_solution_drawer_update_subtitle',
  create_interpretation_order_video_solution_drawer_update_title:
    'create_interpretation_order_video_solution_drawer_update_title',
  create_interpretation_order_video_solution_drawer_wifi_subtitle:
    'create_interpretation_order_video_solution_drawer_wifi_subtitle',
  create_interpretation_order_video_solution_drawer_wifi_title:
    'create_interpretation_order_video_solution_drawer_wifi_title',
  create_payment_method_drawer_add_button:
    'create_payment_method_drawer_add_button',
  create_payment_method_drawer_cancel_button:
    'create_payment_method_drawer_cancel_button',
  create_payment_method_drawer_customer_type:
    'create_payment_method_drawer_customer_type',
  create_payment_method_drawer_departments_placeholder:
    'create_payment_method_drawer_departments_placeholder',
  create_payment_method_drawer_enterprise_label:
    'create_payment_method_drawer_enterprise_label',
  create_payment_method_drawer_enterprise_placeholder:
    'create_payment_method_drawer_enterprise_placeholder',
  create_payment_method_drawer_group_invoices_label:
    'create_payment_method_drawer_group_invoices_label',
  create_payment_method_drawer_info_banner:
    'create_payment_method_drawer_info_banner',
  create_payment_method_drawer_invoice_email_label:
    'create_payment_method_drawer_invoice_email_label',
  create_payment_method_drawer_invoice_email_placeholder:
    'create_payment_method_drawer_invoice_email_placeholder',
  create_payment_method_drawer_label: 'create_payment_method_drawer_label',
  create_payment_method_drawer_no_default_label:
    'create_payment_method_drawer_no_default_label',
  create_payment_method_drawer_set_default_for_departments_checkbox:
    'create_payment_method_drawer_set_default_for_departments_checkbox',
  create_payment_method_drawer_set_default_for_enterprise_checkbox:
    'create_payment_method_drawer_set_default_for_enterprise_checkbox',
  create_supplier_drawer_address_label: 'create_supplier_drawer_address_label',
  create_supplier_drawer_address_placeholder:
    'create_supplier_drawer_address_placeholder',
  create_supplier_drawer_clear_button: 'create_supplier_drawer_clear_button',
  create_supplier_drawer_create_button: 'create_supplier_drawer_create_button',
  create_supplier_drawer_dob_day_error_message:
    'create_supplier_drawer_dob_day_error_message',
  create_supplier_drawer_dob_label: 'create_supplier_drawer_dob_label',
  create_supplier_drawer_dob_max_year_error_message:
    'create_supplier_drawer_dob_max_year_error_message',
  create_supplier_drawer_dob_month_error_message:
    'create_supplier_drawer_dob_month_error_message',
  create_supplier_drawer_dob_year_error_message:
    'create_supplier_drawer_dob_year_error_message',
  create_supplier_drawer_dob_year_placeholder:
    'create_supplier_drawer_dob_year_placeholder',
  create_supplier_drawer_gender_female_label:
    'create_supplier_drawer_gender_female_label',
  create_supplier_drawer_gender_label: 'create_supplier_drawer_gender_label',
  create_supplier_drawer_gender_male_label:
    'create_supplier_drawer_gender_male_label',
  create_supplier_drawer_success_add_skill_button:
    'create_supplier_drawer_success_add_skill_button',
  create_supplier_drawer_success_go_to_profile_button:
    'create_supplier_drawer_success_go_to_profile_button',
  create_supplier_drawer_success_message:
    'create_supplier_drawer_success_message',
  create_translation_order_all_departments_group:
    'create_translation_order_all_departments_group',
  create_translation_order_change_customer_button:
    'create_translation_order_change_customer_button',
  create_translation_order_change_customer_modal_back_button:
    'create_translation_order_change_customer_modal_back_button',
  create_translation_order_change_customer_modal_change_button:
    'create_translation_order_change_customer_modal_change_button',
  create_translation_order_change_customer_modal_description:
    'create_translation_order_change_customer_modal_description',
  create_translation_order_change_customer_modal_label:
    'create_translation_order_change_customer_modal_label',
  create_translation_order_customer_add_department_button:
    'create_translation_order_customer_add_department_button',
  create_translation_order_customer_all_departments_group_label:
    'create_translation_order_customer_all_departments_group_label',
  create_translation_order_customer_booker_name_placeholder:
    'create_translation_order_customer_booker_name_placeholder',
  create_translation_order_customer_department_label:
    'create_translation_order_customer_department_label',
  create_translation_order_customer_departments_group:
    'create_translation_order_customer_departments_group',
  create_translation_order_customer_departments_group_label:
    'create_translation_order_customer_departments_group_label',
  create_translation_order_customer_email_label:
    'create_translation_order_customer_email_label',
  create_translation_order_customer_enterprise_label:
    'create_translation_order_customer_enterprise_label',
  create_translation_order_customer_label:
    'create_translation_order_customer_label',
  create_translation_order_customer_name_label:
    'create_translation_order_customer_name_label',
  create_translation_order_customer_phone_label:
    'create_translation_order_customer_phone_label',
  create_translation_order_information_alternative_language_label:
    'create_translation_order_information_alternative_language_label',
  create_translation_order_information_alternative_language_placeholder:
    'create_translation_order_information_alternative_language_placeholder',
  create_translation_order_information_correction_radio_option:
    'create_translation_order_information_correction_radio_option',
  create_translation_order_information_deadline_flexible:
    'create_translation_order_information_deadline_flexible',
  create_translation_order_information_deadline_label:
    'create_translation_order_information_deadline_label',
  create_translation_order_information_description_hint:
    'create_translation_order_information_description_hint',
  create_translation_order_information_description_label:
    'create_translation_order_information_description_label',
  create_translation_order_information_description_placeholder:
    'create_translation_order_information_description_placeholder',
  create_translation_order_information_file_delete_label:
    'create_translation_order_information_file_delete_label',
  create_translation_order_information_file_download_label:
    'create_translation_order_information_file_download_label',
  create_translation_order_information_file_password_placeholder:
    'create_translation_order_information_file_password_placeholder',
  create_translation_order_information_from_label:
    'create_translation_order_information_from_label',
  create_translation_order_information_from_placeholder:
    'create_translation_order_information_from_placeholder',
  create_translation_order_information_in_person_radio_option:
    'create_translation_order_information_in_person_radio_option',
  create_translation_order_information_label:
    'create_translation_order_information_label',
  create_translation_order_information_language_label:
    'create_translation_order_information_language_label',
  create_translation_order_information_language_placeholder:
    'create_translation_order_information_language_placeholder',
  create_translation_order_information_meeting_date_label:
    'create_translation_order_information_meeting_date_label',
  create_translation_order_information_no_source_language_checkbox:
    'create_translation_order_information_no_source_language_checkbox',
  create_translation_order_information_other_subject_label:
    'create_translation_order_information_other_subject_label',
  create_translation_order_information_other_subject_placeholder:
    'create_translation_order_information_other_subject_placeholder',
  create_translation_order_information_password_protection_toggle:
    'create_translation_order_information_password_protection_toggle',
  create_translation_order_information_subject_label:
    'create_translation_order_information_subject_label',
  create_translation_order_information_subject_other_option:
    'create_translation_order_information_subject_other_option',
  create_translation_order_information_subject_placeholder:
    'create_translation_order_information_subject_placeholder',
  create_translation_order_information_to_label:
    'create_translation_order_information_to_label',
  create_translation_order_information_to_placeholder:
    'create_translation_order_information_to_placeholder',
  create_translation_order_information_translation_radio_option:
    'create_translation_order_information_translation_radio_option',
  create_translation_order_information_type_label:
    'create_translation_order_information_type_label',
  create_translation_order_information_word_count_hint:
    'create_translation_order_information_word_count_hint',
  create_translation_order_information_word_count_label:
    'create_translation_order_information_word_count_label',
  create_translation_order_information_word_count_placeholder:
    'create_translation_order_information_word_count_placeholder',
  create_translation_order_invoicing_add_new_button:
    'create_translation_order_invoicing_add_new_button',
  create_translation_order_label: 'create_translation_order_label',
  create_translation_order_optional_tag:
    'create_translation_order_optional_tag',
  create_translation_order_payment_label:
    'create_translation_order_payment_label',
  create_translation_order_payment_per_booker:
    'create_translation_order_payment_per_booker',
  create_translation_order_payment_per_buyer_order_and_reference:
    'create_translation_order_payment_per_buyer_order_and_reference',
  create_translation_order_payment_per_buyer_reference:
    'create_translation_order_payment_per_buyer_reference',
  create_translation_order_payment_per_customer:
    'create_translation_order_payment_per_customer',
  create_translation_order_payment_per_department:
    'create_translation_order_payment_per_department',
  create_translation_order_payment_per_job:
    'create_translation_order_payment_per_job',
  create_translation_order_payment_per_order_reference:
    'create_translation_order_payment_per_order_reference',
  create_translation_order_postage_add_return_address_address_label:
    'create_translation_order_postage_add_return_address_address_label',
  create_translation_order_postage_add_return_address_address_placeholder:
    'create_translation_order_postage_add_return_address_address_placeholder',
  create_translation_order_postage_add_return_address_company_label:
    'create_translation_order_postage_add_return_address_company_label',
  create_translation_order_postage_add_return_address_company_placeholder:
    'create_translation_order_postage_add_return_address_company_placeholder',
  create_translation_order_postage_add_return_address_manually_radio_option:
    'create_translation_order_postage_add_return_address_manually_radio_option',
  create_translation_order_postage_add_return_address_name_label:
    'create_translation_order_postage_add_return_address_name_label',
  create_translation_order_postage_add_return_address_name_placeholder:
    'create_translation_order_postage_add_return_address_name_placeholder',
  create_translation_order_postage_cover_letter_includes_return_address_checkbox:
    'create_translation_order_postage_cover_letter_includes_return_address_checkbox',
  create_translation_order_postage_cover_letter_label:
    'create_translation_order_postage_cover_letter_label',
  create_translation_order_postage_description:
    'create_translation_order_postage_description',
  create_translation_order_postage_file_delete_label:
    'create_translation_order_postage_file_delete_label',
  create_translation_order_postage_file_download_label:
    'create_translation_order_postage_file_download_label',
  create_translation_order_postage_label:
    'create_translation_order_postage_label',
  create_translation_order_postage_return_address_label:
    'create_translation_order_postage_return_address_label',
  create_translation_order_postage_use_cover_letter_radio_option:
    'create_translation_order_postage_use_cover_letter_radio_option',
  create_translation_order_preview_order_button:
    'create_translation_order_preview_order_button',
  create_translation_order_search_customer_placeholder:
    'create_translation_order_search_customer_placeholder',
  create_translation_order_settings_access_level_description:
    'create_translation_order_settings_access_level_description',
  create_translation_order_settings_access_level_label:
    'create_translation_order_settings_access_level_label',
  create_translation_order_settings_access_level_selector_label:
    'create_translation_order_settings_access_level_selector_label',
  create_translation_order_settings_cc_description:
    'create_translation_order_settings_cc_description',
  create_translation_order_settings_cc_email_add_button:
    'create_translation_order_settings_cc_email_add_button',
  create_translation_order_settings_cc_email_label:
    'create_translation_order_settings_cc_email_label',
  create_translation_order_settings_cc_email_placeholder:
    'create_translation_order_settings_cc_email_placeholder',
  create_translation_order_settings_cc_label:
    'create_translation_order_settings_cc_label',
  create_translation_order_settings_label:
    'create_translation_order_settings_label',
  create_translation_order_summary_back_button:
    'create_translation_order_summary_back_button',
  create_translation_order_summary_customer_booker_label:
    'create_translation_order_summary_customer_booker_label',
  create_translation_order_summary_customer_department_label:
    'create_translation_order_summary_customer_department_label',
  create_translation_order_summary_customer_email_label:
    'create_translation_order_summary_customer_email_label',
  create_translation_order_summary_customer_enterprise_label:
    'create_translation_order_summary_customer_enterprise_label',
  create_translation_order_summary_customer_label:
    'create_translation_order_summary_customer_label',
  create_translation_order_summary_customer_name_label:
    'create_translation_order_summary_customer_name_label',
  create_translation_order_summary_customer_phone_label:
    'create_translation_order_summary_customer_phone_label',
  create_translation_order_summary_information_deadline_label:
    'create_translation_order_summary_information_deadline_label',
  create_translation_order_summary_information_description_label:
    'create_translation_order_summary_information_description_label',
  create_translation_order_summary_information_files_label:
    'create_translation_order_summary_information_files_label',
  create_translation_order_summary_information_from_label:
    'create_translation_order_summary_information_from_label',
  create_translation_order_summary_information_label:
    'create_translation_order_summary_information_label',
  create_translation_order_summary_information_subject_label:
    'create_translation_order_summary_information_subject_label',
  create_translation_order_summary_information_to_label:
    'create_translation_order_summary_information_to_label',
  create_translation_order_summary_information_type_label:
    'create_translation_order_summary_information_type_label',
  create_translation_order_summary_information_word_count_label:
    'create_translation_order_summary_information_word_count_label',
  create_translation_order_summary_invoicing_label:
    'create_translation_order_summary_invoicing_label',
  create_translation_order_summary_invoicing_payment_method_label:
    'create_translation_order_summary_invoicing_payment_method_label',
  create_translation_order_summary_label:
    'create_translation_order_summary_label',
  create_translation_order_summary_place_order_button:
    'create_translation_order_summary_place_order_button',
  create_translation_order_summary_postage_address_label:
    'create_translation_order_summary_postage_address_label',
  create_translation_order_summary_postage_company_label:
    'create_translation_order_summary_postage_company_label',
  create_translation_order_summary_postage_cover_letter_label:
    'create_translation_order_summary_postage_cover_letter_label',
  create_translation_order_summary_postage_file_has_address:
    'create_translation_order_summary_postage_file_has_address',
  create_translation_order_summary_postage_label:
    'create_translation_order_summary_postage_label',
  create_translation_order_summary_postage_name_label:
    'create_translation_order_summary_postage_name_label',
  create_translation_order_summary_postage_return_address_label:
    'create_translation_order_summary_postage_return_address_label',
  create_translation_order_summary_settings_access_level_label:
    'create_translation_order_summary_settings_access_level_label',
  create_translation_order_summary_settings_cc_label:
    'create_translation_order_summary_settings_cc_label',
  create_translation_order_summary_settings_label:
    'create_translation_order_summary_settings_label',
  create_translation_order_top_four_label:
    'create_translation_order_top_four_label',
  customer_page_convert_customer_to_enterprise_tooltip:
    'customer_page_convert_customer_to_enterprise_tooltip',
  customer_page_delete_disabled_tooltip:
    'customer_page_delete_disabled_tooltip',
  customer_page_delete_tooltip: 'customer_page_delete_tooltip',
  customer_page_edit_enterprise_tooltip:
    'customer_page_edit_enterprise_tooltip',
  customer_page_edit_info_tooltip: 'customer_page_edit_info_tooltip',
  customer_page_email_not_verified: 'customer_page_email_not_verified',
  customer_page_email_verification_sent:
    'customer_page_email_verification_sent',
  customer_page_email_verified: 'customer_page_email_verified',
  customer_page_merge_customer_tooltip: 'customer_page_merge_customer_tooltip',
  customer_page_suppressed_email_tooltip:
    'customer_page_suppressed_email_tooltip',
  customers_page_actions_header: 'customers_page_actions_header',
  customers_page_customer_type_company: 'customers_page_customer_type_company',
  customers_page_customer_type_private: 'customers_page_customer_type_private',
  customers_page_customer_type_undefined:
    'customers_page_customer_type_undefined',
  customers_page_delete_modal_banner: 'customers_page_delete_modal_banner',
  customers_page_delete_modal_banner_attached_jobs:
    'customers_page_delete_modal_banner_attached_jobs',
  customers_page_delete_modal_cancel_button:
    'customers_page_delete_modal_cancel_button',
  customers_page_delete_modal_confirm_checkbox:
    'customers_page_delete_modal_confirm_checkbox',
  customers_page_delete_modal_delete_button:
    'customers_page_delete_modal_delete_button',
  customers_page_delete_modal_departments_label:
    'customers_page_delete_modal_departments_label',
  customers_page_delete_modal_enterprise_label:
    'customers_page_delete_modal_enterprise_label',
  customers_page_delete_modal_label: 'customers_page_delete_modal_label',
  customers_page_delete_modal_reason_does_not_work:
    'customers_page_delete_modal_reason_does_not_work',
  customers_page_delete_modal_reason_duplicate:
    'customers_page_delete_modal_reason_duplicate',
  customers_page_delete_modal_reason_label:
    'customers_page_delete_modal_reason_label',
  customers_page_delete_modal_reason_mistake:
    'customers_page_delete_modal_reason_mistake',
  customers_page_delete_modal_reason_other:
    'customers_page_delete_modal_reason_other',
  customers_page_delete_modal_reason_other_input_label:
    'customers_page_delete_modal_reason_other_input_label',
  customers_page_delete_modal_reason_other_input_placeholder:
    'customers_page_delete_modal_reason_other_input_placeholder',
  customers_page_delete_modal_subtitle: 'customers_page_delete_modal_subtitle',
  customers_page_departments_filter_placeholder:
    'customers_page_departments_filter_placeholder',
  customers_page_departments_header: 'customers_page_departments_header',
  customers_page_edit_employee_assign_roles_admin:
    'customers_page_edit_employee_assign_roles_admin',
  customers_page_edit_employee_assign_roles_coordinator:
    'customers_page_edit_employee_assign_roles_coordinator',
  customers_page_edit_employee_assign_roles_manager:
    'customers_page_edit_employee_assign_roles_manager',
  customers_page_edit_employee_assign_roles_title:
    'customers_page_edit_employee_assign_roles_title',
  customers_page_edit_employee_cancel_button:
    'customers_page_edit_employee_cancel_button',
  customers_page_edit_employee_country_code_label:
    'customers_page_edit_employee_country_code_label',
  customers_page_edit_employee_departments_label:
    'customers_page_edit_employee_departments_label',
  customers_page_edit_employee_departments_placeholder:
    'customers_page_edit_employee_departments_placeholder',
  customers_page_edit_employee_edit_button:
    'customers_page_edit_employee_edit_button',
  customers_page_edit_employee_email_label:
    'customers_page_edit_employee_email_label',
  customers_page_edit_employee_email_placeholder:
    'customers_page_edit_employee_email_placeholder',
  customers_page_edit_employee_email_suppressed_error_description:
    'customers_page_edit_employee_email_suppressed_error_description',
  customers_page_edit_employee_email_suppressed_error_label:
    'customers_page_edit_employee_email_suppressed_error_label',
  customers_page_edit_employee_email_suppressed_error_remove_button:
    'customers_page_edit_employee_email_suppressed_error_remove_button',
  customers_page_edit_employee_email_suppressed_info_description:
    'customers_page_edit_employee_email_suppressed_info_description',
  customers_page_edit_employee_first_name_label:
    'customers_page_edit_employee_first_name_label',
  customers_page_edit_employee_first_name_placeholder:
    'customers_page_edit_employee_first_name_placeholder',
  customers_page_edit_employee_last_name_label:
    'customers_page_edit_employee_last_name_label',
  customers_page_edit_employee_last_name_placeholder:
    'customers_page_edit_employee_last_name_placeholder',
  customers_page_edit_employee_phone_label:
    'customers_page_edit_employee_phone_label',
  customers_page_edit_employee_phone_placeholder:
    'customers_page_edit_employee_phone_placeholder',
  customers_page_edit_employee_title: 'customers_page_edit_employee_title',
  customers_page_empty_state_subtitle: 'customers_page_empty_state_subtitle',
  customers_page_empty_state_title: 'customers_page_empty_state_title',
  customers_page_error_state_subtitle: 'customers_page_error_state_subtitle',
  customers_page_error_state_title: 'customers_page_error_state_title',
  customers_page_id_filter_placeholder: 'customers_page_id_filter_placeholder',
  customers_page_id_header: 'customers_page_id_header',
  customers_page_load_more_button: 'customers_page_load_more_button',
  customers_page_merge_cancel_button: 'customers_page_merge_cancel_button',
  customers_page_merge_customers_label: 'customers_page_merge_customers_label',
  customers_page_merge_customers_placeholder:
    'customers_page_merge_customers_placeholder',
  customers_page_merge_duplicate_accounts_description:
    'customers_page_merge_duplicate_accounts_description',
  customers_page_merge_duplicate_accounts_title:
    'customers_page_merge_duplicate_accounts_title',
  customers_page_merge_id_label: 'customers_page_merge_id_label',
  customers_page_merge_info_text: 'customers_page_merge_info_text',
  customers_page_merge_merge_button: 'customers_page_merge_merge_button',
  customers_page_merge_subtitle: 'customers_page_merge_subtitle',
  customers_page_merge_title: 'customers_page_merge_title',
  customers_page_move_cancel_button: 'customers_page_move_cancel_button',
  customers_page_move_departments_label:
    'customers_page_move_departments_label',
  customers_page_move_departments_placeholder:
    'customers_page_move_departments_placeholder',
  customers_page_move_do_not_move_assignments:
    'customers_page_move_do_not_move_assignments',
  customers_page_move_do_not_move_assignments_message:
    'customers_page_move_do_not_move_assignments_message',
  customers_page_move_enterprise_label: 'customers_page_move_enterprise_label',
  customers_page_move_enterprise_placeholder:
    'customers_page_move_enterprise_placeholder',
  customers_page_move_id_label: 'customers_page_move_id_label',
  customers_page_move_move_all_assignments:
    'customers_page_move_move_all_assignments',
  customers_page_move_move_assignments_message:
    'customers_page_move_move_assignments_message',
  customers_page_move_move_button: 'customers_page_move_move_button',
  customers_page_move_subtitle: 'customers_page_move_subtitle',
  customers_page_move_title: 'customers_page_move_title',
  customers_page_name_filter_placeholder:
    'customers_page_name_filter_placeholder',
  customers_page_name_header: 'customers_page_name_header',
  customers_page_org_filter_placeholder:
    'customers_page_org_filter_placeholder',
  customers_page_org_header: 'customers_page_org_header',
  customers_page_status_active: 'customers_page_status_active',
  customers_page_status_banned: 'customers_page_status_banned',
  customers_page_status_blocked: 'customers_page_status_blocked',
  customers_page_status_deceased: 'customers_page_status_deceased',
  customers_page_status_deleted: 'customers_page_status_deleted',
  customers_page_status_filter_placeholder:
    'customers_page_status_filter_placeholder',
  customers_page_status_header: 'customers_page_status_header',
  customers_page_status_inactive: 'customers_page_status_inactive',
  customers_page_status_paused: 'customers_page_status_paused',
  customers_page_status_retired: 'customers_page_status_retired',
  customers_page_title: 'customers_page_title',
  customers_page_to_enterprise_add_payment_button:
    'customers_page_to_enterprise_add_payment_button',
  customers_page_to_enterprise_address_label:
    'customers_page_to_enterprise_address_label',
  customers_page_to_enterprise_brreg_label:
    'customers_page_to_enterprise_brreg_label',
  customers_page_to_enterprise_cancel_button:
    'customers_page_to_enterprise_cancel_button',
  customers_page_to_enterprise_department_label:
    'customers_page_to_enterprise_department_label',
  customers_page_to_enterprise_department_placeholder:
    'customers_page_to_enterprise_department_placeholder',
  customers_page_to_enterprise_municipality_label:
    'customers_page_to_enterprise_municipality_label',
  customers_page_to_enterprise_name_label:
    'customers_page_to_enterprise_name_label',
  customers_page_to_enterprise_org_form_label:
    'customers_page_to_enterprise_org_form_label',
  customers_page_to_enterprise_org_number_label:
    'customers_page_to_enterprise_org_number_label',
  customers_page_to_enterprise_organisation_search_label:
    'customers_page_to_enterprise_organisation_search_label',
  customers_page_to_enterprise_organisation_search_placeholder:
    'customers_page_to_enterprise_organisation_search_placeholder',
  customers_page_to_enterprise_payment_method_label:
    'customers_page_to_enterprise_payment_method_label',
  customers_page_to_enterprise_payment_method_optional_label:
    'customers_page_to_enterprise_payment_method_optional_label',
  customers_page_to_enterprise_success_close_button:
    'customers_page_to_enterprise_success_close_button',
  customers_page_to_enterprise_success_open_enterprise_page_button:
    'customers_page_to_enterprise_success_open_enterprise_page_button',
  customers_page_to_enterprise_success_text:
    'customers_page_to_enterprise_success_text',
  customers_page_to_enterprise_success_title:
    'customers_page_to_enterprise_success_title',
  customers_page_to_enterprise_title: 'customers_page_to_enterprise_title',
  customers_page_to_enterprise_upgrade_button:
    'customers_page_to_enterprise_upgrade_button',
  customers_page_to_enterprise_warning: 'customers_page_to_enterprise_warning',
  customers_page_to_enterprise_website_label:
    'customers_page_to_enterprise_website_label',
  customers_page_type_filter_placeholder:
    'customers_page_type_filter_placeholder',
  customers_page_type_header: 'customers_page_type_header',
  date_picker_cancel_button: 'date_picker_cancel_button',
  date_picker_previous_month_label: 'date_picker_previous_month_label',
  date_picker_previous_year_label: 'date_picker_previous_year_label',
  date_picker_submit_button: 'date_picker_submit_button',
  date_picker_this_month_label: 'date_picker_this_month_label',
  date_picker_today_label: 'date_picker_today_label',
  date_picker_yesterday_label: 'date_picker_yesterday_label',
  default_regular_expression_hint: 'default_regular_expression_hint',
  drawer_back_button: 'drawer_back_button',
  dropdown_menu_clear_selected: 'dropdown_menu_clear_selected',
  edit_booker_drawer_cancel_button: 'edit_booker_drawer_cancel_button',
  edit_booker_drawer_change_owner_button:
    'edit_booker_drawer_change_owner_button',
  edit_booker_drawer_change_owner_button_text:
    'edit_booker_drawer_change_owner_button_text',
  edit_booker_drawer_change_owner_title:
    'edit_booker_drawer_change_owner_title',
  edit_booker_drawer_customer_search_help_text:
    'edit_booker_drawer_customer_search_help_text',
  edit_booker_drawer_department_label: 'edit_booker_drawer_department_label',
  edit_booker_drawer_email_label: 'edit_booker_drawer_email_label',
  edit_booker_drawer_enterprise_label: 'edit_booker_drawer_enterprise_label',
  edit_booker_drawer_name_label: 'edit_booker_drawer_name_label',
  edit_booker_drawer_phone_code_label: 'edit_booker_drawer_phone_code_label',
  edit_booker_drawer_phone_number_label:
    'edit_booker_drawer_phone_number_label',
  edit_booker_drawer_save_button: 'edit_booker_drawer_save_button',
  edit_booker_drawer_search_customer_placeholder:
    'edit_booker_drawer_search_customer_placeholder',
  edit_booker_drawer_title: 'edit_booker_drawer_title',
  edit_booker_drawer_unexpected_error_text:
    'edit_booker_drawer_unexpected_error_text',
  edit_order_drawer_access_level_department_option:
    'edit_order_drawer_access_level_department_option',
  edit_order_drawer_access_level_enterprise_option:
    'edit_order_drawer_access_level_enterprise_option',
  edit_order_drawer_access_level_label: 'edit_order_drawer_access_level_label',
  edit_order_drawer_access_level_owner_option:
    'edit_order_drawer_access_level_owner_option',
  edit_order_drawer_alternative_language_label:
    'edit_order_drawer_alternative_language_label',
  edit_order_drawer_cancel_button: 'edit_order_drawer_cancel_button',
  edit_order_drawer_created_label: 'edit_order_drawer_created_label',
  edit_order_drawer_department_error_text:
    'edit_order_drawer_department_error_text',
  edit_order_drawer_english_option: 'edit_order_drawer_english_option',
  edit_order_drawer_enterprise_error_text:
    'edit_order_drawer_enterprise_error_text',
  edit_order_drawer_external_deadline_label:
    'edit_order_drawer_external_deadline_label',
  edit_order_drawer_logout_email_label: 'edit_order_drawer_logout_email_label',
  edit_order_drawer_logout_email_placeholder:
    'edit_order_drawer_logout_email_placeholder',
  edit_order_drawer_logout_enterprise_label:
    'edit_order_drawer_logout_enterprise_label',
  edit_order_drawer_logout_enterprise_placeholder:
    'edit_order_drawer_logout_enterprise_placeholder',
  edit_order_drawer_logout_first_name_label:
    'edit_order_drawer_logout_first_name_label',
  edit_order_drawer_logout_first_name_placeholder:
    'edit_order_drawer_logout_first_name_placeholder',
  edit_order_drawer_logout_last_name_label:
    'edit_order_drawer_logout_last_name_label',
  edit_order_drawer_logout_last_name_placeholder:
    'edit_order_drawer_logout_last_name_placeholder',
  edit_order_drawer_logout_payment_method_label:
    'edit_order_drawer_logout_payment_method_label',
  edit_order_drawer_logout_payment_method_placeholder:
    'edit_order_drawer_logout_payment_method_placeholder',
  edit_order_drawer_logout_phone_code_label:
    'edit_order_drawer_logout_phone_code_label',
  edit_order_drawer_logout_phone_number_label:
    'edit_order_drawer_logout_phone_number_label',
  edit_order_drawer_logout_phone_number_placeholder:
    'edit_order_drawer_logout_phone_number_placeholder',
  edit_order_drawer_logout_title: 'edit_order_drawer_logout_title',
  edit_order_drawer_no_preference_option:
    'edit_order_drawer_no_preference_option',
  edit_order_drawer_norwegian_option: 'edit_order_drawer_norwegian_option',
  edit_order_drawer_owner_error_text: 'edit_order_drawer_owner_error_text',
  edit_order_drawer_source_label: 'edit_order_drawer_source_label',
  edit_order_drawer_source_language_hint:
    'edit_order_drawer_source_language_hint',
  edit_order_drawer_source_language_label:
    'edit_order_drawer_source_language_label',
  edit_order_drawer_source_language_placeholder:
    'edit_order_drawer_source_language_placeholder',
  edit_order_drawer_source_logout_option:
    'edit_order_drawer_source_logout_option',
  edit_order_drawer_source_personal_email_option:
    'edit_order_drawer_source_personal_email_option',
  edit_order_drawer_source_platform_option:
    'edit_order_drawer_source_platform_option',
  edit_order_drawer_source_post_email_option:
    'edit_order_drawer_source_post_email_option',
  edit_order_drawer_source_post_option: 'edit_order_drawer_source_post_option',
  edit_order_drawer_source_project_email_option:
    'edit_order_drawer_source_project_email_option',
  edit_order_drawer_source_translators_email_option:
    'edit_order_drawer_source_translators_email_option',
  edit_order_drawer_submit_button: 'edit_order_drawer_submit_button',
  edit_order_drawer_title: 'edit_order_drawer_title',
  edit_order_drawer_word_count_label: 'edit_order_drawer_word_count_label',
  edit_skill_drawer_skill_duplicate_lang_combination_error:
    'edit_skill_drawer_skill_duplicate_lang_combination_error',
  edit_skill_drawer_skill_exists_error_text:
    'edit_skill_drawer_skill_exists_error_text',
  edit_skill_drawer_skill_inform_supplier_checkbox_help_text:
    'edit_skill_drawer_skill_inform_supplier_checkbox_help_text',
  edit_skill_drawer_skill_save_button: 'edit_skill_drawer_skill_save_button',
  edit_skill_drawer_skill_type_label: 'edit_skill_drawer_skill_type_label',
  edit_skill_drawer_title: 'edit_skill_drawer_title',
  edit_skill_drawer_tooltip_text: 'edit_skill_drawer_tooltip_text',
  empty_state_refresh_button: 'empty_state_refresh_button',
  enterprise_card_departments_label: 'enterprise_card_departments_label',
  enterprise_card_members_label: 'enterprise_card_members_label',
  enterprise_card_org_number_label: 'enterprise_card_org_number_label',
  enterprise_card_owner_label: 'enterprise_card_owner_label',
  enterprises_page_departments_header: 'enterprises_page_departments_header',
  enterprises_page_empty_state_subtitle:
    'enterprises_page_empty_state_subtitle',
  enterprises_page_empty_state_title: 'enterprises_page_empty_state_title',
  enterprises_page_error_state_subtitle:
    'enterprises_page_error_state_subtitle',
  enterprises_page_error_state_title: 'enterprises_page_error_state_title',
  enterprises_page_id_filter_placeholder:
    'enterprises_page_id_filter_placeholder',
  enterprises_page_id_header: 'enterprises_page_id_header',
  enterprises_page_load_more_button: 'enterprises_page_load_more_button',
  enterprises_page_members_header: 'enterprises_page_members_header',
  enterprises_page_org_name_filter_placeholder:
    'enterprises_page_org_name_filter_placeholder',
  enterprises_page_org_name_header: 'enterprises_page_org_name_header',
  enterprises_page_org_number_filter_placeholder:
    'enterprises_page_org_number_filter_placeholder',
  enterprises_page_org_number_header: 'enterprises_page_org_number_header',
  enterprises_page_owner_filter_placeholder:
    'enterprises_page_owner_filter_placeholder',
  enterprises_page_owner_header: 'enterprises_page_owner_header',
  enterprises_page_posted_jobs_header: 'enterprises_page_posted_jobs_header',
  enterprises_page_title: 'enterprises_page_title',
  female_label: 'female_label',
  file_uploader_actions_delete: 'file_uploader_actions_delete',
  file_uploader_actions_download: 'file_uploader_actions_download',
  file_uploader_actions_rename: 'file_uploader_actions_rename',
  file_uploader_calculating_ocr_status_text:
    'file_uploader_calculating_ocr_status_text',
  file_uploader_error_empty_word_count: 'file_uploader_error_empty_word_count',
  file_uploader_error_estimating_word_count:
    'file_uploader_error_estimating_word_count',
  file_uploader_error_estimating_word_count_taking_time:
    'file_uploader_error_estimating_word_count_taking_time',
  file_uploader_error_file_exists: 'file_uploader_error_file_exists',
  file_uploader_error_files_limit: 'file_uploader_error_files_limit',
  file_uploader_error_invalid_file_type:
    'file_uploader_error_invalid_file_type',
  file_uploader_error_max_file_size: 'file_uploader_error_max_file_size',
  file_uploader_error_number_of_files: 'file_uploader_error_number_of_files',
  file_uploader_error_upload_failed: 'file_uploader_error_upload_failed',
  file_uploader_hint: 'file_uploader_hint',
  file_uploader_main_description: 'file_uploader_main_description',
  file_uploader_main_description_action_text:
    'file_uploader_main_description_action_text',
  file_uploader_rename_modal_file_name_label:
    'file_uploader_rename_modal_file_name_label',
  file_uploader_rename_modal_save_button:
    'file_uploader_rename_modal_save_button',
  file_uploader_rename_modal_subtitle_text:
    'file_uploader_rename_modal_subtitle_text',
  file_uploader_rename_modal_title: 'file_uploader_rename_modal_title',
  file_uploader_retry_button: 'file_uploader_retry_button',
  file_uploader_retry_upload_button: 'file_uploader_retry_upload_button',
  file_uploader_upload_failed: 'file_uploader_upload_failed',
  file_uploader_uploading_status_text: 'file_uploader_uploading_status_text',
  file_uploader_word_count_label: 'file_uploader_word_count_label',
  file_uploader_words_text: 'file_uploader_words_text',
  forgot_password_back_to_login_button: 'forgot_password_back_to_login_button',
  forgot_password_header_description: 'forgot_password_header_description',
  forgot_password_header_title: 'forgot_password_header_title',
  forgot_password_reset_button: 'forgot_password_reset_button',
  forgot_password_username_label: 'forgot_password_username_label',
  forgot_password_username_placeholder: 'forgot_password_username_placeholder',
  form_error_min_length: 'form_error_min_length',
  form_error_mismatch: 'form_error_mismatch',
  form_error_not_valid: 'form_error_not_valid',
  form_error_required: 'form_error_required',
  form_error_required_without_field_name:
    'form_error_required_without_field_name',
  global_date_time_picker_date_label: 'global_date_time_picker_date_label',
  global_date_time_picker_date_placeholder:
    'global_date_time_picker_date_placeholder',
  global_date_time_picker_time_label: 'global_date_time_picker_time_label',
  global_departments_number: 'global_departments_number',
  global_generic_error: 'global_generic_error',
  global_no_label: 'global_no_label',
  global_off_label: 'global_off_label',
  global_on_label: 'global_on_label',
  global_required_field_this: 'global_required_field_this',
  global_yes_label: 'global_yes_label',
  interpretation_skill_new_skill_dialect_input_error:
    'interpretation_skill_new_skill_dialect_input_error',
  interpretation_skills_account_status_header:
    'interpretation_skills_account_status_header',
  interpretation_skills_delete_skill_disabled_tooltip:
    'interpretation_skills_delete_skill_disabled_tooltip',
  interpretation_skills_in_person_assignment_icon_tooltip:
    'interpretation_skills_in_person_assignment_icon_tooltip',
  interpretation_skills_new_skill_add_button:
    'interpretation_skills_new_skill_add_button',
  interpretation_skills_new_skill_cancel_button:
    'interpretation_skills_new_skill_cancel_button',
  interpretation_skills_new_skill_dialect_from_label:
    'interpretation_skills_new_skill_dialect_from_label',
  interpretation_skills_new_skill_dialect_from_placeholder:
    'interpretation_skills_new_skill_dialect_from_placeholder',
  interpretation_skills_new_skill_dialect_to_label:
    'interpretation_skills_new_skill_dialect_to_label',
  interpretation_skills_new_skill_dialect_to_placeholder:
    'interpretation_skills_new_skill_dialect_to_placeholder',
  interpretation_skills_new_skill_language_from_error:
    'interpretation_skills_new_skill_language_from_error',
  interpretation_skills_new_skill_language_from_label:
    'interpretation_skills_new_skill_language_from_label',
  interpretation_skills_new_skill_language_from_placeholder:
    'interpretation_skills_new_skill_language_from_placeholder',
  interpretation_skills_new_skill_language_to_error:
    'interpretation_skills_new_skill_language_to_error',
  interpretation_skills_new_skill_language_to_label:
    'interpretation_skills_new_skill_language_to_label',
  interpretation_skills_new_skill_language_to_placeholder:
    'interpretation_skills_new_skill_language_to_placeholder',
  interpretation_skills_new_skill_native_from_label:
    'interpretation_skills_new_skill_native_from_label',
  interpretation_skills_new_skill_native_to_label:
    'interpretation_skills_new_skill_native_to_label',
  interpretation_skills_new_skill_notify_interpreter_label:
    'interpretation_skills_new_skill_notify_interpreter_label',
  interpretation_skills_new_skill_qualification_error:
    'interpretation_skills_new_skill_qualification_error',
  interpretation_skills_new_skill_qualification_label:
    'interpretation_skills_new_skill_qualification_label',
  interpretation_skills_new_skill_qualification_placeholder:
    'interpretation_skills_new_skill_qualification_placeholder',
  interpretation_skills_new_skill_status_error:
    'interpretation_skills_new_skill_status_error',
  interpretation_skills_new_skill_status_label:
    'interpretation_skills_new_skill_status_label',
  interpretation_skills_new_skill_status_placeholder:
    'interpretation_skills_new_skill_status_placeholder',
  interpretation_skills_new_skill_supplier_error:
    'interpretation_skills_new_skill_supplier_error',
  interpretation_skills_new_skill_supplier_label:
    'interpretation_skills_new_skill_supplier_label',
  interpretation_skills_new_skill_supplier_placeholder:
    'interpretation_skills_new_skill_supplier_placeholder',
  interpretation_skills_new_skill_title:
    'interpretation_skills_new_skill_title',
  interpretation_skills_page_account_status_filter_placeholder:
    'interpretation_skills_page_account_status_filter_placeholder',
  interpretation_skills_page_all_tab: 'interpretation_skills_page_all_tab',
  interpretation_skills_page_approved_status:
    'interpretation_skills_page_approved_status',
  interpretation_skills_page_assignment_type_header:
    'interpretation_skills_page_assignment_type_header',
  interpretation_skills_page_created_at_filter_placeholder:
    'interpretation_skills_page_created_at_filter_placeholder',
  interpretation_skills_page_created_at_header:
    'interpretation_skills_page_created_at_header',
  interpretation_skills_page_declined_status:
    'interpretation_skills_page_declined_status',
  interpretation_skills_page_empty_state_subtitle:
    'interpretation_skills_page_empty_state_subtitle',
  interpretation_skills_page_empty_state_title:
    'interpretation_skills_page_empty_state_title',
  interpretation_skills_page_error_state_subtitle:
    'interpretation_skills_page_error_state_subtitle',
  interpretation_skills_page_error_state_title:
    'interpretation_skills_page_error_state_title',
  interpretation_skills_page_gender_female:
    'interpretation_skills_page_gender_female',
  interpretation_skills_page_gender_filter_placeholder:
    'interpretation_skills_page_gender_filter_placeholder',
  interpretation_skills_page_gender_header:
    'interpretation_skills_page_gender_header',
  interpretation_skills_page_gender_male:
    'interpretation_skills_page_gender_male',
  interpretation_skills_page_id_filter_placeholder:
    'interpretation_skills_page_id_filter_placeholder',
  interpretation_skills_page_id_header: 'interpretation_skills_page_id_header',
  interpretation_skills_page_imdi_tooltip:
    'interpretation_skills_page_imdi_tooltip',
  interpretation_skills_page_language_header:
    'interpretation_skills_page_language_header',
  interpretation_skills_page_languages_filter_placeholder:
    'interpretation_skills_page_languages_filter_placeholder',
  interpretation_skills_page_learning_status:
    'interpretation_skills_page_learning_status',
  interpretation_skills_page_load_more_button:
    'interpretation_skills_page_load_more_button',
  interpretation_skills_page_location_filter_placeholder:
    'interpretation_skills_page_location_filter_placeholder',
  interpretation_skills_page_location_header:
    'interpretation_skills_page_location_header',
  interpretation_skills_page_name_filter_placeholder:
    'interpretation_skills_page_name_filter_placeholder',
  interpretation_skills_page_name_header:
    'interpretation_skills_page_name_header',
  interpretation_skills_page_new_skill_button:
    'interpretation_skills_page_new_skill_button',
  interpretation_skills_page_notes_header:
    'interpretation_skills_page_notes_header',
  interpretation_skills_page_pending_status:
    'interpretation_skills_page_pending_status',
  interpretation_skills_page_qualification_filter_placeholder:
    'interpretation_skills_page_qualification_filter_placeholder',
  interpretation_skills_page_qualifications_filter_placeholder:
    'interpretation_skills_page_qualifications_filter_placeholder',
  interpretation_skills_page_statistic_header:
    'interpretation_skills_page_statistic_header',
  interpretation_skills_page_statistic_invited_cell:
    'interpretation_skills_page_statistic_invited_cell',
  interpretation_skills_page_statistic_late_cell:
    'interpretation_skills_page_statistic_late_cell',
  interpretation_skills_page_statistic_noshow_cell:
    'interpretation_skills_page_statistic_noshow_cell',
  interpretation_skills_page_statistic_showedup_cell:
    'interpretation_skills_page_statistic_showedup_cell',
  interpretation_skills_page_statistic_withdrawn_cell:
    'interpretation_skills_page_statistic_withdrawn_cell',
  interpretation_skills_page_status_header:
    'interpretation_skills_page_status_header',
  interpretation_skills_page_title: 'interpretation_skills_page_title',
  interpretation_skills_phone_assignment_icon_tooltip:
    'interpretation_skills_phone_assignment_icon_tooltip',
  interpretation_skills_video_assignment_icon_tooltip:
    'interpretation_skills_video_assignment_icon_tooltip',
  job_applied_status: 'job_applied_status',
  job_awarded_status: 'job_awarded_status',
  job_book_interpretation_tooltip_label:
    'job_book_interpretation_tooltip_label',
  job_book_translation_tooltip_label: 'job_book_translation_tooltip_label',
  job_booker_label: 'job_booker_label',
  job_cancel_button: 'job_cancel_button',
  job_cancel_modal_admin_banner: 'job_cancel_modal_admin_banner',
  job_cancel_modal_admin_reasons: 'job_cancel_modal_admin_reasons',
  job_cancel_modal_back: 'job_cancel_modal_back',
  job_cancel_modal_cancel_and_make_replacement_option:
    'job_cancel_modal_cancel_and_make_replacement_option',
  job_cancel_modal_cancel_option: 'job_cancel_modal_cancel_option',
  job_cancel_modal_cancel_this_assignment:
    'job_cancel_modal_cancel_this_assignment',
  job_cancel_modal_customer_banner: 'job_cancel_modal_customer_banner',
  job_cancel_modal_customer_reasons: 'job_cancel_modal_customer_reasons',
  job_cancel_modal_description: 'job_cancel_modal_description',
  job_cancel_modal_is_shared_warning: 'job_cancel_modal_is_shared_warning',
  job_cancel_modal_mark_as_no_show: 'job_cancel_modal_mark_as_no_show',
  job_cancel_modal_real_name: 'job_cancel_modal_real_name',
  job_cancel_modal_reason_admin_other: 'job_cancel_modal_reason_admin_other',
  job_cancel_modal_reason_dangerous_fields_edited:
    'job_cancel_modal_reason_dangerous_fields_edited',
  job_cancel_modal_reason_direct_not_responded:
    'job_cancel_modal_reason_direct_not_responded',
  job_cancel_modal_reason_direct_said_no:
    'job_cancel_modal_reason_direct_said_no',
  job_cancel_modal_reason_dont_need_interpreter_anymore:
    'job_cancel_modal_reason_dont_need_interpreter_anymore',
  job_cancel_modal_reason_found_interpreter_from_another_provider:
    'job_cancel_modal_reason_found_interpreter_from_another_provider',
  job_cancel_modal_reason_interpreter_cancelled:
    'job_cancel_modal_reason_interpreter_cancelled',
  job_cancel_modal_reason_interpreter_charging_too_much:
    'job_cancel_modal_reason_interpreter_charging_too_much',
  job_cancel_modal_reason_interpreters_busy:
    'job_cancel_modal_reason_interpreters_busy',
  job_cancel_modal_reason_need_to_rebook_session:
    'job_cancel_modal_reason_need_to_rebook_session',
  job_cancel_modal_reason_no_person_attending:
    'job_cancel_modal_reason_no_person_attending',
  job_cancel_modal_reason_not_shown: 'job_cancel_modal_reason_not_shown',
  job_cancel_modal_reason_other: 'job_cancel_modal_reason_other',
  job_cancel_modal_reason_other_placeholder:
    'job_cancel_modal_reason_other_placeholder',
  job_cancel_modal_reason_solved_problem_without_interpreter:
    'job_cancel_modal_reason_solved_problem_without_interpreter',
  job_cancel_modal_reason_withdrawn: 'job_cancel_modal_reason_withdrawn',
  job_cancel_modal_send_email_to_customer:
    'job_cancel_modal_send_email_to_customer',
  job_cancel_modal_take_to_the_booking_form:
    'job_cancel_modal_take_to_the_booking_form',
  job_cancel_modal_this_action_will: 'job_cancel_modal_this_action_will',
  job_cancel_modal_this_will_take_time_banner:
    'job_cancel_modal_this_will_take_time_banner',
  job_cancel_modal_title: 'job_cancel_modal_title',
  job_cancelled_banner: 'job_cancelled_banner',
  job_confirm_by_label: 'job_confirm_by_label',
  job_copied_from_label: 'job_copied_from_label',
  job_customer_attendees_label: 'job_customer_attendees_label',
  job_customer_cc_label: 'job_customer_cc_label',
  job_customer_change_enterprise_label: 'job_customer_change_enterprise_label',
  job_customer_delete_customer_label: 'job_customer_delete_customer_label',
  job_customer_delete_customer_tooltip: 'job_customer_delete_customer_tooltip',
  job_customer_edit_customer_label: 'job_customer_edit_customer_label',
  job_customer_empty_text: 'job_customer_empty_text',
  job_customer_invoice_button_label: 'job_customer_invoice_button_label',
  job_customer_invoice_error_subtitle: 'job_customer_invoice_error_subtitle',
  job_customer_invoice_loading_subtitle:
    'job_customer_invoice_loading_subtitle',
  job_customer_invoice_title: 'job_customer_invoice_title',
  job_customer_label: 'job_customer_label',
  job_customer_merge_customers_label: 'job_customer_merge_customers_label',
  job_customer_name_label: 'job_customer_name_label',
  job_customer_no_upcoming_jobs_label: 'job_customer_no_upcoming_jobs_label',
  job_date_and_time_label: 'job_date_and_time_label',
  job_edit_customer_button: 'job_edit_customer_button',
  job_edit_customer_drawer_attendees_placeholder:
    'job_edit_customer_drawer_attendees_placeholder',
  job_edit_customer_drawer_booker_name_placeholder:
    'job_edit_customer_drawer_booker_name_placeholder',
  job_edit_customer_drawer_cancel_label:
    'job_edit_customer_drawer_cancel_label',
  job_edit_customer_drawer_department_email_label:
    'job_edit_customer_drawer_department_email_label',
  job_edit_customer_drawer_department_label:
    'job_edit_customer_drawer_department_label',
  job_edit_customer_drawer_department_phone_label:
    'job_edit_customer_drawer_department_phone_label',
  job_edit_customer_drawer_email_label: 'job_edit_customer_drawer_email_label',
  job_edit_customer_drawer_enterprise_label:
    'job_edit_customer_drawer_enterprise_label',
  job_edit_customer_drawer_phone_label: 'job_edit_customer_drawer_phone_label',
  job_edit_customer_drawer_save_label: 'job_edit_customer_drawer_save_label',
  job_edit_customer_drawer_title: 'job_edit_customer_drawer_title',
  job_edit_information_button: 'job_edit_information_button',
  job_edit_information_drawer_cancel_label:
    'job_edit_information_drawer_cancel_label',
  job_edit_information_drawer_rebooking_modal_address_label:
    'job_edit_information_drawer_rebooking_modal_address_label',
  job_edit_information_drawer_rebooking_modal_assignment_reboked_successfully:
    'job_edit_information_drawer_rebooking_modal_assignment_reboked_successfully',
  job_edit_information_drawer_rebooking_modal_confirmation_label:
    'job_edit_information_drawer_rebooking_modal_confirmation_label',
  job_edit_information_drawer_rebooking_modal_description:
    'job_edit_information_drawer_rebooking_modal_description',
  job_edit_information_drawer_rebooking_modal_info_banner:
    'job_edit_information_drawer_rebooking_modal_info_banner',
  job_edit_information_drawer_rebooking_modal_language_label:
    'job_edit_information_drawer_rebooking_modal_language_label',
  job_edit_information_drawer_rebooking_modal_session_type_label:
    'job_edit_information_drawer_rebooking_modal_session_type_label',
  job_edit_information_drawer_rebooking_modal_submit_label:
    'job_edit_information_drawer_rebooking_modal_submit_label',
  job_edit_information_drawer_rebooking_modal_time_label:
    'job_edit_information_drawer_rebooking_modal_time_label',
  job_edit_information_drawer_rebooking_modal_title:
    'job_edit_information_drawer_rebooking_modal_title',
  job_edit_information_drawer_submit_label:
    'job_edit_information_drawer_submit_label',
  job_edit_information_drawer_title: 'job_edit_information_drawer_title',
  job_edit_information_updated_successfully:
    'job_edit_information_updated_successfully',
  job_edit_invoicing_button: 'job_edit_invoicing_button',
  job_edit_invoicing_label: 'job_edit_invoicing_label',
  job_edit_invoicing_manual_booking: 'job_edit_invoicing_manual_booking',
  job_edit_invoicing_manual_booking_description:
    'job_edit_invoicing_manual_booking_description',
  job_edit_invoicing_pricing_templates: 'job_edit_invoicing_pricing_templates',
  job_edit_invoicing_pricing_templates_company:
    'job_edit_invoicing_pricing_templates_company',
  job_edit_invoicing_pricing_templates_customer:
    'job_edit_invoicing_pricing_templates_customer',
  job_edit_invoicing_pricing_templates_honorar:
    'job_edit_invoicing_pricing_templates_honorar',
  job_finance_card_error_label: 'job_finance_card_error_label',
  job_finance_card_total_subtitle: 'job_finance_card_total_subtitle',
  job_finance_card_updating_label: 'job_finance_card_updating_label',
  job_finance_drawer_invoice_tab: 'job_finance_drawer_invoice_tab',
  job_finance_drawer_label: 'job_finance_drawer_label',
  job_finance_drawer_payout_tab: 'job_finance_drawer_payout_tab',
  job_finance_drawer_qty_header: 'job_finance_drawer_qty_header',
  job_finance_drawer_rate_header: 'job_finance_drawer_rate_header',
  job_finance_drawer_services_header: 'job_finance_drawer_services_header',
  job_finance_drawer_total_header: 'job_finance_drawer_total_header',
  job_finished_status: 'job_finished_status',
  job_in_progress_status: 'job_in_progress_status',
  job_information_address_desc_label: 'job_information_address_desc_label',
  job_information_address_label: 'job_information_address_label',
  job_information_blocked_cities_label: 'job_information_blocked_cities_label',
  job_information_blocked_interpreters_label:
    'job_information_blocked_interpreters_label',
  job_information_contact_person_label: 'job_information_contact_person_label',
  job_information_edit_label: 'job_information_edit_label',
  job_information_empty_text: 'job_information_empty_text',
  job_information_files_label: 'job_information_files_label',
  job_information_label: 'job_information_label',
  job_information_message_label: 'job_information_message_label',
  job_interpreter_awared: 'job_interpreter_awared',
  job_interpreter_notes: 'job_interpreter_notes',
  job_interpreter_payout_button_label: 'job_interpreter_payout_button_label',
  job_interpreter_payout_error_subtitle:
    'job_interpreter_payout_error_subtitle',
  job_interpreter_payout_loading_subtitle:
    'job_interpreter_payout_loading_subtitle',
  job_interpreter_payout_title: 'job_interpreter_payout_title',
  job_interpreter_standby: 'job_interpreter_standby',
  job_interpreters_invited_so_far_label:
    'job_interpreters_invited_so_far_label',
  job_interpreters_last_invited_label: 'job_interpreters_last_invited_label',
  job_interpreters_next_invite_label: 'job_interpreters_next_invite_label',
  job_interpreters_section_auto_award_label:
    'job_interpreters_section_auto_award_label',
  job_interpreters_section_auto_invite_disabled_tooltip:
    'job_interpreters_section_auto_invite_disabled_tooltip',
  job_interpreters_section_auto_invite_finished_tooltip:
    'job_interpreters_section_auto_invite_finished_tooltip',
  job_interpreters_section_auto_invite_in_progress_tooltip:
    'job_interpreters_section_auto_invite_in_progress_tooltip',
  job_interpreters_section_auto_invite_label:
    'job_interpreters_section_auto_invite_label',
  job_interpreters_section_auto_invite_no_suitable_interpreters_tooltip:
    'job_interpreters_section_auto_invite_no_suitable_interpreters_tooltip',
  job_interpreters_section_auto_invite_paused_tooltip:
    'job_interpreters_section_auto_invite_paused_tooltip',
  job_interpreters_section_dropdown_field_magic_label:
    'job_interpreters_section_dropdown_field_magic_label',
  job_interpreters_section_dropdown_minutes_label:
    'job_interpreters_section_dropdown_minutes_label',
  job_interpreters_section_forever_label:
    'job_interpreters_section_forever_label',
  job_interpreters_section_pro_active_label:
    'job_interpreters_section_pro_active_label',
  job_interpreters_section_standby_label:
    'job_interpreters_section_standby_label',
  job_interpreters_section_title: 'job_interpreters_section_title',
  job_interpreters_total_invited_label: 'job_interpreters_total_invited_label',
  job_invoiced_status: 'job_invoiced_status',
  job_invoicing_agreement: 'job_invoicing_agreement',
  job_invoicing_booked_at: 'job_invoicing_booked_at',
  job_invoicing_booked_by: 'job_invoicing_booked_by',
  job_invoicing_fees: 'job_invoicing_fees',
  job_invoicing_label: 'job_invoicing_label',
  job_notes_drawer_close_button: 'job_notes_drawer_close_button',
  job_notes_drawer_delete_dropdown_item:
    'job_notes_drawer_delete_dropdown_item',
  job_notes_drawer_edit_cancel_button: 'job_notes_drawer_edit_cancel_button',
  job_notes_drawer_edit_dropdown_item: 'job_notes_drawer_edit_dropdown_item',
  job_notes_drawer_edit_input_placeholder:
    'job_notes_drawer_edit_input_placeholder',
  job_notes_drawer_edit_save_button: 'job_notes_drawer_edit_save_button',
  job_notes_drawer_empty_state_text: 'job_notes_drawer_empty_state_text',
  job_notes_drawer_input_placeholder: 'job_notes_drawer_input_placeholder',
  job_notes_drawer_input_title: 'job_notes_drawer_input_title',
  job_notes_drawer_submit_button: 'job_notes_drawer_submit_button',
  job_notes_drawer_title: 'job_notes_drawer_title',
  job_notes_edit_textfield_required_error_message:
    'job_notes_edit_textfield_required_error_message',
  job_order_settings_cc_add_button: 'job_order_settings_cc_add_button',
  job_order_settings_cc_country_code_label:
    'job_order_settings_cc_country_code_label',
  job_order_settings_cc_description: 'job_order_settings_cc_description',
  job_order_settings_cc_email_label: 'job_order_settings_cc_email_label',
  job_order_settings_cc_mobile_number_label:
    'job_order_settings_cc_mobile_number_label',
  job_order_settings_cc_text: 'job_order_settings_cc_text',
  job_order_settings_cc_title: 'job_order_settings_cc_title',
  job_order_settings_cc_validation_error:
    'job_order_settings_cc_validation_error',
  job_page_needs_manual_approval_tooltip:
    'job_page_needs_manual_approval_tooltip',
  job_page_not_needs_manual_approval_tooltip:
    'job_page_not_needs_manual_approval_tooltip',
  job_payment_method_label: 'job_payment_method_label',
  job_received_status: 'job_received_status',
  job_requirements_card_cancel_if_no_interpreter:
    'job_requirements_card_cancel_if_no_interpreter',
  job_requirements_card_confirm: 'job_requirements_card_confirm',
  job_requirements_card_gender_label: 'job_requirements_card_gender_label',
  job_requirements_card_gender_tooltip: 'job_requirements_card_gender_tooltip',
  job_requirements_card_language_label: 'job_requirements_card_language_label',
  job_requirements_card_language_tooltip:
    'job_requirements_card_language_tooltip',
  job_requirements_card_qualification_all:
    'job_requirements_card_qualification_all',
  job_requirements_card_qualification_category:
    'job_requirements_card_qualification_category',
  job_requirements_card_qualification_label:
    'job_requirements_card_qualification_label',
  job_requirements_card_qualification_tooltip:
    'job_requirements_card_qualification_tooltip',
  job_requirements_card_reset: 'job_requirements_card_reset',
  job_requirements_card_reset_gender: 'job_requirements_card_reset_gender',
  job_requirements_card_reset_gender_description:
    'job_requirements_card_reset_gender_description',
  job_requirements_card_reset_if_no_interpreter:
    'job_requirements_card_reset_if_no_interpreter',
  job_requirements_card_reset_language: 'job_requirements_card_reset_language',
  job_requirements_card_reset_language_description:
    'job_requirements_card_reset_language_description',
  job_requirements_card_reset_qualification:
    'job_requirements_card_reset_qualification',
  job_requirements_card_reset_qualification_description:
    'job_requirements_card_reset_qualification_description',
  job_requirements_card_reset_type: 'job_requirements_card_reset_type',
  job_requirements_card_reset_type_description:
    'job_requirements_card_reset_type_description',
  job_requirements_card_type_label: 'job_requirements_card_type_label',
  job_requirements_card_type_tooltip: 'job_requirements_card_type_tooltip',
  job_risk_factors_accepted: 'job_risk_factors_accepted',
  job_risk_factors_empty_references: 'job_risk_factors_empty_references',
  job_risk_factors_excessive_internal_chat_messages:
    'job_risk_factors_excessive_internal_chat_messages',
  job_risk_factors_negative_margin: 'job_risk_factors_negative_margin',
  job_risk_factors_reference_regex_failed:
    'job_risk_factors_reference_regex_failed',
  job_risk_factors_risky: 'job_risk_factors_risky',
  job_risk_factors_status: 'job_risk_factors_status',
  job_risk_factors_very_high_total: 'job_risk_factors_very_high_total',
  job_statement_actual_finish: 'job_statement_actual_finish',
  job_statement_actual_start: 'job_statement_actual_start',
  job_statement_charge_as: 'job_statement_charge_as',
  job_statement_charge_pay: 'job_statement_charge_pay',
  job_statement_create_ticket: 'job_statement_create_ticket',
  job_statement_customer_decision: 'job_statement_customer_decision',
  job_statement_customer_feedback: 'job_statement_customer_feedback',
  job_statement_delay_caused_by: 'job_statement_delay_caused_by',
  job_statement_delay_caused_by_demander:
    'job_statement_delay_caused_by_demander',
  job_statement_delay_caused_by_supplier:
    'job_statement_delay_caused_by_supplier',
  job_statement_diet_enabled: 'job_statement_diet_enabled',
  job_statement_discount: 'job_statement_discount',
  job_statement_expenses: 'job_statement_expenses',
  job_statement_feedback_locked: 'job_statement_feedback_locked',
  job_statement_feedback_locks: 'job_statement_feedback_locks',
  job_statement_fees: 'job_statement_fees',
  job_statement_interpreter_decision: 'job_statement_interpreter_decision',
  job_statement_interpreter_feedback: 'job_statement_interpreter_feedback',
  job_statement_label: 'job_statement_label',
  job_statement_last_saved: 'job_statement_last_saved',
  job_statement_lock_feedback: 'job_statement_lock_feedback',
  job_statement_lock_feedback_description:
    'job_statement_lock_feedback_description',
  job_statement_lock_feedback_lock_button:
    'job_statement_lock_feedback_lock_button',
  job_statement_no_feedback: 'job_statement_no_feedback',
  job_statement_not_performed_as_ordered:
    'job_statement_not_performed_as_ordered',
  job_statement_not_ready_for_finance: 'job_statement_not_ready_for_finance',
  job_statement_not_ready_for_finance_button:
    'job_statement_not_ready_for_finance_button',
  job_statement_not_ready_for_finance_description:
    'job_statement_not_ready_for_finance_description',
  job_statement_ordered: 'job_statement_ordered',
  job_statement_ordered_placeholder: 'job_statement_ordered_placeholder',
  job_statement_other_method: 'job_statement_other_method',
  job_statement_ready_for_finance: 'job_statement_ready_for_finance',
  job_statement_ready_for_finance_button:
    'job_statement_ready_for_finance_button',
  job_statement_ready_for_finance_description:
    'job_statement_ready_for_finance_description',
  job_statement_ready_for_finance_risk_banner:
    'job_statement_ready_for_finance_risk_banner',
  job_statement_ready_for_finance_risk_checkbox:
    'job_statement_ready_for_finance_risk_checkbox',
  job_statement_ready_for_finance_risk_reason_label:
    'job_statement_ready_for_finance_risk_reason_label',
  job_statement_ready_for_finance_risk_reason_placeholder:
    'job_statement_ready_for_finance_risk_reason_placeholder',
  job_statement_ready_for_finance_tooltip:
    'job_statement_ready_for_finance_tooltip',
  job_statement_receipts: 'job_statement_receipts',
  job_statement_save: 'job_statement_save',
  job_statement_select_placeholder: 'job_statement_select_placeholder',
  job_statement_showed_up: 'job_statement_showed_up',
  job_statement_supplier_travel_method_car:
    'job_statement_supplier_travel_method_car',
  job_statement_supplier_travel_method_default:
    'job_statement_supplier_travel_method_default',
  job_statement_supplier_travel_method_flight:
    'job_statement_supplier_travel_method_flight',
  job_statement_supplier_travel_method_public_transport:
    'job_statement_supplier_travel_method_public_transport',
  job_statement_travel_distance: 'job_statement_travel_distance',
  job_statement_travel_method: 'job_statement_travel_method',
  job_statement_travel_method_car: 'job_statement_travel_method_car',
  job_statement_travel_method_none: 'job_statement_travel_method_none',
  job_statement_travel_method_public_transport:
    'job_statement_travel_method_public_transport',
  job_statement_travel_started: 'job_statement_travel_started',
  job_statement_travel_time: 'job_statement_travel_time',
  job_statement_travel_time_hours: 'job_statement_travel_time_hours',
  jobs_page_all_tab: 'jobs_page_all_tab',
  jobs_page_alternative_language_tooltip:
    'jobs_page_alternative_language_tooltip',
  jobs_page_buyer_ref_filter_placeholder:
    'jobs_page_buyer_ref_filter_placeholder',
  jobs_page_buyer_ref_header: 'jobs_page_buyer_ref_header',
  jobs_page_category_subject_filter_placeholder:
    'jobs_page_category_subject_filter_placeholder',
  jobs_page_category_subject_header: 'jobs_page_category_subject_header',
  jobs_page_created_at_filter_placeholder:
    'jobs_page_created_at_filter_placeholder',
  jobs_page_created_at_header: 'jobs_page_created_at_header',
  jobs_page_customer_template_filter_placeholder:
    'jobs_page_customer_template_filter_placeholder',
  jobs_page_danger_level_a_tooltip_subtitle:
    'jobs_page_danger_level_a_tooltip_subtitle',
  jobs_page_danger_level_a_tooltip_title:
    'jobs_page_danger_level_a_tooltip_title',
  jobs_page_danger_level_b_tooltip_subtitle:
    'jobs_page_danger_level_b_tooltip_subtitle',
  jobs_page_danger_level_b_tooltip_title:
    'jobs_page_danger_level_b_tooltip_title',
  jobs_page_danger_level_c_tooltip_subtitle:
    'jobs_page_danger_level_c_tooltip_subtitle',
  jobs_page_danger_level_c_tooltip_title:
    'jobs_page_danger_level_c_tooltip_title',
  jobs_page_danger_level_d_tooltip_subtitle:
    'jobs_page_danger_level_d_tooltip_subtitle',
  jobs_page_danger_level_d_tooltip_title:
    'jobs_page_danger_level_d_tooltip_title',
  jobs_page_danger_level_e_tooltip_subtitle:
    'jobs_page_danger_level_e_tooltip_subtitle',
  jobs_page_danger_level_e_tooltip_title:
    'jobs_page_danger_level_e_tooltip_title',
  jobs_page_danger_level_f_tooltip_subtitle:
    'jobs_page_danger_level_f_tooltip_subtitle',
  jobs_page_danger_level_f_tooltip_title:
    'jobs_page_danger_level_f_tooltip_title',
  jobs_page_danger_level_warning_tooltip_title:
    'jobs_page_danger_level_warning_tooltip_title',
  jobs_page_empty_state_subtitle: 'jobs_page_empty_state_subtitle',
  jobs_page_empty_state_title: 'jobs_page_empty_state_title',
  jobs_page_error_state_subtitle: 'jobs_page_error_state_subtitle',
  jobs_page_error_state_title: 'jobs_page_error_state_title',
  jobs_page_export: 'jobs_page_export',
  jobs_page_export_date_picker_label: 'jobs_page_export_date_picker_label',
  jobs_page_export_date_picker_placeholder:
    'jobs_page_export_date_picker_placeholder',
  jobs_page_export_description: 'jobs_page_export_description',
  jobs_page_export_info_text: 'jobs_page_export_info_text',
  jobs_page_export_submit_button: 'jobs_page_export_submit_button',
  jobs_page_export_title: 'jobs_page_export_title',
  jobs_page_hints_header: 'jobs_page_hints_header',
  jobs_page_id_filter_placeholder: 'jobs_page_id_filter_placeholder',
  jobs_page_id_header: 'jobs_page_id_header',
  jobs_page_in_person_tooltip: 'jobs_page_in_person_tooltip',
  jobs_page_in_progress_tab: 'jobs_page_in_progress_tab',
  jobs_page_interpreter_header: 'jobs_page_interpreter_header',
  jobs_page_interpreter_placeholder: 'jobs_page_interpreter_placeholder',
  jobs_page_interpreter_template_filter_placeholder:
    'jobs_page_interpreter_template_filter_placeholder',
  jobs_page_invite_status_finished: 'jobs_page_invite_status_finished',
  jobs_page_invite_status_finished_infinite_tooltip:
    'jobs_page_invite_status_finished_infinite_tooltip',
  jobs_page_invite_status_finished_tooltip:
    'jobs_page_invite_status_finished_tooltip',
  jobs_page_invite_status_ongoing: 'jobs_page_invite_status_ongoing',
  jobs_page_invite_status_ongoing_infinite_tooltip:
    'jobs_page_invite_status_ongoing_infinite_tooltip',
  jobs_page_invite_status_ongoing_interval_tooltip:
    'jobs_page_invite_status_ongoing_interval_tooltip',
  jobs_page_invite_status_ongoing_tooltip:
    'jobs_page_invite_status_ongoing_tooltip',
  jobs_page_invite_status_paused: 'jobs_page_invite_status_paused',
  jobs_page_invite_status_paused_infinite_tooltip:
    'jobs_page_invite_status_paused_infinite_tooltip',
  jobs_page_invite_status_paused_tooltip:
    'jobs_page_invite_status_paused_tooltip',
  jobs_page_invite_status_stopped: 'jobs_page_invite_status_stopped',
  jobs_page_invite_status_stopped_infinite_tooltip:
    'jobs_page_invite_status_stopped_infinite_tooltip',
  jobs_page_invite_status_stopped_tooltip:
    'jobs_page_invite_status_stopped_tooltip',
  jobs_page_job_date_filter_placeholder:
    'jobs_page_job_date_filter_placeholder',
  jobs_page_job_date_header: 'jobs_page_job_date_header',
  jobs_page_language_filter_placeholder:
    'jobs_page_language_filter_placeholder',
  jobs_page_language_header: 'jobs_page_language_header',
  jobs_page_last_activity_application_invited:
    'jobs_page_last_activity_application_invited',
  jobs_page_last_activity_application_withdrawn:
    'jobs_page_last_activity_application_withdrawn',
  jobs_page_last_activity_by_text: 'jobs_page_last_activity_by_text',
  jobs_page_last_activity_header: 'jobs_page_last_activity_header',
  jobs_page_last_activity_job_created: 'jobs_page_last_activity_job_created',
  jobs_page_last_activity_job_updated: 'jobs_page_last_activity_job_updated',
  jobs_page_last_activity_job_updated_assignment_type:
    'jobs_page_last_activity_job_updated_assignment_type',
  jobs_page_last_activity_job_updated_expenses:
    'jobs_page_last_activity_job_updated_expenses',
  jobs_page_last_activity_job_updated_fees_and_order:
    'jobs_page_last_activity_job_updated_fees_and_order',
  jobs_page_last_activity_job_updated_invoicing:
    'jobs_page_last_activity_job_updated_invoicing',
  jobs_page_last_activity_job_updated_references:
    'jobs_page_last_activity_job_updated_references',
  jobs_page_load_more_button: 'jobs_page_load_more_button',
  jobs_page_manual_approved_required: 'jobs_page_manual_approved_required',
  jobs_page_no_qualification_tooltip: 'jobs_page_no_qualification_tooltip',
  jobs_page_notes_header: 'jobs_page_notes_header',
  jobs_page_original_language_tooltip: 'jobs_page_original_language_tooltip',
  jobs_page_other_published_assignments:
    'jobs_page_other_published_assignments',
  jobs_page_other_published_tab: 'jobs_page_other_published_tab',
  jobs_page_owner_participant_filter_placeholder:
    'jobs_page_owner_participant_filter_placeholder',
  jobs_page_owner_participant_header: 'jobs_page_owner_participant_header',
  jobs_page_payment_method_filter_placeholder:
    'jobs_page_payment_method_filter_placeholder',
  jobs_page_phone_assignment_tooltip: 'jobs_page_phone_assignment_tooltip',
  jobs_page_qualification_cancel_tooltip:
    'jobs_page_qualification_cancel_tooltip',
  jobs_page_qualification_change_level_tooltip:
    'jobs_page_qualification_change_level_tooltip',
  jobs_page_qualification_not_required: 'jobs_page_qualification_not_required',
  jobs_page_qualification_required: 'jobs_page_qualification_required',
  jobs_page_qualification_reset_tooltip:
    'jobs_page_qualification_reset_tooltip',
  jobs_page_requested_by_tooltip: 'jobs_page_requested_by_tooltip',
  jobs_page_returned_by_finance_tooltip:
    'jobs_page_returned_by_finance_tooltip',
  jobs_page_risky_tab: 'jobs_page_risky_tab',
  jobs_page_session_type_cancel_in_person_tooltip:
    'jobs_page_session_type_cancel_in_person_tooltip',
  jobs_page_session_type_cancel_video_tooltip:
    'jobs_page_session_type_cancel_video_tooltip',
  jobs_page_session_type_in_person: 'jobs_page_session_type_in_person',
  jobs_page_session_type_in_person_to_phone_tooltip:
    'jobs_page_session_type_in_person_to_phone_tooltip',
  jobs_page_session_type_in_person_to_video_tooltip:
    'jobs_page_session_type_in_person_to_video_tooltip',
  jobs_page_session_type_phone: 'jobs_page_session_type_phone',
  jobs_page_session_type_phone_from_in_person_tooltip:
    'jobs_page_session_type_phone_from_in_person_tooltip',
  jobs_page_session_type_phone_from_video_tooltip:
    'jobs_page_session_type_phone_from_video_tooltip',
  jobs_page_session_type_video: 'jobs_page_session_type_video',
  jobs_page_session_type_video_from_in_person_tooltip:
    'jobs_page_session_type_video_from_in_person_tooltip',
  jobs_page_session_type_video_to_phone_tooltip:
    'jobs_page_session_type_video_to_phone_tooltip',
  jobs_page_status_accepted: 'jobs_page_status_accepted',
  jobs_page_status_cancelled: 'jobs_page_status_cancelled',
  jobs_page_status_closed: 'jobs_page_status_closed',
  jobs_page_status_finished: 'jobs_page_status_finished',
  jobs_page_status_header: 'jobs_page_status_header',
  jobs_page_status_in_progress: 'jobs_page_status_in_progress',
  jobs_page_status_placeholder: 'jobs_page_status_placeholder',
  jobs_page_status_published: 'jobs_page_status_published',
  jobs_page_stuck_tab: 'jobs_page_stuck_tab',
  jobs_page_title: 'jobs_page_title',
  jobs_page_type_filter_placeholder: 'jobs_page_type_filter_placeholder',
  jobs_page_urgent_tab: 'jobs_page_urgent_tab',
  jobs_page_video_assignment_not_salita_tooltip:
    'jobs_page_video_assignment_not_salita_tooltip',
  jobs_page_video_assignment_tooltip: 'jobs_page_video_assignment_tooltip',
  jobs_page_vip_tab: 'jobs_page_vip_tab',
  jobs_page_withdrawal_not_withdrawn: 'jobs_page_withdrawal_not_withdrawn',
  jobs_page_withdrawal_withdrawn: 'jobs_page_withdrawal_withdrawn',
  login_button: 'login_button',
  login_forgot_password_button: 'login_forgot_password_button',
  login_header_description: 'login_header_description',
  login_header_title: 'login_header_title',
  login_password_label: 'login_password_label',
  login_password_placeholder: 'login_password_placeholder',
  login_username_label: 'login_username_label',
  login_username_placeholder: 'login_username_placeholder',
  male_label: 'male_label',
  menu_assignment_search_placeholder: 'menu_assignment_search_placeholder',
  menu_assignments_assignment_statistics:
    'menu_assignments_assignment_statistics',
  menu_assignments_interpretation_assignments:
    'menu_assignments_interpretation_assignments',
  menu_assignments_interpretation_assignments_description:
    'menu_assignments_interpretation_assignments_description',
  menu_assignments_interpretation_calendar:
    'menu_assignments_interpretation_calendar',
  menu_assignments_interpretation_calendar_description:
    'menu_assignments_interpretation_calendar_description',
  menu_assignments_interpretation_feedback:
    'menu_assignments_interpretation_feedback',
  menu_assignments_interpretation_feedback_description:
    'menu_assignments_interpretation_feedback_description',
  menu_assignments_interpretation_section:
    'menu_assignments_interpretation_section',
  menu_assignments_item: 'menu_assignments_item',
  menu_assignments_statistics_section: 'menu_assignments_statistics_section',
  menu_assignments_translation_calendar:
    'menu_assignments_translation_calendar',
  menu_assignments_translation_calendar_description:
    'menu_assignments_translation_calendar_description',
  menu_assignments_translation_feedback:
    'menu_assignments_translation_feedback',
  menu_assignments_translation_feedback_description:
    'menu_assignments_translation_feedback_description',
  menu_assignments_translation_orders: 'menu_assignments_translation_orders',
  menu_assignments_translation_orders_description:
    'menu_assignments_translation_orders_description',
  menu_assignments_translation_section: 'menu_assignments_translation_section',
  menu_create_assignment_label: 'menu_create_assignment_label',
  menu_create_button: 'menu_create_button',
  menu_create_new_assignment: 'menu_create_new_assignment',
  menu_create_new_customer: 'menu_create_new_customer',
  menu_create_new_department: 'menu_create_new_department',
  menu_create_new_enterprise: 'menu_create_new_enterprise',
  menu_create_new_interpretation: 'menu_create_new_interpretation',
  menu_create_new_supplier: 'menu_create_new_supplier',
  menu_create_new_translation: 'menu_create_new_translation',
  menu_create_other_label: 'menu_create_other_label',
  menu_customers_customer_accounts_section:
    'menu_customers_customer_accounts_section',
  menu_customers_customer_statistics: 'menu_customers_customer_statistics',
  menu_customers_customers: 'menu_customers_customers',
  menu_customers_customers_description: 'menu_customers_customers_description',
  menu_customers_enterprises: 'menu_customers_enterprises',
  menu_customers_enterprises_description:
    'menu_customers_enterprises_description',
  menu_customers_item: 'menu_customers_item',
  menu_customers_statistics_section: 'menu_customers_statistics_section',
  menu_finance_interpretation_assignments:
    'menu_finance_interpretation_assignments',
  menu_finance_interpretation_assignments_description:
    'menu_finance_interpretation_assignments_description',
  menu_finance_interpretation_customer_invoices:
    'menu_finance_interpretation_customer_invoices',
  menu_finance_interpretation_customer_invoices_description:
    'menu_finance_interpretation_customer_invoices_description',
  menu_finance_interpretation_section: 'menu_finance_interpretation_section',
  menu_finance_interpreter_payouts: 'menu_finance_interpreter_payouts',
  menu_finance_interpreter_payouts_description:
    'menu_finance_interpreter_payouts_description',
  menu_finance_item: 'menu_finance_item',
  menu_finance_price_templates: 'menu_finance_price_templates',
  menu_finance_price_templates_description:
    'menu_finance_price_templates_description',
  menu_finance_prices_and_statistics_section:
    'menu_finance_prices_and_statistics_section',
  menu_finance_statistics: 'menu_finance_statistics',
  menu_finance_statistics_description: 'menu_finance_statistics_description',
  menu_finance_translation_assignments: 'menu_finance_translation_assignments',
  menu_finance_translation_assignments_description:
    'menu_finance_translation_assignments_description',
  menu_finance_translation_customer_invoices:
    'menu_finance_translation_customer_invoices',
  menu_finance_translation_customer_invoices_description:
    'menu_finance_translation_customer_invoices_description',
  menu_finance_translation_section: 'menu_finance_translation_section',
  menu_finance_translator_payouts: 'menu_finance_translator_payouts',
  menu_finance_translator_payouts_description:
    'menu_finance_translator_payouts_description',
  menu_settings_my_organisation: 'menu_settings_my_organisation',
  menu_settings_my_profile: 'menu_settings_my_profile',
  menu_settings_settings_option: 'menu_settings_settings_option',
  menu_settings_sign_out: 'menu_settings_sign_out',
  menu_suppliers_interpretation_section:
    'menu_suppliers_interpretation_section',
  menu_suppliers_interpreter_skills: 'menu_suppliers_interpreter_skills',
  menu_suppliers_interpreter_skills_description:
    'menu_suppliers_interpreter_skills_description',
  menu_suppliers_interpreters: 'menu_suppliers_interpreters',
  menu_suppliers_interpreters_description:
    'menu_suppliers_interpreters_description',
  menu_suppliers_interpreters_statistics:
    'menu_suppliers_interpreters_statistics',
  menu_suppliers_interpreters_statistics_description:
    'menu_suppliers_interpreters_statistics_description',
  menu_suppliers_item: 'menu_suppliers_item',
  menu_suppliers_talent_section: 'menu_suppliers_talent_section',
  menu_suppliers_translation_section: 'menu_suppliers_translation_section',
  menu_suppliers_translation_skills: 'menu_suppliers_translation_skills',
  menu_suppliers_translation_skills_description:
    'menu_suppliers_translation_skills_description',
  menu_suppliers_translators: 'menu_suppliers_translators',
  menu_suppliers_translators_description:
    'menu_suppliers_translators_description',
  menu_suppliers_translators_statistics:
    'menu_suppliers_translators_statistics',
  menu_suppliers_translators_statistics_description:
    'menu_suppliers_translators_statistics_description',
  modal_cancel_button: 'modal_cancel_button',
  new_skill_drawer_cancel_button: 'new_skill_drawer_cancel_button',
  new_skill_drawer_choose_another_language_button:
    'new_skill_drawer_choose_another_language_button',
  new_skill_drawer_create_skill_button: 'new_skill_drawer_create_skill_button',
  new_skill_drawer_default_error_message:
    'new_skill_drawer_default_error_message',
  new_skill_drawer_documentation_text: 'new_skill_drawer_documentation_text',
  new_skill_drawer_documentation_title: 'new_skill_drawer_documentation_title',
  new_skill_drawer_file_selector_delete_option:
    'new_skill_drawer_file_selector_delete_option',
  new_skill_drawer_file_selector_rename_option:
    'new_skill_drawer_file_selector_rename_option',
  new_skill_drawer_interpretation_assignments_tooltip_title:
    'new_skill_drawer_interpretation_assignments_tooltip_title',
  new_skill_drawer_interpreter_banner_text:
    'new_skill_drawer_interpreter_banner_text',
  new_skill_drawer_interpreter_spoiler_category_A:
    'new_skill_drawer_interpreter_spoiler_category_A',
  new_skill_drawer_interpreter_spoiler_category_A_text:
    'new_skill_drawer_interpreter_spoiler_category_A_text',
  new_skill_drawer_interpreter_spoiler_category_B:
    'new_skill_drawer_interpreter_spoiler_category_B',
  new_skill_drawer_interpreter_spoiler_category_B_text:
    'new_skill_drawer_interpreter_spoiler_category_B_text',
  new_skill_drawer_interpreter_spoiler_category_C:
    'new_skill_drawer_interpreter_spoiler_category_C',
  new_skill_drawer_interpreter_spoiler_category_C_text:
    'new_skill_drawer_interpreter_spoiler_category_C_text',
  new_skill_drawer_interpreter_spoiler_category_D:
    'new_skill_drawer_interpreter_spoiler_category_D',
  new_skill_drawer_interpreter_spoiler_category_D_text:
    'new_skill_drawer_interpreter_spoiler_category_D_text',
  new_skill_drawer_interpreter_spoiler_category_E:
    'new_skill_drawer_interpreter_spoiler_category_E',
  new_skill_drawer_interpreter_spoiler_category_E_text:
    'new_skill_drawer_interpreter_spoiler_category_E_text',
  new_skill_drawer_interpreter_spoiler_not_registered_text:
    'new_skill_drawer_interpreter_spoiler_not_registered_text',
  new_skill_drawer_national_id_input_error_message:
    'new_skill_drawer_national_id_input_error_message',
  new_skill_drawer_national_id_input_label:
    'new_skill_drawer_national_id_input_label',
  new_skill_drawer_national_id_input_not_valid_error:
    'new_skill_drawer_national_id_input_not_valid_error',
  new_skill_drawer_national_id_input_placeholder:
    'new_skill_drawer_national_id_input_placeholder',
  new_skill_drawer_qualification_spoiler_title:
    'new_skill_drawer_qualification_spoiler_title',
  new_skill_drawer_rename_file_modal_cancel_button:
    'new_skill_drawer_rename_file_modal_cancel_button',
  new_skill_drawer_rename_file_modal_description:
    'new_skill_drawer_rename_file_modal_description',
  new_skill_drawer_rename_file_modal_save_button:
    'new_skill_drawer_rename_file_modal_save_button',
  new_skill_drawer_rename_file_modal_text_label:
    'new_skill_drawer_rename_file_modal_text_label',
  new_skill_drawer_rename_file_modal_text_placeholder:
    'new_skill_drawer_rename_file_modal_text_placeholder',
  new_skill_drawer_rename_file_modal_title:
    'new_skill_drawer_rename_file_modal_title',
  new_skill_drawer_skills_status_approved_silently_label:
    'new_skill_drawer_skills_status_approved_silently_label',
  new_skill_drawer_skills_status_approved_with_notification_label:
    'new_skill_drawer_skills_status_approved_with_notification_label',
  new_skill_drawer_skills_status_pending_label:
    'new_skill_drawer_skills_status_pending_label',
  new_skill_drawer_supplier_input_label_and_placeholder:
    'new_skill_drawer_supplier_input_label_and_placeholder',
  new_skill_drawer_supplier_type_interpreter:
    'new_skill_drawer_supplier_type_interpreter',
  new_skill_drawer_supplier_type_label: 'new_skill_drawer_supplier_type_label',
  new_skill_drawer_supplier_type_translator:
    'new_skill_drawer_supplier_type_translator',
  new_skill_drawer_title: 'new_skill_drawer_title',
  new_skill_drawer_translation_assignments_tooltip_title:
    'new_skill_drawer_translation_assignments_tooltip_title',
  new_skill_drawer_translator_spoiler_professional_translator_text:
    'new_skill_drawer_translator_spoiler_professional_translator_text',
  new_skill_drawer_translator_spoiler_professional_translator_title:
    'new_skill_drawer_translator_spoiler_professional_translator_title',
  new_skill_drawer_translator_spoiler_state_authorized_text:
    'new_skill_drawer_translator_spoiler_state_authorized_text',
  new_skill_drawer_translator_spoiler_state_authorized_title:
    'new_skill_drawer_translator_spoiler_state_authorized_title',
  new_skill_success_drawer_create_new_skill_button:
    'new_skill_success_drawer_create_new_skill_button',
  new_skill_success_drawer_go_to_skills_button:
    'new_skill_success_drawer_go_to_skills_button',
  new_skill_success_drawer_text: 'new_skill_success_drawer_text',
  new_skill_success_drawer_title: 'new_skill_success_drawer_title',
  notes_drawer_close_button: 'notes_drawer_close_button',
  notes_drawer_delete_note_button: 'notes_drawer_delete_note_button',
  notes_drawer_edit_button: 'notes_drawer_edit_button',
  notes_drawer_edit_cancel_button: 'notes_drawer_edit_cancel_button',
  notes_drawer_edit_save_button: 'notes_drawer_edit_save_button',
  notes_drawer_edit_text_field_label: 'notes_drawer_edit_text_field_label',
  notes_drawer_empty_state_text: 'notes_drawer_empty_state_text',
  notes_drawer_header: 'notes_drawer_header',
  notes_drawer_new_post_placeholder: 'notes_drawer_new_post_placeholder',
  notes_drawer_post_button: 'notes_drawer_post_button',
  notes_drawer_text_area_label: 'notes_drawer_text_area_label',
  order_info_internal_note_title: 'order_info_internal_note_title',
  order_info_order_access_level_title: 'order_info_order_access_level_title',
  order_info_order_alternative_language_title:
    'order_info_order_alternative_language_title',
  order_info_order_created_title: 'order_info_order_created_title',
  order_info_order_external_deadline: 'order_info_order_external_deadline',
  order_info_order_source_type_title: 'order_info_order_source_type_title',
  order_info_order_word_count_title: 'order_info_order_word_count_title',
  order_info_total_target_languages: 'order_info_total_target_languages',
  order_page_accepted_status: 'order_page_accepted_status',
  order_page_access_level_department: 'order_page_access_level_department',
  order_page_access_level_enterprise: 'order_page_access_level_enterprise',
  order_page_access_level_owner: 'order_page_access_level_owner',
  order_page_breadcrumb_current_page: 'order_page_breadcrumb_current_page',
  order_page_breadcrumb_previous_page: 'order_page_breadcrumb_previous_page',
  order_page_cancelled_status: 'order_page_cancelled_status',
  order_page_draft_status: 'order_page_draft_status',
  order_page_finished_status: 'order_page_finished_status',
  order_page_in_progress_status: 'order_page_in_progress_status',
  order_page_quote_rejected_status: 'order_page_quote_rejected_status',
  order_page_quote_requested_status: 'order_page_quote_requested_status',
  order_page_quote_sent_status: 'order_page_quote_sent_status',
  order_page_source_type_copied_from_xtrf:
    'order_page_source_type_copied_from_xtrf',
  order_page_source_type_oversettelse_email:
    'order_page_source_type_oversettelse_email',
  order_page_source_type_personal_email:
    'order_page_source_type_personal_email',
  order_page_source_type_platform: 'order_page_source_type_platform',
  order_page_source_type_post: 'order_page_source_type_post',
  order_page_source_type_post_email: 'order_page_source_type_post_email',
  order_page_source_type_project_email: 'order_page_source_type_project_email',
  radio_options_default_label: 'radio_options_default_label',
  search_customer_placeholder: 'search_customer_placeholder',
  search_customer_top_four_label: 'search_customer_top_four_label',
  search_dropdown_empty_items_state: 'search_dropdown_empty_items_state',
  search_dropdown_items_selected: 'search_dropdown_items_selected',
  search_dropdown_loading_state: 'search_dropdown_loading_state',
  set_password_back_to_login_button: 'set_password_back_to_login_button',
  set_password_confirm_password_label: 'set_password_confirm_password_label',
  set_password_confirm_password_placeholder:
    'set_password_confirm_password_placeholder',
  set_password_header_description: 'set_password_header_description',
  set_password_header_title: 'set_password_header_title',
  set_password_password_label: 'set_password_password_label',
  set_password_password_placeholder: 'set_password_password_placeholder',
  set_password_reset_button: 'set_password_reset_button',
  skills_delete_skill_modal_back_button:
    'skills_delete_skill_modal_back_button',
  skills_delete_skill_modal_delete_button:
    'skills_delete_skill_modal_delete_button',
  skills_delete_skill_modal_description:
    'skills_delete_skill_modal_description',
  skills_delete_skill_modal_title: 'skills_delete_skill_modal_title',
  skills_delete_skill_tooltip: 'skills_delete_skill_tooltip',
  supplier_info_component_interpreting_assignment_label:
    'supplier_info_component_interpreting_assignment_label',
  supplier_info_component_translation_assignment_label:
    'supplier_info_component_translation_assignment_label',
  supplier_page_edit_supplier_tooltip: 'supplier_page_edit_supplier_tooltip',
  supplier_page_in_person_icon_tooltip: 'supplier_page_in_person_icon_tooltip',
  supplier_page_phone_icon_tooltip: 'supplier_page_phone_icon_tooltip',
  supplier_page_translation_icon_tooltip:
    'supplier_page_translation_icon_tooltip',
  supplier_page_video_icon_tooltip: 'supplier_page_video_icon_tooltip',
  suppliers_page_actions_header: 'suppliers_page_actions_header',
  suppliers_page_active_status: 'suppliers_page_active_status',
  suppliers_page_all_tab: 'suppliers_page_all_tab',
  suppliers_page_banned_status: 'suppliers_page_banned_status',
  suppliers_page_blocked_status: 'suppliers_page_blocked_status',
  suppliers_page_create_supplier_cancel_button:
    'suppliers_page_create_supplier_cancel_button',
  suppliers_page_create_supplier_country_code_label:
    'suppliers_page_create_supplier_country_code_label',
  suppliers_page_create_supplier_create_button:
    'suppliers_page_create_supplier_create_button',
  suppliers_page_create_supplier_email_label:
    'suppliers_page_create_supplier_email_label',
  suppliers_page_create_supplier_email_placeholder:
    'suppliers_page_create_supplier_email_placeholder',
  suppliers_page_create_supplier_first_name_label:
    'suppliers_page_create_supplier_first_name_label',
  suppliers_page_create_supplier_first_name_placeholder:
    'suppliers_page_create_supplier_first_name_placeholder',
  suppliers_page_create_supplier_last_name_label:
    'suppliers_page_create_supplier_last_name_label',
  suppliers_page_create_supplier_last_name_placeholder:
    'suppliers_page_create_supplier_last_name_placeholder',
  suppliers_page_create_supplier_phone_label:
    'suppliers_page_create_supplier_phone_label',
  suppliers_page_create_supplier_phone_placeholder:
    'suppliers_page_create_supplier_phone_placeholder',
  suppliers_page_create_supplier_send_password_label:
    'suppliers_page_create_supplier_send_password_label',
  suppliers_page_create_supplier_success_title:
    'suppliers_page_create_supplier_success_title',
  suppliers_page_create_supplier_title: 'suppliers_page_create_supplier_title',
  suppliers_page_deceased_status: 'suppliers_page_deceased_status',
  suppliers_page_deleted_status: 'suppliers_page_deleted_status',
  suppliers_page_edit_supplier_cancel_button:
    'suppliers_page_edit_supplier_cancel_button',
  suppliers_page_edit_supplier_country_code_label:
    'suppliers_page_edit_supplier_country_code_label',
  suppliers_page_edit_supplier_edit_button:
    'suppliers_page_edit_supplier_edit_button',
  suppliers_page_edit_supplier_email_label:
    'suppliers_page_edit_supplier_email_label',
  suppliers_page_edit_supplier_email_placeholder:
    'suppliers_page_edit_supplier_email_placeholder',
  suppliers_page_edit_supplier_first_name_label:
    'suppliers_page_edit_supplier_first_name_label',
  suppliers_page_edit_supplier_first_name_placeholder:
    'suppliers_page_edit_supplier_first_name_placeholder',
  suppliers_page_edit_supplier_last_name_label:
    'suppliers_page_edit_supplier_last_name_label',
  suppliers_page_edit_supplier_last_name_placeholder:
    'suppliers_page_edit_supplier_last_name_placeholder',
  suppliers_page_edit_supplier_phone_label:
    'suppliers_page_edit_supplier_phone_label',
  suppliers_page_edit_supplier_phone_placeholder:
    'suppliers_page_edit_supplier_phone_placeholder',
  suppliers_page_edit_supplier_title: 'suppliers_page_edit_supplier_title',
  suppliers_page_empty_state_subtitle: 'suppliers_page_empty_state_subtitle',
  suppliers_page_empty_state_title: 'suppliers_page_empty_state_title',
  suppliers_page_error_state_subtitle: 'suppliers_page_error_state_subtitle',
  suppliers_page_error_state_title: 'suppliers_page_error_state_title',
  suppliers_page_gender_filter_placeholder:
    'suppliers_page_gender_filter_placeholder',
  suppliers_page_gender_header: 'suppliers_page_gender_header',
  suppliers_page_id_filter_placeholder: 'suppliers_page_id_filter_placeholder',
  suppliers_page_id_header: 'suppliers_page_id_header',
  suppliers_page_name_filter_placeholder:
    'suppliers_page_name_filter_placeholder',
  suppliers_page_name_header: 'suppliers_page_name_header',
  suppliers_page_new_supplier_button: 'suppliers_page_new_supplier_button',
  suppliers_page_paused_status: 'suppliers_page_paused_status',
  suppliers_page_retired_status: 'suppliers_page_retired_status',
  suppliers_page_statistic_header: 'suppliers_page_statistic_header',
  suppliers_page_statistic_invited_cell:
    'suppliers_page_statistic_invited_cell',
  suppliers_page_statistic_late_cell: 'suppliers_page_statistic_late_cell',
  suppliers_page_statistic_noshow_cell: 'suppliers_page_statistic_noshow_cell',
  suppliers_page_statistic_showedup_cell:
    'suppliers_page_statistic_showedup_cell',
  suppliers_page_statistic_withdrawn_cell:
    'suppliers_page_statistic_withdrawn_cell',
  suppliers_page_status_header: 'suppliers_page_status_header',
  suppliers_page_status_paused_invalid_date:
    'suppliers_page_status_paused_invalid_date',
  suppliers_page_status_paused_until: 'suppliers_page_status_paused_until',
  suppliers_page_supplier_gender_female:
    'suppliers_page_supplier_gender_female',
  suppliers_page_supplier_gender_male: 'suppliers_page_supplier_gender_male',
  suppliers_page_supplier_type_both: 'suppliers_page_supplier_type_both',
  suppliers_page_supplier_type_interpreter:
    'suppliers_page_supplier_type_interpreter',
  suppliers_page_supplier_type_translator:
    'suppliers_page_supplier_type_translator',
  suppliers_page_supplier_type_undefined:
    'suppliers_page_supplier_type_undefined',
  suppliers_page_title: 'suppliers_page_title',
  suppliers_page_type_filter_placeholder:
    'suppliers_page_type_filter_placeholder',
  suppliers_page_type_header: 'suppliers_page_type_header',
  suppliers_table_not_standby_filter: 'suppliers_table_not_standby_filter',
  tooltip_click_to_copy_text: 'tooltip_click_to_copy_text',
  tooltip_click_to_see_details_text: 'tooltip_click_to_see_details_text',
  transgender_label: 'transgender_label',
  translation_order_access_level_department:
    'translation_order_access_level_department',
  translation_order_access_level_enterprise:
    'translation_order_access_level_enterprise',
  translation_order_access_level_owner: 'translation_order_access_level_owner',
  translation_order_alternative_language_label:
    'translation_order_alternative_language_label',
  translation_order_alternative_language_no_preference:
    'translation_order_alternative_language_no_preference',
  translation_order_archive_file: 'translation_order_archive_file',
  translation_order_cancel_order_modal_back_button:
    'translation_order_cancel_order_modal_back_button',
  translation_order_cancel_order_modal_cancellation_reason_label:
    'translation_order_cancel_order_modal_cancellation_reason_label',
  translation_order_cancel_order_modal_cancellation_reason_placeholder:
    'translation_order_cancel_order_modal_cancellation_reason_placeholder',
  translation_order_cancel_order_modal_cancelled_by_customer_option:
    'translation_order_cancel_order_modal_cancelled_by_customer_option',
  translation_order_cancel_order_modal_cancelled_by_label:
    'translation_order_cancel_order_modal_cancelled_by_label',
  translation_order_cancel_order_modal_cancelled_by_platform_option:
    'translation_order_cancel_order_modal_cancelled_by_platform_option',
  translation_order_cancel_order_modal_description:
    'translation_order_cancel_order_modal_description',
  translation_order_cancel_order_modal_submit_button:
    'translation_order_cancel_order_modal_submit_button',
  translation_order_cancel_order_modal_title:
    'translation_order_cancel_order_modal_title',
  translation_order_cancel_order_modal_warning:
    'translation_order_cancel_order_modal_warning',
  translation_order_chat_section_new_message_placeholder:
    'translation_order_chat_section_new_message_placeholder',
  translation_order_chat_section_no_messages:
    'translation_order_chat_section_no_messages',
  translation_order_chat_section_title: 'translation_order_chat_section_title',
  translation_order_chat_section_today_label:
    'translation_order_chat_section_today_label',
  translation_order_chat_section_yesterday_label:
    'translation_order_chat_section_yesterday_label',
  translation_order_create_task_cancel_button:
    'translation_order_create_task_cancel_button',
  translation_order_create_task_create_button:
    'translation_order_create_task_create_button',
  translation_order_create_task_target_language_label:
    'translation_order_create_task_target_language_label',
  translation_order_create_task_target_language_placeholder:
    'translation_order_create_task_target_language_placeholder',
  translation_order_create_task_title: 'translation_order_create_task_title',
  translation_order_details_access_level_label:
    'translation_order_details_access_level_label',
  translation_order_details_address_label:
    'translation_order_details_address_label',
  translation_order_details_booker_department_label:
    'translation_order_details_booker_department_label',
  translation_order_details_booker_email_label:
    'translation_order_details_booker_email_label',
  translation_order_details_booker_enterprise_label:
    'translation_order_details_booker_enterprise_label',
  translation_order_details_booker_header:
    'translation_order_details_booker_header',
  translation_order_details_booker_name_label:
    'translation_order_details_booker_name_label',
  translation_order_details_booker_phone_label:
    'translation_order_details_booker_phone_label',
  translation_order_details_cancelled_banner:
    'translation_order_details_cancelled_banner',
  translation_order_details_cancelled_banner_customer:
    'translation_order_details_cancelled_banner_customer',
  translation_order_details_cancelled_banner_platform:
    'translation_order_details_cancelled_banner_platform',
  translation_order_details_case_number_label:
    'translation_order_details_case_number_label',
  translation_order_details_client_name_label:
    'translation_order_details_client_name_label',
  translation_order_details_client_number_label:
    'translation_order_details_client_number_label',
  translation_order_details_created_label:
    'translation_order_details_created_label',
  translation_order_details_description_label:
    'translation_order_details_description_label',
  translation_order_details_edit_button:
    'translation_order_details_edit_button',
  translation_order_details_english_alternative_label:
    'translation_order_details_english_alternative_label',
  translation_order_details_external_deadline_label:
    'translation_order_details_external_deadline_label',
  translation_order_details_invoicing_header:
    'translation_order_details_invoicing_header',
  translation_order_details_logout_details_assign_owner_button:
    'translation_order_details_logout_details_assign_owner_button',
  translation_order_details_logout_details_edit_button:
    'translation_order_details_logout_details_edit_button',
  translation_order_details_logout_details_email_label:
    'translation_order_details_logout_details_email_label',
  translation_order_details_logout_details_enterprise_label:
    'translation_order_details_logout_details_enterprise_label',
  translation_order_details_logout_details_header:
    'translation_order_details_logout_details_header',
  translation_order_details_logout_details_name_label:
    'translation_order_details_logout_details_name_label',
  translation_order_details_logout_details_payment_method_enterprise_label:
    'translation_order_details_logout_details_payment_method_enterprise_label',
  translation_order_details_logout_details_phone_label:
    'translation_order_details_logout_details_phone_label',
  translation_order_details_order_details_header:
    'translation_order_details_order_details_header',
  translation_order_details_order_emails_button:
    'translation_order_details_order_emails_button',
  translation_order_details_order_label:
    'translation_order_details_order_label',
  translation_order_details_payment_method_label:
    'translation_order_details_payment_method_label',
  translation_order_details_price_offer_email_label:
    'translation_order_details_price_offer_email_label',
  translation_order_details_quote_header:
    'translation_order_details_quote_header',
  translation_order_details_quote_label:
    'translation_order_details_quote_label',
  translation_order_details_quote_logout_estimate:
    'translation_order_details_quote_logout_estimate',
  translation_order_details_quote_logout_estimate_not_shown:
    'translation_order_details_quote_logout_estimate_not_shown',
  translation_order_details_quote_price_offer_not_sent:
    'translation_order_details_quote_price_offer_not_sent',
  translation_order_details_quote_price_offer_sent:
    'translation_order_details_quote_price_offer_sent',
  translation_order_details_section_edit_button:
    'translation_order_details_section_edit_button',
  translation_order_details_section_resend_button:
    'translation_order_details_section_resend_button',
  translation_order_details_section_view_button:
    'translation_order_details_section_view_button',
  translation_order_details_send_by_post_cover_letter_label:
    'translation_order_details_send_by_post_cover_letter_label',
  translation_order_details_send_by_post_header:
    'translation_order_details_send_by_post_header',
  translation_order_details_send_by_post_not_requested:
    'translation_order_details_send_by_post_not_requested',
  translation_order_details_send_saved_responses_button:
    'translation_order_details_send_saved_responses_button',
  translation_order_details_source_files_header:
    'translation_order_details_source_files_header',
  translation_order_details_source_files_ocr_toggle:
    'translation_order_details_source_files_ocr_toggle',
  translation_order_details_source_label:
    'translation_order_details_source_label',
  translation_order_details_subject_label:
    'translation_order_details_subject_label',
  translation_order_details_system_log_button:
    'translation_order_details_system_log_button',
  translation_order_details_time_ago: 'translation_order_details_time_ago',
  translation_order_details_time_today: 'translation_order_details_time_today',
  translation_order_details_translation_order_label:
    'translation_order_details_translation_order_label',
  translation_order_details_type_label: 'translation_order_details_type_label',
  translation_order_details_word_count_label:
    'translation_order_details_word_count_label',
  translation_order_edit_invoicing_cancel_button:
    'translation_order_edit_invoicing_cancel_button',
  translation_order_edit_invoicing_client_name_label:
    'translation_order_edit_invoicing_client_name_label',
  translation_order_edit_invoicing_client_name_placeholder:
    'translation_order_edit_invoicing_client_name_placeholder',
  translation_order_edit_invoicing_client_number_label:
    'translation_order_edit_invoicing_client_number_label',
  translation_order_edit_invoicing_client_number_placeholder:
    'translation_order_edit_invoicing_client_number_placeholder',
  translation_order_edit_invoicing_default_label:
    'translation_order_edit_invoicing_default_label',
  translation_order_edit_invoicing_payment_methods_email_label:
    'translation_order_edit_invoicing_payment_methods_email_label',
  translation_order_edit_invoicing_payment_methods_empty_state:
    'translation_order_edit_invoicing_payment_methods_empty_state',
  translation_order_edit_invoicing_payment_methods_label:
    'translation_order_edit_invoicing_payment_methods_label',
  translation_order_edit_invoicing_save_button:
    'translation_order_edit_invoicing_save_button',
  translation_order_edit_invoicing_title:
    'translation_order_edit_invoicing_title',
  translation_order_edit_task_cancel_button:
    'translation_order_edit_task_cancel_button',
  translation_order_edit_task_save_button:
    'translation_order_edit_task_save_button',
  translation_order_edit_task_target_language_hint:
    'translation_order_edit_task_target_language_hint',
  translation_order_edit_task_target_language_label:
    'translation_order_edit_task_target_language_label',
  translation_order_edit_task_target_language_placeholder:
    'translation_order_edit_task_target_language_placeholder',
  translation_order_edit_task_title: 'translation_order_edit_task_title',
  translation_order_emails_cc_order_completed:
    'translation_order_emails_cc_order_completed',
  translation_order_emails_label: 'translation_order_emails_label',
  translation_order_emails_not_sent_label:
    'translation_order_emails_not_sent_label',
  translation_order_emails_order_cancelled:
    'translation_order_emails_order_cancelled',
  translation_order_emails_order_completed:
    'translation_order_emails_order_completed',
  translation_order_emails_order_in_progress:
    'translation_order_emails_order_in_progress',
  translation_order_emails_order_label: 'translation_order_emails_order_label',
  translation_order_emails_order_received:
    'translation_order_emails_order_received',
  translation_order_emails_resend_button:
    'translation_order_emails_resend_button',
  translation_order_emails_send_button: 'translation_order_emails_send_button',
  translation_order_emails_send_by_post:
    'translation_order_emails_send_by_post',
  translation_order_emails_sent_at_label:
    'translation_order_emails_sent_at_label',
  translation_order_emails_title: 'translation_order_emails_title',
  translation_order_files_download_all_tooltip:
    'translation_order_files_download_all_tooltip',
  translation_order_files_get_ocr_tooltip:
    'translation_order_files_get_ocr_tooltip',
  translation_order_files_list_archived_label:
    'translation_order_files_list_archived_label',
  translation_order_files_upload_tooltip:
    'translation_order_files_upload_tooltip',
  translation_order_menu_new_subtask: 'translation_order_menu_new_subtask',
  translation_order_menu_new_task: 'translation_order_menu_new_task',
  translation_order_new_response_cancel_button:
    'translation_order_new_response_cancel_button',
  translation_order_new_response_go_back_button:
    'translation_order_new_response_go_back_button',
  translation_order_new_response_label: 'translation_order_new_response_label',
  translation_order_new_response_message_label:
    'translation_order_new_response_message_label',
  translation_order_new_response_message_placeholder:
    'translation_order_new_response_message_placeholder',
  translation_order_new_response_name_label:
    'translation_order_new_response_name_label',
  translation_order_new_response_name_placeholder:
    'translation_order_new_response_name_placeholder',
  translation_order_new_response_save_button:
    'translation_order_new_response_save_button',
  translation_order_new_response_title_label:
    'translation_order_new_response_title_label',
  translation_order_new_response_title_placeholder:
    'translation_order_new_response_title_placeholder',
  translation_order_no_files: 'translation_order_no_files',
  translation_order_no_owner_error_text:
    'translation_order_no_owner_error_text',
  translation_order_order_details_cancel_button:
    'translation_order_order_details_cancel_button',
  translation_order_order_details_case_number_label:
    'translation_order_order_details_case_number_label',
  translation_order_order_details_case_number_placeholder:
    'translation_order_order_details_case_number_placeholder',
  translation_order_order_details_description_label:
    'translation_order_order_details_description_label',
  translation_order_order_details_description_placeholder:
    'translation_order_order_details_description_placeholder',
  translation_order_order_details_save_button:
    'translation_order_order_details_save_button',
  translation_order_order_details_subject_label:
    'translation_order_order_details_subject_label',
  translation_order_order_details_subject_placeholder:
    'translation_order_order_details_subject_placeholder',
  translation_order_order_details_title:
    'translation_order_order_details_title',
  translation_order_order_label: 'translation_order_order_label',
  translation_order_order_status_accepted:
    'translation_order_order_status_accepted',
  translation_order_order_status_cancelled:
    'translation_order_order_status_cancelled',
  translation_order_order_status_completed:
    'translation_order_order_status_completed',
  translation_order_order_status_draft: 'translation_order_order_status_draft',
  translation_order_order_status_finished:
    'translation_order_order_status_finished',
  translation_order_order_status_in_progress:
    'translation_order_order_status_in_progress',
  translation_order_order_status_quote_rejected:
    'translation_order_order_status_quote_rejected',
  translation_order_order_status_quote_requested:
    'translation_order_order_status_quote_requested',
  translation_order_order_status_quote_sent:
    'translation_order_order_status_quote_sent',
  translation_order_saved_responses_cancel_button:
    'translation_order_saved_responses_cancel_button',
  translation_order_saved_responses_cc_label:
    'translation_order_saved_responses_cc_label',
  translation_order_saved_responses_label:
    'translation_order_saved_responses_label',
  translation_order_saved_responses_message_label:
    'translation_order_saved_responses_message_label',
  translation_order_saved_responses_message_placeholder:
    'translation_order_saved_responses_message_placeholder',
  translation_order_saved_responses_new_response_button:
    'translation_order_saved_responses_new_response_button',
  translation_order_saved_responses_order_label:
    'translation_order_saved_responses_order_label',
  translation_order_saved_responses_saved_response_label:
    'translation_order_saved_responses_saved_response_label',
  translation_order_saved_responses_saved_response_placeholder:
    'translation_order_saved_responses_saved_response_placeholder',
  translation_order_saved_responses_send_button:
    'translation_order_saved_responses_send_button',
  translation_order_saved_responses_title_label:
    'translation_order_saved_responses_title_label',
  translation_order_saved_responses_title_placeholder:
    'translation_order_saved_responses_title_placeholder',
  translation_order_send_by_post_address_label:
    'translation_order_send_by_post_address_label',
  translation_order_send_by_post_address_placeholder:
    'translation_order_send_by_post_address_placeholder',
  translation_order_send_by_post_cancel_button:
    'translation_order_send_by_post_cancel_button',
  translation_order_send_by_post_enterprise_label:
    'translation_order_send_by_post_enterprise_label',
  translation_order_send_by_post_enterprise_placeholder:
    'translation_order_send_by_post_enterprise_placeholder',
  translation_order_send_by_post_mark_as_sent_label:
    'translation_order_send_by_post_mark_as_sent_label',
  translation_order_send_by_post_recipient_name_label:
    'translation_order_send_by_post_recipient_name_label',
  translation_order_send_by_post_recipient_name_placeholder:
    'translation_order_send_by_post_recipient_name_placeholder',
  translation_order_send_by_post_save_button:
    'translation_order_send_by_post_save_button',
  translation_order_send_by_post_send_documents_switch:
    'translation_order_send_by_post_send_documents_switch',
  translation_order_send_by_post_summary_not_sent:
    'translation_order_send_by_post_summary_not_sent',
  translation_order_send_by_post_summary_sent:
    'translation_order_send_by_post_summary_sent',
  translation_order_send_by_post_title: 'translation_order_send_by_post_title',
  translation_order_source_copied_from_xtrf:
    'translation_order_source_copied_from_xtrf',
  translation_order_source_logout: 'translation_order_source_logout',
  translation_order_source_personal_email:
    'translation_order_source_personal_email',
  translation_order_source_platform: 'translation_order_source_platform',
  translation_order_source_post: 'translation_order_source_post',
  translation_order_source_post_email: 'translation_order_source_post_email',
  translation_order_source_project_email:
    'translation_order_source_project_email',
  translation_order_source_translators_email:
    'translation_order_source_translators_email',
  translation_order_subtask_type_correction:
    'translation_order_subtask_type_correction',
  translation_order_subtask_type_in_person:
    'translation_order_subtask_type_in_person',
  translation_order_subtask_type_other: 'translation_order_subtask_type_other',
  translation_order_subtask_type_translation:
    'translation_order_subtask_type_translation',
  translation_order_system_logs_announce_cc_finished_order_to:
    'translation_order_system_logs_announce_cc_finished_order_to',
  translation_order_system_logs_announce_customer_feedback_for_service_to:
    'translation_order_system_logs_announce_customer_feedback_for_service_to',
  translation_order_system_logs_announce_customer_posted_order_to:
    'translation_order_system_logs_announce_customer_posted_order_to',
  translation_order_system_logs_announce_customer_updated_order_to:
    'translation_order_system_logs_announce_customer_updated_order_to',
  translation_order_system_logs_announce_early_deadline_warning_to:
    'translation_order_system_logs_announce_early_deadline_warning_to',
  translation_order_system_logs_announce_finished_order_to:
    'translation_order_system_logs_announce_finished_order_to',
  translation_order_system_logs_announce_in_progress_order_to:
    'translation_order_system_logs_announce_in_progress_order_to',
  translation_order_system_logs_announce_order_cancelled_to:
    'translation_order_system_logs_announce_order_cancelled_to',
  translation_order_system_logs_announce_order_cover_letter_rejected_to:
    'translation_order_system_logs_announce_order_cover_letter_rejected_to',
  translation_order_system_logs_announce_order_logout_posted_to:
    'translation_order_system_logs_announce_order_logout_posted_to',
  translation_order_system_logs_announce_order_moved_to_xtrf_to:
    'translation_order_system_logs_announce_order_moved_to_xtrf_to',
  translation_order_system_logs_announce_order_quote_to:
    'translation_order_system_logs_announce_order_quote_to',
  translation_order_system_logs_announce_order_sent_by_post_to:
    'translation_order_system_logs_announce_order_sent_by_post_to',
  translation_order_system_logs_announce_order_task_finished_to:
    'translation_order_system_logs_announce_order_task_finished_to',
  translation_order_system_logs_announce_posted_order_to:
    'translation_order_system_logs_announce_posted_order_to',
  translation_order_system_logs_announce_saved_response_to:
    'translation_order_system_logs_announce_saved_response_to',
  translation_order_system_logs_announce_subtask_accepted_to:
    'translation_order_system_logs_announce_subtask_accepted_to',
  translation_order_system_logs_announce_subtask_cancelled_to:
    'translation_order_system_logs_announce_subtask_cancelled_to',
  translation_order_system_logs_announce_subtask_changes_required_to:
    'translation_order_system_logs_announce_subtask_changes_required_to',
  translation_order_system_logs_announce_subtask_internal_deadline_change_requested_to:
    'translation_order_system_logs_announce_subtask_internal_deadline_change_requested_to',
  translation_order_system_logs_announce_subtask_internal_deadline_passed_to:
    'translation_order_system_logs_announce_subtask_internal_deadline_passed_to',
  translation_order_system_logs_announce_subtask_invite_reminder_to:
    'translation_order_system_logs_announce_subtask_invite_reminder_to',
  translation_order_system_logs_announce_subtask_invited_to:
    'translation_order_system_logs_announce_subtask_invited_to',
  translation_order_system_logs_announce_subtask_published_to:
    'translation_order_system_logs_announce_subtask_published_to',
  translation_order_system_logs_announce_subtask_rejected_to:
    'translation_order_system_logs_announce_subtask_rejected_to',
  translation_order_system_logs_file_archived:
    'translation_order_system_logs_file_archived',
  translation_order_system_logs_file_downloaded:
    'translation_order_system_logs_file_downloaded',
  translation_order_system_logs_file_unarchived:
    'translation_order_system_logs_file_unarchived',
  translation_order_system_logs_file_updated:
    'translation_order_system_logs_file_updated',
  translation_order_system_logs_item_count:
    'translation_order_system_logs_item_count',
  translation_order_system_logs_load_more_button:
    'translation_order_system_logs_load_more_button',
  translation_order_system_logs_off_value:
    'translation_order_system_logs_off_value',
  translation_order_system_logs_on_value:
    'translation_order_system_logs_on_value',
  translation_order_system_logs_order_attachment_downloaded:
    'translation_order_system_logs_order_attachment_downloaded',
  translation_order_system_logs_order_created:
    'translation_order_system_logs_order_created',
  translation_order_system_logs_order_ocr_triggered:
    'translation_order_system_logs_order_ocr_triggered',
  translation_order_system_logs_order_transferred_to_xtrf:
    'translation_order_system_logs_order_transferred_to_xtrf',
  translation_order_system_logs_order_updated:
    'translation_order_system_logs_order_updated',
  translation_order_system_logs_order_updated_access_level:
    'translation_order_system_logs_order_updated_access_level',
  translation_order_system_logs_order_updated_alternative_target_language_id:
    'translation_order_system_logs_order_updated_alternative_target_language_id',
  translation_order_system_logs_order_updated_booking_reference:
    'translation_order_system_logs_order_updated_booking_reference',
  translation_order_system_logs_order_updated_case_number:
    'translation_order_system_logs_order_updated_case_number',
  translation_order_system_logs_order_updated_cc_emails:
    'translation_order_system_logs_order_updated_cc_emails',
  translation_order_system_logs_order_updated_client_id:
    'translation_order_system_logs_order_updated_client_id',
  translation_order_system_logs_order_updated_client_name:
    'translation_order_system_logs_order_updated_client_name',
  translation_order_system_logs_order_updated_count_of_words:
    'translation_order_system_logs_order_updated_count_of_words',
  translation_order_system_logs_order_updated_cover_letters:
    'translation_order_system_logs_order_updated_cover_letters',
  translation_order_system_logs_order_updated_customer_input_status:
    'translation_order_system_logs_order_updated_customer_input_status',
  translation_order_system_logs_order_updated_delivery_method:
    'translation_order_system_logs_order_updated_delivery_method',
  translation_order_system_logs_order_updated_delivery_method_email:
    'translation_order_system_logs_order_updated_delivery_method_email',
  translation_order_system_logs_order_updated_delivery_method_post:
    'translation_order_system_logs_order_updated_delivery_method_post',
  translation_order_system_logs_order_updated_demander_feedback:
    'translation_order_system_logs_order_updated_demander_feedback',
  translation_order_system_logs_order_updated_department_id:
    'translation_order_system_logs_order_updated_department_id',
  translation_order_system_logs_order_updated_enterprise_id:
    'translation_order_system_logs_order_updated_enterprise_id',
  translation_order_system_logs_order_updated_external_deadline:
    'translation_order_system_logs_order_updated_external_deadline',
  translation_order_system_logs_order_updated_instructions_from_demander:
    'translation_order_system_logs_order_updated_instructions_from_demander',
  translation_order_system_logs_order_updated_internal_note:
    'translation_order_system_logs_order_updated_internal_note',
  translation_order_system_logs_order_updated_invoicing:
    'translation_order_system_logs_order_updated_invoicing',
  translation_order_system_logs_order_updated_is_logged_out:
    'translation_order_system_logs_order_updated_is_logged_out',
  translation_order_system_logs_order_updated_logged_out_booker_email:
    'translation_order_system_logs_order_updated_logged_out_booker_email',
  translation_order_system_logs_order_updated_logged_out_booker_first_name:
    'translation_order_system_logs_order_updated_logged_out_booker_first_name',
  translation_order_system_logs_order_updated_logged_out_booker_last_name:
    'translation_order_system_logs_order_updated_logged_out_booker_last_name',
  translation_order_system_logs_order_updated_logged_out_booker_org_name:
    'translation_order_system_logs_order_updated_logged_out_booker_org_name',
  translation_order_system_logs_order_updated_logged_out_booker_org_number:
    'translation_order_system_logs_order_updated_logged_out_booker_org_number',
  translation_order_system_logs_order_updated_logged_out_booker_payment_org_number:
    'translation_order_system_logs_order_updated_logged_out_booker_payment_org_number',
  translation_order_system_logs_order_updated_logged_out_booker_phone_code:
    'translation_order_system_logs_order_updated_logged_out_booker_phone_code',
  translation_order_system_logs_order_updated_logged_out_booker_phone_number:
    'translation_order_system_logs_order_updated_logged_out_booker_phone_number',
  translation_order_system_logs_order_updated_name:
    'translation_order_system_logs_order_updated_name',
  translation_order_system_logs_order_updated_other_subject:
    'translation_order_system_logs_order_updated_other_subject',
  translation_order_system_logs_order_updated_payment_booking_reference:
    'translation_order_system_logs_order_updated_payment_booking_reference',
  translation_order_system_logs_order_updated_payment_method_id:
    'translation_order_system_logs_order_updated_payment_method_id',
  translation_order_system_logs_order_updated_requested_short_notice:
    'translation_order_system_logs_order_updated_requested_short_notice',
  translation_order_system_logs_order_updated_send_by_post_address:
    'translation_order_system_logs_order_updated_send_by_post_address',
  translation_order_system_logs_order_updated_send_by_post_cover_letter:
    'translation_order_system_logs_order_updated_send_by_post_cover_letter',
  translation_order_system_logs_order_updated_send_by_post_enterprise_name:
    'translation_order_system_logs_order_updated_send_by_post_enterprise_name',
  translation_order_system_logs_order_updated_send_by_post_name:
    'translation_order_system_logs_order_updated_send_by_post_name',
  translation_order_system_logs_order_updated_sent_by_post:
    'translation_order_system_logs_order_updated_sent_by_post',
  translation_order_system_logs_order_updated_sentence:
    'translation_order_system_logs_order_updated_sentence',
  translation_order_system_logs_order_updated_source_language_id:
    'translation_order_system_logs_order_updated_source_language_id',
  translation_order_system_logs_order_updated_source_type:
    'translation_order_system_logs_order_updated_source_type',
  translation_order_system_logs_order_updated_status:
    'translation_order_system_logs_order_updated_status',
  translation_order_system_logs_order_updated_translation_method_id:
    'translation_order_system_logs_order_updated_translation_method_id',
  translation_order_system_logs_subtask_accepted:
    'translation_order_system_logs_subtask_accepted',
  translation_order_system_logs_subtask_applied:
    'translation_order_system_logs_subtask_applied',
  translation_order_system_logs_subtask_created:
    'translation_order_system_logs_subtask_created',
  translation_order_system_logs_subtask_deadline_change_request_created:
    'translation_order_system_logs_subtask_deadline_change_request_created',
  translation_order_system_logs_subtask_invited:
    'translation_order_system_logs_subtask_invited',
  translation_order_system_logs_subtask_rejected:
    'translation_order_system_logs_subtask_rejected',
  translation_order_system_logs_subtask_review_requested:
    'translation_order_system_logs_subtask_review_requested',
  translation_order_system_logs_subtask_statement_updated:
    'translation_order_system_logs_subtask_statement_updated',
  translation_order_system_logs_subtask_updated:
    'translation_order_system_logs_subtask_updated',
  translation_order_system_logs_subtask_updated_count_of_words:
    'translation_order_system_logs_subtask_updated_count_of_words',
  translation_order_system_logs_subtask_updated_delivered_on:
    'translation_order_system_logs_subtask_updated_delivered_on',
  translation_order_system_logs_subtask_updated_delivered_short_notice:
    'translation_order_system_logs_subtask_updated_delivered_short_notice',
  translation_order_system_logs_subtask_updated_instructions_from_project_manager_to_seller:
    'translation_order_system_logs_subtask_updated_instructions_from_project_manager_to_seller',
  translation_order_system_logs_subtask_updated_internal_deadline:
    'translation_order_system_logs_subtask_updated_internal_deadline',
  translation_order_system_logs_subtask_updated_platform_quote_amount:
    'translation_order_system_logs_subtask_updated_platform_quote_amount',
  translation_order_system_logs_subtask_updated_quality_assurer_uid:
    'translation_order_system_logs_subtask_updated_quality_assurer_uid',
  translation_order_system_logs_subtask_updated_review_comment_from_project_manager:
    'translation_order_system_logs_subtask_updated_review_comment_from_project_manager',
  translation_order_system_logs_subtask_updated_review_comment_from_seller:
    'translation_order_system_logs_subtask_updated_review_comment_from_seller',
  translation_order_system_logs_subtask_updated_seller_id:
    'translation_order_system_logs_subtask_updated_seller_id',
  translation_order_system_logs_subtask_updated_seller_quote_amount:
    'translation_order_system_logs_subtask_updated_seller_quote_amount',
  translation_order_system_logs_subtask_updated_sentence:
    'translation_order_system_logs_subtask_updated_sentence',
  translation_order_system_logs_subtask_updated_source_language_id:
    'translation_order_system_logs_subtask_updated_source_language_id',
  translation_order_system_logs_subtask_updated_status:
    'translation_order_system_logs_subtask_updated_status',
  translation_order_system_logs_subtask_updated_subtask_type:
    'translation_order_system_logs_subtask_updated_subtask_type',
  translation_order_system_logs_subtask_updated_target_language_id:
    'translation_order_system_logs_subtask_updated_target_language_id',
  translation_order_system_logs_system_label:
    'translation_order_system_logs_system_label',
  translation_order_system_logs_task_created:
    'translation_order_system_logs_task_created',
  translation_order_system_logs_task_replaced:
    'translation_order_system_logs_task_replaced',
  translation_order_system_logs_task_updated:
    'translation_order_system_logs_task_updated',
  translation_order_system_logs_task_updated_sentence:
    'translation_order_system_logs_task_updated_sentence',
  translation_order_system_logs_task_updated_status:
    'translation_order_system_logs_task_updated_status',
  translation_order_system_logs_task_updated_target_language_id:
    'translation_order_system_logs_task_updated_target_language_id',
  translation_order_system_logs_title: 'translation_order_system_logs_title',
  translation_order_trigger_ocr: 'translation_order_trigger_ocr',
  translation_order_unarchive_file: 'translation_order_unarchive_file',
  translation_order_upload_files_cancel_button:
    'translation_order_upload_files_cancel_button',
  translation_order_upload_files_password_placeholder:
    'translation_order_upload_files_password_placeholder',
  translation_order_upload_files_password_protection_label:
    'translation_order_upload_files_password_protection_label',
  translation_order_upload_files_title: 'translation_order_upload_files_title',
  translation_order_upload_files_upload_button:
    'translation_order_upload_files_upload_button',
  translation_order_waiting_for_the_customer_toggle:
    'translation_order_waiting_for_the_customer_toggle',
  translation_skills_account_status_filter_placeholder:
    'translation_skills_account_status_filter_placeholder',
  translation_skills_account_status_header:
    'translation_skills_account_status_header',
  translation_skills_all_tab: 'translation_skills_all_tab',
  translation_skills_created_at_filter_placeholder:
    'translation_skills_created_at_filter_placeholder',
  translation_skills_created_at_header: 'translation_skills_created_at_header',
  translation_skills_delete_skill_disabled_tooltip:
    'translation_skills_delete_skill_disabled_tooltip',
  translation_skills_empty_state_subtitle:
    'translation_skills_empty_state_subtitle',
  translation_skills_empty_state_title: 'translation_skills_empty_state_title',
  translation_skills_error_state_subtitle:
    'translation_skills_error_state_subtitle',
  translation_skills_error_state_title: 'translation_skills_error_state_title',
  translation_skills_id_filter_placeholder:
    'translation_skills_id_filter_placeholder',
  translation_skills_id_header: 'translation_skills_id_header',
  translation_skills_language_filter_placeholder:
    'translation_skills_language_filter_placeholder',
  translation_skills_language_header: 'translation_skills_language_header',
  translation_skills_load_more_button: 'translation_skills_load_more_button',
  translation_skills_name_filter_placeholder:
    'translation_skills_name_filter_placeholder',
  translation_skills_name_header: 'translation_skills_name_header',
  translation_skills_native_tooltip: 'translation_skills_native_tooltip',
  translation_skills_new_skill_add_button:
    'translation_skills_new_skill_add_button',
  translation_skills_new_skill_cancel_button:
    'translation_skills_new_skill_cancel_button',
  translation_skills_new_skill_language_from_error:
    'translation_skills_new_skill_language_from_error',
  translation_skills_new_skill_language_from_label:
    'translation_skills_new_skill_language_from_label',
  translation_skills_new_skill_language_from_placeholder:
    'translation_skills_new_skill_language_from_placeholder',
  translation_skills_new_skill_language_to_error:
    'translation_skills_new_skill_language_to_error',
  translation_skills_new_skill_language_to_label:
    'translation_skills_new_skill_language_to_label',
  translation_skills_new_skill_language_to_placeholder:
    'translation_skills_new_skill_language_to_placeholder',
  translation_skills_new_skill_native_from_label:
    'translation_skills_new_skill_native_from_label',
  translation_skills_new_skill_native_to_label:
    'translation_skills_new_skill_native_to_label',
  translation_skills_new_skill_notify_translator_label:
    'translation_skills_new_skill_notify_translator_label',
  translation_skills_new_skill_qualification_error:
    'translation_skills_new_skill_qualification_error',
  translation_skills_new_skill_qualification_label:
    'translation_skills_new_skill_qualification_label',
  translation_skills_new_skill_qualification_placeholder:
    'translation_skills_new_skill_qualification_placeholder',
  translation_skills_new_skill_status_error:
    'translation_skills_new_skill_status_error',
  translation_skills_new_skill_status_label:
    'translation_skills_new_skill_status_label',
  translation_skills_new_skill_status_placeholder:
    'translation_skills_new_skill_status_placeholder',
  translation_skills_new_skill_supplier_error:
    'translation_skills_new_skill_supplier_error',
  translation_skills_new_skill_supplier_label:
    'translation_skills_new_skill_supplier_label',
  translation_skills_new_skill_supplier_placeholder:
    'translation_skills_new_skill_supplier_placeholder',
  translation_skills_new_skill_title: 'translation_skills_new_skill_title',
  translation_skills_notes_header: 'translation_skills_notes_header',
  translation_skills_page_new_skill_button:
    'translation_skills_page_new_skill_button',
  translation_skills_status_approved: 'translation_skills_status_approved',
  translation_skills_status_declined: 'translation_skills_status_declined',
  translation_skills_status_header: 'translation_skills_status_header',
  translation_skills_status_learning: 'translation_skills_status_learning',
  translation_skills_status_pending: 'translation_skills_status_pending',
  translation_skills_title: 'translation_skills_title',
  translation_subtask_correction: 'translation_subtask_correction',
  translation_subtask_create_drawer_cancel_button:
    'translation_subtask_create_drawer_cancel_button',
  translation_subtask_create_drawer_create_button:
    'translation_subtask_create_drawer_create_button',
  translation_subtask_create_drawer_delivered_short_notice_label:
    'translation_subtask_create_drawer_delivered_short_notice_label',
  translation_subtask_create_drawer_delivery_date_label:
    'translation_subtask_create_drawer_delivery_date_label',
  translation_subtask_create_drawer_delivery_date_placeholder:
    'translation_subtask_create_drawer_delivery_date_placeholder',
  translation_subtask_create_drawer_external_deadline_label:
    'translation_subtask_create_drawer_external_deadline_label',
  translation_subtask_create_drawer_internal_deadline_label:
    'translation_subtask_create_drawer_internal_deadline_label',
  translation_subtask_create_drawer_salita_quote_label:
    'translation_subtask_create_drawer_salita_quote_label',
  translation_subtask_create_drawer_seller_quote_label:
    'translation_subtask_create_drawer_seller_quote_label',
  translation_subtask_create_drawer_task_label:
    'translation_subtask_create_drawer_task_label',
  translation_subtask_create_drawer_task_placeholder:
    'translation_subtask_create_drawer_task_placeholder',
  translation_subtask_create_drawer_title:
    'translation_subtask_create_drawer_title',
  translation_subtask_create_drawer_type_correction:
    'translation_subtask_create_drawer_type_correction',
  translation_subtask_create_drawer_type_in_person:
    'translation_subtask_create_drawer_type_in_person',
  translation_subtask_create_drawer_type_label:
    'translation_subtask_create_drawer_type_label',
  translation_subtask_create_drawer_type_translation:
    'translation_subtask_create_drawer_type_translation',
  translation_subtask_details_add_button:
    'translation_subtask_details_add_button',
  translation_subtask_details_assign_myself_button:
    'translation_subtask_details_assign_myself_button',
  translation_subtask_details_assign_qa_button:
    'translation_subtask_details_assign_qa_button',
  translation_subtask_details_assign_qa_drawer_cancel_button:
    'translation_subtask_details_assign_qa_drawer_cancel_button',
  translation_subtask_details_assign_qa_drawer_empty_state:
    'translation_subtask_details_assign_qa_drawer_empty_state',
  translation_subtask_details_assign_qa_drawer_search_placeholder:
    'translation_subtask_details_assign_qa_drawer_search_placeholder',
  translation_subtask_details_assign_qa_drawer_select_button:
    'translation_subtask_details_assign_qa_drawer_select_button',
  translation_subtask_details_assign_qa_drawer_title:
    'translation_subtask_details_assign_qa_drawer_title',
  translation_subtask_details_assign_translator_auto_assign_button:
    'translation_subtask_details_assign_translator_auto_assign_button',
  translation_subtask_details_assign_translator_button:
    'translation_subtask_details_assign_translator_button',
  translation_subtask_details_assign_translator_drawer_accept_deadline_button:
    'translation_subtask_details_assign_translator_drawer_accept_deadline_button',
  translation_subtask_details_assign_translator_drawer_available_tab:
    'translation_subtask_details_assign_translator_drawer_available_tab',
  translation_subtask_details_assign_translator_drawer_cancel_button:
    'translation_subtask_details_assign_translator_drawer_cancel_button',
  translation_subtask_details_assign_translator_drawer_days_difference:
    'translation_subtask_details_assign_translator_drawer_days_difference',
  translation_subtask_details_assign_translator_drawer_empty_state:
    'translation_subtask_details_assign_translator_drawer_empty_state',
  translation_subtask_details_assign_translator_drawer_has_contract_label:
    'translation_subtask_details_assign_translator_drawer_has_contract_label',
  translation_subtask_details_assign_translator_drawer_original_internal_deadline:
    'translation_subtask_details_assign_translator_drawer_original_internal_deadline',
  translation_subtask_details_assign_translator_drawer_proactive_label:
    'translation_subtask_details_assign_translator_drawer_proactive_label',
  translation_subtask_details_assign_translator_drawer_reason_deadline:
    'translation_subtask_details_assign_translator_drawer_reason_deadline',
  translation_subtask_details_assign_translator_drawer_reason_label:
    'translation_subtask_details_assign_translator_drawer_reason_label',
  translation_subtask_details_assign_translator_drawer_reason_language_pair:
    'translation_subtask_details_assign_translator_drawer_reason_language_pair',
  translation_subtask_details_assign_translator_drawer_reason_not_available:
    'translation_subtask_details_assign_translator_drawer_reason_not_available',
  translation_subtask_details_assign_translator_drawer_reject_deadline_button:
    'translation_subtask_details_assign_translator_drawer_reject_deadline_button',
  translation_subtask_details_assign_translator_drawer_rejected_status:
    'translation_subtask_details_assign_translator_drawer_rejected_status',
  translation_subtask_details_assign_translator_drawer_rejected_tab:
    'translation_subtask_details_assign_translator_drawer_rejected_tab',
  translation_subtask_details_assign_translator_drawer_remove_from_rejected_button:
    'translation_subtask_details_assign_translator_drawer_remove_from_rejected_button',
  translation_subtask_details_assign_translator_drawer_requested_internal_deadline:
    'translation_subtask_details_assign_translator_drawer_requested_internal_deadline',
  translation_subtask_details_assign_translator_drawer_requested_label:
    'translation_subtask_details_assign_translator_drawer_requested_label',
  translation_subtask_details_assign_translator_drawer_search_placeholder:
    'translation_subtask_details_assign_translator_drawer_search_placeholder',
  translation_subtask_details_assign_translator_drawer_select_button:
    'translation_subtask_details_assign_translator_drawer_select_button',
  translation_subtask_details_assign_translator_drawer_subtasks_label:
    'translation_subtask_details_assign_translator_drawer_subtasks_label',
  translation_subtask_details_assign_translator_drawer_title:
    'translation_subtask_details_assign_translator_drawer_title',
  translation_subtask_details_assign_translator_no_files:
    'translation_subtask_details_assign_translator_no_files',
  translation_subtask_details_cancelled_banner:
    'translation_subtask_details_cancelled_banner',
  translation_subtask_details_customer_description_header:
    'translation_subtask_details_customer_description_header',
  translation_subtask_details_customer_emails_button:
    'translation_subtask_details_customer_emails_button',
  translation_subtask_details_deadline_request_label:
    'translation_subtask_details_deadline_request_label',
  translation_subtask_details_delivery_date_label:
    'translation_subtask_details_delivery_date_label',
  translation_subtask_details_delivery_header:
    'translation_subtask_details_delivery_header',
  translation_subtask_details_edit_button:
    'translation_subtask_details_edit_button',
  translation_subtask_details_external_deadline_label:
    'translation_subtask_details_external_deadline_label',
  translation_subtask_details_in_progress_header:
    'translation_subtask_details_in_progress_header',
  translation_subtask_details_internal_deadline_label:
    'translation_subtask_details_internal_deadline_label',
  translation_subtask_details_internal_review_header:
    'translation_subtask_details_internal_review_header',
  translation_subtask_details_locked_banner:
    'translation_subtask_details_locked_banner',
  translation_subtask_details_no_comments:
    'translation_subtask_details_no_comments',
  translation_subtask_details_salita_quote_label:
    'translation_subtask_details_salita_quote_label',
  translation_subtask_details_seller_quote_label:
    'translation_subtask_details_seller_quote_label',
  translation_subtask_details_seller_word_count_label:
    'translation_subtask_details_seller_word_count_label',
  translation_subtask_details_short_notice_label:
    'translation_subtask_details_short_notice_label',
  translation_subtask_details_source_files_header:
    'translation_subtask_details_source_files_header',
  translation_subtask_details_status_accepted:
    'translation_subtask_details_status_accepted',
  translation_subtask_details_status_cancelled:
    'translation_subtask_details_status_cancelled',
  translation_subtask_details_status_changed_required:
    'translation_subtask_details_status_changed_required',
  translation_subtask_details_status_draft:
    'translation_subtask_details_status_draft',
  translation_subtask_details_status_finished:
    'translation_subtask_details_status_finished',
  translation_subtask_details_status_in_progress:
    'translation_subtask_details_status_in_progress',
  translation_subtask_details_status_invited:
    'translation_subtask_details_status_invited',
  translation_subtask_details_status_rejected:
    'translation_subtask_details_status_rejected',
  translation_subtask_details_status_review_requested:
    'translation_subtask_details_status_review_requested',
  translation_subtask_details_subtask_emails_label:
    'translation_subtask_details_subtask_emails_label',
  translation_subtask_details_translator_emails_button:
    'translation_subtask_details_translator_emails_button',
  translation_subtask_details_translator_review_header:
    'translation_subtask_details_translator_review_header',
  translation_subtask_details_unassign_seller_button:
    'translation_subtask_details_unassign_seller_button',
  translation_subtask_edit_drawer_cancel_button:
    'translation_subtask_edit_drawer_cancel_button',
  translation_subtask_edit_drawer_delivery_date_label:
    'translation_subtask_edit_drawer_delivery_date_label',
  translation_subtask_edit_drawer_delivery_date_placeholder:
    'translation_subtask_edit_drawer_delivery_date_placeholder',
  translation_subtask_edit_drawer_external_deadline_hint:
    'translation_subtask_edit_drawer_external_deadline_hint',
  translation_subtask_edit_drawer_external_deadline_label:
    'translation_subtask_edit_drawer_external_deadline_label',
  translation_subtask_edit_drawer_internal_deadline_label:
    'translation_subtask_edit_drawer_internal_deadline_label',
  translation_subtask_edit_drawer_salita_quote_label:
    'translation_subtask_edit_drawer_salita_quote_label',
  translation_subtask_edit_drawer_save_button:
    'translation_subtask_edit_drawer_save_button',
  translation_subtask_edit_drawer_seller_quote_label:
    'translation_subtask_edit_drawer_seller_quote_label',
  translation_subtask_edit_drawer_seller_word_count_label:
    'translation_subtask_edit_drawer_seller_word_count_label',
  translation_subtask_edit_drawer_seller_word_count_placeholder:
    'translation_subtask_edit_drawer_seller_word_count_placeholder',
  translation_subtask_edit_drawer_short_notice_checkbox:
    'translation_subtask_edit_drawer_short_notice_checkbox',
  translation_subtask_edit_drawer_short_notice_tag:
    'translation_subtask_edit_drawer_short_notice_tag',
  translation_subtask_edit_drawer_title:
    'translation_subtask_edit_drawer_title',
  translation_subtask_edit_drawer_type_correction:
    'translation_subtask_edit_drawer_type_correction',
  translation_subtask_edit_drawer_type_in_person:
    'translation_subtask_edit_drawer_type_in_person',
  translation_subtask_edit_drawer_type_label:
    'translation_subtask_edit_drawer_type_label',
  translation_subtask_edit_drawer_type_translation:
    'translation_subtask_edit_drawer_type_translation',
  translation_subtask_emails_cancellation_label:
    'translation_subtask_emails_cancellation_label',
  translation_subtask_emails_changes_required_label:
    'translation_subtask_emails_changes_required_label',
  translation_subtask_emails_confirmation_label:
    'translation_subtask_emails_confirmation_label',
  translation_subtask_emails_emails_label:
    'translation_subtask_emails_emails_label',
  translation_subtask_emails_invited_label:
    'translation_subtask_emails_invited_label',
  translation_subtask_emails_not_sent_label:
    'translation_subtask_emails_not_sent_label',
  translation_subtask_emails_resend_button:
    'translation_subtask_emails_resend_button',
  translation_subtask_emails_send_button:
    'translation_subtask_emails_send_button',
  translation_subtask_emails_sent_at_label:
    'translation_subtask_emails_sent_at_label',
  translation_subtask_emails_title: 'translation_subtask_emails_title',
  translation_subtask_emails_translator_label:
    'translation_subtask_emails_translator_label',
  translation_subtask_salita_instructions_cancel_button:
    'translation_subtask_salita_instructions_cancel_button',
  translation_subtask_salita_instructions_description:
    'translation_subtask_salita_instructions_description',
  translation_subtask_salita_instructions_description_placeholder:
    'translation_subtask_salita_instructions_description_placeholder',
  translation_subtask_salita_instructions_save_button:
    'translation_subtask_salita_instructions_save_button',
  translation_subtask_salita_instructions_title:
    'translation_subtask_salita_instructions_title',
  translation_subtask_salita_review_cancel_button:
    'translation_subtask_salita_review_cancel_button',
  translation_subtask_salita_review_description:
    'translation_subtask_salita_review_description',
  translation_subtask_salita_review_description_placeholder:
    'translation_subtask_salita_review_description_placeholder',
  translation_subtask_salita_review_save_button:
    'translation_subtask_salita_review_save_button',
  translation_subtask_salita_review_title:
    'translation_subtask_salita_review_title',
  translation_subtask_type_translation: 'translation_subtask_type_translation',
  translation_subtasks_all_tab: 'translation_subtasks_all_tab',
  translation_subtasks_created_at_filter_placeholder:
    'translation_subtasks_created_at_filter_placeholder',
  translation_subtasks_created_at_header:
    'translation_subtasks_created_at_header',
  translation_subtasks_deadline_passed_text:
    'translation_subtasks_deadline_passed_text',
  translation_subtasks_deadline_time_left:
    'translation_subtasks_deadline_time_left',
  translation_subtasks_document_attached:
    'translation_subtasks_document_attached',
  translation_subtasks_documents_attached_tooltip:
    'translation_subtasks_documents_attached_tooltip',
  translation_subtasks_documents_header:
    'translation_subtasks_documents_header',
  translation_subtasks_empty_state_subtitle:
    'translation_subtasks_empty_state_subtitle',
  translation_subtasks_empty_state_title:
    'translation_subtasks_empty_state_title',
  translation_subtasks_error_state_subtitle:
    'translation_subtasks_error_state_subtitle',
  translation_subtasks_error_state_title:
    'translation_subtasks_error_state_title',
  translation_subtasks_external_deadline_filter_placeholder:
    'translation_subtasks_external_deadline_filter_placeholder',
  translation_subtasks_external_deadline_header:
    'translation_subtasks_external_deadline_header',
  translation_subtasks_id_filter_placeholder:
    'translation_subtasks_id_filter_placeholder',
  translation_subtasks_id_header: 'translation_subtasks_id_header',
  translation_subtasks_internal_deadline_filter_placeholder:
    'translation_subtasks_internal_deadline_filter_placeholder',
  translation_subtasks_internal_deadline_header:
    'translation_subtasks_internal_deadline_header',
  translation_subtasks_language_filter_placeholder:
    'translation_subtasks_language_filter_placeholder',
  translation_subtasks_language_header: 'translation_subtasks_language_header',
  translation_subtasks_load_more_button:
    'translation_subtasks_load_more_button',
  translation_subtasks_next_week_tab: 'translation_subtasks_next_week_tab',
  translation_subtasks_no_document_attached:
    'translation_subtasks_no_document_attached',
  translation_subtasks_not_documents_tooltip:
    'translation_subtasks_not_documents_tooltip',
  translation_subtasks_notes_header: 'translation_subtasks_notes_header',
  translation_subtasks_order_filter_tooltip:
    'translation_subtasks_order_filter_tooltip',
  translation_subtasks_owner_filter_placeholder:
    'translation_subtasks_owner_filter_placeholder',
  translation_subtasks_owner_header: 'translation_subtasks_owner_header',
  translation_subtasks_status_accepted: 'translation_subtasks_status_accepted',
  translation_subtasks_status_applied: 'translation_subtasks_status_applied',
  translation_subtasks_status_cancelled:
    'translation_subtasks_status_cancelled',
  translation_subtasks_status_changes_requested:
    'translation_subtasks_status_changes_requested',
  translation_subtasks_status_draft: 'translation_subtasks_status_draft',
  translation_subtasks_status_filter_placeholder:
    'translation_subtasks_status_filter_placeholder',
  translation_subtasks_status_finished: 'translation_subtasks_status_finished',
  translation_subtasks_status_header: 'translation_subtasks_status_header',
  translation_subtasks_status_in_progress:
    'translation_subtasks_status_in_progress',
  translation_subtasks_status_invited: 'translation_subtasks_status_invited',
  translation_subtasks_status_proactive:
    'translation_subtasks_status_proactive',
  translation_subtasks_status_rejected: 'translation_subtasks_status_rejected',
  translation_subtasks_status_review_requested:
    'translation_subtasks_status_review_requested',
  translation_subtasks_status_transfered:
    'translation_subtasks_status_transfered',
  translation_subtasks_status_updated_by_customer:
    'translation_subtasks_status_updated_by_customer',
  translation_subtasks_status_waiting_for_customer:
    'translation_subtasks_status_waiting_for_customer',
  translation_subtasks_this_month_tab: 'translation_subtasks_this_month_tab',
  translation_subtasks_this_week_tab: 'translation_subtasks_this_week_tab',
  translation_subtasks_title: 'translation_subtasks_title',
  translation_subtasks_translator_filter_placeholder:
    'translation_subtasks_translator_filter_placeholder',
  translation_subtasks_translator_header:
    'translation_subtasks_translator_header',
  translation_subtasks_updated_by_customer_tooltip:
    'translation_subtasks_updated_by_customer_tooltip',
  translation_subtasks_updated_label: 'translation_subtasks_updated_label',
  translation_subtasks_waiting_for_customer_tooltip:
    'translation_subtasks_waiting_for_customer_tooltip',
  translation_subtasks_waiting_label: 'translation_subtasks_waiting_label',
  translation_subtasks_word_count_filter_placeholder:
    'translation_subtasks_word_count_filter_placeholder',
  translation_subtasks_word_count_header:
    'translation_subtasks_word_count_header',
  translation_task_details_add_subtask_button:
    'translation_task_details_add_subtask_button',
  translation_task_details_create_label:
    'translation_task_details_create_label',
  translation_task_details_subtasks_empty_state:
    'translation_task_details_subtasks_empty_state',
  translation_task_details_subtasks_header:
    'translation_task_details_subtasks_header',
  translation_task_details_task_label: 'translation_task_details_task_label',
  translation_task_status_cancelled: 'translation_task_status_cancelled',
  translation_task_status_draft: 'translation_task_status_draft',
  translation_task_status_finished: 'translation_task_status_finished',
  translation_task_status_published: 'translation_task_status_published',
  wrong_reference: 'wrong_reference',
  dropdown_menu_load_more: 'dropdown_menu_load_more',
  create_interpretation_order_settings_qualification_subtitle_plura:
    'create_interpretation_order_settings_qualification_subtitle_plura',
  job_requirements_card_gender_labe: 'job_requirements_card_gender_labe',
} as const;

export default translationKeys;

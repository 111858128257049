import * as yup from 'yup';

const statementSchema = () => {
  return yup.object().shape({
    chargeAs: yup.string().nullable(),
    orderMethod: yup.string().nullable(),
    orderedDate: yup.string().nullable(),
    orderedTime: yup.object().nullable(),
    fees: yup.array().nullable(),
    chargeable: yup.boolean().nullable(),
    payable: yup.boolean().nullable(),
    demanderShowedUp: yup.boolean().nullable(),
    demanderInvoiceDiscountPercentage: yup.number().nullable(),
    demanderActualStartTime: yup.object().nullable(),
    demanderActualFinishTime: yup.object().nullable(),
    demanderDelayCausedBy: yup.string().nullable(),
    demanderTravelExpensesAmountOverride: yup.number().nullable(),
    demanderTravelDietEnabled: yup.boolean().nullable(),
    demanderTravelType: yup.string().nullable(),
    demanderTravelDistance: yup.string().nullable(),
    demanderTravelTime: yup.string().nullable(),
    supplierShowedUp: yup.boolean().nullable(),
    supplierInvoiceDiscountPercentage: yup.number().nullable(),
    supplierActualStartTime: yup.object().nullable(),
    supplierActualFinishTime: yup.object().nullable(),
    supplierDelayCausedBy: yup.string().nullable(),
    supplierTravelExpensesAmountOverride: yup.number().nullable(),
    supplierTravelDietEnabled: yup.boolean().nullable(),
    supplierTravelType: yup.string().nullable(),
    supplierTravelDistance: yup.string().nullable(),
    supplierTravelTime: yup.string().nullable(),
  });
};

export default statementSchema;

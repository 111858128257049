import React, { useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, DatePicker, TextField, TimePicker } from '@skiwo/components';
import { TimePickerItem } from '@skiwo/components/src/TimePicker/TimePicker';
import { format } from 'date-fns';
import translationKeys from '../../translations/translationKeys';
import styles from './ManualBookingSection.module.scss';

interface ManualBookingConfirmationsProps {
  deadlineConfirmationActive?: boolean;
  confirmationByDate?: Date;
  confirmationByTime?: TimePickerItem;
  setDeadlineConfirmationActive: (value: boolean) => void;
  setConfirmationByDate: (date: Date) => void;
  setConfirmationByTime: (time: TimePickerItem) => void;
  hasConfirmationError?: boolean;
  confirmationError?: string;
}

export const ManualBookingConfirmations = ({
  deadlineConfirmationActive,
  confirmationByDate,
  confirmationByTime,
  setDeadlineConfirmationActive,
  setConfirmationByDate,
  setConfirmationByTime,
  hasConfirmationError,
  confirmationError,
}: ManualBookingConfirmationsProps) => {
  const intl = useIntl();
  const [showDeadlineDatePicker, setShowDeadlineDatePicker] = useState(false);

  return (
    <div className={styles.manualBookingSection}>
      <span className={styles.label}>
        <FormattedMessage
          id={translationKeys.create_interpretation_order_manual_booking_confirmations}
        />
      </span>
      <div className={styles.radioItem}>
        <B_Form.Check
          data-testid="deadline-confirmation"
          type="checkbox"
          label={intl.formatMessage({
            id: translationKeys.create_interpretation_order_manual_booking_set_deadline_checkbox,
          })}
          name="deadlineConfirmation"
          checked={deadlineConfirmationActive}
          onChange={(e) => setDeadlineConfirmationActive(e.target.checked)}
        />
      </div>
      {deadlineConfirmationActive && (
        <div className={styles.flexRow}>
          <div>
            <TextField
              data-testid="confirmation-by-date"
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_manual_booking_date_label,
              })}
              placeholder={intl.formatMessage({
                id: translationKeys.create_interpretation_order_manual_booking_date_placeholder,
              })}
              icon={<FontAwesomeIcon icon={faCalendar} />}
              value={confirmationByDate && format(confirmationByDate, 'dd.MM.yyyy')}
              size="large"
              onFocus={() => setShowDeadlineDatePicker(true)}
              type="search"
              isInvalid={hasConfirmationError}
              readOnly
            />
            {showDeadlineDatePicker && (
              <DatePicker
                monthCount={1}
                onClose={() => setShowDeadlineDatePicker(false)}
                singleDate
                onChange={(date) => {
                  setConfirmationByDate(date);
                  setShowDeadlineDatePicker(false);
                }}
                selected={confirmationByDate}
                minDate={new Date()}
              />
            )}
          </div>

          <div className={styles.timePicker}>
            <TimePicker
              data-testid="confirmation-by-time"
              label={intl.formatMessage({
                id: translationKeys.create_interpretation_order_manual_booking_time_label,
              })}
              placeholder="00:00"
              interval={1}
              selected={confirmationByTime ? [confirmationByTime] : []}
              onChange={(item) => {
                if (item && item.length > 0) {
                  setConfirmationByTime(item[0]);
                }
              }}
              isInvalid={hasConfirmationError}
            />
          </div>
        </div>
      )}
      {hasConfirmationError && (
        <Banner data-testid="deadline-banner" variant="error" text={confirmationError} />
      )}
    </div>
  );
};

import {
  JobCategoriesResponse,
  JobNoteResponse,
  ManagerJobsResponse,
  ManagerJobStats,
} from '../../../types';
import { JobInvoice } from '../../../types/JobInvoice';
import {
  ManagerJobCancellationReason,
  ManagerJobCancelResponse,
  ManagerJobDetails,
  ManagerJobDowngradeRequirement,
  ManagerJobResponse,
} from '../../../types/ManagerJob';
import { ManagerJobStatement, ManagerJobUpdateStatement } from '../../../types/ManagerJobStatement';
import fetchApi, { UrlParams } from '../../fetchApi';

export default {
  createJob: (body: FormData) => {
    return fetchApi<ManagerJobResponse>(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs`,
        body,
      },
      true,
    );
  },
  updateJob: (id: number, body: FormData) => {
    return fetchApi<ManagerJobResponse>(
      {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}`,
        body,
      },
      true,
    );
  },
  cancelJob: (
    id: number,
    body?: {
      reason: ManagerJobCancellationReason;
      other: boolean;
      description?: string;
      send_email: boolean;
      cancellation_date?: Date;
      admin_other?: string;
      real_person_name?: string;
    },
  ) => {
    // TODO: update type
    return fetchApi<ManagerJobCancelResponse>(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}/cancel`,
        body,
      },
      true,
    );
  },
  getJobs: (urlParams: UrlParams, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerJobsResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/dashboards/manager_jobs`,
      urlParams,
      loadingCallback,
    });
  },
  getJob: (id: string) => {
    return fetchApi<ManagerJobDetails>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}`,
      },
      true,
    );
  },
  checkForDuplicateJobIds: (body: FormData) => {
    return fetchApi<{
      ids: number[];
    }>({
      method: 'POST',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/duplicate_job_ids`,
      body,
    });
  },
  getJobsStats: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerJobStats>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/dashboards/manager_job_stats`,
        loadingCallback,
      },
      true,
    );
  },
  getCategories: (loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<JobCategoriesResponse>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/job_categories`,
      loadingCallback,
    });
  },
  getJobNotes: (jobId: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<JobNoteResponse>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/manager_chat/messages`,
        loadingCallback,
      },
      true,
    );
  },
  postJobNote: (jobId: string, note: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/manager_chat/messages`,
        body: { message: { text: note } },
        loadingCallback,
      },
      true,
    );
  },
  updateJobNote: (
    jobId: string,
    noteId: string,
    note: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'PATCH',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/manager_chat/messages/${noteId}`,
      body: { message: { text: note } },
      loadingCallback,
    });
  },
  deleteJobNote: (
    jobId: string,
    noteId: string,
    loadingCallback?: (isLoading: boolean) => void,
  ) => {
    return fetchApi({
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/manager_chat/messages/${noteId}`,
      loadingCallback,
    });
  },
  getJobStatement: (id: string, loadingCallback?: (isLoading: boolean) => void) => {
    return fetchApi<ManagerJobStatement>({
      method: 'GET',
      url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${id}/statements`,
      loadingCallback,
    });
  },
  updateJobStatement: (
    jobId: string,
    statementId: string,
    statement: Partial<ManagerJobUpdateStatement>,
  ) => {
    return fetchApi(
      {
        method: 'PUT',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/statements/${statementId}`,
        body: statement,
      },
      true,
    );
  },
  getJobDemanderInvoice: (jobId: string) => {
    return fetchApi<JobInvoice>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/invoices/demander_invoice`,
      },
      true,
    );
  },
  getJobSupplierInvoice: (jobId: string) => {
    return fetchApi<JobInvoice>(
      {
        method: 'GET',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/invoices/supplier_invoice`,
      },
      true,
    );
  },
  lockJob: (jobId: string) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/lock`,
      },
      true,
    );
  },
  downgradeJobRequirement: (
    jobId: string,
    downgradeRequirement: ManagerJobDowngradeRequirement,
  ) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/downgrade_requirement`,
        body: {
          requirementParameter: downgradeRequirement,
        },
      },
      true,
    );
  },
  setReadyForFinance: (jobId: string, reason?: string) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/set_ready_for_finance`,
        body: { reason },
      },
      true,
    );
  },
  unsetReadyForFinance: (jobId: string) => {
    return fetchApi(
      {
        method: 'POST',
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/managers/jobs/${jobId}/set_not_ready_for_finance`,
      },
      true,
    );
  },
};

import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { Avatar } from '@skiwo/components';
import { FormikProps, FormikValues } from 'formik';
import { useGetCustomersQuery } from '../../Api/Endpoints/Customers/Customers.hooks';
import { useGetCustomerDetailsQuery } from '../../Api/Endpoints/Customers/Customers.hooks';
import mapManagerCustomerToOption from '../../helpers/mapManagerCustomerToOption';
import useDebounce from '../../hooks/useDebounce';
import usePagination from '../../hooks/usePagination';
import translationKeys from '../../translations/translationKeys';
import { ManagerCustomer } from '../../types';
import CustomerSearchDropdown, {
  CustomerSearchDropdownMenuOption,
} from '../CustomerSearchDropdown/CustomerSearchDropdown';
import CustomerSkeleton from './CustomerSkeleton/CustomerSkeleton';
import styles from './SearchCustomerSection.module.scss';

interface SearchCustomerSectionProps<V extends FormikValues> {
  setCustomer: (customer: CustomerShape) => void;
  formikProps: FormikProps<V>;
}

export interface CustomerShape {
  id?: number;
  uid: string;
  name: string;
  email: string;
  phone?: string | null;
  phoneNumber?: string | null;
  isShared: boolean;
  defaultBookingReference?: string | null;
  defaultPaymentBookingReference?: string | null;
  emailVerified?: boolean;
  enterprise?: {
    id?: number | string;
    name?: string;
    logoUrl?: string | null;
    defaultBookingReference?: string | null;
    defaultPaymentBookingReference?: string | null;
  };
  departments?: {
    id: number;
    name: string;
  }[];
}

const CUSTOMER_ID_PARAM_NAME = 'customeruid';

const SearchCustomerSection = <V extends FormikValues>({
  setCustomer,
  formikProps,
}: SearchCustomerSectionProps<V>) => {
  const intl = useIntl();
  const [customerResults, setCustomerResults] = useState<ManagerCustomer[]>([]);
  const [customerOptions, setCustomerOptions] = useState<CustomerSearchDropdownMenuOption[]>([]);
  const { pagination, setPagination, resetPagination } = usePagination();
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const debounceCustomerSearch = useDebounce(300);
  const [searchParams] = useSearchParams();
  const customerUidParam = searchParams.get(CUSTOMER_ID_PARAM_NAME);

  const {
    data: customerData,
    isError: isGetCustomerDataError,
    isLoading: isGetCustomerDataLoading,
  } = useGetCustomerDetailsQuery(
    {
      customerUid: customerUidParam,
    },
    {
      enabled: !!customerUidParam,
    },
  );

  const isGetCustomersQueryEnabled = customerUidParam ? isGetCustomerDataError : true;

  const { data: topListCustomerData, isLoading: isTopListCustomerLoading } = useGetCustomersQuery(
    {
      's[enterprise_members]': true,
      's[s]': 'orders_count desc',
      items: 4,
    },
    {
      enabled: isGetCustomersQueryEnabled,
    },
  );

  const { data, isLoading: isGetCustomersLoading } = useGetCustomersQuery(
    {
      's[enterprise_members]': true,
      's[lookup_fields_or_enterprise_or_departments_cont]': debouncedQuery,
      page: pagination.page > 0 ? pagination.page : 1,
    },
    { enabled: isGetCustomersQueryEnabled },
  );

  useEffect(() => {
    if (!data) return;

    const customerOptions =
      data.customers.map((customer) => mapManagerCustomerToOption(customer, intl)) || [];
    const appendData = data.page > 1;

    setCustomerResults(appendData ? (prev) => [...prev, ...data.customers] : data.customers);
    setCustomerOptions(appendData ? (prev) => [...prev, ...customerOptions] : customerOptions);
    setPagination({ page: data.page, totalPages: data.pages });
  }, [data]);

  const handleCustomerSelect = (customers: CustomerSearchDropdownMenuOption[] | null) => {
    const selectedCustomer = customerResults.find(
      (customerResult) => customerResult.id === customers?.[0].id,
    );

    if (!selectedCustomer) return;
    setCustomer(selectedCustomer);
    formikProps.setFieldValue('customerId', selectedCustomer.id.toString());
  };

  useEffect(() => {
    if (!customerData) return;

    setCustomer({
      id: Number(customerData.id),
      uid: customerData.uid,
      departments: customerData.departments,
      isShared: !!customerData.isShared,
      name: customerData.name,
      email: customerData.email,
      phone: customerData.phone,
      phoneNumber: customerData.phoneNumber,
      defaultBookingReference: customerData.defaultBookingReference,
      defaultPaymentBookingReference: customerData.defaultPaymentBookingReference,
      enterprise: customerData.enterprise,
    });
  }, [customerData]);

  return (
    <div data-testid="search-customer-section">
      <div data-testid="customer-search-dropdown">
        <CustomerSearchDropdown
          options={customerOptions}
          data-testid="customer-search-input"
          onSearch={(query) => {
            resetPagination();
            debounceCustomerSearch(() => {
              setDebouncedQuery(query);
            });
          }}
          onLoadMore={() => {
            setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
          }}
          isLoadingMore={isGetCustomersLoading}
          pagination={pagination}
          onChange={handleCustomerSelect}
          placeholder={intl.formatMessage({
            id: translationKeys.search_customer_placeholder,
          })}
        />

        <span className={styles.sectionLabel}>
          <FormattedMessage id={translationKeys.search_customer_top_four_label} />
        </span>
        <div className={styles.customers} data-testid="search-customer-top-4-section">
          {isTopListCustomerLoading || isGetCustomerDataLoading ? (
            <>
              <CustomerSkeleton />
              <CustomerSkeleton />
              <CustomerSkeleton />
              <CustomerSkeleton />
            </>
          ) : (
            topListCustomerData?.customers.map((customer) => (
              <button
                key={customer.id}
                className={styles.option}
                data-testid="search-customer-top-4-option"
                type="button"
                onClick={() => {
                  setCustomer(customer);
                  formikProps.setFieldValue('customerId', customer.id.toString());
                }}
              >
                <Avatar url={customer.enterprise?.logoUrl} altText={customer.name} size="medium" />
                <div className={styles.infoDetails}>
                  <span className={styles.title}>{customer.name}</span>
                  <span className={styles.subtitle}>{customer.enterprise?.name || '-'}</span>
                </div>
              </button>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchCustomerSection;

import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import {
  faBuilding,
  faEllipsis,
  faEnvelope,
  faLanguage,
  faMessages,
  faPen,
  faPersonMilitaryToPerson,
  faPhone,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Dropdown, IconButton, Tag, Tooltip } from '@skiwo/components';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { format } from 'date-fns';
import {
  customersQueryKeys,
  useGetCustomerDetailsQuery,
} from '../../../Api/Endpoints/Customers/Customers.hooks';
import EditEmployeeDrawer from '../../../components/EditEmployeeDrawer/EditEmployeeDrawer';
import MergeCustomersDrawer from '../../../components/MergeCustomersDrawer/MergeCustomersDrawer';
import MoveMemberDrawer from '../../../components/MoveMemberDrawer/MoveMemberDrawer';
import { getAssignmentTypeIcon } from '../../../CreateInterpretationOrder/utils';
import { DeleteCustomerModal } from '../../../Customers/Modals/DeleteCustomerModal/DeleteCustomerModal';
import routes from '../../../helpers/routes';
import getJobStatusName from '../../../Jobs/utils/getJobStatusName';
import translationKeys from '../../../translations/translationKeys';
import { ManagerCustomerType } from '../../../types/ManagerCustomer';
import BookerHoverCardSkeleton from './BookerHoverCardSkeleton';
import styles from './BookerHoverCard.module.scss';

interface BookHoverCardProps {
  customerUid: string | undefined;
  onComplete?: (hardReload?: boolean) => void;
  children: React.ReactElement;
}

enum DrawerName {
  EditEmployeeDrawer = 'edit-employee-drawer',
  MoveMemberDrawer = 'move-member-drawer',
  MergeCustomersDrawer = 'merge-customers-drawer',
  DeleteCustomerModal = 'delete-customer-drawer',
}

export default function BookerHoverCard({ customerUid, onComplete, children }: BookHoverCardProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [openedDrawer, setOpenedDrawer] = useState<DrawerName | null>(null);
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { data: customer } = useGetCustomerDetailsQuery(
    {
      customerUid: customerUid,
    },
    {
      enabled: isHovered,
    },
  );

  const handleComplete = (hardReload?: boolean) => {
    onComplete?.(hardReload);
    queryClient.invalidateQueries({
      queryKey: customersQueryKeys.customer(customerUid),
    });
  };

  const handleCloseDrawer = () => setOpenedDrawer(null);
  const isDrawerOpen = (drawerName: DrawerName) => openedDrawer === drawerName;

  const getDepartmentsLabel = () => {
    if (!customer?.departments) return;

    if (customer.departments.length === 1) return customer.departments[0].name;

    return intl.formatMessage(
      {
        id: translationKeys.global_departments_number,
      },
      { departmentsNumber: customer?.departments?.length },
    );
  };

  return (
    <>
      <OverlayTrigger
        placement="top"
        onToggle={setIsHovered}
        show={isHovered}
        offset={[0, 0]}
        overlay={(props) => (
          <Popover
            {...props}
            className={styles.overlayCardWrapper}
            style={{
              // eslint-disable-next-line react/prop-types
              ...(props.style ?? {}),
              borderRadius: 0,
              border: 0,
              backgroundColor: 'transparent',
            }}
            arrowProps={{
              style: {
                display: 'none',
              },
            }}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
          >
            {customer ? (
              <div className={styles.overlayCard}>
                <div className={styles.bookerHoverCardTop}>
                  <div className={styles.bookerHoverCardIconRow}>
                    <Avatar
                      size="medium"
                      className={styles.bookerHoverCardAvatar}
                      url={customer.avatar}
                      altText={`${customer.name} avatar`}
                    />
                    {customer?.email && (
                      <Tooltip title={customer.email} copyable>
                        <IconButton icon={<FontAwesomeIcon icon={faEnvelope} />} />
                      </Tooltip>
                    )}
                    {customer?.phone && (
                      <Tooltip title={customer.phone} copyable>
                        <IconButton icon={<FontAwesomeIcon icon={faPhone} />} />
                      </Tooltip>
                    )}
                    <>
                      <IconButton
                        hoverText={intl.formatMessage({
                          id: translationKeys.job_book_interpretation_tooltip_label,
                        })}
                        icon={<FontAwesomeIcon icon={faMessages} />}
                        href={`${routes.createInterpretationOrder}?customeruid=${customerUid}`}
                      />
                      <IconButton
                        hoverText={intl.formatMessage({
                          id: translationKeys.job_book_translation_tooltip_label,
                        })}
                        icon={<FontAwesomeIcon icon={faLanguage} />}
                        href={`${routes.createTranslationOrder}?customeruid=${customerUid}`}
                      />
                      <Dropdown
                        toggle={<IconButton icon={<FontAwesomeIcon icon={faEllipsis} />} />}
                        onSelect={(action) => {
                          setOpenedDrawer(action as DrawerName);
                          setIsHovered(false);
                        }}
                        items={[
                          {
                            id: DrawerName.EditEmployeeDrawer,
                            text: intl.formatMessage({
                              id: translationKeys.job_customer_edit_customer_label,
                            }),
                            icon: faPen,
                            'data-testId': 'customer-action-edit-customer',
                          },
                          {
                            id: DrawerName.MoveMemberDrawer,
                            text: intl.formatMessage({
                              id: translationKeys.job_customer_change_enterprise_label,
                            }),
                            icon: faBuilding,
                            disabled: customer.type !== ManagerCustomerType.Enterprise,
                            'data-testId': 'customer-action-move',
                          },
                          {
                            id: DrawerName.MergeCustomersDrawer,
                            text: intl.formatMessage({
                              id: translationKeys.job_customer_merge_customers_label,
                            }),
                            icon: faPersonMilitaryToPerson,
                            disabled: customer.type !== ManagerCustomerType.Enterprise,
                            'data-testId': 'customer-action-merge',
                          },
                          {
                            id: DrawerName.DeleteCustomerModal,
                            text: intl.formatMessage({
                              id: translationKeys.job_customer_delete_customer_label,
                            }),
                            icon: faTrash,
                            disabled: customer.hasOngoingAssignments,
                            tooltip: customer.hasOngoingAssignments
                              ? intl.formatMessage({
                                  id: translationKeys.job_customer_delete_customer_tooltip,
                                })
                              : undefined,
                            variant: 'critical',
                            'data-testId': 'customer-action-delete',
                          },
                        ]}
                      />
                    </>
                  </div>
                  <div>
                    <div className={styles.bookerHoverCardFullName}>{customer.name}</div>
                    <div>
                      {[customer?.enterprise?.name, getDepartmentsLabel()]
                        .filter(Boolean)
                        .join(', ')}
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.bookerHoverCardBottom,
                    !customer.statistics.upcomingJob &&
                      styles['bookerHoverCardBottom--is-centered'],
                  )}
                >
                  {customer.statistics?.upcomingJob ? (
                    <>
                      <FontAwesomeIcon
                        icon={getAssignmentTypeIcon(customer.statistics.upcomingJob.sessionType)}
                      />
                      <span>{`${customer.statistics.upcomingJob.id} • ${
                        customer.statistics.upcomingJob.languageTo
                      } • ${format(
                        new Date(customer.statistics.upcomingJob.startTime),
                        'dd.MM.yyyy, HH:mm',
                      )} - ${format(
                        new Date(customer.statistics.upcomingJob.finishTime),
                        'HH:mm',
                      )}`}</span>
                      <Tag
                        className={styles.bookerHoverCardTag}
                        color="neutral"
                        size="small"
                        variant="square"
                      >
                        {getJobStatusName(customer.statistics.upcomingJob.status, intl)}
                      </Tag>
                    </>
                  ) : (
                    intl.formatMessage({
                      id: translationKeys.job_customer_no_upcoming_jobs_label,
                    })
                  )}
                </div>
              </div>
            ) : (
              <BookerHoverCardSkeleton />
            )}
          </Popover>
        )}
      >
        {children}
      </OverlayTrigger>
      {!!customer && (
        <>
          <EditEmployeeDrawer
            customer={customer}
            show={isDrawerOpen(DrawerName.EditEmployeeDrawer)}
            onClose={handleCloseDrawer}
            onComplete={handleComplete}
          />
          <MoveMemberDrawer
            customer={customer}
            show={isDrawerOpen(DrawerName.MoveMemberDrawer)}
            onClose={handleCloseDrawer}
            onComplete={handleComplete}
          />
          <MergeCustomersDrawer
            customer={customer}
            show={isDrawerOpen(DrawerName.MergeCustomersDrawer)}
            onClose={handleCloseDrawer}
            onComplete={handleComplete}
          />
          <DeleteCustomerModal
            customer={customer}
            show={isDrawerOpen(DrawerName.DeleteCustomerModal)}
            onClose={handleCloseDrawer}
            onComplete={handleComplete}
          />
        </>
      )}
    </>
  );
}

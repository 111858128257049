import { IntlShape } from 'react-intl';
import { BooleanValueVariant } from '../components/BooleanValue/BooleanValue';
import translationKeys from '../translations/translationKeys';

export const getBooleanLabel = (value: boolean, intl: IntlShape, variant?: BooleanValueVariant) => {
  if (variant === BooleanValueVariant.YesNo) {
    return value
      ? intl.formatMessage({
          id: translationKeys.global_yes_label,
        })
      : intl.formatMessage({
          id: translationKeys.global_no_label,
        });
  }

  return value
    ? intl.formatMessage({
        id: translationKeys.global_on_label,
      })
    : intl.formatMessage({
        id: translationKeys.global_off_label,
      });
};

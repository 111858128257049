import { IntlShape } from 'react-intl';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobStatementTravelType } from '../../../../types/ManagerJobStatement';

export const getTravelMethodLabel = (
  travelMethod: ManagerJobStatementTravelType,
  intl: IntlShape,
) => {
  switch (travelMethod) {
    case ManagerJobStatementTravelType.Car:
      return intl.formatMessage({ id: translationKeys.job_statement_travel_method_car });

    case ManagerJobStatementTravelType.None:
      return intl.formatMessage({ id: translationKeys.job_statement_travel_method_none });

    case ManagerJobStatementTravelType.PublicTransport:
      return intl.formatMessage({
        id: translationKeys.job_statement_travel_method_public_transport,
      });
  }
};

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Banner, Stepper } from '@skiwo/components';
import { format } from 'date-fns';
import { useGetJobQuery } from '../../Api/Endpoints/Jobs/Jobs.hooks';
import getJobStatusName from '../../Jobs/utils/getJobStatusName';
import translationKeys from '../../translations/translationKeys';
import { ManagerJobRiskStatus, ManagerJobStatus } from '../../types/ManagerJob';
import useGetJobIdFromParam from '../hooks/useGetJobIdFromParam';
import getCancellationReason from '../utils/getCancellationReason';
import { jobSectionVisibilityGuard } from '../utils/jobSectionVisibilityGuard';
import { ActionButtonsSection } from './ActionButtonsSection/ActionButtonsSection';
import FinanceSection from './FinanceSection/FinanceSection';
import InterpreterCard from './InterpreterCard/InterpreterCard';
import InterpretersSection from './InterpretersSection/InterpretersSection';
import { JobDetailsDrawerProvider } from './JobDetailsDrawerContext/JobDetailsDrawerContext';
import JobFooter from './JobFooter/JobFooter';
import RequirementsSection from './RequirementsSection/RequirementsSection';
import { RiskFactorsSection } from './RiskFactorsSection/RiskFactorsSection';
import Statement from './Statement/Statement';
import InfoSection from './InfoSection';
import styles from './JobDetails.module.scss';

export const JobDetails = () => {
  const intl = useIntl();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  if (!job) return null;

  const statuses = [
    ManagerJobStatus.Published,
    ManagerJobStatus.Applied,
    ManagerJobStatus.Accepted,
    ManagerJobStatus.InProgress,
    ManagerJobStatus.Finished,
  ];

  const getCancelledStatuses = () => {
    if (!job.cancellation) return [];
    const lastStatusIndex = statuses.indexOf(job.cancellation?.previousJobState);

    if (lastStatusIndex !== -1) {
      return [...statuses.slice(0, lastStatusIndex + 1), ManagerJobStatus.Cancelled].map((status) =>
        getJobStatusName(status, intl),
      );
    } else {
      return [getJobStatusName(ManagerJobStatus.Cancelled, intl)];
    }
  };

  return (
    <JobDetailsDrawerProvider>
      <section className={styles.orderSection}>
        {job.cancellation && (
          <Banner
            variant="error"
            text={
              <span className={styles.cancelBannerText}>
                <FormattedMessage
                  id={translationKeys.job_cancelled_banner}
                  values={{
                    author: (
                      <span className={styles[`cancelBannerText--bold`]}>
                        {job.cancellation.realPersonName || job.cancellation.canceller?.name}
                      </span>
                    ),
                    date: (
                      <span className={styles[`cancelBannerText--bold`]}>
                        {job.cancellation.cancellationDate
                          ? format(new Date(job.cancellation.cancellationDate), 'dd.MM.yyyy HH:mm')
                          : format(new Date(job.cancellation.createdAt), 'dd.MM.yyyy HH:mm')}
                      </span>
                    ),
                    reason: (
                      <span className={styles[`cancelBannerText--bold`]}>
                        {job.cancellation.description ||
                          getCancellationReason(job.cancellation.reason, intl)}
                      </span>
                    ),
                  }}
                />
              </span>
            }
          />
        )}
        <Stepper
          steps={
            job.status === ManagerJobStatus.Cancelled
              ? getCancelledStatuses()
              : statuses.map((status) => getJobStatusName(status, intl))
          }
          currentStep={
            job.status === ManagerJobStatus.Cancelled
              ? getCancelledStatuses().length
              : statuses.indexOf(job.status) + 1
          }
          variant={job.status === ManagerJobStatus.Cancelled ? 'error' : 'primary'}
        />
        <InfoSection />
        <ActionButtonsSection />
        {job.risk.status !== ManagerJobRiskStatus.NoRisk && <RiskFactorsSection risk={job.risk} />}
        <RequirementsSection />
        {jobSectionVisibilityGuard({
          currentStatus: job.status,
          statuses: [
            ManagerJobStatus.Accepted,
            ManagerJobStatus.InProgress,
            ManagerJobStatus.Finished,
          ],
        }) && <InterpreterCard />}
        {jobSectionVisibilityGuard({
          currentStatus: job.status,
          statuses: [
            ManagerJobStatus.Published,
            ManagerJobStatus.Applied,
            ManagerJobStatus.Accepted,
            ManagerJobStatus.InProgress,
          ],
        }) && <InterpretersSection />}
        {jobSectionVisibilityGuard({
          currentStatus: job.status,
          statuses: [ManagerJobStatus.Finished, ManagerJobStatus.Cancelled],
        }) && <Statement />}
        {jobSectionVisibilityGuard({
          currentStatus: job.status,
          statuses: [
            ManagerJobStatus.Accepted,
            ManagerJobStatus.InProgress,
            ManagerJobStatus.Finished,
            ManagerJobStatus.Cancelled,
          ],
        }) && <FinanceSection />}
        <JobFooter />
      </section>
    </JobDetailsDrawerProvider>
  );
};

export default JobDetails;

import React from 'react';
import { useIntl } from 'react-intl';
import { faFile, faLanguage, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@skiwo/components';
import { getFormattedAddress } from '@skiwo/utils';
import { useGetJobQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import { useApi } from '../../../providers/ApiProvider';
import translationKeys from '../../../translations/translationKeys';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import EditInformationFormDrawer from '../EditInformationFormDrawer/EditInformationFormDrawer';
import {
  JobDetailsDrawerName,
  useJobDetailsDrawer,
} from '../JobDetailsDrawerContext/JobDetailsDrawerContext';
import Card from './Card/Card';

const InformationCard = () => {
  const intl = useIntl();
  const api = useApi();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const { openDrawer } = useJobDetailsDrawer();

  if (!job) return null;

  const handleOpenDrawer = () => openDrawer(JobDetailsDrawerName.EditInformationDrawer);

  const filteredAttachments =
    job.attachments?.filter(
      (attachment) => attachment.fileName !== null && attachment.fileUrl !== null,
    ) || [];

  return (
    <>
      <Card
        titleIcon={<FontAwesomeIcon icon={faLanguage} />}
        title={intl.formatMessage({ id: translationKeys.job_information_label })}
        emptyText={intl.formatMessage({ id: translationKeys.job_information_empty_text })}
        handleEdit={handleOpenDrawer}
      >
        {job.contact && (
          <Card.BodyItem
            title={intl.formatMessage({ id: translationKeys.job_information_address_label })}
          >
            {getFormattedAddress(job.contact)}
          </Card.BodyItem>
        )}

        {job.contact?.extra && (
          <Card.BodyItem
            title={intl.formatMessage({ id: translationKeys.job_information_address_desc_label })}
          >
            {job.contact.extra}
          </Card.BodyItem>
        )}

        {job.contact && (
          <Card.BodyItem
            title={intl.formatMessage({ id: translationKeys.job_information_contact_person_label })}
          >
            <Card.ItemRow>
              {job.contact.phone && (
                <Tooltip title={job.contact.phone} copyable>
                  <FontAwesomeIcon icon={faPhone} />
                </Tooltip>
              )}
              {job.contact.name}
            </Card.ItemRow>
          </Card.BodyItem>
        )}
        {job.info.description && (
          <Card.BodyItem
            title={intl.formatMessage({ id: translationKeys.job_information_message_label })}
          >
            {job.info.description}
          </Card.BodyItem>
        )}
        {filteredAttachments.length > 0 && (
          <Card.BodyItem
            title={
              intl.formatMessage({ id: translationKeys.job_information_files_label }) +
              ` (${filteredAttachments.length})`
            }
          >
            {filteredAttachments.map((attachment) => (
              <Card.Button
                key={attachment.fileUrl}
                icon={<FontAwesomeIcon icon={faFile} />}
                onClick={async () => {
                  const { data } = await api.downloadFileUrlWithToken(attachment.fileUrl);

                  if (data) {
                    window.open(data, '_blank');
                  }
                }}
              >
                {attachment.fileName}
              </Card.Button>
            ))}
          </Card.BodyItem>
        )}
        {job.blockedInterpreters && job.blockedInterpreters.length > 0 && (
          <Card.BodyItem
            title={intl.formatMessage({
              id: translationKeys.job_information_blocked_interpreters_label,
            })}
          >
            {job.blockedInterpreters.map(({ name }) => name).join(', ')}
          </Card.BodyItem>
        )}
        {job.blockedCities && job.blockedCities.length > 0 && (
          <Card.BodyItem
            title={intl.formatMessage({ id: translationKeys.job_information_blocked_cities_label })}
          >
            {job.blockedCities
              .map(({ name, country }) => getFormattedAddress({ city: name, country }))
              .join(' • ')}
          </Card.BodyItem>
        )}
      </Card>
      <EditInformationFormDrawer job={job} />
    </>
  );
};

export default InformationCard;

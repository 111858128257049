import React, { useEffect, useState } from 'react';
import { Tab, Tabs as B_Tabs } from 'react-bootstrap';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import Badge from '../Badge/Badge';
import styles from './Tabs.module.scss';

export interface TabItem {
  id: string;
  title: string;
  icon?: IconDefinition;
  count?: number;
  disabled?: boolean;
  active?: boolean;
}

interface TabsProps {
  items: TabItem[];
  onSelect?: (activeTab: string) => void;
  'data-testid'?: string;
}

const Tabs = (props: TabsProps) => {
  const { items, onSelect } = props;
  const defaultActiveTab = items.find((items) => items.active) || items[0];
  const [activeTab, setActiveTab] = useState(defaultActiveTab.id);

  useEffect(() => {
    if (onSelect) {
      onSelect(defaultActiveTab.id);
    }
  }, []);

  useEffect(() => {
    setActiveTab(defaultActiveTab.id);
  }, [items]);

  const handleSelectTab = (tabId: string | null) => {
    if (tabId) {
      setActiveTab(tabId);

      if (onSelect) {
        onSelect(tabId);
      }
    }
  };

  return (
    <B_Tabs
      variant="pills"
      className={styles.tabs}
      activeKey={activeTab}
      onSelect={handleSelectTab}
      data-testid={props['data-testid']}
    >
      {items.map((item) => {
        const { id, title, icon, count, disabled } = item;

        const getBadgeVariant = () => {
          if (disabled) return 'disabled';
          if (activeTab === id) return 'active';

          return undefined;
        };

        const formatCount = (count: number) => {
          if (count >= 1000) {
            return `${(count / 1000).toFixed(1)}k`;
          }
          return count;
        };

        return (
          <Tab
            data-testid="tab-item"
            key={id}
            eventKey={id}
            tabClassName={classnames({ [styles.active]: activeTab === id })}
            title={
              <>
                {icon && (
                  <FontAwesomeIcon className={styles.icon} icon={icon} data-testid="tab-icon" />
                )}
                <span data-testid="tab-title">{title}</span>
                {count != null && (
                  <Badge variant={getBadgeVariant()} data-testid="tab-badge">
                    {formatCount(count)}
                  </Badge>
                )}
              </>
            }
            disabled={disabled}
          />
        );
      })}
    </B_Tabs>
  );
};

export default Tabs;

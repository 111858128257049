import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag, TimePicker } from '@skiwo/components';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { getTypesafeSetFieldValue } from '../../../../helpers/getTypesafeSetFieldValue';
import translationKeys from '../../../../translations/translationKeys';
import { StatementFormValues } from '../Statement';
import styles from './StatementForm.module.scss';
interface ActualStartRowProps {
  jobStart: string;
  demanderFeedbackActualStartTime?: string;
  supplierFeedbackActualStartTime?: string;
  isFeedbackLocked: boolean;
  readonly?: boolean;
}

export const ActualStartRow = ({
  jobStart,
  demanderFeedbackActualStartTime,
  supplierFeedbackActualStartTime,
  isFeedbackLocked,
  readonly,
}: ActualStartRowProps) => {
  const formikProps = useFormikContext<StatementFormValues>();
  const { values } = formikProps;
  const setFieldValue = getTypesafeSetFieldValue(formikProps);
  const formatDate = (date?: string) => (date ? format(new Date(date), 'HH:mm') : '-');

  return (
    <tr>
      <td>
        <span className={classNames(styles.rowName, styles.rowNameFlex)}>
          <FormattedMessage id={translationKeys.job_statement_actual_start} />
          <Tag variant="square" size="small" color="neutral">
            <span className={styles.dateTag}>{format(new Date(jobStart), 'HH:mm')}</span>
          </Tag>
        </span>
      </td>
      <td>{formatDate(demanderFeedbackActualStartTime)}</td>
      <td>{formatDate(supplierFeedbackActualStartTime)}</td>
      {isFeedbackLocked && (
        <>
          <td>
            {readonly ? (
              values.demanderActualStartTime?.label || '-'
            ) : (
              <div className={styles.input}>
                <TimePicker
                  size="medium"
                  hideIcon
                  selected={values.demanderActualStartTime ? [values.demanderActualStartTime] : []}
                  onChange={(item) =>
                    item && item.length && setFieldValue('demanderActualStartTime', item[0])
                  }
                  disabled={!isFeedbackLocked}
                />
              </div>
            )}
          </td>
          <td>
            {readonly ? (
              values.supplierActualStartTime?.label || '-'
            ) : (
              <div className={styles.input}>
                <TimePicker
                  size="medium"
                  hideIcon
                  selected={values.supplierActualStartTime ? [values.supplierActualStartTime] : []}
                  onChange={(item) =>
                    item && item.length && setFieldValue('supplierActualStartTime', item[0])
                  }
                  disabled={!isFeedbackLocked}
                />
              </div>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export default ActualStartRow;

import { useQuery } from '@tanstack/react-query';
import Finance from './Finance';

export const financeQueryKeys = {
  all: ['finance'] as const,
  paymentMethods: ['paymentMethods'] as const,
  demanderPricingTemplates: ['demanderPricingTemplates'] as const,
  supplierPricingTemplates: ['supplierPricingTemplates'] as const,
};

export const useGetDemanderPricingTemplatesQuery = () =>
  useQuery({
    queryKey: financeQueryKeys.demanderPricingTemplates,
    queryFn: () => {
      return Finance.getDemanderPricingTemplates().then((res) => res.data);
    },
  });

export const useGetSupplierPricingTemplatesQuery = () =>
  useQuery({
    queryKey: financeQueryKeys.supplierPricingTemplates,
    queryFn: () => {
      return Finance.getSupplierPricingTemplates().then((res) => res.data);
    },
  });

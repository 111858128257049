export const appendNestedFormDataObjectWithoutEmptyValues = (
  formData: FormData,
  data: any,
  prefix?: string,
) => {
  Object.keys(data).forEach((key) => {
    const value = data[key];
    const newKey = prefix ? `${prefix}[${key}]` : key;
    if (value !== undefined && value !== null && value !== '') {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          if (typeof item === 'object' && item !== null) {
            appendNestedFormDataObjectWithoutEmptyValues(formData, item, `${newKey}[]`);
          } else {
            formData.append(`${newKey}[]`, item);
          }
        });
      } else if (typeof value === 'object' && value !== null) {
        appendNestedFormDataObjectWithoutEmptyValues(formData, value, newKey);
      } else {
        formData.append(newKey, value);
      }
    }
  });
};

export const appendNestedFormObjectWithEmptyValues = (
  formData: FormData,
  data: any,
  prefix?: string,
) => {
  Object.keys(data).forEach((key) => {
    const value = data[key] ?? "";
    const newKey = prefix ? `${prefix}[${key}]` : key;
    const isArray = Array.isArray(value);

    if (isArray && value.length > 0) {
      value.forEach((item) => {
        if (typeof item === 'object' && item !== null) {
          appendNestedFormObjectWithEmptyValues(formData, item, `${newKey}[]`);
        } else {
          formData.append(`${newKey}[]`, item);
        }
      });
    } else if (isArray && value.length === 0) {
      formData.append(`${newKey}[]`, '');
    } else if (typeof value === 'object' && value !== null) {
      appendNestedFormObjectWithEmptyValues(formData, value, newKey);
    } else {
      formData.append(newKey, value);
    }
  });
};
